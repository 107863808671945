import React, { useState } from 'react';
import arrowUpIcon from "../../../images/svg/arrowCircleUpAction.svg";
import arrowDownIcon from "../../../images/svg/arrowCircleDownAction.svg";
import selectedCheckboxIcon from "../../../images/svg/selectedCheckbox.svg";
import unselectedCheckboxIcon from "../../../images/svg/unselectedCheckbox.svg";
import Input from './Input';

const PermissionsBoxCreateExternalDocumentModal = () => {
    const [isArrowUp, setIsArrowUp] = useState(true);
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);

    const toggleArrowIcon = () => {
        setIsArrowUp((prevValue) => !prevValue);
    };

    const toggleCheckbox1 = () => {
        setIsChecked1((prevValue) => !prevValue);
    };

    const toggleCheckbox2 = () => {
        setIsChecked2((prevValue) => !prevValue);
    };

    const container = {
        width: "100%",
        borderBottom: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    };

    const styleTitle = {
        margin: "0",
        fontSize: "17px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleInfosBox = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "20px",
    };

    const styleCheckboxTextBox = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
    };

    const styleTextInfos = {
        fontSize: "14px",
        color: "var(--black)",
    };

    return (
        <div style={container}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }} onClick={toggleArrowIcon}>
                <img src={isArrowUp ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                <h3 style={styleTitle}>Permissões & Avisos</h3>
            </div>
            {!isArrowUp && (
                <div style={{ margin: "20px 0 0 27px" }}>

                    <div style={styleInfosBox} onClick={toggleCheckbox1}>
                        <div style={styleCheckboxTextBox}>
                            <img src={isChecked1 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
                            <p style={styleTextInfos}>Aviso de publicação e edição por e-mail</p>
                        </div>
                        <Input
                            width="400px"
                            marginBottom="0"
                        />
                    </div>
                    <div style={styleInfosBox} onClick={toggleCheckbox2}>
                        <div style={styleCheckboxTextBox}>
                            <img src={isChecked2 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
                            <p style={styleTextInfos}>Quem pode acessar</p>
                        </div>
                        <Input
                            width="400px"
                            marginBottom="0"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PermissionsBoxCreateExternalDocumentModal;
