import flagDownIcon from "../../../images/svg/flagDown.svg"

const PendingIndicators = () => {
    const container = {
        width: "999px",
        height: "73px",
        borderRadius: "10px",
        border: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleIndicatorBox = {
        gap: "10px",
        display: "flex",
        alignItems: "center"
    };

    const styleIndicator = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        fontWeight: 500,
    };

    return (
        <div style={container}>
            <div style={{...styleIndicatorBox, width: "105px"}}>
                <h4 style={styleIndicator}>Status</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{...styleIndicatorBox, width: "152px"}}>
                <h4 style={styleIndicator}>Pendência</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{...styleIndicatorBox, width: "188px"}}>
                <h4 style={styleIndicator}>Documento</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{...styleIndicatorBox, width: "171px"}}>
                <h4 style={styleIndicator}>Processo</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{...styleIndicatorBox, width: "256px"}}>
                <h4 style={styleIndicator}>Responsável</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{...styleIndicatorBox, width: "72px"}}>
                <h4 style={styleIndicator}>Prazo</h4>
                <img src={flagDownIcon} />
            </div>
        </div>
    );
};

export default PendingIndicators;