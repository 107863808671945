import React, { useContext } from "react";
import ModalContainer from "./ModalContainer";
import ModalFilterCards from "./ModalFilterCards";
import closeIcon from '../../../images/svg/closeIcon.svg';
import ContainerControllerExternalDocuments from "./ContainerControllerExternalDocuments";
import { ExternalDocumentsContext } from "../../../context/Quality/ExternalDocumentsContext";
import ModalColumnsCard from "./ModalColumnsCard";
import DocumentIndicators from "./DocumentIndicators";
import { externalDocuments } from "./mocks/externalDocumentsMock";
import DocumentCard from "./DocumentCard";
import ModalCreateDocument from "./ModalCreateDocument";
import FloatButtonBox from "./FloatButtonBox";

const ContainerContentExternalDocumentPage = () => {

  const { modalFilterCardsOpen, modalColumnsCardOpen, handleModalClose, modalCreateDocumentOpen } = useContext(ExternalDocumentsContext);

  return (
    <div style={{ margin: "0 auto" }}>

      <ContainerControllerExternalDocuments />

      <div style={{ marginTop: "40px" }}>
        <DocumentIndicators />
        <ul style={{ margin: "0", padding: "0" }}>
          {externalDocuments.map((document) => <DocumentCard key={document.id} document={document} />)}
        </ul>
      </div>

      <ModalContainer isActive={modalColumnsCardOpen} closeModal={handleModalClose}>
        <ModalColumnsCard
          closeModal={handleModalClose}
          closeIcon={closeIcon}
        />
      </ModalContainer>

      <ModalContainer isActive={modalFilterCardsOpen} closeModal={handleModalClose}>
        <ModalFilterCards
          closeModal={handleModalClose}
          closeIcon={closeIcon}
        />
      </ModalContainer>


      <ModalContainer isActive={modalCreateDocumentOpen} closeModal={handleModalClose}>
        <ModalCreateDocument
          closeModal={handleModalClose}
          closeIcon={closeIcon}
        />
      </ModalContainer>

      <FloatButtonBox />
    </div>
  );
};

export default ContainerContentExternalDocumentPage;
