import React, { useContext, useState } from "react";
import profile from "../../../images/svg/profile.svg";
import send from "../../../images/png/send.png";
import attachmentFile from "../../../images/svg/clip-archive.svg";
import { DashContext } from "../../../context/Dashboard/DashContext";
import jwt_decode from "jwt-decode";


const FooterMensageInput = (
  {
    name,
    response,
    paramId,
    subCommentType,
    setSubCommentType,
    commentId
  }) => {

  const { createComments, createSubComments } = useContext(DashContext);
  const [comment, setComment] = useState("");
  const [fileInfo, setFileInfo] = useState({ file: "", file_type: "" });

  const sendMensage = () => {
    const data = { message: comment, parameter_id: paramId };
    if (fileInfo.file) {
      data.file = fileInfo.file;
      data.file_type = fileInfo.file_type;
    }
    setComment("");
    setFileInfo({ file: "", file_type: "" });
    const decoded = jwt_decode(localStorage.getItem("@XPM_CLIENT_TOKEN:"));
    if (subCommentType) {
      data.comment_id = commentId;
      createSubComments(data, paramId, decoded.id);
      setSubCommentType(false);
    } else {
      createComments(data, paramId, decoded.id);
    };
  };

  const attachFile = () => {
    const fileInput = document.getElementById('attachment-file');
    fileInput.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function(event) {
          const fileContentBase64 = event.target.result.split(',')[1];
          console.log(fileContentBase64);
          const fileType = file.type;
          setFileInfo({ file: fileContentBase64, file_type: fileType });
      };
      reader.readAsDataURL(file);
    }
  };

  /* STYLES */
  const containerStyle = {
    borderTop: "1px solid #d7d7d7",
    borderRadius: "10px",
    height: "100%",
    backgroundColor: "#fff",
  };

  const boxResponse = {
    width: "100%",
    backgroundColor: "#d7d7d7",
    position: "absolute",
    top: "-18px",
    padding: "2px 18px",
  };

  const innerContainerStyle = {
    padding: "14px 14px",
  };

  const inputContainerStyle = {
    border: "1px solid #d7d7d7",
    padding: "4px 10px 4px 4px",
    borderRadius: "60px",
    gap: "20px",
    display: "flex",
    alignItems: "center",
  };

  const profileImageStyle = {
    border: "1px solid #d7d7d7",
    borderRadius: "60px",
    width: "35px",
    objectFit: "contain",
  };

  const inputStyle = {
    outline: "none",
    border: "none",
    width: "100%",
    fontSize: "14px",
  };

  const sendImageStyle = {
    height: "15px",
    cursor: "pointer",
    filter: 'brightness(0)' 
  };

  const sendImageStyleFile = {
    height: "15px",
    cursor: "pointer",
    filter: 'brightness(1)' 
  };

  const attachmentStyle = {
    display: 'none',
  }

  return (
    <div style={{ position: "relative", zIndex: 2 }}>
      {response ? (
        <div style={boxResponse}>
          <p className="textResponseComments">
            Responder: <strong>@{name}</strong>
          </p>
        </div>
      ) : (
        <></>
      )}
      <div style={containerStyle}>
        <div style={innerContainerStyle}>
          <div style={inputContainerStyle}>
            <img src={profile} style={profileImageStyle} alt="Profile" />
            <input
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  sendMensage();
                  e.target.blur();
                }
              }}
              placeholder="Faça um comentário"
              style={inputStyle}
            />
            <input
              id="attachment-file"
              type="file"
              style={attachmentStyle}
              onChange={handleChange}
            />
            <img
              onClick={attachFile}
              src={attachmentFile}
              style={fileInfo.file ? sendImageStyleFile : sendImageStyle}
              alt="Attachement"
            />
            <img
              onClick={sendMensage}
              src={send}
              style={sendImageStyle}
              alt="Send"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMensageInput;
