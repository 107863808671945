import React from "react";

const Input = ({
    name,
    type,
    placeholder,
    onChange,
    width,
    error,
    height,
    marginLeft,
    color,
    onBlur,
    value,
    marginBottom,
    marginRight,
}) => {
    const containerInput = {
        display: "flex",
        flexDirection: "column",
        marginLeft: marginLeft,
        marginRight: marginRight,
        width: width,
        position: "relative",
    };

    const styleInput = {
        width: width != undefined ? width : "418px",
        color: color != undefined ? color : "",
        height: height != undefined ? height : "50px",
        borderRadius: "10px",
        border: "1px solid #D7D7D7",
        marginBottom: marginBottom ? marginBottom : "1.9rem",
        outline: "none",
    };

    const styleLabel = {
        marginBottom: "14.43px",
    };

    const styleError = {
        position: "absolute",
        bottom: 0,
        left: 0,
    };

    return (
        <div style={containerInput}>
            <label className="textPrimaryLabelInput" style={styleLabel}>
                {name}
            </label>
            <input
                onBlur={onBlur}
                className="textPrimaryInput"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                type={type}
                style={styleInput}
            />
            {error != undefined && (
                <p className="textErrorInput" style={styleError}>
                    {error}
                </p>
            )}
        </div>
    );
};

export default Input;
