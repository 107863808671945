import React from 'react';
import checkOnSquareIcon from '../../../images/svg/checkOnSquareIcon.svg';
import checkOffSquereIcon from '../../../images/svg/checkOffSquareIcon.svg';

const CategoriesSelect = ({
  selectedCategories,
  setSelectedCategories,
  setModalCategories,
  modalCategories,
  typeCategoryAll,
  topModal,
  leftModal,
  modalId,
  paramId,
  deleteParamCategory,
  createParamCategory,
}) => {

  const containerStyles = {
    width: '100%',
    display: 'flex',
    gap: 13,
    flexDirection: 'column',
    position: 'relative'
  };

  const modalStyles = {
    position: 'absolute',
    top: topModal,
    left: leftModal,
    backgroundColor: '#F9F3FF',
    padding: '10px 10px',
    border: '1px solid #9966CC',
    borderRadius: 10,
    color: '#9966CC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 200,
    fontSize: 13,
    zIndex: 10,
    gap: 5,
  };

  const inputStyles = {
    width: '100%',
    height: 50,
    border: '1px solid #D7D7D7',
    borderRadius: 10,
    padding: 10,
  };

  const labelStyles = {
    display: 'flex',
    gap: 5,
  };

  const handleChange = ({ target }, categoryName, categoryCode) => {
    const { name } = target;
    // const value = target.type === 'checkbox' ? target.checked : target.value;
    const newArray = [...selectedCategories] || [];
    if (target.checked) {
      const newObj = {
        name: name.trim(),
        code: categoryCode.trim(),
      }
      newArray.push(newObj);
      setSelectedCategories(newArray);
      if (paramId) {
        createParamCategory(categoryName, categoryCode, paramId)
      }
    } else {
      const filtered = newArray.filter((e) => e.name !== name);
      const existing = newArray.filter((e) => e.name === name);
      if (existing.length > 0) {
        deleteParamCategory(existing[0].id);
      }
      setSelectedCategories(filtered);
    }
  };

  return (
    <div style={containerStyles} id={modalId}>
      <p className="textTitleParms">Categorias</p>
      <input
        type="text"
        style={inputStyles}
        value={`${selectedCategories.length} Selecionadas`}
        onClick={() => setModalCategories(!modalCategories)}
        // onClick={() => console.log('clicou')}
        readOnly
      />
      
      {
        modalCategories ? (
          <div
            style={modalStyles}
          >
            {
              typeCategoryAll.map((category) => (
                <label style={labelStyles}>
                  <input
                    type="checkbox"
                    onChange={(e) => handleChange(e, category.des_categoria.trim(), category.cat_item.trim())}
                    name={category.des_categoria.trim()}
                    checked={selectedCategories.map((e) => e.name).includes(category.des_categoria.trim())}
                    style={{display: 'none'}}
                  />
                  {
                    selectedCategories.map((e) => e.name).includes(category.des_categoria.trim()) ? (
                      <img src={checkOnSquareIcon} width={15} />
                    ) : <img src={checkOffSquereIcon} width={15} />
                  }
                  <p>{category.des_categoria.trim()}</p>
                </label>
              ))
            }
          </div>
        ) : null
      }
    </div>
  )
};

export default CategoriesSelect;