import React from "react";
import FeedbackGeneralBox from "./FeedbackGeneralBox";
import FeedbackQuestionBox from "./FeedbackQuestionBox";

const CardAnswersAndComments = ({ companyName, comment, feedbackGeneralNumber, question1, answer1, feedbackNumberQuestion1, question2, answer2, feedbackNumberQuestion2 }) => {
    const styleCard = {
        borderBottom: "1px solid var(--grey-0)",
        marginBottom: "20px"
    };

    const styleHeaderCard = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    };

    const styleImageCompanyNameBox = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    };

    const styleImageBox = {
        width: "33px",
        height: "33px",
        overflow: "hidden",
        border: "2px solid var(--grey-0)",
        borderRadius: "100%"
    };

    const styleImage = {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        // borderRadius: "100%" // Parece que não vai precisar, mas analisar se vai precisar
    };

    const styleCompanyName = {
        fontSize: "14px",
        fontWeight: 500,
        color: "var(--black)",
        marginLeft: "10px"
    };

    const styleComment = {
        fontSize: "13px",
        fontWeight: 400,
        color: "var(--black)",
        lineHeight: "20px",
        margin: "20px 0"
    };

    const styleQuestionAnswerBox = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }

    const styleQuestion = {
        fontSize: "14px",
        fontWeight: 500,
        color: "var(--grey-1)",
        lineHeight: "19px",
    };

    const styleAnswer = {
        fontSize: "14px",
        fontWeight: 400,
        color: "var(--black)",
        lineHeight: "21px",
        marginBottom: "15px"
    };

    return (
        <div style={styleCard}>
            <div style={styleHeaderCard}>
                <div style={styleImageCompanyNameBox}>
                    <div style={styleImageBox}>
                        <img style={styleImage} alt={`Logo da ${companyName}`} />
                    </div>
                    <p style={styleCompanyName}>{companyName}</p>
                </div>
                <FeedbackGeneralBox number={feedbackGeneralNumber} />
            </div>
            <p style={styleComment}>{comment}</p>
            <div style={styleQuestionAnswerBox}>
                <div>
                    <p style={styleQuestion}>{question1}</p>
                    <p style={styleAnswer}>{answer1}</p>
                </div>
                <FeedbackQuestionBox number={feedbackNumberQuestion1} />
            </div>
            <div style={styleQuestionAnswerBox}>
                <div>
                    <p style={styleQuestion}>{question2}</p>
                    <p style={styleAnswer}>{answer2}</p>
                </div>
                <FeedbackQuestionBox number={feedbackNumberQuestion2} />
            </div>
        </div>
    );
};

export default CardAnswersAndComments;
