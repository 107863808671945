import React, { useEffect, useState } from 'react'
import BoxParameterSelect from './BoxParameterSelect'
import { set } from 'react-hook-form';

const NewVariableCard = ({params, variables, setVariables, updatedVariables, index, deleteIcon, setUpdatedVariables, handleDelete, variable, type, handleChange}) => {

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <BoxParameterSelect
        valueInput={variable.process || 'selecione'}
        // onChange={(e) => setProcess(e.target.value)}
        onBlur={(e) => handleChange(e, index, type)}
        width={"200px"}
        wait={true}
        // number={index + 1}
        type={"process"}
        name="process"
      />
      <BoxParameterSelect
        valueInput={variable.name || 'selecione'}
        // onChange={(e) => setName(e.target.value)}
        onBlur={(e) => handleChange(e, index, type)}
        width={"200px"}
        wait={true}
        // number={index + 1}
        type={"paramsSelector"}
        options={params.filter((e) => e.process === variable.process).map((param) => param.name)}
        name="name"
      />
      <BoxParameterSelect
        width={"100%"}
        // onChange={(e) => handleChange(e, index, type)}
        // onBlur={(e) => handleChange(e, index, type)}
        type={"custo"}
        valueInput={Number(variable.value)?.toLocaleString('pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              }
            )}
        name="value"
      />
      <button style={{background: 'none', border: 'none', height: '100%', display: 'flex', marginTop: 40}} onClick={() => handleDelete(type, index, variable.id)}>
        <img src={deleteIcon} alt="delete" />
      </button>
    </div>
  )
}

export default NewVariableCard