import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderManager from "../components/HeaderManager";
import Sidebar from "../../../components/Sidebar";
import BoxContainerShedules from "../components/BoxContainerShedules";

const Scheduling = () => {
  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };
  return (
    <ThowColumLayout
      page={"Company"}
      colum1={<Sidebar page="Shedules" />}
      colum2={
        <div style={container}>
          <HeaderManager name="Agendamento"/>
          <BoxContainerShedules
            width={'100%'}
          />
        </div>
      }
    ></ThowColumLayout>
  );
};

export default Scheduling;
