/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import api from "../../../services/api";
import { DashContext } from "../../../context/Dashboard/DashContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CardCompanyDetails = ({ update, value, clientsSelect, setClientsSelect }) => {
  const { updateFunction } = useContext(DashContext);
  const [clientsAll, setClientsAll] = useState([]);
  const [status, setStatus] = useState('');

  const navigate = useNavigate();

  const getClientBillingMonth = async () => {
    try {
      const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
      const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
      const date = `${year}-${month}`;
      const cnpj = localStorage.getItem('@XPM_CLIENT_COMPANY:');
      const response = await api.get(`/billing/client/${cnpj}/${date}`);
      if(response.data.payment_made) {
        setStatus("Fatura paga");
      } else if(response.data.checked_by_client) {
        setStatus("Fatura aprovada");
      } else if(response.data.checked_by_admin) {
        setStatus("Fatura conferida");
      } else if(!response.data.checked_by_admin) {
        setStatus("Aguardando conferência");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (error?.response.status === 401) {
        localStorage.removeItem('@XPM_CLIENT_COMPANY:');
        localStorage.removeItem('@XPM_ADM_TOKEN:');
        localStorage.removeItem('@XPM_CLIENT_MONTH_COMMENT:');
        navigate("/");
      }
    }
  };

  const getClients = async () => {
    try {
      const response = await api.get("/clients");
      setClientsAll(response.data);

      if (localStorage.getItem("@XPM_CLIENT_COMPANY:")) {
        const filter = response.data.filter(
          (item) => item.cnpj === localStorage.getItem("@XPM_CLIENT_COMPANY:")
        );
        console.log(filter);
        setClientsSelect(filter[0]);
      } else {
        setClientsSelect(response.data[0]);

        localStorage.setItem("@XPM_CLIENT_COMPANY:", response.data[0].cnpj);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getClients();
    getClientBillingMonth();
  }, []);

  const userInfo = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    width: "280px",
    background: "white",
    border: "1px solid #d8d8d8",
    borderRadius: "60px",
    padding: "6px",
    paddingLeft: "20px",
    height: "40px",
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
        width: "319px",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <p className="textNameCompanyCard">{ (clientsSelect.name)}</p>

      <div
        style={{
          marginTop: "17px",
          display: "flex",
          flexDirection: "column",
          gap: "17px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="textTitleValue">Valor a pagar</p>
          <p className="textContentValue">{value}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="textTitleValue">Status</p>
          <p className="textStatusValue">{status}</p>
        </div>
      </div>
    </div>
  );
};

export default CardCompanyDetails;