import { useContext, useEffect, useState } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import CardTableListDash from "./CardTableListDash";
import PageNumber from "./PageNumber";
import ScrollBotton from "./ScrollBotton";
import Loading from "./Loading";
import CardDateYearAlternative from "./CardDateYearAlternative";
import Button from "./Button";
import { CSVLink, CSVDownload } from "react-csv";
import FilterPage from "./FilterPage";
import ModalContainer from "./ModalContainer";
import ModalFilterDetailsDahboard from "./ModalFilterDetailsDahboard";
import createExcelFileHeader from "../../../utils/craeteExcelFileHeaders";

const ContainerOutbound = () => {
  const {
    getExitByMonthAndYear,
    exitDetails,
    getExitByPage,
    dowloadExcel,
    resultFile,
    setResultFile,
    dowloadExcelXLSX,
  } = useContext(GraphsAndParametersContext);

  const [status, setStatus] = useState("");

  const [daysOrHours, setDaysOrHours] = useState("");

  const [justification, setJustification] = useState("");

  const [isActive, setIsActive] = useState(false);

  const [listItens, setListItens] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const [btd, setBtd] = useState(true);

  let div = document.getElementById("test");

  const syncScroll = () => {
    div = document.getElementById("test");

    // Iterar sobre os elementos do loop e definir o scroll esquerdo igual ao do elemento principal
    if (listItens && listItens.length) {
      listItens.forEach((item) => {
        let divLoop = document.getElementById(item);

        if (divLoop) {
          divLoop.scrollLeft = div.scrollLeft;
        }
      });
    }
  };

  const nextPage = async () => {
    setLoading(true);
    const page = currentPage + 1;
    setCurrentPage(currentPage + 1);

    await getExitByPage(
      page,
      status,
      justification,
      daysOrHours
    );
    setLoading(false);
  };

  const backPage = async () => {
    setLoading(true);
    const page = currentPage - 1;
    setCurrentPage(currentPage - 1);

    await getExitByPage(
      page,
      status,
      justification,
      daysOrHours
    );
    setLoading(false);
  };

  const searchByMonth = async () => {
    setLoading(true);
    setCurrentPage(1);
    await getExitByPage(1, status, justification, daysOrHours);
    setLoading(false);
  };

  const getExcel = async () => {
    setBtd(false);

    const data = await getExitByMonthAndYear();
    const excelData = await dowloadExcelXLSX("Saida", data);

    createExcelFileHeader(
      excelData.data,
      excelData.filename,
      `${excelData.filename}.xlsx`,
      excelData.headers
    );

    setBtd(true);
  };

  useEffect(() => {
    if (!exitDetails.length) {
      getExitByPage(1);
    }
  }, []);

  useEffect(() => {
    const div = document.getElementById("test");
    if (div && listItens) {
      div.addEventListener("scroll", syncScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", syncScroll);
      }
    };
  }, [listItens]);

  useEffect(() => {
    if (resultFile.headers.length > 0) {
      const button = document.getElementById("downloadOrders");
      button.click();

      setResultFile({
        filename: "report.csv",
        headers: "",
        data: "",
      });
    }
  }, [resultFile]);

  return (
    <div
      style={{
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "30px",
          alignItems: "center",
        }}
      >
        <CardDateYearAlternative search={searchByMonth} />
        <PageNumber
          currentPage={currentPage}
          backPage={backPage}
          nextPage={nextPage}
          limitPage={exitDetails.totalPages}
        />

        <FilterPage onClick={() => setIsActive(true)} />
        <Button
          padding={"0 20px"}
          height="35px"
          background={"var(--color-primary)"}
          borderRadius="8px"
          marginTop="0"
          name={"Exportar dados"}
          color={"#fff"}
          border="none"
          onClick={() => getExcel()}
          disabled={!btd}
        />

        <CSVLink id="downloadOrders" {...resultFile} />
      </div>

      {loading ? (
        <Loading />
      ) : exitDetails?.data?.length > 0 ? (
        exitDetails?.data?.map((elem) => {
          return (
            <CardTableListDash
              setListItens={setListItens}
              dataObject={elem}
              searchByMonth={searchByMonth}
            />
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <h3 className="notFount">Não está disponível</h3>
        </div>
      )}

      <div style={{ height: "20px" }}></div>

      <ModalContainer
        isActive={isActive}
        closeModal={() => setIsActive(true)}
      >
        <ModalFilterDetailsDahboard
          setStatus={setStatus}
          setJustification={setJustification}
          status={status}
          justification={justification}
          search={searchByMonth}
          closeModal={() => setIsActive(false)}
          daysOrHours={daysOrHours}
          setDaysOrHours={setDaysOrHours}
        />
      </ModalContainer>

      <ScrollBotton width={"4060px"} />
    </div>
  );
};

export default ContainerOutbound;

{
  /* {visible &&
          <CardGraphicsLarge max={sumTotal} title={"Unidades expedidas VS Contrato"} listMonths={sumArray} months={monthAll} />
      }<CardGraphicsLarge max={4000} title={"Notas expedidas VS Contrato"} listMonths={[1100, 985, 1205, 2000, 850, 730, 999, 921, 506, 1093, 1001, 804]} /> */
}
