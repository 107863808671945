import { useEffect, useState } from "react";
import StorageLineDetailsCardTable from "./StorageLineDetailsCardTable";

const CardStorage = ({
  titleCard,
  priceCard,
  value,
  unit,
  typeCount,
  valueCount,
}) => {

  const [groupedElements, setGroupedElements] = useState([]);

  /* STYLES */
  const cardStyle = {
    border: "1px solid #79797950",
    borderRadius: "10px",
    padding: "17px 18px",
    width: "487px",
    height: "530px",
    backgroundColor: "#fff",
  };
  // console.log(valueCount, 2000);

  const headerStyle = {
    borderBottom: "1px solid #79797950",
    display: "flex",
    paddingBottom: "17px",
    alignItems: "center",
  };

  const columnStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "7px",
    borderRight: "1px solid #79797950",
    marginRight: "18px",
    paddingRight: "23px",
  };

  const contentStyle = {
    height: "90%",
    padding: "12px 0",
  };

  const scrollContentStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    height: "100%",
    overflowY: "scroll",
    paddingRight: "8px",
  };

  const groupElements = () => {
    const newArray = [];
    const datesArray = [];
    if (value?.length < 1) {
      return null;
    }

    if (value?.length === 1) {
      const newObj = {
        data_posicao_estoque: value[0].data_posicao_estoque,
        [valueCount]: value[0][valueCount]
      }
      newArray.push(newObj);
      setGroupedElements(newArray);
      return null;
    }
    for (let i = 0; i < value.length; i++) {
      const currentDate = value[i].data_posicao_estoque;
      const newObj = {
        data_posicao_estoque: value[i].data_posicao_estoque,
        [valueCount]: value[i][valueCount]
      }

      if (!datesArray || !datesArray.some((e) => e === currentDate)) {
        datesArray.push(currentDate);
        for (let j = i + 1; j < value.length ; j++) {
          if (value[j].data_posicao_estoque === currentDate) {
            newObj[valueCount] += value[j][valueCount];
          }
        }
        newArray.push(newObj);
      }
    }
    console.log('newArray', newArray)
    console.log('datesArray', datesArray)
    setGroupedElements(newArray);
  };

  useEffect(() => {
    groupElements();
  }, [value]);

  /* RENDER */
  return (
    <div style={cardStyle}>
      <div style={headerStyle}>
        <div style={columnStyle}>
          <p className="titleNameCompanyCard">{titleCard?.split("-")[0]}</p>
          {titleCard?.split("-")[1] && (
            <p className="textTitleValueCard">{titleCard?.split("-")[1]}</p>
          )}
        </div>

        <div>
          <p className="titleNameCompanyCard">
            {priceCard.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </p>
        </div>
      </div>

      <div style={contentStyle}>
        <div className="scrollLower" style={scrollContentStyle}>
          {value?.length > 0 && value.map((item) => {
            return (
              <StorageLineDetailsCardTable
                border={true}
                date={item.data_posicao_estoque}
                qtd={item[valueCount]}
                rate={unit.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
                typeCount={typeCount}
                factor={item.factor}
                price={unit * item[valueCount] * item.factor}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CardStorage;
