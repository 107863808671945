import React, { useState } from 'react';
import arrowUpIcon from "../../../images/svg/arrowCircleUpAction.svg";
import arrowDownIcon from "../../../images/svg/arrowCircleDownAction.svg";

const ResponsibleBoxDocumentDetailModal = () => {
    const [isArrowUp, setIsArrowUp] = useState(true);

    const toggleArrowIcon = () => {
        setIsArrowUp((prevValue) => !prevValue);
    };

    const container = {
        maxWidth: "100%",
        borderBottom: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px 0",
        margin: "0 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    };

    const styleTitle = {
        margin: "0",
        fontSize: "17px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleDocumentinfosKey = {
        color: "(--black)",
        fontSize: "14px",
        fontWeight: 500,
        marginBottom: "10px"
    };

    const styleDocumentinfosValue = {
        color: "#6A6A6A",
        fontSize: "14px",
        fontWeight: 400,
    };

    const styleDate = {
        ...styleDocumentinfosValue,
        fontSize: "11px",
        marginLeft: "10px"
    };

    return (
        <div style={container}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }} onClick={toggleArrowIcon}>
                <img src={isArrowUp ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                <h3 style={styleTitle}>Responsáveis</h3>
            </div>
            {!isArrowUp && (
                <div style={{ overflowX: "auto", paddingBottom: "20px" }}>
                    <div style={{ margin: "20px 0 0 27px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "15px", }}>
                            <div>
                                <p style={styleDocumentinfosKey}>Cadastro(s)</p>
                                <p style={styleDocumentinfosValue}>{"Patrícia Andrade"}<small style={styleDate}>{"(31/05/2021)"}</small></p>
                            </div>
                            <div>
                                <p style={styleDocumentinfosKey}>Elaboração</p>
                                <p style={styleDocumentinfosValue}>{"Janaina Barros"}<small style={styleDate}>{"(31/05/2021)"}</small></p>
                            </div>
                            <div>
                                <p style={styleDocumentinfosKey}>Consensadores</p>
                                <p style={styleDocumentinfosValue}>{"Janaina Barros"}<small style={styleDate}>{"(31/05/2021)"}</small></p>
                            </div>
                            <div>
                                <p style={styleDocumentinfosKey}>Aprovador</p>
                                <p style={styleDocumentinfosValue}>{"Patrícia Andrade"}<small style={styleDate}>{"(31/05/2021)"}</small></p>
                            </div>
                            <div>
                                <p style={styleDocumentinfosKey}>Outra chave...</p>
                                <p style={styleDocumentinfosValue}>{"Patrícia Andrade"}<small style={styleDate}>{"(31/05/2021)"}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResponsibleBoxDocumentDetailModal;
