import Button from "./Button";
import InputSearch from "./InputSearch";

const ContainerInputSearchAndButton = ({
  widthInput,
  heightInput,
  nameButton,
  colorButtom,
  value,
  onChange,
  listCardSelected,
  setModalFormAdmin,
  setOptionModalFormAdmin,
  setModalFormClient,
  setOptionModalFormClient,
}) => {
  const container = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const handleClick = () => {
    if (nameButton == "Novo usuário") {
      setOptionModalFormAdmin("create");
      setModalFormAdmin(true);
    } else if (nameButton == "Novo cliente") {
      setOptionModalFormClient("create");
      setModalFormClient(true);
    } else if (nameButton == "Deletar usuário(s)") {
      // Fazer um loop em listCardSelected para deletar cada card
      // listCardSelected é um arrai que contem o id de cada usuários quando é selecionado no checkbox
    } else if (nameButton == "Deletar todos") {
    }
  };

  return (
    <div className="containerBox" style={container}>
      <InputSearch
        width={widthInput}
        height={heightInput}
        value={value}
        onChange={onChange}
      />
      <Button
        width="195px"
        height="45px"
        background={colorButtom}
        borderRadius="60px"
        marginTop="0"
        name={nameButton}
        color={"var(--white)"}
        onClick={handleClick}
        border="none"
      />
    </div>
  );
};

export default ContainerInputSearchAndButton;
