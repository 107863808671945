const LineGap = () => {
  return (
    <div
      style={{
        paddingLeft: "56px",
      }}
    >
      <div
        style={{ width: "97%", height: "1px", backgroundColor: "#d7d7d7" }}
      />
    </div>
  );
};

export default LineGap;
