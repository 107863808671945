export const documentListMock = [
    {
        id: 1,
        code: "AP0001:05",
        title: "Apresentação Integração - Qualidade",
        category: "Qualidade",
        date: "11/05/2023",
        subDocuments: [
            {
                id: 101,
                code: "SUB001:01",
                title: "Subdocumento 1",
                category: "Categoria 1",
                date: "12/05/2023",
                type: "pdf"
            },
        ],
    },
    {
        id: 2,
        code: "AP0002:05",
        title: "Outra apresentação",
        category: "Outra categoria",
        date: "13/05/2023",
        subDocuments: [
            {
                id: 102,
                code: "SUB002:01",
                title: "Subdocumento 2",
                category: "Categoria 2",
                date: "14/05/2023",
                type: "csv"
            },
            {
                id: 103,
                code: "SUB002:01",
                title: "Subdocumento 3",
                category: "Categoria 3",
                date: "14/05/2023",
                type: "pdf"
            },
            {
                id: 105,
                code: "SUB002:01",
                title: "Subdocumento 5",
                category: "Categoria 5",
                date: "14/05/2023",
                type: "pdf"
            },
        ],
    },
    {
        id: 3,
        code: "AP0001:05",
        title: "Apresentação Integração - Qualidade",
        category: "Qualidade",
        date: "11/05/2023",
        subDocuments: [],
    },
    {
        id: 4,
        code: "AP0001:05",
        title: "Apresentação Integração - Qualidade",
        category: "Qualidade",
        date: "11/05/2023",
        subDocuments: [],
    },
    {
        id: 5,
        code: "AP0002:05",
        title: "Outra apresentação",
        category: "Outra categoria",
        date: "13/05/2023",
        subDocuments: [
            {
                id: 104,
                code: "SUB002:01",
                title: "Subdocumento 4",
                category: "Categoria 4",
                date: "14/05/2023",
                type: "pdf"
            },
        ],
    },
];

export default documentListMock;
