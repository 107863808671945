import { useContext, useEffect, useState } from "react";
import CardContractualParameter from "./CardContractualParameter";
import { ContactedContext } from "../../../context/Dashboard/ContactedContext";
import FilterContacted from "./FilterContacted";

const ContactedVSAccomplished = ({ type }) => {
  const { receipt, weekOrMonth, typeUnit, receiptParameter } =
    useContext(ContactedContext);
  // Declaração dos useState
  const [dates, setDates] = useState([]);
  const [weeks, setWeeks] = useState([]);

  const [quantityEntries, setQuantityEntries] = useState([]);
  const [lineEntries, setLineEntries] = useState([]);

  const [quantityConferences, setQuantityConferences] = useState([]);
  const [lineConferences, setLineConferences] = useState([]);

  const [metrics, setMetrics] = useState(0);
  const [lineOrQtd, setLineOrQtd] = useState("line");

  const onTitle = () => {
    const data = {
      Recebimento: "de recebimento - Contratado",
      Saida: "de expedição - Contratado",
      Urgente: "de urgência - Contratado",
    };

    return data[typeUnit];
  };

  const visible = () => {
    if (type === "Contratada vs Realizada") return true;
    return false;
  };

  const onSeparateEntries = () => {
    // Extraindo os dados do receipt e armazenando nos estados

    const date = receipt?.map((elem) =>
      weekOrMonth == "week" ? elem.dayOfWeek : elem.date
    );
    setDates(date);

    const week = receipt?.map((elem) => elem.dayOfWeek);
    setWeeks(week);

    const quantityEntry = receipt?.map((elem) => elem.entry.quantity_entry);
    console.log(receipt, 2020);

    setQuantityEntries(quantityEntry);

    const lineEntry = receipt?.map((elem) => elem.entry.line_entry);
    setLineEntries(lineEntry);

    const quantityConference = receipt?.map(
      (elem) => elem.conference.quantity_conference
    );
    setQuantityConferences(quantityConference);

    const lineConference = receipt?.map(
      (elem) => elem.conference.line_conference
    );
    setLineConferences(lineConference);

    const metric = receiptParameter.find((elem) => {
      if (elem.type === typeUnit)
        if (lineOrQtd === "line" && elem.line_or_qtd === "Linha") return elem;
        else if (lineOrQtd === "qtd" && elem.line_or_qtd === "Quantidade")
          return elem;
    })?.metric;
    setMetrics(metric);
  };

  const onVisibleEntriesLineOrQtd = () => {
    if (lineOrQtd === "line") return lineEntries;
    if (lineOrQtd === "qtd") return quantityEntries;
  };

  const onVisibleConferencesLineOrQtd = () => {
    if (lineOrQtd === "line") return lineConferences;
    if (lineOrQtd === "qtd") return quantityConferences;
  };

  useEffect(() => {
    onSeparateEntries();
  }, [receipt, lineOrQtd]);

  return (
    visible() && (
      <div style={{ marginTop: "80px" }}>
        {/* <h4 className="textContactedVSAccomplished">Contratada vs Realizada</h4> */}

        <CardContractualParameter
          max={100}
          title={`Unidades ${onTitle()} vs Conferido`}
          content={"Parâmetro contratual"}
          contentTwo={{ label: "Contratado", cicle: "#d7d7d7" }}
          contentThree={{ label: "Conferido", cicle: "var(--color-primary)" }}
          metrics={metrics ?? 0}
          listMonths={onVisibleConferencesLineOrQtd()}
          listMonthsTwo={onVisibleEntriesLineOrQtd()}
          labels={dates}
          labelColOne={"Conferido"}
          labelColTwo={"Contratado"}
          height={"auto"}
          heightGraphics={"500px"}
          html={
            <FilterContacted
              visible={() => visible()}
              type={type}
              setLineOrQtd={setLineOrQtd}
            />
          }
        />
      </div>
    )
  );
};

export default ContactedVSAccomplished;
