/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
// import { read, utils } from "xlsx";
import { toast } from "react-toastify";
import eye from "../../../images/svg/olho.svg";
import cloud from '../../../images/svg/upload-na-nuvem 1.svg';
import checkOn from '../../../images/svg/checkOnIcon.svg';
import checkOff from '../../../images/svg/checkOffIcon.svg';

const UploadInput = ({setGroupingModal, setListStructures, index, uploadList, content, disabled, tryingUpload, setTryingUpload}) => {
  
  const visualizeStructures = () => {
    if (content.length > 0) {
      setListStructures(content)
      setGroupingModal(true)
    } else {
      toast.error("Não há conteúdo para visualização!", {
        autoClose: 2000,
      });
    }
  };

  const handleClick = () => {
    setTryingUpload(true)
    if (disabled) {
      toast.error("Grupo inválido ou não disponível", {
        autoClose: 2000,
      });
    } else {
      document.getElementById(`file-${index}`).click();
    }
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: '30%', cursor: 'pointer'}}>
      <div style={{position: 'relative'}}>
        <div style={{marginBottom: 10}}>
          <span className='textTitleParms'>Upload excel</span>
          <button
            style={{background: 'none', border: 'none'}}
            onClick={() => visualizeStructures()}
          >
            <img
            src={eye}
            width={15}
            alt="visualizar"
            style={{marginLeft: 10, cursor: 'pointer'}}
          />
          </button>
        </div>
        <input
          type="text"
          style={{border: '1px dashed #D7D7D7', borderRadius: 10, height: 45, padding: "10px 10px 10px 40px", width: '100%'}}
          value="Upload excel"
          readOnly={true}
          onClick={handleClick}
        />
        <img
          src={cloud}
          width={25}
          style={{left: 10, bottom: 10, position: 'absolute'}}
          onClick={handleClick}
        />
        <img
          src={content.length > 0 ? checkOn : checkOff}
          width={25}
          style={{right: 10, bottom: 10, position: 'absolute'}}
          onClick={handleClick}
        />
      </div>

      <input
        onChange={(e) => uploadList(e.target.files[0])}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        name="file"
        id={`file-${index}`}
        type="file"
        style={{ display: "none" }}
      />
    </div>
  )
};

export default UploadInput;
