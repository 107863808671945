import React, { useEffect, useState } from "react";
import Textarea from "./Textarea";
import { DashContext } from "../../../context/Dashboard/DashContext";
import { useContext } from "react";

const arrowSvg = `
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.274428 1.61166C0.109402 1.37389 0.109402 1.05863 0.274428 0.820855C0.550264 0.423421 1.13801 0.423421 1.41385 0.820855L2.57301 2.49102C3.41008 3.69709 5.19369 3.69709 6.03076 2.49102L7.18993 0.820855C7.46576 0.423421 8.05351 0.423421 8.32934 0.820855C8.49437 1.05863 8.49437 1.37389 8.32934 1.61166L7.20524 3.23131C5.79952 5.25671 2.80425 5.25671 1.39854 3.23131L0.274428 1.61166Z" fill="black"/>
  </svg>
`;

const arrowSvgUri = encodeURIComponent(arrowSvg);

const BoxParameterSelect = ({
  name,
  number,
  type,
  width,
  onChange,
  value,
  valueInput,
  onBlur,
  height,
  options,
  listValues,
  update,
  percentage,
  coin,
  gap,
  withDefault,
  wait,
  margin,
  flexGrow,
}) => {
  const { listGeneralities } = useContext(DashContext);
  const [valueInputState, setValueInputState] = useState("");
  const [optionSelect, setOptionSelect] = useState("");
  const [typeStructureAll, setTypeStructureAll] = useState([]);

  const [typeCategoryAll, setTypeCategoryAll] = useState([]);
  const [typeNumber, setTypeNumber] = useState(true);

  /* STYLES */
  const boxCard = {
    display: "flex",
    gap: "18px",
    alignItems: "end",
    width: width,
    grow: flexGrow || 0,
  };

  const numberBox = {
    backgroundColor: "#96C",
    color: "#fff",
    fontSize: "19px",
    padding: "6px 16px",
    borderRadius: "60px",
    marginBottom: "5px",
  };

  const selectBox = {
    outline: "none",
    width: width || "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "12px 13px",
    appearance: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px center",
    paddingRight: "30px",
  };

  const inputBox = {
    outline: "none",
    width: width || "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "12px 13px",
    backgroundColor: withDefault && "#d7d7d770",
    margin: margin || 0,
  };

  const verifyNameType = () => {
    if (type === "process") {
      return "Processo";
    } else if (type === "description") {
      return "Descrição da atividade";
    } else if (type === "unit") {
      return "Unidade de medida";
    } else if (type === "price") {
      return `Preço ${value || ""}`;
    } else if (type === "titleGenerality") {
      return "Tipo de generalidade";
    } else if (type === "dateGenerality") {
      return "Data";
    } else if (type === "qtdGenerality") {
      return "Quantidade";
    } else if (type === "typeStructure") {
      return "Tipo de estrutura";
    } else if (type === "typeCategory") {
      return "Tipo de categoria";
    } else if (type === "justification") {
      return "Justificativa";
    } else if (type === "typeCalculation") {
      return "Tipo de calculo";
    } else if (type === "conversion") {
      return "Conversão (Em desenvolvimento)";
    } else if (type === "activityDescription") {
      return "Descrição da atividade";
    } else if (type === "documentation") {
      return "Pedido/ Nota Fiscal";
    } else if (type === "cubagemLogix") {
      return "Cubagem Logix";
    } else if (type === "cubageContract") {
      return "Cubagem Contrato";
    } else if (type === "factor") {
      return "Fator";
    } else if (type === "recurrences") {
      return "Recorrências";
    } else if (type === "variable") {
      return "Nome da variável";
    } else if (type === "custo") {
      return "Custo";
    } else if (type === "fixedMonthValue") {
      return "Valor fixo mensal";
    } else if (type === "paramsSelector") {
      return "Variável";
    } else if (type === "structureGroup") {
      return "Tipo de estrutura";
    } else if (type === "code") {
      return "Código da estrutura";
    } else if (type === "quantityContract") {
      return "Quantidade contratada";
    } else if (type === "recurrence") {
      return "Recorrente";
    }
  };

  const formatCoin = (typeCoin, valueCoin, setValueCoin) => {
    if (percentage) {
      const number = Number(valueCoin);
      setValueCoin(`${number}%`);
    } else if (coin === "real" || typeCoin === "real") {
      const number = Number(valueCoin);
      setValueCoin(
        number.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
    }
  };

  useEffect(() => {
    setTypeCategoryAll(listValues);
    setTypeStructureAll(listValues);
  }, [listValues]);

  // useEffect(() => {
  //   getStructure();
  // }, []);

  // useEffect(() => {
  //   getStructure();
  // }, []);

  // useEffect(() => {
  //   getCategory();
  // }, [update]);

  useEffect(() => {
    verifyNameType(value);
  }, [value]);

  useEffect(() => {
    setOptionSelect(options);
  }, [options]);

  useEffect(() => {
    setValueInputState(valueInput);
  }, [valueInput]);

  return (
    <div style={boxCard}>
      {number && <div style={numberBox}>{number}</div>}
      {gap && <div style={{ marginLeft: "39px ", backgroundColor: "" }}></div>}
      <div
        style={{
          display: "flex",
          gap: "13px",
          flexDirection: "column",
          width: width,
        }}
      >
        {
          <p
            className="textTitleParms"
            style={{ margin: margin || 0, width: width }}
          >
            {verifyNameType()}
          </p>
        }

        {type !== "description" &&
          type !== "price" &&
          type !== "price" &&
          type !== "dateGenerality" &&
          type !== "qtdGenerality" &&
          type !== "activityDescription" &&
          type !== "conversion" &&
          type !== "documentation" &&
          type !== "cubagemLogix" &&
          type !== "cubageContract" &&
          type !== "factor" &&
          type !== "quantityContract" &&
          valueInputState &&
          wait && (
            <select
              name={name}
              defaultValue={valueInput}
              onBlur={onBlur}
              onChange={onChange}
              style={{
                ...selectBox,
                backgroundImage: `url('data:image/svg+xml;charset=utf-8,${arrowSvgUri}')`,
              }}
              disabled={withDefault}
            >
              {type === "process" && (
                <>
                  <option value="">Selecione</option>
                  <option value="Recebimento">Recebimento</option>
                  <option value="Expedição">Expedição</option>
                  <option value="Armazenagem">Armazenagem</option>
                  {/* <option value="Inventário">Inventário</option> */}
                  <option value="Seguro de Armazenagem">
                    Seguro de Armazenagem
                  </option>
                </>
              )}
              {type === "unit" && optionSelect === "Armazenagem" && (
                <>
                  <option value={valueInput}>
                    {valueInput?.split(":")[1]}
                  </option>
                  <option value="">Selecione</option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="qtd_palete_fisico - sum: Palete fisico">
                    Palete fisico
                  </option>
                  <option value="qtd_enderecos - sum linha: Endereços">
                    Endereços
                  </option>
                  <option value="qtd_saldo - sum: Saldo">Saldo</option>
                </>
              )}
              {type === "unit" && optionSelect === "Recebimento" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_item - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                  <option value="qtd_palete_fisico - sum: Pallet">
                    Pallet
                  </option>
                  <option value="seq_aviso_recebto - sum: Linhas">
                    Linhas
                  </option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                </>
              )}
              {type === "unit" && optionSelect === "Expedição" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_itens - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                  <option value="trans_solicitacao - sum linhas: Notas Fiscais Expedidas">
                    Notas Fiscais Expedidas
                  </option>
                  <option value="trans_solicitacao - count pedido_urgent:  Pedidos Urgentes">
                    Pedidos Urgentes
                  </option>
                  <option value="qtd_palete_fisico - sum: Pallet">
                    Pallet
                  </option>
                  <option value="qtd_sequencias - sum: Linhas">Linhas</option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                </>
              )}
              {type === "unit" && optionSelect === "Seguro de Armazenagem" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_itens - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                </>
              )}
              {type === "unit" && optionSelect === "default" && (
                <>
                  <option value="">Selecione</option>
                  <option value="Container">Container</option>
                  <option value="Hora">Hora</option>
                  <option value="Horas fins de semana">
                    Horas fins de semana
                  </option>
                  <option value="Unidade">Unidade</option>
                  <option value="Urgência">Urgência</option>
                  <option value="Adequação">Adequação</option>
                  <option value="Rotulagem">Rotulagem</option>
                </>
              )}
              {type === "recurrences" && optionSelect === "analyst" && (
                <>
                  <option value="">Selecione</option>
                  {/* <option value="withoutRecurrence">Sem recorrências</option>
                  <option value="daily">Diário</option>
                  <option value="weekly">Semanal</option>
                  <option value="monthly">Mensal</option> */}
                  <option value="workday">Dias úteis</option>
                  <option value="weekend">Fim de semana / Feriado</option>
                </>
              )}
              {type === "recurrences" && optionSelect === "administrative" && (
                <>
                  <option value="withoutRecurrence">Selecione</option>
                  <option value="withoutRecurrence">Variável</option>
                  <option value="monthly">Fixo</option>
                </>
              )}
              {type === "titleGenerality" && (
                <>
                  <option value="">Selecione</option>
                  {listGeneralities
                    ?.filter((e) => e.recurrence === "withoutRecurrence")
                    .map((item) => {
                      return (
                        <>
                          <option value={item?.id}>{item?.description}</option>
                        </>
                      );
                    })}
                </>
              )}
              {type === "typeCalculation" && (
                <>
                  <option value={valueInput}>{valueInput}</option>
                  <option value="">Selecione</option>
                  <option value="Soma">Soma</option>
                  {options !== "Recebimento" && (
                    <option value="Pico">Pico</option>
                  )}
                </>
              )}
              {type === "paramsSelector" && (
                <>
                  <option value={valueInput || "selecione"}>
                    {valueInput || "selecione"}
                  </option>
                  {optionSelect.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </>
              )}
              {/* {type === "typeStructure" &&
                typeStructureAll.map((item, i) => {
                  if (
                    i == 0 &&
                    options !== "Recebimento" &&
                    i == 0 &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={valueInput}>{valueInput}</option>
                        <option value="default">Selecione</option>;
                        <option value={item.des_tip_estrutura}>
                          {item.des_tip_estrutura}
                        </option>
                        ;
                      </>
                    );
                  } else if (
                    options !== "Recebimento" &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={item.des_tip_estrutura}>
                          {item.des_tip_estrutura}
                        </option>
                      </>
                    );
                  } else if (
                    (i == 0 && options === "Recebimento") ||
                    (i == 0 && options === "Expedição")
                  ) {
                    return <option value="default">Não se aplica</option>;
                  }
                })} */}
              {type === "typeStructure" &&
                typeStructureAll.map((item, i) => {
                  if (
                    i == 0 &&
                    options !== "Recebimento" &&
                    i == 0 &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={valueInput}>{valueInput}</option>
                        <option value="default">Selecione</option>;
                        <option value={item.name}>{item.name}</option>;
                      </>
                    );
                  } else if (
                    options !== "Recebimento" &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={item.name}>{item.name}</option>
                      </>
                    );
                  } else if (
                    (i == 0 && options === "Recebimento") ||
                    (i == 0 && options === "Expedição")
                  ) {
                    return <option value="default">Não se aplica</option>;
                  }
                })}
              {type === "typeCategory" && typeCategoryAll?.length ? (
                typeCategoryAll.map((item, i) => {
                  if (i == 0) {
                    return (
                      <>
                        <option value={valueInput}>{valueInput}</option>
                        <option value="default">Selecione</option>;
                        <option value={item.des_categoria}>
                          {item.des_categoria}
                        </option>
                        ;
                      </>
                    );
                  }
                  return (
                    <option value={item.des_categoria}>
                      {item.des_categoria}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
              {/* {
                type === "structureGroup" ? (
                  <>
                    <option value="blocados">Blocados</option>
                    <option value="caramara-fria">Camara fria</option>
                    <option value="drive-in">Drive-in</option>
                    <option value="drive-thru">Drive-thru</option>
                    <option value="porta-paletes">Porta paletes</option>
                    <option value="prateleiras">Prateleiras</option>
                    <option value="silos">Silos</option>
                    <option value="veiculo">Veiculo</option>
                    <option value="selecione">Selecione</option>
                  </>
                ) : null
              } */}
              {type === "structureGroup" && (
                <>
                  <option value={valueInput || "Selecione"}>
                    {valueInput || "Selecione"}
                  </option>
                  {options && options.length > 0
                    ? options.map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))
                    : null}
                </>
              )}
            </select>
          )}

        {type === "cubagemLogix" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Cubagem Logix"
            style={inputBox}
            type="number"
            name="cubagem_logix"
          />
        )}
        {type === "cubageContract" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Cubagem Contrato"
            style={inputBox}
            type="number"
          />
        )}
        {type === "code" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Código da estrutura"
            style={inputBox}
            type="number"
            name="code"
          />
        )}
        {type === "factor" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Fator"
            style={inputBox}
            type="number"
          />
        )}
        {type === "description" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Descrição"
            style={inputBox}
          />
        )}
        {type === "quantityContract" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder=""
            style={inputBox}
          />
        )}
        {type === "documentation" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Descrição"
            style={inputBox}
          />
        )}
        {type === "activityDescription" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Descrição da atividade"
            style={inputBox}
          />
        )}
        {type === "conversion" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira a conversão"
            style={inputBox}
          />
        )}
        {type === "price" && (
          <input
            onBlur={(e) => {
              setTypeNumber(false);
              onBlur(e);
              formatCoin("real", valueInput, setValueInputState);
            }}
            onChange={onChange}
            onClick={() => {
              setTypeNumber(true);
              setValueInputState(valueInput);
            }}
            value={valueInputState}
            placeholder="Preço"
            type={typeNumber ? "number" : "string"}
            style={{ ...inputBox, appearance: "textarea" }}
            disabled={withDefault}
          />
        )}
        {type === "dateGenerality" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            type="date"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "qtdGenerality" && (
          <input
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Quantidade"
            type="number"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "justification" && (
          <Textarea
            width={width}
            height={height}
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Descrição da justificativa"
            type="number"
            style={{ inputBox }}
          />
        )}
        {type === "variable" && (
          <input
            name={name}
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Nome"
            type="text"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "custo" && (
          <input
            name={name}
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="R$ 0,00"
            type="string"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "fixedMonthValue" && (
          <input
            name={name}
            disabled={withDefault}
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="R$ 0,00"
            type="string"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
      </div>
    </div>
  );
};

export default BoxParameterSelect;
