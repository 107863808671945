import ContainerWigth from "../components/ContainerWigth";
import SearchBranch from "./SearchBranch";
import Button from "../components/Button";
// import Calendar from "./Calendar";
import { useContext, useState } from "react";
import ModalContainer from "./ModalContainer";
import ModalActions from "./ModalActions";
import ModalDelete from "./ModalDelete";
import closeIcon from "../../../images/svg/closeIcon.svg";
import ModalNewSchedule from "./ModalNewSchedule";
import ModalEditSchedule from "./ModalEditSchedule";
import SchedulesContainer from "./SchedulesContainer";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

import ModalFilter from "./ModalFilter";

const BoxContainerShedules = () => {
    const { locations, selectedDate, setSelectedDate } = useContext(SchedulesContext);

    const [schedules, setSchedules] = useState([]);
    // const [schedulesByBranch, setSchedulesByBranch] = useState([]);
    // const [schedulesByDate, setSchedulesByDate] = useState([]);

    const [menuOpen, setMenuOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);

    const [selectedSchedule, setSelectedSchedule] = useState({});
    // const [selectedDate, setSelectedDate] = useState("");
    const [branches, setBranches] = useState([]);

    const [currentBranch, setCurrentBranch] = useState({});
    // const [places, setPlaces] = useState([]);

    const [typeForm, setTypeForm] = useState("");

    const [openFilter, setOpenFilter] = useState(false);

    // const handleBranch = ({ target }) => {
    //     const current = branches.filter((e) => e.name === target.value)[0];
    //     setCurrentBranch(current);
    //     setSchedulesByBranch(
    //         schedules.filter((e) => e.branchId === current.id)
    //     );
    // };

    const openMenu = (id) => {
        if (menuOpen) {
            setMenuOpen(false);
            setSelectedSchedule({});
        } else {
            const selected = schedules.filter((e) => e.id === id)[0];
            setSelectedSchedule(selected);
            setMenuOpen(true);
        }
    };

    const handleFormOpen = (type) => {
        setTypeForm(type);
        setFormOpen(true);
    };

    // useEffect(() => {
    //     setSchedulesByDate(
    //         schedulesByBranch.filter((e) => e.date === selectedDate)
    //     );
    // }, [selectedDate, schedulesByBranch]);

    return (
        <ContainerWigth>
            <SearchBranch
                setOpenFilter={setOpenFilter}
                // handleBranch={handleBranch}
                // branches={branches}
                // currentBranch={currentBranch}
                selectedDate={selectedDate}
            />

            <Button
                width={216}
                height={45}
                background={"#9966CC"}
                borderRadius={60}
                marginTop={0}
                name={"Novo agendamento"}
                border={"none"}
                disabled={false}
                onClick={() => handleFormOpen("Criar")}
                color={"#FFF"}
                padding={0}
            />

            {/* <Calendar
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
            /> */}

            {locations &&
                locations.length > 0 &&
                locations.map((e) => (
                    <SchedulesContainer
                        place={e}
                        openMenu={openMenu}
                        setSelectedSchedule={setSelectedSchedule}
                    />
                ))}

            {menuOpen && (
                <ModalContainer isActive={menuOpen} closeModal={openMenu}>
                    <ModalActions
                        closeModal={openMenu}
                        item={selectedSchedule}
                        setDeleteOpen={() => setDeleteOpen(true)}
                        setEditOpen={() => setEditOpen(true)}
                        closeIcon={closeIcon}
                        setMenuOpen={setMenuOpen}
                    />
                </ModalContainer>
            )}

            {deleteOpen && (
                <ModalContainer
                    isActive={deleteOpen}
                    closeModal={() => setDeleteOpen(false)}
                >
                    <ModalDelete
                        closeModal={setDeleteOpen}
                        item={selectedSchedule}
                        closeIcon={closeIcon}
                        setMenuOpen={setMenuOpen}
                    />
                </ModalContainer>
            )}

            {editOpen && (
                <ModalContainer
                    isActive={editOpen}
                    closeModal={() => setEditOpen(false)}
                >
                    <ModalEditSchedule
                        closeModal={setEditOpen}
                        closeIcon={closeIcon}
                        selectedSchedule={selectedSchedule}
                    />
                </ModalContainer>
            )}

            {formOpen && (
                <ModalContainer
                    isActive={formOpen}
                    closeModal={() => setFormOpen(false)}
                >
                    <ModalNewSchedule
                        closeModal={setFormOpen}
                        closeIcon={closeIcon}
                        branches={branches}
                        currentBranch={currentBranch}
                        setCurrentBranch={setCurrentBranch}
                        schedules={schedules}
                        setSchedules={setSchedules}
                        typeForm={typeForm}
                    />
                </ModalContainer>
            )}

            {openFilter && (
                <ModalContainer
                    isActive={openFilter}
                    closeModal={setOpenFilter}
                >
                    <ModalFilter
                        setSelectedDate={setSelectedDate}
                        setOpenFilter={setOpenFilter}
                    />
                </ModalContainer>
            )}
        </ContainerWigth>
    );
};

export default BoxContainerShedules;
