import React, { useState } from 'react';
import closeIcon from "../../../images/svg/closeIcon.svg";
import LineOptions from './LineOptions';
import add from '../../../images/svg/add.svg';
import deleteIcon from '../../../images/svg/deleteIcon.svg';
import Button from './Button';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const ModalGroupingVisualization = ({closeModal, items, setItems}) => {
  const [updatedItems, setUpdatedItems] = useState([]);

	const createNewLine = () => {
    const newArray = [...items];
    newArray.push({ code: '', cubagem_logix: '', group_id: items[0].group_id || ''});
    setItems(newArray);
  };

  const createStructures = async () => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    const newItems = items.filter((item) => item.id === undefined);
    if (newItems && newItems.length > 0) {
      const response = await Promise.all(
        newItems.map((structure) => (
          api.post('/structures', {
            code: `${structure.code}`,
            cubagem_logix: structure.cubagem_logix,
            group_id: structure.group_id,
            date: `${year}-${month}`,
          })
        ))
      );
      return response;
    };
  };

  const updateStructures = async () => {
    const updated = updatedItems.filter((item) => item.id !== undefined);
    if (updated && updated.length > 0) {
      const response = await Promise.all(
        updated.map((structure) => (
          api.patch(`/structures/${structure.id}`, {
            code: `${structure.code}`,
            cubagem_logix: structure.cubagem_logix,
          })
        ))
      );
      return response;
    };
  };

  const handleChange = ({target}, index) => {
    const {name, value} = target;

    const newArray = [...items];
    newArray[index][name] = value;
    setItems(newArray);

    const updatedIndex = updatedItems.findIndex((item) => item.id && item.id === newArray[index].id);
    
    if (updatedIndex >= 0) {
      const newUpdatedArray = [...updatedItems];
      newUpdatedArray[updatedIndex][name] = value;  
      setUpdatedItems(newUpdatedArray);    
    } else if (newArray[index].id) {
      console.log('aqui!!!', newArray[index])
      const newUpdatedArray = [...updatedItems];
      newUpdatedArray.push(newArray[index]);
      setUpdatedItems(newUpdatedArray);
    }
  };

  const saveForm = async () => {
    const newItems = items.filter((item) => item.id === undefined)
    if (newItems && newItems.length > 0) {
      const response = await createStructures();
      if (response && response.length === newItems.length) {
        toast.success('Novas estruturas cadastradas com sucesso', {autoClose: 2000});
      } else {
        toast.error('Desculpe, ocorreu um erro ao salvar estruturas', {autoClose: 2000});
      }
    };

    if (updatedItems && updatedItems.length > 0) {
      const response = await updateStructures();
      if (response && response.length === updatedItems.filter((item) => item.id !== undefined).length) {
        toast.success('Estruturas editadas com sucesso', {autoClose: 2000});
      } else {
        toast.error('Desculpe, ocorreu um erro ao editar estruturas', {autoClose: 2000});
      }
    };
    closeModal();
  };

const deleteStructure = async (id) => {
    try {
      const response = await api.delete(`/structures/${id}`)
      if (response) {
        toast.success("Estrutura deletada com sucesso!", { autoClose: 2000 });
      };
    } catch (error) {
      toast.error("Não foi possível deletar o estrutura!", {
        autoClose: 2000,
      });
    }
  };

const handleDelete = (id) => {
    const newArray = items.filter((e, i) => e.id !== id);
    setItems(newArray);
    deleteStructure(id);
  };

  const container = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: "690px",
		height: '600px',
    boxShadow: "0px 0px 13px -3px #00000033",
    border: "1px solid transparent",
    borderRadius: "20px",
    padding: '10px 15px',
  };

  const headerOption = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    padding: "15px 18px 18px",
  };

  return (
    <main style={container}>
      <header style={headerOption}>
        <div style={{display: 'flex', gap: 40}}>
          <div
            onClick={closeModal}
            style={{
              cursor: "pointer",
            }}
          >
            <img src={closeIcon} />
          </div>
          <p className="textModalTitleOption">{`Estruturas do grupo`}</p>
        </div>
				<Button
          width="100px"
          height="33px"
          background="var(--color-primary)"
          borderRadius="60px"
          marginTop="0"
          name="Salvar"
          onClick={() => saveForm()}
          border="none"
        />
      </header>
      <section style={{display: 'flex', flexDirection: 'column', gap: 15, overflowY: 'auto', padding: '20px 10px'}} className="scrollLower">
				{
					items.length > 0? (
						items.map((item, i) => (
              <article style={{display: 'flex', gap: 14}}>
                <LineOptions code={item.code} qtdBox={item.cubagem_logix} i={i} handleChange={handleChange} />
                <button
                  onClick={() => handleDelete(item.id)}
                  style={{background: 'none', border: 'none', height: '100%', display: 'flex', marginTop: 40}}
                >
                  <img src={deleteIcon} alt="del" />
                </button>
              </article>
						))
					) : null
				}
        {
					items.length === 0 ? (<p>Nenhuma estrutura cadastrada</p>) : null
				}
				<div
					style={{display: 'flex', gap: 10, color: '#9966CC', cursor: 'pointer', alignSelf: 'flex-start', marginTop: 15}}
					onClick={createNewLine}
				>
					<img src={add} alt="adicionar checklist" />
					<p>Adicionar estrutura ao grupo</p>
				</div>
      </section>
    </main>
  )
}

export default ModalGroupingVisualization