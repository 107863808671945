import React from 'react';

const ViewCard = ({ person, date }) => {
  const styleIndicatorBox = {
    gap: "10px",
    display: "flex",
    alignItems: "center"
  };

  const styleDocumentinfosValue = {
    color: "(--black)",
    fontSize: "14px",
    fontWeight: 400,
  };

  return (
    <li style={{ border: "1px solid var(--grey-0)", backgroundColor: "var(--white)", padding: "20px 20px 20px 32px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div style={{ ...styleIndicatorBox, width: "407px" }}>
        <p style={styleDocumentinfosValue}>{person}</p>
      </div>
      <div style={{ ...styleIndicatorBox, width: "374px" }}>
        <p style={styleDocumentinfosValue}>{date}</p>
      </div>
    </li>
  );
};

export default ViewCard;
