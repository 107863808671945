import { useEffect } from "react";
import backArrow from "../../../images/svg/backArrow.svg";
import passArrow from "../../../images/svg/passArrow.svg";

const PageNumber = ({ currentPage, backPage, nextPage, limitPage }) => {
  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <img src={backArrow} onClick={backPage} style={{ cursor: "pointer" }} />
      <div>
        {currentPage} - {limitPage}
      </div>
      <img src={passArrow} onClick={nextPage} style={{ cursor: "pointer" }} />
    </div>
  );
};

export default PageNumber;
