import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const AdjustmentContainer = () => {
  const [params, setParams] = useState([]);
  const [categories, setCategories] = useState([]);
  const [generalAdjustment, setGeneralAdjustment] = useState('');
  const [adjustmentsArray, setAdjustmentsArray] = useState([]);

  const standardCategories = [
    'Recebimento',
    'Expedição',
    'Armazenagem',
    'Invenvário',
    'Seguro de Armazenagem',
  ];

  const inputStyle = {
    border: '1px solid #D7D7D7',
    height: 37,
    borderRadius: 5,
    maxWidth: 150,
    padding: 10,
    fontSize: 12,
  };

  const getLastAdjustments = async () => {
    try {
      const array = await Promise.all(params.map(async (param) => {
        const response = await api.get(`/adjustment/${param.id}`)
        if (response.data && response.data.length > 0) {
          response.data[0].newAdjustment = "0"
          return response.data[0]
        } else {
          return ({
            param_id: param.id,
            adjustment: 0,
            initial_price: Number(param.price),
            last_price: Number(param.price),
            adjusted_price: Number(param.price),
            date: 'sem reajuste',
            newAdjustment: "0",
          })
        }
      }))
      setAdjustmentsArray(array);
    } catch (error) {
      toast.error('Não foi possível encontrar reajuste para o parâmetro');
    }
  };

  const getParameters = async () => {
    try {
      const cnpj = localStorage.getItem('@XPM_CLIENT_COMPANY:');
      const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
      const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
      const response = await api.get(`/parameter/${cnpj}/${year}-${month}`);
      if (response.data && response.data.length > 0) {
        setParams(response.data);
      }
    } catch (error) {
      console.log(error.message);
      toast.error('Desculpe ocorreu um erro, contate o administrador', { autoClose: 2000 })
    }
  };

  const postAdjustment = async (param) => {
    try {
      const { newAdjustment, param_id, initial_price, client_cnpj } = adjustmentsArray.filter((e) => e.param_id === param.id)[0];
      const cleanValue = Number(newAdjustment.replace(/[^0-9.-]/g, ''));
      const payload = {
        adjustment: cleanValue / 100,
        param_id,
        initial_price,
        client_cnpj,
      };
      const response = cleanValue != 0 ? await api.post('/adjustment', payload) : {};
      if (response.data && response.data.id) {
        getParameters();
        toast.success('Reajuste efetuado com sucesso', { autoClose: 2000 })
      }
    } catch (error) {
      console.log(error)
      toast.error('Desculpe ocorreu um erro, contate o administrador', { autoClose: 2000 })
    }
  };

  const postManyAdjustments = async () => {
  try {
    const responseArray = await Promise.all(adjustmentsArray.map(async (e) => {
      const { newAdjustment, param_id, initial_price } = e;
      const cleanValue = Number(newAdjustment.replace(/[^0-9.-]/g, ''));
      const payload = {
        adjustment: cleanValue / 100,
        param_id,
        initial_price,
      };

      // Using await here to ensure that the promise is resolved before moving to the next iteration
      return await api.post(`/adjustment`, payload);
    }));

    // Check for response status or any other conditions that determine success
    const isSuccess = responseArray.every(response => response.status === 201);

    if (isSuccess) {
      // Only call getParameters if all adjustments were successfully posted
      getParameters();
      setGeneralAdjustment('');
      toast.success('Todos os parâmetros foram reajustados', { autoClose: 2000 });
    } else {
      // Handle error condition if needed
      toast.error('Não foi possível salvar um ou mais reajustes', { autoClose: 2000 });
    }
  } catch (error) {
    // Handle errors that may occur during the Promise.all or api.post
    console.error('Error posting adjustments:', error);
  }
};

  const adjustElement = (id, value) => {
    const cleanValue = value.replace(/[^0-9.-]/g, '');
    const newArray = [...adjustmentsArray];
    const index = newArray.findIndex((e) => e.param_id === id);
    newArray[index].newAdjustment = cleanValue;
    setAdjustmentsArray(newArray);
  };

  const adjustAll = (value) => {
    const cleanValue = value.replace(/[^0-9.-]/g, '');
    const newArray = [...adjustmentsArray];
    newArray.forEach((adjustment) => {
      adjustment.newAdjustment = cleanValue;
    });
    setAdjustmentsArray(newArray);
    setGeneralAdjustment(`${cleanValue}%`);
  };

  const getParamCurrentData = (param) => {
    const filtered = adjustmentsArray.filter((e) => e.param_id === param.id);

    if (filtered.length > 0) {
      return filtered[0];
    } else {
      return param.price
    }
  };

  const dateFormatter = (string) => {
    const array = string.split('T')[0].split('-')
    return `${array[2]}/${array[1]}/${array[0]}`
  };

  useEffect(() => {
    getParameters();
  }, []);

  useEffect(() => {
    getLastAdjustments();
  }, [params]);

  useEffect(() => {
    if (params.length > 0 && categories.length === 0) {
      const filteredCategories = standardCategories.filter((category) => {
        const usedProcess = params.map((param) => param.process);
        return usedProcess.includes(category);
      });

      setCategories(
        filteredCategories.map((category) => ({
          name: category,
        }))
      );
    }
  }, [params]);

  return (
    <main style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', paddingRight: 10 }} className="scrollLower">
      <label style={{ marginBottom:15 }}>
        <p className="titleNameCompanyCard">Porcentagem geral</p>
        <input
          type="string"
          style={inputStyle}
          value={generalAdjustment || '0%'}
          onChange={(e) => adjustAll(e.target.value)}
          onBlur={() => postManyAdjustments()}
        />
      </label>
      {categories.map((category) => (
        <section key={category.name} style={{ marginBottom: 36 }}>
          <h3 className="textTitleDetailsInvoicing">{category.name}</h3>
          {params
            .filter((param) => param.process === category.name)
            .map((param) => (
              <div
                key={`${param.process}-${param.description}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  border: '1px solid #D7D7D7',
                  borderRadius: 10,
                  gap: 10,
                  height: 84,
                  padding: '17px 25px',
                  marginTop: '14px',
                  justifyContent: 'space-between',
                }}
              >
                <p style={{ width: 150 }} className="textContentDetailsInvoicing">
                  {param.description}
                </p>
                <div style={{ borderRight: '1px solid #D7D7D7', width: 1, height: '100%' }}></div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <p className="titleNameCompanyCard">{param.unit.split(': ')[1]}</p>
                  <p className="textTitleValueCard">UOM</p>
                </div>
                <div style={{textAlign: 'center'}}>
                  <p className="titleNameCompanyCard">
                    {
                      (getParamCurrentData(param).adjusted_price || 0).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }
                  </p>
                  <p className="textTitleValueCard">Preço atual</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <p className="titleNameCompanyCard">
                    {
                      adjustmentsArray.length && adjustmentsArray.some((e) => e.param_id === param.id && e.date && e.adjustment > 0) ? `${getParamCurrentData(param).adjustment * 100}% em ${dateFormatter(getParamCurrentData(param).createdAt)}` : <p>sem reajuste</p>
                    }
                  </p>
                  <p className="textTitleValueCard">Último reajuste</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <p className="titleNameCompanyCard">
                    {
                      (getParamCurrentData(param).adjusted_price * (1 +  Number(getParamCurrentData(param).newAdjustment) / 100) || 0).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }
                  </p>
                  <p className="textTitleValueCard">Preço reajustado</p>
                </div>
                <label>
                  <p className="titleNameCompanyCard">Porcentagem reajuste</p>
                  <input
                    type="string"
                    style={inputStyle}
                    value={`${ getParamCurrentData(param).newAdjustment || 0}%`}
                    onChange={(e) => adjustElement(param.id, e.target.value)}
                    onBlur={() => postAdjustment(param)}
                  />
                </label>
              </div>
            ))}
        </section>
      ))}
    </main>
  );
};

export default AdjustmentContainer;
