import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

const CardContractualParameter = ({
  title,
  listMonths,
  listMonthsTwo,
  max,
  nameOne,
  nameTwo,
  metrics,
  content,
  labels,
  labelColOne,
  labelColTwo,
  html,
  height,
  heightGraphics,
  contentTwo,
  contentThree,
}) => {
  const calculateRepeatedAverage = (arr) => {
    const newArray = metrics ? Array(arr.length).fill(Number(metrics)) : 0;
    return newArray;
  };

  const chartData = {
    labels: labels ?? [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    datasets: [
      {
        label: labelColOne ?? "Dentro do prazo",
        backgroundColor: "#96C",
        data: listMonths,
      },
      {
        label: labelColTwo ?? "Fora do prazo",
        backgroundColor: "#d7d7d7d7",
        data: listMonthsTwo,
      },
      {
        label: "Métrica de Contrato",
        type: "line",
        borderColor: "#FFA776",
        fill: false,
        data: calculateRepeatedAverage(listMonths),
        pointRadius: 0,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
        max: max,
      },
    },
    plugins: {
      legend: {
        display: false, // Remove a exibição da legenda
      },
      title: {
        display: true,
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    elements: {
      bar: {
        borderWidth: 1,
        borderRadius: 4,
      },
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, // Remove a grade de fundo no eixo X
        },
      },
      y: {
        grid: {
          display: false, // Remove a grade de fundo no eixo Y
        },
        type: "linear",
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    console.log(labelColOne);
  }, [labelColOne]);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        width: "100%",
        height: height ?? "329px",
        borderRadius: "10px",
        border: "1px solid #d7d7d7",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <p className="textTitleContractualParameter">{title}</p>
          {html}
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              gap: "50px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div className="circleCard"></div>
              <p className="textContentContractualParameter">
                {content ?? "Métrica de Contrato"}
              </p>

              {contentTwo ? (
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    style={{
                      backgroundColor: contentTwo?.cicle,
                      width: 10,
                      height: 10,
                      borderRadius: 60,
                    }}
                  />
                  <p className="textContentContractualParameter">
                    {contentTwo?.label}
                  </p>
                </div>
              ) : (
                <></>
              )}

              {contentThree ? (
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    style={{
                      backgroundColor: contentThree?.cicle,
                      width: 10,
                      height: 10,
                      borderRadius: 60,
                    }}
                  />
                  <p className="textContentContractualParameter">
                    {contentThree?.label}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>

            {nameOne && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="circleCard"
                  style={{ backgroundColor: "#96C" }}
                ></div>
                <p className="textContentContractualParameter">{nameOne}</p>
              </div>
            )}

            {nameTwo && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="circleCard"
                  style={{ backgroundColor: "#d7d7d7d7" }}
                ></div>
                <p className="textContentContractualParameter">{nameTwo}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: heightGraphics ?? "270px", margin: "10px 0" }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default CardContractualParameter;
