import ContainerContactedGraphics from "./ContainerContactedGraphics";
import ContainerContractualParameter from "./ContainerContractualParameter";
import ContainerInbound from "./ContainerInbound";
import ContainerLabeling from "./ContainerLabeling";
import ContainerOccupation from "./ContainerOccupation";
import ContainerOutbound from "./ContainerOutbound";
import ContainerQuality from "./ContainerQuality";
import ContainerStockValue from "./ContainerStockValue";

const ContentDashboard = ({ btnState }) => {
  return (
    <>
      {
        (btnState == "KPIS" && <ContainerContractualParameter />) ||
          (btnState == "Entrada" && <ContainerInbound />) ||
          (btnState == "Saída" && <ContainerOutbound />) ||
          (btnState == "Urgente" && <ContainerOccupation />) ||
          (btnState == "Contratada vs Realizada" && (
            <ContainerContactedGraphics />
          ))
        // (btnState == "Armazenagem" && <ContainerLabeling />) ||
        // btnState == 'Qualidade' && <ContainerQuality />
        // (btnState == "Qualidade" && <ContainerOccupation />) ||
        // (btnState == "Valor do estoque" && <ContainerStockValue />)
      }
    </>
  );
};

export default ContentDashboard;
