import { useState } from "react";
import camera from "../../../images/svg/camera.svg";
import imageDefaultUser from "../../../images/svg/imageDefaultUser.svg";

const ImageUser = ({ image }) => {
  const [imageRender, setImageRender] = useState(image);

  const containerImageStyle = {
    maxWidth: "100px",
    maxHeight: "100px",
    position: "relative",
  };

  const imageStyle = {
    width: "100px",
    height: "100px",
    borderRadius: "100%",
    objectFit: "cover",
  };

  const cameraStyle = {
    position: "absolute",
    bottom: "5px",
    right: "5px",
  };

  return (
    <>
      <label for="image_uploads" style={{ cursor: "pointer" }}>
        <div style={containerImageStyle}>
          {imageRender ? (
            <img src={imageRender} style={imageStyle} />
          ) : image ? (
            <img src={image} style={imageStyle} />
          ) : (
            <img src={imageDefaultUser} />
          )}
          <div style={cameraStyle}>
            <img src={camera} />
          </div>
        </div>
      </label>
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        id="image_uploads"
        style={{ display: "none" }}
        onChange={(e) => setImageRender(URL.createObjectURL(e.target.files[0]))}
      />
    </>
  );
};

export default ImageUser;
