import React, { useState } from "react"
import Legend from "./Legend";
import PendingCard from "./PendingCard";
import PendingIndicators from "./PendingIndicators";
import ModalContainer from "../components/ModalContainer";
import ModalDetails from "../components/ModalDetails";
import closeIcon from '../../../images/svg/closeIcon.svg';
import { pendingCardsData } from "./mocks/pendingCardsData";

const ContainerContentPendencies = () => {

    const [selectedCard, setSelectedCard] = useState(null);
    const [modalDetailsOpen, setModalDetailsOpen] = useState(false);

    const handleCardClick = (pending) => {
        setSelectedCard(pending);
        setModalDetailsOpen(true);
    };

    const handleModalClose = () => {
        setModalDetailsOpen(false);
    };

    const container = {
        marginTop: "40px",
    };

    const styleList = {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        padding: "0",
        marginTop: "15px"
    };

    return (
        <main style={container}>
            <PendingIndicators />

            <ul style={styleList}>
                {pendingCardsData.map((pending) => (
                    <PendingCard
                        key={pending.cardId}
                        pending={pending}
                        openModal={() => handleCardClick(pending)}
                    />
                ))}
            </ul>

            <Legend />

            <ModalContainer isActive={modalDetailsOpen} closeModal={handleModalClose}>
                <ModalDetails
                    item={selectedCard}
                    closeModal={handleModalClose}
                    setModalDetailsOpen={() => setModalDetailsOpen(true)}
                    closeIcon={closeIcon}
                />
            </ModalContainer>
        </main>
    );
};

export default ContainerContentPendencies;