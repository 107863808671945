import { useContext, useEffect } from "react";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";
import icon from "../../../images/svg/shedulesHeaderIcon.svg";
import filterIcon from "../../../images/svg/filter-icon.svg";
import { Link } from "react-router-dom";

const SearchBranch = ({ selectedDate, setOpenFilter }) => {
    const { branchs, getBrachs, selectedBranches, setSelectedBranches } =
        useContext(SchedulesContext);

    useEffect(() => {
        getBrachs();
    }, []);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    backgroundColor: "#fff",
                    border: "1px solid #d7d7d7",
                    borderRadius: "60px",
                    width: "341px",
                    padding: "10px 17px",
                }}
            >
                <p className="titleSelectShedules">Filial:</p>
                <select
                    style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                    }}
                    className="textOptionsSchedules"
                    onChange={(e) => setSelectedBranches(e.target.value)}
                    value={selectedBranches}
                >
                    <option value="">Selecione</option>
                    {branchs
                        ? branchs?.map((e) => (
                              <option key={e.id} value={e.id}>
                                  {e.branch_name}
                              </option>
                          ))
                        : null}
                </select>
            </div>

            <Link
                to={{
                    pathname: "/dashboard/scheduling/details",
                    state: { selectedDate },
                }}
            >
                <img src={icon} />
            </Link>

            <img
                src={filterIcon}
                style={{
                    cursor: "pointer",
                }}
                onClick={() => setOpenFilter(true)}
            />
        </div>
    );
};

export default SearchBranch;
