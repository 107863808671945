import React from "react";
import menuIcon from "../../../images/svg/menuIcon.svg";

const SchedulesContainer = ({ place, openMenu, setSelectedSchedule }) => {
    const getStatusStyle = (status) => {
        const stylesGeneral = {
            width: 5,
            height: "100%",
            borderRadius: 5,
        };

        const stylesLate = {
            background: "#FF4C54",
            color: "#fff",
        };

        const stylesDelivered = {
            background: "#E0FFF0",
            color: "#00B386",
        };

        const stylesWaiting = {
            background: "#FFF7AA",
            color: "#C6B200",
        };

        const closedWindow = {
            background: "#F2F2F2",
            color: "#784A86",
        };

        switch (status) {
            case "atrasado":
                return { ...stylesGeneral, ...stylesLate };
            case "concluído":
                return { ...stylesGeneral, ...stylesDelivered };
            case "aguardando":
                return { ...stylesGeneral, ...stylesWaiting };
            case "janela fechada":
                return { ...stylesGeneral, ...closedWindow };
            case "cancelado":
                return { ...stylesGeneral, ...stylesLate };
            default:
                return stylesGeneral;
        }
    };

    return (
        <section
            style={{
                border: "1px solid #D7D7D7",
                borderRadius: 10,
                width: "33%",
                background: "#fff",
                display: "flex",
                flexDirection: "column",
                padding: 15,
                marginTop: 20
            }}
        >
            <header
                style={{
                    width: "100%",
                    display: "flex",
                    borderBottom: "1px solid #D7D7D7",
                    paddingBottom: 15,
                }}
            >
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        borderRight: "1px solid #D7D7D7",
                    }}
                >
                    <p className="textScheduleListData">
                        {place.local.local_name}
                    </p>
                    <p className="textScheduleListFooter">{place.local.type}</p>
                </div>

                <div
                    style={{
                        display: "flex",
                        alingItem: "center",
                        justifyContent: "flex-end",
                        width: "50%",
                    }}
                >
                    <p
                        style={{
                            background: "#F7F9FB",
                            color: "#9966CC",
                            padding: "10px 25px",
                            borderRadius: 20,
                        }}
                        className="textScheduleListData"
                    >
                        {place.schedules.length}
                    </p>
                </div>
            </header>

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "scroll",
                    marginTop: 10,
                    paddingRight: 10,
                }}
                className="scrollLower"
            >
                {place.schedules
                    ? place.schedules
                          .filter((e) => e.place === place.id)
                          .map((schedule) => (
                              <article
                                  style={{
                                      width: "100%",
                                      display: "flex",
                                      flexWrap: "no-wrap",
                                      justifyContent: "space-between",
                                      borderBottom: "1px solid #D7D7D7",
                                      paddingBottom: 10,
                                      marginBottom: 10,
                                      marginTop: 10,
                                  }}
                                  onClick={() =>
                                      setSelectedSchedule({ place, schedule })
                                  }
                              >
                                  <div
                                      style={{
                                          width: "100%",
                                          display: "flex",
                                          gap: 10,
                                          alignItems: "center",
                                      }}
                                  >
                                      <div>
                                          <p className="textScheduleListFooter">
                                              {schedule.start_time.substring(
                                                  schedule.start_time.length - 5
                                              )}
                                          </p>
                                      </div>

                                      <div
                                          style={getStatusStyle(
                                              schedule.status
                                          )}
                                      />

                                      <div
                                          style={{
                                              width: "100%",
                                          }}
                                      >
                                          <p className="textScheduleListData">
                                              {schedule.transporter_name}
                                          </p>
                                          <p className="textScheduleListFooter">{`${schedule.vehicle_plate} - ${schedule.vehicle_model}`}</p>
                                      </div>
                                  </div>

                                  <button
                                      style={{
                                          background: "none",
                                          border: "none",
                                      }}
                                      onClick={() => openMenu(schedule.id)}
                                  >
                                      <img src={menuIcon} alt="schedule-menu" />
                                  </button>
                              </article>
                          ))
                    : null}
            </div>
        </section>
    );
};

export default SchedulesContainer;
