import React, { useContext } from "react";
import flagDownIcon from "../../../images/svg/flagDown.svg";
import dotsIcon from "../../../images/svg/dots.svg"
import { ExternalDocumentsContext } from "../../../context/Quality/ExternalDocumentsContext";

const DocumentIndicators = () => {

    const { setModalColumnsCardOpen } = useContext(ExternalDocumentsContext);

    const container = {
        width: "999px",
        height: "73px",
        borderRadius: "10px 10px 0 0",
        border: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px 20px 20px 65px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleIndicatorBox = {
        gap: "10px",
        display: "flex",
        alignItems: "center"
    };

    const styleIndicator = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        fontWeight: 500,
    };

    return (
        <div style={container}>
            <div style={{ ...styleIndicatorBox, width: "266px" }}>
                <h4 style={styleIndicator}>Título</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{ ...styleIndicatorBox, width: "166px" }}>
                <h4 style={styleIndicator}>Distribuição</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{ ...styleIndicatorBox, width: "166px" }}>
                <h4 style={styleIndicator}>Localização</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{ ...styleIndicatorBox, width: "166px" }}>
                <h4 style={styleIndicator}>Responsável</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{ ...styleIndicatorBox, width: "135px" }}>
                <h4 style={styleIndicator}>Validade</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{ width: "14px", display: "flex", justifyContent: "flex-end" }}>
                <img src={dotsIcon} onClick={() => setModalColumnsCardOpen(true)} />
            </div>
        </div>
    );
};

export default DocumentIndicators;