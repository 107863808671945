import moment from "moment";
import CardGraphicsLarge from "./CardGraphicsLarge";
import CardYear from "./CardYear";
import CardsDetailsLabeling from "./CardsDetailsLabeling";
import { useContext, useEffect, useState } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";

const ContainerLabeling = () => {
  const [dateMoment, setDateMoment] = useState(moment().format("YYYY"));
  const { updateStorages, getStoragesYears } = useContext(
    GraphsAndParametersContext
  );

  useEffect(() => {
    getStoragesYears();
  }, [dateMoment]);

  return (
    <div
      style={{
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <CardYear
        dateMoment={dateMoment}
        setDateMoment={setDateMoment}
        updateIcon={true}
        updateFunction={() => updateStorages()}
      />
      <CardsDetailsLabeling />

      {/* <CardGraphicsLarge max={1200} title={"Unidades etiquetadas x Contrato"} listMonths={[1100, 985, 1205, 2000, 850, 730, 999, 921, 506, 1093, 1001, 804]} /> */}
    </div>
  );
};

export default ContainerLabeling;
