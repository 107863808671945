import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

export const AuthContext = createContext({});

export const AuthValidation = ({ children }) => {
  const navigate = useNavigate();
  const [nameClient, setNameClient] = useState("");

  const getInfoUser = async () => {
    try {
      const response = await api.get("/client/profile");
      console.log('login', response.data)
      localStorage.setItem("@XPM_CLIENT_COMPANY:", response.data.cnpj);
      localStorage.setItem("@XPM_CLIENT_SELECTED", JSON.stringify(response.data));
      setNameClient(response.data.name);
    } catch (error) {}
  };

  const sessionLogin = async (data) => {
    try {
      const response = await api.post("/session/client", data);

      const { token } = response.data;

      localStorage.setItem("@XPM_CLIENT_TOKEN:", token);
      await getInfoUser();
      navigate("/dashboard", { replace: true });
    } catch (error) {
      if (error.response.status == 403) {
        toast.info(error.response.data.error, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          sessionLogin,
          nameClient,
          getInfoUser,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
