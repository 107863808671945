import moment from "moment";

const CardMinInsurance = ({
  date,
  valueStock,
  invoicedStock,
  positiveOrNegative,
}) => {
  /* STYLES */

  const containerCard = {
    border: "1px solid #79797970",
    display: "flex",
    gap: "15px",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    width: "49%",
    justifyContent: "space-between",
  };

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  };

  return (
    <div style={containerCard}>
      <div style={contentValueCard}>
        <p className="titleNameCompanyCard">
          {" "}
          {moment.utc(date).format("DD/MM/YYYY")}
        </p>
        <p className="textTitleValueCard">Data</p>
      </div>

      <div style={contentValueCard}>
        <p className="titleNameCompanyCard">
          {valueStock.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </p>
        <p className="textTitleValueCard">Valor do estoque</p>
      </div>

      <div style={contentValueCard}>
        <p className="titleNameCompanyCard">
          {invoicedStock.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </p>
        <p className="textTitleValueCard">Estoque faturado</p>
      </div>
    </div>
  );
};

export default CardMinInsurance;
