import React from 'react';

const DocumentCardToList = ({ subDocuments }) => {

    const container = {
        width: "999px",
        height: "73px",
        border: "1px solid var(--grey-0)",
        backgroundColor: "#F6F6F6",
        padding: "20px 20px 20px 65px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleValue = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        fontWeight: 400,
    };

    return (
        <>
            {subDocuments.map(subDocument => (
                <li key={subDocument.id} style={container}>
                    <div style={{ width: "120px" }}>
                        <p style={styleValue}>{subDocument.code}</p>
                    </div>
                    <div style={{ width: "496px" }}>
                        <p style={styleValue}>{subDocument.title}</p>
                    </div>
                    <div style={{ width: "146px" }}>
                        <p style={styleValue}>{subDocument.category}</p>
                    </div>
                    <div style={{ width: "154px" }}>
                        <p style={styleValue}>{subDocument.date}</p>
                    </div>
                </li>
            ))}
        </>
    );
};

export default DocumentCardToList;
