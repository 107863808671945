import React, { useEffect, useState } from 'react'
import BoxParameterSelect from './BoxParameterSelect'
import deleteIcon from '../../../images/svg/deleteIcon.svg';
import UploadInput from './UploadInput';
import { read, utils } from "xlsx";
import { toast } from "react-toastify";
import api from '../../../services/api';

const GroupingCard = (
  {
    index,
    handleDelete,
    handleChange,
    item,
    setGroupingModal,
    groupingModal,
    setListStructures,
    allItems,
    allGroups,
  }
) => {

  /* TABLE GROUPS */
  const [process, setProcess] = useState(item.process);
  const [name, setName] = useState(item.name);

  /* TABLE GROUPS FILES */
  const [content, setContent] = useState([]);
  const [fileCvg, setFileCvg] = useState([]);
  const [stock, setStock] = useState([]);

  /* AVALIABLE GROUPS */
  const [usedGroup, setUsedGroup] = useState(false);
  const [tryingUpload, setTryingUpload] = useState(false);

  const createStructures = async () => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    await Promise.all(
      stock.map((structure) => (
        api.post('/structures', {
          code: `${structure['Código Estrutura']}`,
          cubagem_logix: structure['Cubagem'],
          group_id: item.id,
          date: `${year}-${month}`
        })
      ))
    );
  };

  const getStructures = async () => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    const date = `${year}-${month}`;
    const response = await api.get(`/structures/all/${item.id}/${date}`);
    if (response.data && response.data.length > 0) {
      setContent(response.data)
    }
  };

  const uploadList = async (file) => {
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Formato de arquivo inválido!", {
        autoClose: 2000,
      });
      return false;
    }

    const data = await file.arrayBuffer();

    const workbook = read(data);

    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheet = utils.sheet_to_json(workSheet);

    setFileCvg(file);
    setStock(worksheet);
    setTryingUpload(false);

    console.log(worksheet);
  };

  useEffect(() => {

    if (stock.length > 0) {
      const data = stock.map((item) => {
        return { code: item["Código Estrutura"], cubagem_logix: item["Cubagem"], client: item["Cliente"] };
      });

      setContent(data);
    }
  }, [stock]);

  useEffect(() => {
    const used = allItems.filter((e) => e.process === process && e.name === name);
    console.log('verificando se o grupo existe', used)
    setUsedGroup(used.length > 1);
  }, [allItems, process, name])

  useEffect(() => {
    createStructures();
  }, [stock]);

  useEffect(() => {
    if (!groupingModal) {
      getStructures();
    }
  }, [item, groupingModal]);

  const articleStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  };

  return (
    <article style={articleStyle}>
      <BoxParameterSelect
        valueInput={item.process || 'Selecione'}
        onChange={(e) => setProcess(e.target.value)}
        onBlur={(e) => handleChange(e, index)}
        // width={"30%"}
        wait={true}
        number={index + 1}
        type={"process"}
        name="process"
      />
      <BoxParameterSelect
        valueInput={item.name || 'Selecione'}
        onChange={(e) => setName(e.target.value)}
        onBlur={(e) => handleChange(e, index)}
        // width={"30%"}
        wait={true}
        type={"structureGroup"}
        name="name"
        options={allGroups}
      />
      <UploadInput
        uploadList={uploadList}
        content={content}
        setGroupingModal={setGroupingModal}
        setListStructures={setListStructures}
        index={index}
        tryingUpload={tryingUpload}
        setTryingUpload={setTryingUpload}
        disabled={usedGroup || process === undefined || name === undefined || name === 'Selecione' || process === 'Selecione'}
      />
      <button
        onClick={() => handleDelete(item.id)}
        style={{background: 'none', border: 'none', height: '100%', display: 'flex', marginTop: 40}}
      >
        <img src={deleteIcon} alt="del" />
      </button>
      {
        usedGroup && tryingUpload ? <p style={{color: 'red'}}>Grupo inválido ou repetido</p> : null
      }
    </article>
  )
};

export default GroupingCard;
