import React from "react";

const BoxSchedulesList = ({ schedules, active }) => {
    const stylesContainer = {
        background: "#fff",
        border: "1px solid #D7D7D7",
        borderRadius: 10,
        padding: "20px 10px 20px 30px",
        width: "100%",
    };

    const stylesListContainer = {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        overflowY: "scroll",
        paddingRight: 10,
        maxHeight: 400,
    };

    const stylesScheduleCard = {
        border: "1px solid #D7D7D7",
        borderRadius: 10,
        display: "grid",
        // gridTemplateColumns: " 1fr 1fr 1fr 0.5fr 2fr 2fr 2.5fr 2fr 2fr",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 2fr 2fr 2.5fr 2fr 2fr",
        minHeight: 70,
        marginTop: 15,
        padding: "20px 10px 20px 30px",
        width: "100%",
    };

    const stylesCardData = {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        height: "100%",
    };

    const getStatusStyle = (status) => {
        const stylesGeneral = {
            width: "100%",
            // height: "100%",
            borderRadius: 5,
        };

        const stylesWaiting = {
            background: "#F9F3FF",
            color: "#9966CC",
        };

        const stylesLate = {
            background: "#FFF7AA",
            color: "#C6B200",
        };

        const closedWindow = {
            background: "#d7d7d710",
            color: "#d7d7d7",
        };

        const stylesCancelled = {
            background: "#FFE1E1",
            color: "#EA0000",
        };

        const stylesDelivered = {
            background: "#E0FFF0",
            color: "#00B386",
        };

        switch (status) {
            case "aguardando":
                return { ...stylesGeneral, ...stylesWaiting };
            case "atrasado":
                return { ...stylesGeneral, ...stylesLate };
            case "janela fechada":
                return { ...stylesGeneral, ...closedWindow };
            case "cancelado":
                return { ...stylesGeneral, ...stylesCancelled };
            case "concluído":
                return { ...stylesGeneral, ...stylesDelivered };
            default:
                return stylesGeneral;
        }
    };

    return (
        <main style={stylesContainer}>
            <h5>
                {active === "Recebimentos"
                    ? "Programação de entrega"
                    : "Programação de coleta"}
            </h5>

            <section style={stylesListContainer}>
                {schedules?.map((data) => {
                    const [datePart, timePart] = data.start_time.split(" ");

                    const [year, month, day] = datePart.split("-");
                    const formattedDateString = `${day.padStart(
                        2,
                        "0"
                    )}/${month.padStart(2, "0")}/${year}`;

                    const [hour, minute] = timePart.split(":");
                    const formattedTimeString = `${hour.padStart(
                        2,
                        "0"
                    )}:${minute.padStart(2, "0")}:00`;

                    return (
                        <div style={stylesScheduleCard}>
                            <div style={stylesCardData}>
                                <p className="textScheduleListData">
                                    {formattedDateString}
                                </p>
                                <p className="textScheduleListFooter">Dia</p>
                            </div>

                            <div style={stylesCardData}>
                                <p className="textScheduleListData">
                                    {formattedTimeString}
                                </p>
                                <p className="textScheduleListFooter">
                                    Horário
                                </p>
                            </div>

                            <div style={stylesCardData}>
                                <p className="textScheduleListData">
                                    {data.local.local_name}
                                </p>
                                <p className="textScheduleListFooter">
                                    N° Doca
                                </p>
                            </div>

                            <div style={stylesCardData}>
                                <p className="textScheduleListData">
                                    {data.cesv}
                                </p>
                                <p className="textScheduleListFooter">CESV</p>
                            </div>

                            <div style={stylesCardData}>
                                <p className="textScheduleListData">
                                    {data.vehicle_model}
                                </p>
                                <p className="textScheduleListFooter">
                                    Tipo de veículo
                                </p>
                            </div>

                            <div style={stylesCardData}>
                                <p className="textScheduleListData">
                                    {data.vehicle_plate}
                                </p>
                                <p className="textScheduleListFooter">Placa</p>
                            </div>

                            {/*<div style={stylesCardData}>
                                <p className="textScheduleListData">
                                     {schedule.origin} 
                                </p>
                                <p className="textScheduleListFooter">
                                    Origem do processo
                                </p>
                            </div>*/}

                            <div style={stylesCardData}>
                                <p className="textScheduleListData">
                                    {data.client.name}
                                </p>
                                <p className="textScheduleListFooter">
                                    Depositante
                                </p>
                            </div>

                            <div style={stylesCardData}>
                                <p className="textScheduleListData">
                                    {data.transporter_name}
                                </p>
                                <p className="textScheduleListFooter">
                                    Transportadora
                                </p>
                            </div>

                            <div style={stylesCardData}>
                                <p
                                    className="textScheduleListData"
                                    style={getStatusStyle(data.status)}
                                >
                                    {data.status}
                                </p>
                                <p className="textScheduleListFooter">Status</p>
                            </div>
                        </div>
                    );
                })}
            </section>
        </main>
    );
};

export default BoxSchedulesList;
