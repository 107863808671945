const mockBillingByMonth = [
  {
    year: 2023,
    month: 12,
    clients: [
      {
        client: '101DB COMERCIO',
        cnpj: '024447702000267',
        billing: 180000,
        monthlyBilling: 145000,
      },
      {
        client: 'ARION',
        cnpj: '021659802000188',
        billing: 75000,
        monthlyBilling: 80000,
      },
      {
        client: 'AGRARIA SP',
        cnpj: '077890846004409',
        billing: 150000,
        monthlyBilling: 150000,
      },
      {
        client: 'BD IND',
        cnpj: '021551379002141',
        billing: 175000,
        monthlyBilling: 80000,
      },
    ]
  },
  {
    year: 2023,
    month: 11,
    clients: [
      {
        client: '101DB COMERCIO',
        cnpj: '024447702000267',
        billing: 160000,
        monthlyBilling: 145000,
      },
      {
        client: 'ARION',
        cnpj: '021659802000188',
        billing: 72000,
        monthlyBilling: 80000,
      },
      {
        client: 'AGRARIA SP',
        cnpj: '077890846004409',
        billing: 140000,
        monthlyBilling: 150000,
      },
      {
        client: 'BD IND',
        cnpj: '021551379002141',
        billing: 165000,
        monthlyBilling: 80000,
      },
    ]
  },
  {
    year: 2023,
    month: 10,
    clients: [
      {
        client: '101DB COMERCIO',
        cnpj: '024447702000267',
        billing: 155000,
        monthlyBilling: 145000,
      },
      {
        client: 'ARION',
        cnpj: '021659802000188',
        billing: 90000,
        monthlyBilling: 80000,
      },
      {
        client: 'AGRARIA SP',
        cnpj: '077890846004409',
        billing: 145000,
        monthlyBilling: 150000,
      },
      {
        client: 'BD IND',
        cnpj: '021551379002141',
        billing: 160000,
        monthlyBilling: 80000,
      },
    ]
  },
  {
    year: 2023,
    month: 9,
    clients: [
      {
        client: '101DB COMERCIO',
        cnpj: '024447702000267',
        billing: 150000,
        monthlyBilling: 145000,
      },
      {
        client: 'ARION',
        cnpj: '021659802000188',
        billing: 70000,
        monthlyBilling: 80000,
      },
      {
        client: 'AGRARIA SP',
        cnpj: '077890846004409',
        billing: 140000,
        monthlyBilling: 150000,
      },
      {
        client: 'BD IND',
        cnpj: '021551379002141',
        billing: 155000,
        monthlyBilling: 80000,
      },
    ]
  },
];

export default mockBillingByMonth;
