import { useContext, useEffect, useRef, useState } from "react";
import { ContactedContext } from "../../../context/Dashboard/ContactedContext";

const FilterContacted = ({ visible, type, setLineOrQtd }) => {
  const {
    onContacted,
    weekOrMonth,
    setWeekOrMonth,
    numberWeek,
    setNumberWeek,
  } = useContext(ContactedContext);
  const isFirstRender = useRef(true);

  const onGetContactedVSAccomplished = () => {
    try {
      onContacted(weekOrMonth == "week" && numberWeek);
    } catch (error) {}
  };

  useEffect(() => {
    if (isFirstRender.current) {
      // Na primeira renderização, marcamos como false
      isFirstRender.current = false;
    } else {
      // Executa apenas nas mudanças subsequentes de numberWeek
      onGetContactedVSAccomplished();
    }
  }, [weekOrMonth, numberWeek]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        marginBottom: "20px",
        gap: "30px",
      }}
    >
      <label
        className="textContentContractualParameter"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        Tipo de visualização
        <select
          onChange={(e) => setWeekOrMonth(e.target.value)}
          style={{
            border: "none",
            borderBottom: "1px solid #d7d7d7",
            outline: "none",
          }}
        >
          <option value={"week"}>Semanal</option>
          <option value={"month"} selected>
            Mensal
          </option>
        </select>
      </label>

      {weekOrMonth == "week" && (
        <label
          className="textContentContractualParameter"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          Selecione a semana
          <select
            style={{
              border: "none",
              borderBottom: "1px solid #d7d7d7",
              outline: "none",
            }}
            onChange={(e) => setNumberWeek(e.target.value)}
          >
            <option value={1} selected>
              1º semana
            </option>
            <option value={2}>2º semana</option>
            <option value={3}>3º semana</option>
            <option value={4}>4º semana</option>
            <option value={5}>5º semana</option>
            <option value={6}>6º semana</option>
          </select>
        </label>
      )}

      <label
        className="textContentContractualParameter"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        Linha ou quantidade
        <select
          onChange={(e) => setLineOrQtd(e.target.value)}
          style={{
            border: "none",
            borderBottom: "1px solid #d7d7d7",
            outline: "none",
          }}
        >
          <option value={"line"}>Em linha</option>
          <option value={"qtd"}>Em Unidades</option>
        </select>
      </label>
    </div>
  );
};

export default FilterContacted;
