

const CardsDetailsYear = ({data}) => {
   let total = 0
    /* MODEL RESPONSE LIST DETAILS */

    // const listNF = [
    //     { month: "Jan", value: "2.058" },
    //     { month: "Fev", value: "3.712" },
    //     { month: "Mar", value: "4.923" },
    //     { month: "Abr", value: "2.189" },
    //     { month: "Mai", value: "5.378" },
    //     { month: "Jun", value: "3.879" },
    //     { month: "Jul", value: "6.120" },
    //     { month: "Ago", value: "4.237" },
    //     { month: "Set", value: "2.901" },
    //     { month: "Out", value: "3.654" },
    //     { month: "Nov", value: "4.432" },
    //     { month: "Dez", value: "5.345" },
    // ];

    // const listLine = [
    //     { month: "Jan", value: "1.958" },
    //     { month: "Fev", value: "3.205" },
    //     { month: "Mar", value: "4.567" },
    //     { month: "Abr", value: "2.856" },
    //     { month: "Mai", value: "4.945" },
    //     { month: "Jun", value: "2.457" },
    //     { month: "Jul", value: "3.876" },
    //     { month: "Ago", value: "1.789" },
    //     { month: "Set", value: "5.298" },
    //     { month: "Out", value: "4.134" },
    //     { month: "Nov", value: "2.980" },
    //     { month: "Dez", value: "1.502" },
    // ];

    // const listUnits = [
    //     { month: "Jan", value: "6.258" },
    //     { month: "Fev", value: "5.768" },
    //     { month: "Mar", value: "4.563" },
    //     { month: "Abr", value: "7.938" },
    //     { month: "Mai", value: "6.481" },
    //     { month: "Jun", value: "8.105" },
    //     { month: "Jul", value: "6.542" },
    //     { month: "Ago", value: "4.901" },
    //     { month: "Set", value: "7.328" },
    //     { month: "Out", value: "6.918" },
    //     { month: "Nov", value: "5.403" },
    //     { month: "Dez", value: "6.972" },
    // ];

    // const listUrgency = [
    //     { month: "Jan", value: "1.635" },
    //     { month: "Fev", value: "2.560" },
    //     { month: "Mar", value: "3.389" },
    //     { month: "Abr", value: "2.876" },
    //     { month: "Mai", value: "1.987" },
    //     { month: "Jun", value: "3.702" },
    //     { month: "Jul", value: "2.421" },
    //     { month: "Ago", value: "3.809" },
    //     { month: "Set", value: "1.935" },
    //     { month: "Out", value: "2.710" },
    //     { month: "Nov", value: "2.489" },
    //     { month: "Dez", value: "1.803" },
    // ];


    const container = {
        display: "flex",
        flexDirection: "column",
        gap: "13px"
    }

    const containerContent = {
        width: "100%",
        padding: "20px 24px",
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
        borderRadius: "10px"
    }

    const contentValueCard = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
        whiteSpace: "nowrap"
    }

    return (
        <div style={container}>
            {/* LIST NFS */}
           <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", minHeight: "55px",overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>
 
                    <div style={{ ...contentValueCard, position: "absolute" }}>
                        <p className="titleNameCompanyCard">N° de pedidos/NF expedidos</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "250px" }}>
                    {
                            data.map(item => {
                                let sum = 0
                                item?.value.map(elem => sum += elem?.qtd_item[0] || 0  + elem?.qtd_item[1] || 0 )
                                total += sum    
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{(sum).toLocaleString("pt-BR")}</p>
                                        <p className="textTitleValueCard">{item.month}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>

            {/* LIST LINES */}
            {/* <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute", left: "15px" }}>
                        <p className="titleNameCompanyCard">N° de linhas expedidas</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "250px" }}>
                        {
                            listLine.map(item => {
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{item.value}</p>
                                        <p className="textTitleValueCard">{item.month}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div> */}

            {/* LIST UNITS */}
            {/* <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute" }}>
                        <p className="titleNameCompanyCard">N° de unidades expedidas</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "250px" }}>
                        {
                            listUnits.map(item => {
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{item.value}</p>
                                        <p className="textTitleValueCard">{item.month}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div> */}

            {/* LIST URGENCY */}
            {/* <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute", left: "50px" }}>
                        <p className="titleNameCompanyCard">Urgências</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "250px" }}>
                        {
                            listUrgency.map(item => {
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{item.value}</p>
                                        <p className="textTitleValueCard">{item.month}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div> */}
        </div>
    )
}

export default CardsDetailsYear