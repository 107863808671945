import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

const Gauge = ({ gaugeValue }) => {
  let textColor, feedbackText;

  if (gaugeValue >= 1 && gaugeValue <= 40) {
    textColor = "#ea0000";
    feedbackText = "Ruim";
  } else if (gaugeValue >= 41 && gaugeValue <= 80) {
    textColor = "#ECA336";
    feedbackText = "Neutro";
  } else if (gaugeValue >= 81 && gaugeValue <= 100) {
    textColor = "#00B386";
    feedbackText = "Excelente";
  };

  const styleContainer = {
    width: "385px",
    height: "230px",
    border: "1px solid var(--grey-0)",
    borderRadius: "7px",
    backgroundColor: "var(--white)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  };

  const styleValue = {
    fontSize: "36px",
    fontWeight: 500,
    color: textColor
  };

  return (
    <div style={styleContainer}>
      <ReactSpeedometer
        customSegmentStops={[0, 40, 80, 100]}
        segments={[0, 40, 80, 100]}
        width={300}
        height={180}
        minValue={0}
        maxValue={100}
        needleHeightRatio={0.7}
        needleColor='var(--grey-1)'
        segmentColors={["#EA0000", "#ECA336", "#00B386"]}
        value={gaugeValue}
        currentValueText={`Seu NPS está ${feedbackText}`}
      />
      <p style={styleValue}>{gaugeValue}</p>
    </div>
  );
};

export default Gauge;


