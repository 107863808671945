import { useContext, useEffect, useState } from "react";
import LinePercentageInput from "./LinePercentageInput";
import Button from "./Button";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import { toast } from "react-toastify";
import updateIconImg from "../../../images/png/atualizar.png";
import ModalContainer from "./ModalContainer";
import ModalAttachment from "./ModalAttachment";

const InventoryConsolidation = ({ update }) => {
  const {
    getAccuracy,
    createAccuracy,
    storeAccuracyAndConsolidation,
    consolidation,
    setStoreAccuracyAndConsolidation,
  } = useContext(GraphsAndParametersContext);
  const [months, setMonths] = useState([
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ]);

  const [isModal, setIsModal] = useState(false);

  const [accuracyPercentage, setAccuracyPercentage] = useState([
    89, 95, 84, 37, 80, 70, 80, 73, 68, 73, 81, 67,
  ]);

  const containerGeneral = {
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    backgroundColor: "#fff",
  };

  const containerMonth = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    width: "55px",
  };

  const monthText = {
    borderBottom: "1px solid #d7d7d7",
    width: "100%",
    textAlign: "center",
    padding: "6px 0",
  };

  const lineMonths = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "10px",
  };

  const lineMonthsStyle = {
    borderBottom: "2px solid #d7d7d7",
    width: "40px",
    textAlign: "center",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const monthsLineStyle = {
    borderBottom: "2px solid #d7d7d7",
    width: "100%",
    textAlign: "center",
    // padding: "5px 0",
    height: "27.65px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const monthsLineStyleMany = {
    padding: "3px 0",
    width: "100%",
    textAlign: "center",
    height: "27.65px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const lineMetaContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    width: "auto",
    overflow: "hidden",
  };

  const textLineTitle = {
    borderBottom: "1px solid #d7d7d7",
    width: "100%",
    textAlign: "center",
    padding: "6px 0",
  };

  const conteinerLineAccuracy = {
    display: "flex",
    width: "195px",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  };

  const constainerTitleAccuracy = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "10px",
  };

  const titleAccuracy = {
    borderBottom: "2px solid #d7d7d7",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const lineInputsStyle = {
    borderBottom: "2px solid #d7d7d7",
    padding: "1px 0 ",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "27.65px",
    width: "100%",
    margin: "0 auto",
  };

  const getDetails = async () => {
    try {
      await getAccuracy("CONSOLIDACAO");
    } catch (error) {}
  };

  // const createAccuracyArray = async () => {
  //   await Promise.all(
  //     storeAccuracyAndConsolidation?.map(async (elem) => {
  //       await createAccuracy(elem);
  //     })
  //   );

  //   toast.success("Salvo com sucesso!", {
  //     position: "top-right",
  //     autoClose: 2500,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //   });
  //   setStoreAccuracyAndConsolidation([]);
  // };

  useEffect(() => {
    getDetails();
  }, [update]);

  return (
    <div>
      <div style={containerGeneral}>
        <h3
          className="textMonthAccuracyTitleMaster"
          style={{
            borderBottom: "1px solid #d7d7d7",
            display: "flex",
            width: "100%",
            gap: "10px",
          }}
        >
          INVENTÁRIO CONSOLIDAÇÃO
          {/* <Button
            width="80px"
            height="auto"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Salvar"
            fontSize={13}
            onClick={() => createAccuracyArray()}
            border="none"
          /> */}
          <img
            src={updateIconImg}
            className="hoverRotation"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() => getDetails()}
          />
        </h3>
        <div style={{ display: "flex", gap: "10px", padding: "0 10px 10px" }}>
          {/* MESES */}
          <div>
            <div style={containerMonth}>
              <p className="textMonthAccuracyTitle" style={monthText}>
                MÊS
              </p>

              <div style={lineMonths}>
                {months.map((elem, i) => {
                  if (i === 0) {
                    return (
                      <>
                        <p
                          className="textMonthAccuracyTitle"
                          style={lineMonthsStyle}
                        >
                          -
                        </p>
                        <p
                          className="textMonthAccuracyTitle"
                          style={monthsLineStyle}
                        >
                          {elem}
                        </p>
                      </>
                    );
                  }
                  return (
                    <p
                      className="textMonthAccuracyTitle"
                      style={{
                        ...monthsLineStyleMany,
                        borderBottom: i < 11 && "2px solid #d7d7d7",
                      }}
                    >
                      {elem}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>

          {/* META */}
          <div>
            <div style={lineMetaContainer}>
              <div
                style={{
                  backgroundColor: "#E0FFF0",
                  width: "100%",
                }}
              >
                <p className="textMonthAccuracyTitleMeta" style={textLineTitle}>
                  META
                </p>
              </div>

              <div style={conteinerLineAccuracy}>
                <div style={constainerTitleAccuracy}>
                  <div>
                    <p style={titleAccuracy} className="textMonthAccuracyTitle">
                      ACURACIDADE
                    </p>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {consolidation?.length > 0 ? (
                        consolidation.map((elem, i) => {
                          return (
                            <div style={lineInputsStyle}>
                              <LinePercentageInput
                                month={months[i]}
                                value={elem?.meta?.accuracy}
                                type={"META"}
                                typeMonths={"CONSOLIDACAO"}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                {/* LINHA DE DIVISÃO */}
                <div
                  style={{
                    height: "350px",
                    border: "1px solid #d7d7d7",
                    width: "1px",
                  }}
                ></div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingBottom: "10px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        borderBottom: "2px solid #d7d7d7",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                      className="textMonthAccuracyTitle"
                    >
                      LIMITE DIVERGÊNCIA
                    </p>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {consolidation?.length > 0 ? (
                        consolidation.map((elem) => {
                          return (
                            <div
                              style={{
                                borderBottom: "2px solid #d7d7d7",
                                padding: "1px 0 ",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "27.65px",
                                width: "100%",
                                margin: "0 auto",
                              }}
                            >
                              <LinePercentageInput
                                disabled={true}
                                value={elem?.meta?.divergence_limit}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* RESULTADO */}
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #d7d7d7",
                borderRadius: "10px",
                width: "auto",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F9F3FF",
                  width: "100%",
                }}
              >
                <p
                  className="textMonthAccuracyTitleResult"
                  style={{
                    borderBottom: "1px solid #d7d7d7",
                    width: "100%",
                    textAlign: "center",
                    padding: "6px 0",
                  }}
                >
                  RESULTADO
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "195px",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingBottom: "10px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        borderBottom: "2px solid #d7d7d7",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="textMonthAccuracyTitle"
                    >
                      ACURACIDADE
                    </p>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {consolidation?.length > 0 ? (
                        consolidation.map((elem, i) => {
                          return (
                            <div
                              style={{
                                borderBottom: "2px solid #d7d7d7",
                                padding: "1px 0 ",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "27.65px",
                                width: "100%",
                                margin: "0 auto",
                              }}
                            >
                              <LinePercentageInput
                                value={elem?.result?.accuracy}
                                month={months[i]}
                                type={"RESULTADO"}
                                typeMonths={"CONSOLIDACAO"}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                {/* LINHA DE DIVISÃO */}
                <div
                  style={{
                    height: "350px",
                    border: "1px solid #d7d7d7",
                    width: "1px",
                  }}
                ></div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingBottom: "10px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        borderBottom: "2px solid #d7d7d7",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                      className="textMonthAccuracyTitle"
                    >
                      LIMITE DIVERGÊNCIA
                    </p>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {consolidation?.length > 0 ? (
                        consolidation.map((elem) => {
                          return (
                            <div
                              style={{
                                borderBottom: "2px solid #d7d7d7",
                                padding: "1px 0 ",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "27.65px",
                                width: "100%",
                                margin: "0 auto",
                              }}
                            >
                              <LinePercentageInput
                                disabled={true}
                                value={elem?.result?.divergence_limit}
                              />
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  border: "1px solid transparent",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    parseInt(elem.meta.accuracy) < 1
                                      ? "#909286"
                                      : elem.status === "ok"
                                      ? "#4ded29"
                                      : elem.status === "nok"
                                      ? "#ec0510"
                                      : "#909286",
                                }}
                              ></div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ padding: "10px", display: "flex", justifyContent: "end" }}
        >
          <Button
            padding={"0 20px"}
            height="35px"
            background={"var(--color-primary)"}
            borderRadius="8px"
            marginTop="0"
            name={"Anexos"}
            color={"#fff"}
            border="none"
            onClick={() => setIsModal(true)}
          />

          <ModalContainer isActive={isModal}>
            <ModalAttachment
              title={"CONSOLIDACAO"}
              type={"CONSOLIDACAO"}
              closeModal={() => setIsModal(false)}
            />
          </ModalContainer>
        </div>
      </div>
    </div>
  );
};

export default InventoryConsolidation;
