import React, { useState } from 'react';
import Input from './Input';

const PeopleCard = () => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();

    // O campo error está com a estilização da mensagem de erro configura no componente Input desse módulo
    const [nameError, setNameError] = useState();
    const [emailError, setEmailError] = useState();
    const [phoneError, setPhoneError] = useState();

    const styledPeopleNumberBox = {
        width: "36px",
        heigth: "36px",
        marginRight: "30px",
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "var(--color-primary)"
    }

    const styledPeopleNumber = {
        color: "var(--white)",
        fontSize: "21px",
        fontWeight: 500
    }

    return (
        <li>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={styledPeopleNumberBox}>
                    <p style={styledPeopleNumber}>1</p>
                </div>
                <Input
                    name="Nome"
                    error={nameError}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nome"
                    type={"text"}
                    width="269px"
                    marginBottom={"20px"}
                />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input
                    name="E-mail"
                    error={emailError}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail"
                    type={"text"}
                    width="100%"
                    marginRight={"5px"}
                    marginBottom={"20px"}
                />
                <Input
                    name="Celular"
                    error={phoneError}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Celular"
                    type={"text"}
                    width="100%"
                    marginLeft={"5px"}
                    marginBottom={"20px"}
                />
            </div>
        </li>
    )
};

export default PeopleCard;