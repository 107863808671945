import { useEffect, useState } from "react";
import BoxParameterSelect from "./BoxParameterSelect";

const LineOptions = ({ code, qtdBox, i }) => {
  /* STATES */
  const [codeState, setCodeState] = useState("");
  const [qtdBoxState, setQtdBoxState] = useState("");

  /* BLUR */
  const [codeStateBlur, setCodeStateBlur] = useState("");
  const [qtdBoxStateBlur, setQtdBoxStateBlur] = useState("");

  useEffect(() => {
    setCodeState(code);
    setQtdBoxState(qtdBox);
  }, [code, qtdBox]);

  return (
    <div
      style={{
        display: "flex",
        gap: "14px",
      }}
    >
      <BoxParameterSelect
        valueInput={codeState}
        onChange={(e) => setCodeState(e.target.value)}
        onBlur={(e) => setCodeStateBlur(e.target.value)}
        width={"100%"}
        number={i + 1}
        type={"code"}
      />
      <BoxParameterSelect
        valueInput={qtdBoxState}
        onChange={(e) => setQtdBoxState(e.target.value)}
        onBlur={(e) => setQtdBoxStateBlur(e.target.value)}
        width={"100%"}
        type={"qtdBox"}
      />
    </div>
  );
};

export default LineOptions;
