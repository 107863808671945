import React, { useContext, useState } from "react";
import Input from "../../QualityInternalDocuments/components/Input";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

const ModalDelete = ({ item, closeModal, closeIcon, setMenuOpen }) => {
    const { deleteSchedule } = useContext(SchedulesContext);

    const [cancelReason, setCancelReason] = useState(undefined);

    const stylesMenu = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        border: "1px solid #D7D7D7",
        borderRadius: "20px",
        width: "360px",
        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.18)",
    };

    const stylesHeaderMenu = {
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid #D7D7D7",
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 15px",
        width: "100%",
    };

    const stylesCloseBtn = {
        background: "none",
        border: "none",
    };

    const stylesDeleteBtn = {
        background: "#FFE1E1",
        borderRadius: "20px",
        border: "none",
        color: "#EA0000",
        padding: "10px 25px",
        height: "90%",
    };

    const stylesMessage = {
        textAlign: "center",
        padding: "30px",
        boxSizing: "border-box",
    };

    return (
        <section style={stylesMenu}>
            <header style={stylesHeaderMenu}>
                <button
                    name="closeDeleteMenu"
                    style={stylesCloseBtn}
                    onClick={() => closeModal(false)}
                >
                    <img src={closeIcon} alt="close.png" />
                </button>

                <p>Excluir agendamento</p>

                <button
                    style={stylesDeleteBtn}
                    onClick={() => {
                        deleteSchedule(item.schedule.id, cancelReason);
                        closeModal(false);
                        setMenuOpen(false);
                        setCancelReason(undefined);
                    }}
                >
                    Excluir
                </button>
            </header>

            <p style={stylesMessage}>
                Tem certeza que deseja excluir o agentamento{" "}
                <strong>{item.schedule.transporter_name}</strong>
                {" às "}
                <strong>{`${item.schedule.start_time.substring(
                    item.schedule.start_time.length - 5
                )} ?`}</strong>
            </p>

            <Input
                width={"95%"}
                marginLeft={"18px"}
                placeholder={"Escreva o motivo"}
                onChange={(e) => setCancelReason(e.target.value)}
            />
        </section>
    );
};

export default ModalDelete;
