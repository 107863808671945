import React from "react";

const FeedbackQuestionBox = ({ number }) => {
    let backgroundColor, textColor, feedbackText;

    if (number >= 1 && number <= 4) {
        backgroundColor = "#f4aaaa";
        textColor = "#ea0000";
        feedbackText = "Ruim";
    } else if (number >= 5 && number <= 8) {
        backgroundColor = "rgba(255, 247, 170, 1)";
        textColor = "rgba(198, 178, 0, 1)";
        feedbackText = "Bom";
    } else if (number >= 9 && number <= 10) {
        backgroundColor = "rgba(224, 255, 240, 1)";
        textColor = "rgba(0, 179, 134, 1)";
        feedbackText = "Ótimo";
    }

    const styleContainer = {
        width: "140px",
        height: "32px",
        borderRadius: "60px",
        backgroundColor: backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const styleText = {
        color: textColor,
        margin: 0,
    };

    return (
        <div style={styleContainer}>
            <p style={styleText}>{feedbackText}</p>
        </div>
    );
};

export default FeedbackQuestionBox;
