/* eslint-disable jsx-a11y/alt-text */
import responseIcon from "../../../images/svg/responseComment.svg";
import userNotImage from "../../../images/png/user.png";
import arrowIcon from "../../../images/svg/arrowIconResponse.svg";
import downloadFileIcon from '../../../images/png/file.png';
import { useEffect, useState } from "react";

const CardMensageAndSubMensage = ({
  name,
  picture,
  subComments,
  createdAt,
  content,
  subCommentType,
  setSubCommentType,
  id,
  commentId,
  setCommentId,
  file,
}) => {
  const [subVisible, setSubVisible] = useState(false);

  const cardContainerStyle = {
    width: "100%",
    minHeight: "100px",
    border: "2px solid #9966CC",
    borderRadius: "8px",
    padding: "10px 15px",
    marginTop: 10,
  };

  const cardContainerStyleAnswered = {
    width: "100%",
    minHeight: "100px",
    border: "1px solid #d7d7d7",
    borderRadius: "8px",
    padding: "10px 15px",
    marginTop: 10,
  }; 

  const headerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    // marginTop: "20px",
    cursor: "pointer",
  };

  const pictureStyle = {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
  };

  const responseContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "18px",
    overflow: "hidden",
  };

  const arrowIconStyle = {
    width: "85%",
    minHeight: "100px",
    border: "1px solid #d7d7d7",
    borderRadius: "8px",
    padding: "10px 15px",
    margin: "0 0 0 auto",
    textAlign: 'left',
  };

  const subCommentsContainerStyle = {
    borderLeft: "1px solid #d7d7d7",
    borderBottom: "1px solid #d7d7d7",
    position: "absolute",
    top: -5780,
    left: "-50px",
    width: "30px",
    height: 5800,
  };

  const archiveStyle = {
    color: '#9966CC',
    display: 'flex',
    fontSize: 14,
    textDecoration: 'none',
    border: '1px solid #9966CC',
    borderRadius: 12,
    padding: 10,
    fontWeight: 500,
    backgroundColor: '#F9F3FF',
    marginTop: 10,
    maxWidth: 125,
  };

  return (
    <>
      <div style={subComments?.length > 0 ? cardContainerStyleAnswered : cardContainerStyle}>
        <div style={{ display: "flex", gap: "13px", textAlign: 'left' }}>
          <img
            src={picture || userNotImage}
            style={{ width: "35px", height: "35px", objectFit: "contain" }}
          />
          <div>
            <p className="textTileNameMessage">{name}</p>
            <p className="textTileCreateMessage">{createdAt}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 10}}>
          <p className="textContentNameMessage">{content}</p>
          {
            file ? (
              <a href={file} target="_blank" rel="noreferrer" style={archiveStyle}>
                <img src={downloadFileIcon} alt="archive" />
                <p>Ver anexo</p>
              </a>
            ) : null
          }
          <div style={headerStyle} onClick={() => {
            setSubCommentType(commentId === id ? false : true);
            setCommentId(commentId === id ? "" : id);
          }}>
            <img src={responseIcon} />
            {/* <p className="textTitleResponse" style={responseStyle}>Responder</p> */}
            {
              subCommentType && commentId === id ? (
                <p className="textTitleResponse" style={{color: '#9966CC'}}>Responder</p>
              ) : (
                <p className="textTitleResponse" style={{color: '#000000'}}>Responder</p>
              )
            }
          </div>
        </div>
      </div>

      <div style={pictureStyle} onClick={() => setSubVisible(!subVisible)}>
        <img
          src={arrowIcon}
          style={{ transform: subVisible ? "rotate(0deg)" : "rotate(178deg)" }}
        />
        <p className="textVisibleTitleResponse">
          {subVisible ? "Ocultar respostas" : "Listar respostas"}
        </p>
      </div>

      <div style={responseContainerStyle}>
        {subVisible &&
          subComments?.map((item, index) => {
            return (
              <div style={arrowIconStyle}>
                <div
                  style={{ display: "flex", gap: "13px", position: "relative" }}
                >
                  <img
                    src={picture || userNotImage}
                    style={{
                      width: "35px",
                      height: "35px",
                      objectFit: "contain",
                    }}
                  />
                  <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left' }}>
                    <p className="textTileNameMessage">{item.name}</p>
                    <p className="textTileCreateMessage">{item.createdAt}</p>
                  </div>

                  <div style={subCommentsContainerStyle}></div>
                </div>

                <p className="textContentNameMessage">{item.message}</p>
                {
                  item?.file ? (
                    <a href={item?.file} target="_blank" rel="noreferrer" style={archiveStyle}>
                      <img src={downloadFileIcon} alt="archive" />
                      <p>Ver anexo</p>
                    </a>
                  ) : null
                }
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CardMensageAndSubMensage;
