import React from "react";
import Lottie from "lottie-react";
import animationData from "../../../animations/4Hrv2cyJmF.json";

const Loading = ({ absolute }) => {
  return absolute ? (
    <div
      style={{
        width: "10vw",
        margin: "120px auto 0",
        transform: "translateX(300px)",
      }}
    >
      <Lottie animationData={animationData} />
    </div>
  ) : (
    <div style={{ width: "22%", margin: "120px auto 0" }}>
      <Lottie animationData={animationData} />
    </div>
  );
};

export default Loading;
