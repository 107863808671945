const Textarea = ({
  name,
  width,
  height,
  placeholder,
  onChange,
  borderRadius,
  className,
  value,
}) => {
  const containerTextarea = {
    display: "flex",
    flexDirection: "column",
    gap: "14.43px",
  };

  const textareaStyle = {
    width: width,
    height: height,
    resize: "none",
    padding: "15px",
    outline: "none",
    border: "1px solid #D7D7D7",
    borderRadius: borderRadius ? borderRadius : "10px",
    fontSize: "15px",
    color: "#5c5b5b",
  };

  return (
    <div style={containerTextarea}>
      <label className="textPrimaryLabelInput">{name}</label>
      <textarea
        onChange={onChange}
        placeholder={placeholder}
        style={textareaStyle}
        className={className}
        value={value}
      />
    </div>
  );
};

export default Textarea;
