export const options = [
    {
        id: "1",
        name: "Opção 1",
        value: "opcao1"
    },
    {
        id: "2",
        name: "Opção 2",
        value: "opcao2"
    },
    {
        id: "3",
        name: "Opção 3",
        value: "opcao3"
    },
]