import HeaderManager from "../components/HeaderManager";
import OneColumnLayout from "../../../layouts/OneColumLayout";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import BoxSchedulesList from "../components/BoxSchedulesList";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

const SchedulingByDate = () => {
    const { receivedSchedules, deliveredSchedules } =
        useContext(SchedulesContext);

    const [active, setActive] = useState("Recebimentos");

    const navigate = useNavigate();

    const container = {
        backgroundColor: "#F9F9F9",
        height: "100%",
        padding: "0px 50px",
        width: "100%",
    };

    return (
        <OneColumnLayout
            page={"Company"}
            colum1={
                <div style={container}>
                    <HeaderManager
                        name=""
                        adm={true}
                        back={true}
                        logo={true}
                        active={active}
                        setActive={setActive}
                        onClick={() => navigate("/dashboard/scheduling")}
                    />

                    <BoxSchedulesList
                        schedules={
                            active === "Recebimentos"
                                ? receivedSchedules
                                : deliveredSchedules
                        }
                        active={active}
                    />
                </div>
            }
        />
    );
};

export default SchedulingByDate;
