import React from "react";

const Button = ({
  width,
  height,
  background,
  borderRadius,
  marginTop,
  name,
  border,
  disabled,
  onClick,
  color,
  padding,
}) => {
  const styleButton = {
    width: width,
    height: height,
    background: background,
    borderRadius: borderRadius,
    marginTop: marginTop,
    border: border,
    cursor: "pointer",
    color: color,
    padding: padding,
  };

  const styleButtonDisabled = {
    width: width,
    height: height,
    background: "#5c5b5b",
    borderRadius: borderRadius,
    marginTop: marginTop,
    border: border,
  };

  return disabled == true ? (
    <button
      className="textPrimaryButton"
      disabled={disabled}
      onClick={onClick}
      style={styleButtonDisabled}
    >
      {name}
    </button>
  ) : (
    <button
      className="textPrimaryButton"
      disabled={disabled}
      onClick={onClick}
      style={styleButton}
    >
      {name}
    </button>
  );
};

export default Button;
