import React from 'react';

const TextArea = ({
    name,
    placeholder,
    onChange,
    width,
    height,
    marginLeft,
    color,
    onBlur,
    value,
    marginBottom,
    marginRight,
    error,
}) => {
    const containerTextArea = {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: marginLeft,
        marginRight: marginRight,
        width: width,
        position: 'relative',
    };

    const styleTextArea = {
        width: width !== undefined ? width : '100%',
        color: color !== undefined ? color : '',
        height: height !== undefined ? height : '133px',
        border: '1px solid #D7D7D7',
        borderRadius: '7px',
        marginBottom: marginBottom ? marginBottom : '0',
        padding: "16px",
        outline: 'none',
        resize: 'none',
    };

    const styleLabel = {
        marginBottom: '14.43px',
    };

    const styleError = {
        position: 'absolute',
        bottom: 0,
        left: 0,
    };

    return (
        <div style={containerTextArea}>
            <label className="textPrimaryLabelInput" style={styleLabel}>
                {name}
            </label>
            <textarea
                onBlur={onBlur}
                className="textPrimaryInput"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={styleTextArea}
            />
            {error !== undefined && (
                <p className="textErrorInput" style={styleError}>
                    {error}
                </p>
            )}
        </div>
    );
};

export default TextArea;
