import React from "react";
import pdfIcon from "../../../images/svg/pdfIcon.svg"
import csvIcon from "../../../images/svg/csvIcon.svg"
import remove from "../../../images/svg/removeSubDocument.svg"

const AttachedDocument = ({ subDocuments }) => {

    const container = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRight: "1px solid var(--grey-0)",
        paddingRight: "20px",
        gap: "10px"
    };

    const styleSubDocumentIconButtonBox = {
        position: "relative"

    };

    const styleRemoveButton = {
        position: "absolute",
        top: "-2.5px",
        right: "-2.5px"
    };

    const styleTitle = {
        fontWeigth: 400,
        fontSize: "12px",
        color: "var(--black)"
    };

    return (
        <div style={container}>
            <div style={styleSubDocumentIconButtonBox}>
                <img src={remove} style={styleRemoveButton} />
                <img src={subDocuments.type === "pdf" ? pdfIcon : csvIcon} />
            </div>
            <p style={styleTitle}>{subDocuments.title}</p>
        </div>
    );
};

export default AttachedDocument;