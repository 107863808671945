
import 'chart.js/auto'; // Importe esta linha para carregar o chart.js corretamente
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const CardGraphicsLarge = ({ title, listMonths, max, months }) => {

    const chartId = `chart-${uuidv4()}`;
    const chartRef = useRef(null);
    const [listAverage, setListAverage] = useState([])

    const calculateRepeatedAverage = (arr) => {
        const sum = arr.reduce((total, value) => total + value, 0);
        const average = sum / arr.length;
        const newArray = Array(arr.length).fill(average);
        return newArray;
    };



    const data = {
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        datasets: [
            {
                type: 'line',
                data: calculateRepeatedAverage(listMonths),
                borderColor: '#FFA776', // Cor da linha
                fill: false,
                pointStyle: 'line',
            },
            {
                type: 'bar',
                data: listMonths,
                backgroundColor: '#96C', // Cor das colunas
            }
        ],
    };

    const options = {
        scales: {
            y: {
                type: 'linear',
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false, // Remove a exibição da legenda
            },
            title: {
                display: true,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        elements: {
            bar: {
                borderWidth: 1,
                borderRadius: 4
            },
        },
        layout: {
            padding: {
                top: 20,
                bottom: 20,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false, // Remove a grade de fundo no eixo X
                },
            },
            y: {
                grid: {
                    display: false, // Remove a grade de fundo no eixo Y
                },
                type: 'linear',
                beginAtZero: true,
                max: max || 1000
            },
        },
    };

    useEffect(() => {
        // Cria o gráfico quando o componente é montado
        chartRef.current = new Chart(chartId, {
            type: 'bar',
            data: data,
            options: options,
        });

        return () => {
            // Destroi o gráfico quando o componente é desmontado
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, []);

    return (
        <div style={{
            height: "501px",
            width: "100%",
            backgroundColor: "#fff",
            border: "1px solid #d7d7d7",
            borderRadius: "10px",
            padding: "12px 14px"
        }}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <p className="textTitleContractualParameter">{title}</p>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <div className="circleCard"></div>
                    <p className="textContentContractualParameter">Parâmetro contratual</p>
                </div>
            </div>
            <div style={{ height: "95%", margin: "10px 0" }}>
                <canvas id={chartId}></canvas>
            </div>
        </div>
    )
}

export default CardGraphicsLarge