import React, { useState } from 'react';
import Input from './Input';

const QuestionCard = () => {
    const [name, setName] = useState();
    const [weight, setWeight] = useState();

    // O campo error está com a estilização da mensagem de erro configura no componente Input desse módulo
    const [nameError, setNameError] = useState();
    const [weightError, setWeightError] = useState();

    const styledQuestionNumberBox = {
        width: "36px",
        heigth: "36px",
        marginRight: "30px",
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "var(--color-primary)"
    }

    const styledQuestionNumber = {
        color: "var(--white)",
        fontSize: "21px",
        fontWeight: 500
    }

    return (
        <li>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={styledQuestionNumberBox}>
                    <p style={styledQuestionNumber}>1</p>
                </div>
            
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input
                    name="Nome da pergunta"
                    error={nameError}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nome da pesquisa"
                    type={"text"}
                    width="205px"
                    marginBottom={"20px"}
                    marginRight={"10px"}
                />
                <Input
                    name="Peso"
                    error={weightError}
                    onChange={(e) => setWeight(e.target.value)}
                    placeholder=""
                    type={"text"}
                    width="85px"
                    marginBottom={"20px"}
                />
            </div>
            </div>
        </li>
    )
};

export default QuestionCard;