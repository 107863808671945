import React, { useState } from 'react';
import arrowUpIcon from "../../../images/svg/arrowCircleUpAction.svg";
import arrowDownIcon from "../../../images/svg/arrowCircleDownAction.svg";
import Input from './Input';
import Select from './Select';
import { options } from './mocks/SelectGenericOptions';

const ResponsibleBoxCreateExternalDocumentModal = () => {
    const [isArrowUp, setIsArrowUp] = useState(true);

    const toggleArrowIcon = () => {
        setIsArrowUp((prevValue) => !prevValue);
    };

    const container = {
        width: "100%",
        borderBottom: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    };

    const styleTitle = {
        margin: "0",
        fontSize: "17px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleInfosBox = {
        display: "flex",
        alignItems: "center",
        gap: "20px",
    };

    return (
        <div style={container}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }} onClick={toggleArrowIcon}>
                <img src={isArrowUp ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                <h3 style={styleTitle}>Responsáveis</h3>
            </div>
            {!isArrowUp && (
                <div style={{ margin: "20px 0 0 27px" }}>
                    <div style={styleInfosBox}>
                        <Select
                            label="Elaborador*"
                            width="277px"
                            marginBottom="0"
                            options={options}
                        />
                        <Input
                            name="Validade"
                            width="165px"
                            marginBottom="0"
                            type="date"
                        />
                        <Input
                            name="Avisar antes"
                            width="165px"
                            marginBottom="0"
                            type="date"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResponsibleBoxCreateExternalDocumentModal;
