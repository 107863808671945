import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderManager from "../components/HeaderManager";
import Sidebar from "../../../components/Sidebar";
import ResearchSummary from "../components/ResearchSummary";
import AnswersAndComments from "../components/AnswersAndComments";
import Gauge from "../components/Gauge";
import Graphic from "../components/Graphic";


const SurveyDetails = () => {
  const navigate = useNavigate();
  const { cardId } = useParams();

  const [gaugeValue, setGaugeValue] = useState("75");

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "30px"
  };

  const styleDetailsBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
    gap: "20px"
  };

  return (
    <ThowColumLayout
      page={"NpsResearch"}
      colum1={<Sidebar page="NpsResearch" />}
      colum2={
        <div style={container}>
          <HeaderManager name="Detalhes da pesquisa" adm={true} back={true} onClick={() => navigate("/dashboard/quality/nps-research")} />
          <div style={{ margin: "0 auto", marginTop: "42px" }} className="containerBox">
            {/* <p>Detalhes da pesquisa de ID: {cardId}</p> */}
            <section style={styleDetailsBox}>
              <Gauge gaugeValue={gaugeValue}/>
              <Graphic/>
            </section>
          
            <section style={styleDetailsBox}>
              <ResearchSummary />
              <AnswersAndComments />
            </section>
          </div>
        </div>
      }
    />
  );
};

export default SurveyDetails;
