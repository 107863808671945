import React, { useState } from "react";
import Switch from "./Switch";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const SatisfactionSurveyCard = ({ title, period, responseCount, onDelete, cardId }) => {
    const navigate = useNavigate();

    const [switchValue, setSwitchValue] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const borderHoverStyle = {
        borderColor: isHovered ? 'var(--color-primary)' : 'var(--grey-0)',
    };

    const cardStyles = {
        minWidth: '100%',
        height: '84px',
        border: '1px solid var(--grey-0)',
        borderRadius: '10px',
        paddingInline: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'var(--white)',
        marginBottom: '10px',
        transition: '0.8s',
        ...borderHoverStyle
    };

    const titleHoverStyle = {
        cursor: 'pointer',
        textDecoration: isHovered ? 'underline' : null,
        color: isHovered ? 'var(--color-primary)' : 'black',
    };

    const titleStyle = {
        width: '200px',
        color: 'black',
        fontSize: '14px',
        fontWeight: '500',
        transition: '0.8s',
        ...titleHoverStyle,
    };

    const dividerStyles = {
        width: '1px',
        height: '50px',
        backgroundColor: '#797979',
    };

    const infoBlockStyles = {
        width: '200px',
        color: 'black',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '21px',
        textAlign: 'center',
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <li style={cardStyles} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
                <Switch onToggle={(isOn) => setSwitchValue(isOn)} />
            </div>
            <p style={titleStyle} onClick={() => navigate(`/dashboard/quality/nps-research/survey/${cardId}`)}>{title}</p>
            <div style={dividerStyles} />
            <div>
                <p style={infoBlockStyles}>{period}</p>
                <p style={{ ...infoBlockStyles, color: '#6A6A6A' }}>Período da pesquisa</p>
            </div>
            <div>
                <p style={infoBlockStyles}>{responseCount}</p>
                <p style={{ ...infoBlockStyles, color: '#6A6A6A' }}>N° de respostas</p>
            </div>
            <Button width="164px" height="37px" background={"var(--color-primary)"}
                borderRadius="60px" marginTop="0" name="Link da pesquisa"
                color={"var(--white)"} onClick={() => navigate(`/dashboard/quality/searchhappy/${cardId}`)}
                border="none"
                disabled={!switchValue} />
            <div onClick={() => onDelete(cardId)}>
                <svg width="5" height="19" viewBox="0 0 5 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2.17315" cy="2.17315" r="2.17315" fill="black" />
                    <circle cx="2.1773" cy="8.69268" r="2.17315" fill="black" />
                    <circle cx="2.1773" cy="16.2981" r="2.17315" fill="black" />
                </svg>
            </div>
        </li>
    );
};

export default SatisfactionSurveyCard;
