import React, { useEffect, useState } from 'react';

const CardValueTotal = ({ icon, title, value, afterIcon, result, type, onClick, list, topHelper, leftHelper }) => {
  const [isDivVisible, setDivVisibility] = useState(false);

  const stylesCardContainerNormal = {
    position: 'relative',
    width: '100%',
    height: 110,
    border: '1px solid #d7d7d7',
    borderRadius: '10px',
    padding: '17px',
    display: 'flex',
    flexDirection: 'column',
    gap: '17px',
    backgroundColor: '#fff',      
  };

  const stylesCardContainerResult = {
    position: 'relative',
    width: '100%',
    height: 110,
    border: '1px solid #9966CC',
    borderRadius: '10px',
    padding: '17px',
    display: 'flex',
    flexDirection: 'column',
    gap: '17px',
    backgroundColor: '#FCF9FF',
    color: '#9966CC'
  };

  const getContainerStyles = () => {
    return result ? stylesCardContainerResult : stylesCardContainerNormal;
  };

  const helperContainer = {
    position: 'absolute',
    top: topHelper, // Posiciona a div abaixo do afterIcon
    left: leftHelper,
    backgroundColor: '#F9F3FF',
    padding: '0px 10px',
    border: '1px solid #9966CC',
    borderRadius: 10,
    color: '#9966CC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 10,
  };

  const helperText = {
    width: 150,
    borderBottom: '1px solid #9966CC',
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: 11,
  };

  const helperExemple = {
    fontWeight: 500,
    fontSize: 12
  };

  const getFormattedValue = (item) => {
    let result = '';
    if(typeof(item) === 'string') {
      result = Number(item).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    } else if(typeof(item) === 'number') {
      result = item.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    }
    

    return result;
  };

  return (
    <div
      style={getContainerStyles()}
    >
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        {
          icon ? (<img src={icon} />) : null
        }
        <p className="textTitleTotalCard">{title}</p>
        {afterIcon && (
          <img
            src={afterIcon}
            alt="afterIcon"
            onClick={() => onClick()}
            onMouseEnter={() => setDivVisibility(true)}
            onMouseLeave={() => setDivVisibility(false)}
            style={{cursor: 'pointer', width: '20px', height: '20px'}}
          />
        )}
      </div>

      {isDivVisible && (
        <div
          style={helperContainer}
        >
          {
            list ? list.map((e) => (
              <div style={helperText}>
                {
                  e.process ? (
                    <p>{`${e.process}: ${e.name}`}</p>
                  ) : <p>{`${e.name}`}</p>
                }
                <p style={helperExemple}>{getFormattedValue(e.value)}</p>
              </div>
            )) : null
          }
        </div>
      )}
      
      <div>
          <p className="textNameCompanyCard">
            {
              value ? (
               Number(value).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              ) : 'R$ 0,00'
            }
          </p>
        </div>

    </div>
  );
};

export default CardValueTotal;
