import React, { useState } from "react";
import companyIcon from "../../../images/svg/companyIcon.svg";
import NumberButtonList from "../components/NumberButtonList";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

const SearchHappy = () => {
    const navigate = useNavigate();

    const [assessmentQuestion1, setAssessmentQuestion1] = useState(null);
    const [assessmentQuestion2, setAssessmentQuestion2] = useState(null);

    const handleClick = () => {
        // Função para enviar as requisições e navegar para a página de feedback
        navigate("/dashboard/quality/searchhappy/feedback");
    }

    const container = {
        backgroundColor: "#F9F9F9",
        height: "100vh",
        width: "100vw",
        display: "Flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleSearchBox = {
        border: "1px solid var(--grey-0)",
        borderRadius: "10px",
        maxHeight: "758px",
        width: "672px",
        backgroundColor: "var(--white)",
    }

    const styleHeader = {
        height: "88px",
        borderBottom: "1px solid var(--grey-0)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }

    const styleQuestion = {
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "20px"
    }

    const styleAssessmentBox = {
        marginBottom: "30px"
    }

    const styleButtonBox = {
        borderTop: "1px solid var(--grey-0)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        height: '86px'
    };

    return (
        <main style={container}>
            <div style={styleSearchBox}>
                <div style={styleHeader}>
                    <img src={companyIcon} alt="XPM Logística" width={135.73} height={55} />
                </div>
                <div style={{ padding: "30px", height: "500px", overflowY: "auto" }}>
                    <h1 className="textPrimaryLanding" style={{ textAlign: "center" }}>Pesquisa de satisfação</h1>
                    <div style={styleAssessmentBox}>
                        <h3 style={styleQuestion}>1 - {"Infraestrutura"}</h3>
                        <NumberButtonList assessmentQuestion={assessmentQuestion1} setAssessmentQuestion={setAssessmentQuestion1} />
                    </div>
                    <div style={styleAssessmentBox}>
                        <h3 style={styleQuestion}>2 - {"Organização e limpeza"}</h3>
                        <NumberButtonList assessmentQuestion={assessmentQuestion2} setAssessmentQuestion={setAssessmentQuestion2} />
                    </div>
                    <TextArea
                        placeholder="Insira seus comentários..."
                    />
                </div>
                <div style={styleButtonBox}>
                    <Button width="100%" height="37px" background={"var(--color-primary)"}
                        borderRadius="60px" margin="0" marginBottom="0" name="Enviar a resposta"
                        color={"var(--white)"} onClick={handleClick}
                        border="none" />
                </div>
            </div>
        </main>
    )
}

export default SearchHappy;