// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
}



table {
  border-collapse: separate;
  border-spacing: 0;
  color: #6A6A6A;
}

td {
  padding: 18px;
  background: white;
  border: solid 1px #D7D7D7;
  border-style: solid none solid none;
  vertical-align: middle;
}

tr:first-child td:first-child {
  border: solid 1px #D7D7D7;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-style: solid none solid solid;
}

tr:first-child td:last-child {
  border: solid 1px #D7D7D7;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-style: solid solid solid none;
}

input[type="checkbox"]{
  cursor: pointer;
}

input[type="checkbox"]:checked{
  appearance: none;
  background-color: #6927DA;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,eAAe;EACf,wBAAwB;EACxB,gBAAgB;AAClB;;;;AAIA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,mCAAmC;EACnC,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;EAC5B,+BAA+B;EAC/B,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,gCAAgC;EAChC,oCAAoC;AACtC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-size: 100%;\n  vertical-align: baseline;\n  list-style: none;\n}\n\n\n\ntable {\n  border-collapse: separate;\n  border-spacing: 0;\n  color: #6A6A6A;\n}\n\ntd {\n  padding: 18px;\n  background: white;\n  border: solid 1px #D7D7D7;\n  border-style: solid none solid none;\n  vertical-align: middle;\n}\n\ntr:first-child td:first-child {\n  border: solid 1px #D7D7D7;\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n  border-style: solid none solid solid;\n}\n\ntr:first-child td:last-child {\n  border: solid 1px #D7D7D7;\n  border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px;\n  border-style: solid solid solid none;\n}\n\ninput[type=\"checkbox\"]{\n  cursor: pointer;\n}\n\ninput[type=\"checkbox\"]:checked{\n  appearance: none;\n  background-color: #6927DA;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
