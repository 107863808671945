import downloadIcon from "../../../images/svg/download.svg"
import printIcon from "../../../images/svg/print.svg"
import imageMock from "./mocks/mockParaComponenteDocumentBox.svg"

const DocumentBox = () => {

    const container = {
        display: "flex",
        flexDirection: "column",
        background: "var(--white)",
        border: "1px solid var(--grey-0)",
        borderRadius: "20px",
        width: "100%",
        minHeight: "500px",
    };

    const stylesHeader = {
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid var(--grey-0)",
        display: "flex",
        justifyContent: "space-between",
        padding: "0 20px",
        width: "100%",
        height: "54px"
    };

    const styleTitle = {
        color: "var(--black)",
        fontSize: "14px",
        fontWeight: 500,
        margin: "0"
    };

    const styleDocument = {
        padding: "20px"
    }

    return (
        <div style={container}>
            <div style={stylesHeader}>
                <h3 style={styleTitle}>Visualização do documento</h3>
                <div>
                    <img src={downloadIcon} alt="Download" />
                    <img src={printIcon} alt="Imprimir" />
                </div>
            </div>

            <div style={styleDocument}>
                <img src={imageMock} />
            </div>

        </div>
    );
};

export default DocumentBox;