import React, { useContext, useEffect } from "react";
import FormInputText from "./FormInputText";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

import AttachmentBoxForModal from "../../QualityInternalDocuments/components/AttachmentBoxForModal";

const ScheduleForm = () => {
    const {
        branchs,
        getBrachs,
        selectedBranchByForm,
        setSelectedBranchByForm,
        locations,
        selectedLocationByForm,
        setSelectedLocationByForm,
        nameByForm,
        setNameByForm,
        vehicle_plateByForm,
        setVehicle_plateByForm,
        model_vehicleByForm,
        setModel_vehicleByForm,
        cesvByForm,
        setCesvByForm,
        // dateByForm,
        // setDateByForm,
        // anexoByForm,
        // setAnexoByForm,
        // statusByForm,
        // setStatusByForm,
        hourByForm,
        setHourByForm,
        availableTimes,
    } = useContext(SchedulesContext);

    const stylesLabelContainer = {
        marginTop: 10,
        marginBottom: 10,
        width: "100%",
    };

    const stylesSelect = {
        width: "100%",
        border: "1px solid #D7D7D7",
        padding: 10,
        borderRadius: 10,
        background: "none",
        marginTop: 10,
        outline: "none",
    };

    useEffect(() => {
        getBrachs();
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
            }}
        >
            <label style={stylesLabelContainer}>
                Filial
                <select
                    name="branch"
                    style={stylesSelect}
                    onChange={(e) => setSelectedBranchByForm(e.target.value)}
                    className="textOptionsSchedules"
                    value={selectedBranchByForm}
                >
                    <option value={"default"}>Selecione</option>
                    {branchs.map((e, index) => (
                        <option key={`${e.id}-${index}`} value={e.id}>
                            {e.branch_name}
                        </option>
                    ))}
                </select>
            </label>

            <label style={stylesLabelContainer}>
                Doca
                <select
                    name="dock"
                    style={stylesSelect}
                    onChange={(e) => setSelectedLocationByForm(e.target.value)}
                    className="textOptionsSchedules"
                    value={selectedLocationByForm}
                >
                    <option value={"default"}>Selecione</option>

                    {locations?.map((e, index) => (
                        <option
                            key={`${e.local.id}-${index}`}
                            value={e.local.id}
                        >
                            {e.local.local_name}
                        </option>
                    ))}
                </select>
            </label>

            <FormInputText
                name={"driver"}
                width={"100%"}
                height={"45px"}
                value={nameByForm}
                label={"Nome da transportadora ou motorista"}
                placeholder={"Insira o nome"}
                border={`1px solid #D7D7D7`}
                borderRadius={"7px"}
                onChange={(e) => setNameByForm(e.target.value)}
            />

            <FormInputText
                name={"plate"}
                width={"48%"}
                height={"45px"}
                value={vehicle_plateByForm}
                label={"Placa do veículo"}
                placeholder={"Insira a placa"}
                border={`1px solid #D7D7D7`}
                borderRadius={"7px"}
                onChange={(e) => setVehicle_plateByForm(e.target.value)}
            />

            <FormInputText
                name={"model"}
                width={"48%"}
                height={"45px"}
                value={model_vehicleByForm}
                label={"Modelo do veículo"}
                placeholder={"Insira o modelo"}
                border={`1px solid #D7D7D7`}
                borderRadius={"7px"}
                onChange={(e) => setModel_vehicleByForm(e.target.value)}
            />

            <FormInputText
                name={"cesv"}
                width={"48%"}
                height={"45px"}
                value={cesvByForm}
                label={"CESV"}
                placeholder={"Insira o CESV"}
                border={`1px solid #D7D7D7`}
                borderRadius={"7px"}
                onChange={(e) => setCesvByForm(e.target.value)}
            />

            <div
                style={{
                    display: "flex",
                    width: "48%",
                    flexDirection: "column",
                }}
            >
                <label style={{ ...stylesLabelContainer, marginTop: 0 }}>
                    Horários
                    <select
                        name="dock"
                        style={{ ...stylesSelect }}
                        onChange={(e) => setHourByForm(e.target.value)}
                        className="textOptionsSchedules"
                        value={hourByForm}
                    >
                        <option value={"default"}>Selecione</option>
                        {availableTimes?.map((e, index) => (
                            <option key={`${e}-${index}`} value={e}>
                                {e}
                            </option>
                        ))}
                    </select>
                </label>
            </div>

            <AttachmentBoxForModal />
        </div>
    );
};

export default ScheduleForm;
