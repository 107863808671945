const XLSX = require('xlsx');

function createExcelFile(data, sheetName, fileName = 'relatorio.xlsx') {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data, { header: Object.keys(data[0]) });

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    XLSX.writeFile(workbook, fileName);
};

export default createExcelFile;