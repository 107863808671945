/* eslint-disable jsx-a11y/alt-text */
import checkIconOn from "../../../images/svg/checkOnIcon.svg";
import checkIconOff from "../../../images/svg/checkOffIcon.svg";
import Button from "./Button";
import { useState } from "react";
import { useEffect } from "react";
import CardComments from "./CardComments";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DashContext } from "../../../context/Dashboard/DashContext";
import { useContext } from "react";

const CardDetailsContentGeneralities = ({
  marginLeft,
  onClick,
  item,
  onlyOne,
  percentage,
}) => {
  const { checkBilling, setAwaitingPayment, paymentConfirmed } =
    useContext(DashContext);
  const [check, setCheck] = useState(false);
  const [commentsVisible, setCommentsVisible] = useState(false);

  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);

  const [UOM, setUOM] = useState(0);
  const [description, setDescription] = useState("");
  const [priceUnit, setPriceUnit] = useState(0);

  /* STYLES */
  const container = {
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "17px 23px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    width: "100%",
    position: "relative",
  };

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  };

  const content = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "150px",
    cursor: "pointer",
  };

  const navigationDetailsPage = () => {
    localStorage.setItem(
      "@XPM_CLIENT_PAGEMENT_DETAILS_GENERALITIES_ID:",
      item.id
    );
    // onClick(quantity);
    onClick(10);
  };

  const letterLimit = (text, limit, threePoints) => {
    let description = [];

    if (threePoints) {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 1) {
          description = `${description.join("")}...`;
        }
      });
    } else {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 2) {
          description = `${description.join("")}...`;
        }
      });
    }

    return description;
  };

  const onCheckBillingFunction = () => {
    checkBilling(item?.price_not_formact || 0, !check);
    setCheck(!check);
  };

  useEffect(() => {
    setDescription(item?.description);
    setQuantity(item?.quantity);
    setUOM(item?.unit);
    setPriceUnit(item?.price);
    setTotal(item?.total);
  }, [item]);

  useEffect(() => {
    setAwaitingPayment(0);
  }, []);

  return (
    <div style={container}>
      <div
        onClick={() =>
        onCheckBillingFunction()}
        style={content} className="card-details-check"
      >
        <img
          style={{
            marginRight: "10px",
          }}
          src={
            paymentConfirmed ? checkIconOn : check ? checkIconOn : checkIconOff
          }
        />

        <p
          data-tooltip-place="top"
          data-tooltip-position-strategy="fixed"
          data-tooltip-id="description"
          data-tooltip-content={description}
          className="textContentDetailsInvoicing"
        >
          {letterLimit(description, 11, true)}
          <ReactTooltip id="description" />
        </p>
      </div>

      <div
        style={{
          borderLeft: "1px solid #79797970",
          marginLeft: marginLeft,
          paddingLeft: "31px",
          display: "flex",
          gap: "15px",
        }}
      >
        <div style={contentValueCard}>
          <p className="titleNameCompanyCard">{quantity}</p>
          <p className="textTitleValueCard">
            {onlyOne ? "N° de estruturas" : "Quantidade"}
          </p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "130px" }}>
          <p className="titleNameCompanyCard">{UOM}</p>
          <p className="textTitleValueCard">UOM</p>
        </div>

        {!onlyOne && (
          <div style={contentValueCard}>
            <p className="titleNameCompanyCard">{priceUnit}</p>
            <p className="textTitleValueCard">Preço unitário</p>
          </div>
        )}

        <div style={contentValueCard}>
          <p className="titleNameCompanyCard">{total}</p>
          <p className="textTitleValueCard">Total geral</p>
        </div>
        <div style={{ ...contentValueCard, position: "relative" }}>
          {commentsVisible && (
            <>
              <div
                onClick={() => setCommentsVisible(false)}
                style={{
                  position: "fixed",
                  width: "100vw",
                  height: "100vh",
                  top: 0,
                  left: 0,
                  zIndex: 2,
                }}
              ></div>
              <CardComments
                id={item.id}
                closeCard={() => setCommentsVisible(!commentsVisible)}
              />
            </>
          )}
          <p
            onClick={() => setCommentsVisible(!commentsVisible)}
            className="titleNameCompanyCardComments"
          >
            Comentarios
          </p>
          <p className="textTitleValueCard">Comentario</p>
        </div>

        <div
          style={{
            position: "absolute",
            right: "20px",
            top: "20px",
          }}
        >
          <Button
            width="144px"
            height="37px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Detalhes"
            border="none"
            onClick={navigationDetailsPage}
          />
        </div>
      </div>
    </div>
  );
};

export default CardDetailsContentGeneralities;
