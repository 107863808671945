import React, { useState } from 'react';
import selectedCheckboxIcon from "../../../images/svg/selectedCheckbox.svg";
import unselectedCheckboxIcon from "../../../images/svg/unselectedCheckbox.svg";

const ModalColumnsCard = ({
  closeModal,
  closeIcon,
}) => {

  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [isChecked6, setIsChecked6] = useState(false);
  const [isChecked7, setIsChecked7] = useState(false);
  const [isChecked8, setIsChecked8] = useState(false);
  const [isChecked9, setIsChecked9] = useState(false);
  const [isChecked10, setIsChecked10] = useState(false);

  const toggleCheckbox1 = () => {
    setIsChecked1((prevValue) => !prevValue);
  };

  const toggleCheckbox2 = () => {
    setIsChecked2((prevValue) => !prevValue);
  };

  const toggleCheckbox3 = () => {
    setIsChecked3((prevValue) => !prevValue);
  };

  const toggleCheckbox4 = () => {
    setIsChecked4((prevValue) => !prevValue);
  };

  const toggleCheckbox5 = () => {
    setIsChecked5((prevValue) => !prevValue);
  };

  const toggleCheckbox6 = () => {
    setIsChecked6((prevValue) => !prevValue);
  };

  const toggleCheckbox7 = () => {
    setIsChecked7((prevValue) => !prevValue);
  };

  const toggleCheckbox8 = () => {
    setIsChecked8((prevValue) => !prevValue);
  };

  const toggleCheckbox9 = () => {
    setIsChecked9((prevValue) => !prevValue);
  };

  const toggleCheckbox10 = () => {
    setIsChecked10((prevValue) => !prevValue);
  };

  const stylesMenu = {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    border: '1px solid #D7D7D7',
    borderRadius: '20px',
    width: '319px',
    height: '482px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.18)',
  };

  const stylesHeaderMenu = {
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: '1px solid #D7D7D7',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 15px',
    width: '100%',
  };

  const stylesCloseBtn = {
    background: 'none',
    border: 'none',
    marginRight: '16px'
  };

  const styleTitle = {
    color: 'var(--black)',
    fontSize: '18px',
    fontWeight: 500
  };

  const styleContentBox = {
    padding: "20px",
    display: "flex",
    flexDirection: "Column",
    gap: "10px",
    overflowY: "auto",
  };

  const styleInfosBox = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  };

  const styleTextInfos = {
    fontSize: "14px",
    color: "var(--black)",
  };

  return (
    <div style={stylesMenu}>
      <header style={stylesHeaderMenu}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button name="closeDeleteMenu" style={stylesCloseBtn} onClick={() => closeModal(false)}>
            <img src={closeIcon} alt='close.png' />
          </button>
          <p style={styleTitle}>Exibir mais colunas</p>
        </div>
      </header>
      <div style={styleContentBox}>
        <div style={styleInfosBox} onClick={toggleCheckbox1}>
          <img src={isChecked1 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Cód. do documento</p>
        </div>
        <div style={styleInfosBox} onClick={toggleCheckbox2}>
          <img src={isChecked2 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Título do documento</p>
        </div>
        <div style={styleInfosBox} onClick={toggleCheckbox3}>
          <img src={isChecked3 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Processo</p>
        </div>
        <div style={styleInfosBox} onClick={toggleCheckbox4}>
          <img src={isChecked4 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Elaborador</p>
        </div>
        <div style={styleInfosBox} onClick={toggleCheckbox5}>
          <img src={isChecked5 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Publicação</p>
        </div>
        <div style={styleInfosBox} onClick={toggleCheckbox6}>
          <img src={isChecked6 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Validade</p>
        </div>
        <div style={styleInfosBox} onClick={toggleCheckbox7}>
          <img src={isChecked7 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Status</p>
        </div>
        <div style={styleInfosBox} onClick={toggleCheckbox8}>
          <img src={isChecked8 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Unidades</p>
        </div>
        <div style={styleInfosBox} onClick={toggleCheckbox9}>
          <img src={isChecked9 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Consensadores</p>
        </div>
        <div style={styleInfosBox} onClick={toggleCheckbox10}>
          <img src={isChecked10 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
          <p style={styleTextInfos}>Aprovador</p>
        </div>
      </div>
    </div>
  )
}

export default ModalColumnsCard;
