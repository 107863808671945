import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const InternalDocumentsContext = createContext({});

export const InternalDocumentsValidation = ({ children }) => {
  const navigate = useNavigate();

  const [btnSelect, setBtnSelect] = useState("Pendências");
  const [listOption, setListOption] = useState(["Pendências", "Documentos"]);
  const [modalFilterCardsOpen, setModalFilterCardsOpen] = useState(false);
  const [modalCreateDocumentOpen, setModalCreateDocumentOpen] = useState(false);
  const [modalEditDocumentOpen, setModalEditDocumentOpen] = useState(false);
  const [modalColumnsCardOpen, setModalColumnsCardOpen] = useState(false);
  const [modalDocumentDetailOpen, setModalDocumentDetailOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleModalClose = () => {
    setModalCreateDocumentOpen(false);
    setModalColumnsCardOpen(false);
    setModalFilterCardsOpen(false);
    setModalDocumentDetailOpen(false);
    setModalDeleteOpen(false);
    setModalEditDocumentOpen(false);
    setSelectedCard(null);
  };

  return (
    <InternalDocumentsContext.Provider
      value={{
        btnSelect,
        setBtnSelect,
        listOption,
        modalFilterCardsOpen,
        setModalFilterCardsOpen,
        modalCreateDocumentOpen,
        modalColumnsCardOpen,
        setModalColumnsCardOpen,
        setModalCreateDocumentOpen,
        handleModalClose,
        modalDocumentDetailOpen,
        setModalDocumentDetailOpen,
        selectedCard,
        setSelectedCard,
        modalDeleteOpen,
        setModalDeleteOpen,
        modalEditDocumentOpen,
        setModalEditDocumentOpen
      }}
    >
      {children}
    </InternalDocumentsContext.Provider>
  );
};
