import closeImg from "../../../images/svg/closeIcon.svg";
import InputMask from "react-input-mask";
import Textarea from "./Textarea.js";
import Button from "./Button";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters.js";
import { useState } from "react";

const ModalFilterDetailsDahboard = ({
  closeModal,
  type,
  setStatus,
  setJustification,
  search,
  status,
  justification,
  daysOrHours,
  setDaysOrHours,
}) => {
  const modalContainerStyles = {
    width: "370px",
    backgroundColor: "#fff",
    position: "absolute",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
  };

  const headerStyles = {
    display: "flex",
    gap: "15px",
    borderBottom: "1px solid #d7d7d7",
    padding: "15px 20px 10px",
  };

  const closeIconStyles = {
    width: "12px",
    cursor: "pointer",
  };

  const contentStyles = {
    padding: "15px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  };

  const selectContainerStyles = {
    display: "flex",
    gap: "10px",
  };

  return (
    <div style={modalContainerStyles}>
      <div style={headerStyles}>
        <img src={closeImg} style={closeIconStyles} onClick={closeModal} />
        <p className="textTitleJustificationDescription">Filtro de busca</p>
      </div>

      <div style={contentStyles}>
        <div style={{ ...selectContainerStyles, gap: "68px" }}>
          <label
            htmlFor="typeTime"
            className="textLabelJustificationDescription"
          >
            Status:
          </label>
          <select
            defaultValue={status}
            id="typeTime"
            className="textSelectOptionsJustificationDescription"
            onChange={(e) => setStatus(e.target.value)}
            style={{
              outline: "none",
              border: "none",
              borderBottom: "1px solid #d7d7d7",
              width: "150px",
            }}
          >
            <option value="default">Selecione</option>
            <option value="all">Todos</option>
            <option value="1">OK</option>
            <option value="0">NOK</option>
          </select>
        </div>

        <div style={{ ...selectContainerStyles, gap: "26px" }}>
          <label
            htmlFor="typeTime"
            className="textLabelJustificationDescription"
          >
            Justificados:
          </label>
          <select
            defaultValue={justification}
            id="typeTime"
            className="textSelectOptionsJustificationDescription"
            onChange={(e) => setJustification(e.target.value)}
            style={{
              outline: "none",
              border: "none",
              borderBottom: "1px solid #d7d7d7",
              width: "150px",
            }}
          >
            <option value="default">Selecione</option>
            <option value="all">Todos</option>
            <option value="allowance">Com justificativa</option>
            <option value="notAllowance">Sem justificativa</option>
          </select>
        </div>

        <div style={{ ...selectContainerStyles, gap: "75px" }}>
          <label
            htmlFor="typeTime"
            className="textLabelJustificationDescription"
          >
            Prazo:
          </label>
          <select
            defaultValue={daysOrHours}
            id="typeTime"
            className="textSelectOptionsJustificationDescription"
            onChange={(e) => setDaysOrHours(e.target.value)}
            style={{
              outline: "none",
              border: "none",
              borderBottom: "1px solid #d7d7d7",
              width: "150px",
            }}
          >
            <option value="default">Selecione</option>
            <option value="all">Todos</option>
            <option value="days">Em dias</option>
            <option value="hours">Em horas</option>
          </select>
        </div>

        <Button
          padding={"0 20px"}
          height="35px"
          background={"var(--color-primary)"}
          borderRadius="8px"
          marginTop="0"
          name={"Filtro"}
          color={"#fff"}
          border="none"
          onClick={search}
        />
      </div>
    </div>
  );
};

export default ModalFilterDetailsDahboard;
