import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ModalContainer from "./ModalContainer";
import ModalJustificationDetailsDashboard from "./ModalJustificationDetailsDashboard";

const CardTableListDash = ({
  setListItens,
  dataObject,
  searchByMonth,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [idOne, setIdOne] = useState("");
  const [idTwo, setIdTwo] = useState("");

  const [objectRenderKeys, setObjectRenderKeys] = useState([]);
  const [objectRender, setObjectRender] = useState([]);

  const letterLimit = (text, limit, threePoints) => {
    let description = [];

    if (threePoints) {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 1) {
          description = `${description.join("")}...`;
        }
      });
    } else {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 2) {
          description = `${description.join("")}...`;
        }
      });
    }

    return description;
  };

  const containerStyle = {
    width: "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    marginTop: "15px",
    padding: "25px",
    backgroundColor: "#fff",
  };

  const headerRowStyle = {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    overflowX: "hidden",
  };

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  };

  useEffect(() => {
    const idDiv1 = uuidv4();
    const idDiv2 = uuidv4();

    setIdOne(idDiv1);
    setIdTwo(idDiv2);

    setListItens((prevList) => [...prevList, idDiv1, idDiv2]);
  }, []);

  useEffect(() => {
    setObjectRenderKeys(Object.keys(dataObject));
    setObjectRender(dataObject);
  }, [dataObject]);

  return (
    <div>
      <div style={containerStyle}>
        <div id={idOne} style={headerRowStyle}>
          {objectRenderKeys.map((elem, index) => {
            if (
              !elem.includes("id") &&
              !elem.includes("STATUS") &&
              !elem.includes("JUSTIFICATIVA")
            ) {
              return (
                <div
                  key={index}
                  style={{ ...contentValueCard, width: "auto" }}
                >
                  <p
                    className="titleNameCompanyCard"
                    style={{
                      whiteSpace: "nowrap",
                      width: "auto",
                      minWidth: "140px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {elem}
                  </p>

                  <p
                    data-tooltip-place="top"
                    data-tooltip-position-strategy="fixed"
                    data-tooltip-id="description"
                    data-tooltip-content={
                      String(objectRender[elem]) ||
                      "Não registrado"
                    }
                    className="titleNameCompanyCard"
                    style={{ cursor: "pointer" }}
                  >
                    {letterLimit(
                      String(objectRender[elem]) ||
                        "Não registrado",
                      11,
                      true
                    )}
                    <ReactTooltip id="description" />
                  </p>
                  {/* </p>
                  {objectRender[elem] || "Não registrado"}
                </p> */}
                </div>
              );
            }
            if (elem.includes("STATUS")) {
              return (
                <div
                  key={index}
                  style={{ ...contentValueCard, width: "auto" }}
                >
                  <p
                    className="titleNameCompanyCard"
                    style={{
                      whiteSpace: "nowrap",
                      width: "auto",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {elem}
                  </p>

                  <p
                    data-tooltip-place="top"
                    data-tooltip-position-strategy="fixed"
                    data-tooltip-id="description"
                    data-tooltip-content={
                      objectRender[elem] === 1 ? "OK" : "NOK"
                    }
                    className={`titleNameCompanyCard ${
                      objectRender[elem] === 1
                        ? "statusOn"
                        : "statusOff"
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    {objectRender[elem] === 1 ? "OK" : "NOK"}
                    <ReactTooltip id="description" />
                  </p>
                </div>
              );
            }
            if (elem.includes("JUSTIFICATIVA")) {
              return (
                <div
                  key={index}
                  style={{
                    ...contentValueCard,
                    width: "auto",
                  }}
                >
                  <p
                    className="titleNameCompanyCard"
                    style={{
                      whiteSpace: "nowrap",
                      width: "auto",
                      minWidth: "140px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {elem}
                  </p>

                  <p
                    data-tooltip-place="top"
                    data-tooltip-position-strategy="fixed"
                    data-tooltip-id="description"
                    data-tooltip-content={
                      String(objectRender[elem]) ||
                      "Não registrado"
                    }
                    className="titleNameCompanyCard"
                    style={{ cursor: "pointer" }}
                  >
                    {letterLimit(
                      String(objectRender[elem]) ||
                        "Não registrado",
                      11,
                      true
                    )}
                    <ReactTooltip id="description" />
                  </p>
                </div>
              );
            }
          })}
        </div>
      </div>

      <ModalContainer
        isActive={isActive}
        closeModal={() => setIsActive(true)}
      >
        <ModalJustificationDetailsDashboard
          id={dataObject.id}
          closeModal={() => setIsActive(false)}
          searchByMonth={searchByMonth}
        />
      </ModalContainer>
    </div>
  );
};

export default CardTableListDash;
