import Button from "./Button";

const SelectSessionButtons = ({
  btnSelect,
  setBtnSelect,
  buttons,
  width,
  marginBottom,
}) => {
  const container = {
    width: width || "100%",
    backgroundColor: "#fff",
    borderRadius: "50px",
    marginTop: "24px",
    padding: "6px",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #d7d7d7",
    marginBottom: marginBottom,
  };

  return (
    <div style={container}>
      {buttons.map((item) => {
        const backgroundColor =
          item === btnSelect ? "var(--color-primary)" : "#fff";
        const colorText = item === btnSelect ? "#fff" : "var(--color-primary)";

        return (
          <Button
            padding={"0 20px"}
            height="35px"
            background={backgroundColor}
            borderRadius="60px"
            marginTop="0"
            name={item}
            color={colorText}
            border="none"
            onClick={() => setBtnSelect(item)}
          />
        );
      })}
    </div>
  );
};

export default SelectSessionButtons;
