import { useEffect } from "react";
import backArrow from "../../../images/svg/backArrow.svg";
import passArrow from "../../../images/svg/passArrow.svg";

const CardDateYear = ({
  date,
  add,
  back,
  width,
  gap,
  fontSize,
  isActive,
  biggestDate,
  shorttestDate,
}) => {
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        gap: gap || "30px",
        width: width || "250px",
      }}
    >
      <img
        onClick={() => isActive && back()}
        style={{
          cursor: "pointer",
          position: "absolute",
          left: 0,
          filter: shorttestDate ? "grayscale(100%)" : "grayscale(0%)",
        }}
        src={backArrow}
      />
      <p style={{ fontSize: fontSize }} className="textNameCompanyCard">
        {date.split("_").join("de")}
      </p>
      <img
        onClick={() => isActive && add()}
        style={{
          cursor: "pointer",
          position: "absolute",
          right: 0,
          filter: biggestDate ? "grayscale(100%)" : "grayscale(0%)",
        }}
        src={passArrow}
      />
    </div>
  );
};

export default CardDateYear;
