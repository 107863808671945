import React from 'react';

const DocumentCardToList = ({ attachments }) => {

    const container = {
        width: "999px",
        height: "73px",
        border: "1px solid var(--grey-0)",
        backgroundColor: "#F6F6F6",
        padding: "20px 20px 20px 65px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    };

    const styleValue = {
        margin: "0",
        fontSize: "14px",
        color: "var(--color-primary)",
        fontWeight: 400,
    };

    return (
        <>
            {attachments.map(attachments => (
                <li key={attachments.id} style={container}>
                    <div style={{ width: "120px" }}>
                        <p style={styleValue}>{attachments.title}</p>
                    </div>
                    <div style={{ width: "496px" }}>
                        <p style={styleValue}>({attachments.date_inclusion})</p>
                    </div>
                </li>
            ))}
        </>
    );
};

export default DocumentCardToList;
