import React from "react";
import companyIcon from "../../../images/svg/companyIcon.svg";
import positive from "../../../images/svg/positive.svg";

const SearchHappyFeedback = () => {

    const container = {
        backgroundColor: "#F9F9F9",
        height: "100vh",
        width: "100vw",
        display: "Flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleFeedbackBox = {
        border: "1px solid var(--grey-0)",
        borderRadius: "10px",
        maxHeight: "758px",
        width: "672px",
        backgroundColor: "var(--white)",
    };

    const styleHeader = {
        width: "100%",
        height: "88px",
        borderBottom: "1px solid var(--grey-0)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleContentBox = {
        padding: "30px",
        height: "500px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleImageBox = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleMessage = {
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "20px"
    };

    return (
        <main style={container}>
            <div style={styleFeedbackBox}>
                <div style={styleHeader}>
                    <img src={companyIcon} alt="XPM Logística" width={135.73} height={55} />
                </div>
                <div style={styleContentBox}>
                    <div style={styleImageBox}>
                        <img src={positive} alt="Obrigada pelo seu feedback" />
                    </div>
                    <h1 className="textPrimaryLanding" style={{ textAlign: "center" }}>Obrigado pelo seu feedback!</h1>
                    <p style={styleMessage}>Vamos usá-lo para melhorar a sua experiência.</p>
                </div>
            </div>
        </main>
    )
};

export default SearchHappyFeedback;