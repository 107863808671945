import React, { useState } from "react";
import Button from "./Button";

const ModalFilter = ({ setSelectedDate, setOpenFilter }) => {
    const [selectedInputDate, setSelectedInputDate] = useState("");

    const stylesMenu = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        border: "1px solid #D7D7D7",
        borderRadius: "20px",
        width: "360px",
        height: "275px",
        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.18)",
        gap: "10px",
    };

    const stylesHeaderMenu = {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: "1px solid #D7D7D7",
        padding: "20px 15px",
        boxSizing: "border-box",
    };

    const handleClearFilters = () => {
        setSelectedInputDate("");
        setSelectedDate("");
    };

    return (
        <section style={stylesMenu}>
            <header style={stylesHeaderMenu}>
                <p>Filtros</p>

                <button
                    style={{
                        height: 25,
                        background: "#fff",
                        borderRadius: 60,
                        border: "1px solid #d7d7d7",
                        cursor: "pointer",
                        color: "#5D5D5D",
                        padding: 0,
                        width: "35%",
                    }}
                    onClick={handleClearFilters}
                    disabled={false}
                >
                    <p
                        style={{
                            fontSize: "14px",
                        }}
                    >
                        Limpar filtros
                    </p>
                </button>
            </header>

            <div style={{ width: "80%" }}>
                <label
                    className="textPrimaryLabelInput"
                    style={{
                        marginBottom: "10px",
                    }}
                >
                    Data
                </label>

                <input
                    style={{
                        width: "100%",
                        border: "1px solid #D7D7D7",
                        borderRadius: "10px",
                        height: "50px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                    }}
                    placeholder="Selecione"
                    type="date"
                    value={selectedInputDate}
                    onChange={(e) => setSelectedInputDate(e.target.value)}
                />

                <Button
                    width={"100%"}
                    height={45}
                    background={"#9966CC"}
                    borderRadius={60}
                    marginTop={40}
                    name={"Salvar filtros"}
                    border={"none"}
                    disabled={false}
                    color={"#FFF"}
                    padding={0}
                    onClick={() => {
                        setSelectedDate(selectedInputDate);
                        setOpenFilter(false);
                    }}
                />
            </div>
        </section>
    );
};

export default ModalFilter;
