import { useNavigate } from "react-router-dom";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderDashboard from "../components/HeaderDashboard";
import Sidebar from "../../../components/Sidebar";
import ContainerInputSearchAndButton from "../components/ContainerInputSearchAndButton";
import CardDetails from "../components/CardDetails";
import { useEffect, useState } from "react";
import CardTableList from "../components/CardTableList";
import ScrollBotton from "../components/ScrollBotton";
import CardTableListShipping from "../components/CardTableListShipping";
import api from "../../../services/api";
import Loading from "../components/Loading";
import createExcelFile from "../../../utils/craeteExcelFile";

const DetailsShipping = () => {
  const [expeditionAll, setExpeditionAll] = useState([]);
  const [expeditionDetailsResume, setExpeditionDetailsResume] = useState({});
  const [expeditionQtd, setExpeditionQtd] = useState(0);

  const [expeditionPrice, setExpeditionPrice] = useState(0);
  const [listItens, setListItens] = useState([]);
  const [expeditionUOM, setExpeditionUOM] = useState(0);

  const [expeditionDescription, setExpeditionDescription] = useState(0);
  const [expeditionPriceUnit, setExpeditionPriceUnit] = useState(0);

  const navigate = useNavigate();

  let div = document.getElementById("test");
  let div2 = document.getElementById("test2");
  let div3 = document.getElementById("test3");
  let div4 = document.getElementById("test4");
  
  const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
  const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
  const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
  const id = localStorage.getItem("@XPM_CLIENT_PAGEMENT_DETAILS_ID:");

  const getInfo = async () => {

    try {
      const [expeditions, expeditionsDetails] = await Promise.all([
        api.get(`/expedition/details/complete/${clientCnpj}/${id}/${year}/${month}`),
        api.get(`/expedition/month/owner/${clientCnpj}/${year}/${month}/${id}`),
      ]);

      setExpeditionAll(expeditions.data);
      setExpeditionDetailsResume(expeditionsDetails.data);
    } catch (error) {
      console.log(error)
    }
  };

  const getExpedition = async () => {
    console.log('expeditionAll', expeditionAll)
    if (expeditionAll.length > 0) {
      const qtdAll = expeditionAll.map((item) => item.qtd_item);
      console.log('qtdAll expedition', qtdAll)

      setExpeditionQtd(
        qtdAll
          .reduce((total, quantity) => total + quantity, 0)
          // .toLocaleString("pt-BR")
      );
      setExpeditionPrice(
        qtdAll.reduce((total, quantity) => total + quantity, 0)
      );
    } else {
      setExpeditionQtd(0);
      setExpeditionPrice(0);
    }
  };

  const syncScroll = () => {
    div = document.getElementById("test");

    // Iterar sobre os elementos do loop e definir o scroll esquerdo igual ao do elemento principal
    if (listItens && listItens.length) {
      listItens.forEach((item) => {
        let divLoop = document.getElementById(item);
        console.log(divLoop, 8080);

        if (divLoop) {
          divLoop.scrollLeft = div.scrollLeft;
        }
      });
    }
  };

  useEffect(() => {
    const div = document.getElementById("test");
    if (div && listItens) {
      div.addEventListener("scroll", syncScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", syncScroll);
      }
    };
  }, [listItens]);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (expeditionAll?.length > 0) {
      getExpedition();
    }
  }, [expeditionAll]);

  useEffect(() => {
    div = document.getElementById("test");
    div2 = document.getElementById("test2");
    div3 = document.getElementById("test3");
    div4 = document.getElementById("test4");

    div.addEventListener("scroll", () => {
      console.log(div3);
      if (div2) {
        div2.scrollLeft = div.scrollLeft;
      }
      if (div3) {
        div3.scrollLeft = div.scrollLeft;
      }
      if (div4) {
        div4.scrollLeft = div.scrollLeft;
      }

      if (div3 == null) {
        div3 = document.getElementById("test3");
      }

      if (div4 == null) {
        div4 = document.getElementById("test4");
      }
    });
  }, [div]);

  //   STYLES

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    marginBottom: "50px",
  };

  const boxCard = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };

  return (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar page="Dashboard" />}
      colum2={
        <div style={container}>
          <HeaderDashboard
            back={true}
            name="Fatura expedição"
            adm={true}
            onClick={() => navigate("/dashboard/company")}
          />
          <div style={{ margin: "42px auto 0" }} className="containerBox">
            <ContainerInputSearchAndButton onClick={() => createExcelFile(expeditionAll, `${'Expedição'} ${year}-${month}`, `${'Expedição'} ${year}-${month}.xlsx`)} />

            <div
              style={{
                display: "flex",
                marginTop: "33px",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <CardDetails
                marginLeft="140px"
                paddingLeft={"150px"}
                content={expeditionDetailsResume[1]?.description}
                quantity={expeditionDetailsResume[1]?.qtd_item}
                UOM={expeditionDetailsResume[1]?.UOM}
                priceUnit={expeditionDetailsResume[1]?.price}
                total={expeditionDetailsResume[1]?.soma_qtd_item || 0}
                totalValue={"R$ 11.980,21"}
              />

              {expeditionAll.length > 0 ? (
                expeditionAll.map((item) => {
                  return (
                    <CardTableListShipping
                      ctrOpenAndExit={item.ctr_ent_sai_veic_docum}
                      loadingPlanning={item.planejto_embarq}
                      qtdSequencias={item.qtd_sequencias}
                      qtdItem={item.qtd_item}
                      qtdPaleteFisico={item.qtd_palete_fisico}
                      qtdPaletePadrao={item.qtd_palete_padrao}
                      pesoBruto={item.peso_bruto}
                      pesoLiquido={item.peso_liquido}
                      expedition={expeditionAll}
                      volumeM3={item.volume_m3}
                      dataComplet={item.dat_hor_conclusao_cesv_docum}
                      dataInclusao={item.dat_hor_inclusao_solicitacao}
                      dataOpen={item.dat_hor_abertura_cesv_docum}
                      typeStock={item.tip_estoque}
                      restriction={item.restricao}
                      valueUnit={1}
                      sequencialItem={item.sequencial_item}
                      item={item.item}
                      listItens={listItens}
                      setListItens={setListItens}
                      notaFiscal={`${item.nota_fiscal}`.trim() || ""}
                      pedido={`${item.pedido}`.trim() || ""}
                    />
                  );
                })
              ) : (
                <Loading />
              )}

              <ScrollBotton width={"2081px"} />
            </div>

            <div style={boxCard}></div>
          </div>
        </div>
      }
    />
  );
};

export default DetailsShipping;
