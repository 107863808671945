import { useState, useEffect, useContext } from "react";
import InputsPrime from "../../../components/InputsPrime";
import Button from "./Button";
import { ContactedContext } from "../../../context/Dashboard/ContactedContext";

const ModalParameterContract = ({ closeModal }) => {
  const {
    createParameter,
    parameters,
    getParameters,
    alreadyRequestParameters,
  } = useContext(ContactedContext);
  const [typeUnit, setTypeUnit] = useState("");
  const [lineOrQtd, setLineOrQtd] = useState("");
  const [metric, setMetric] = useState(0);
  const [metrics, setMetrics] = useState([]);

  const styles = {
    selectBox: {
      border: "none",
      border: "1px solid #d7d7d7",
      padding: "8px 12px",
      outline: "none",
      borderRadius: "8px",
      width: "200px",
    },
    container: {
      width: "650px",
      height: "400px",
      backgroundColor: "#fff",
      padding: "20px",
      border: "1px solid #d7d7d7",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    boxGeneral: { display: "flex", gap: "20px", flexDirection: "column" },
    boxInputs: { display: "flex", gap: "20px" },
  };

  // Função para atualizar a métrica para o tipo de unidade selecionado
  const handleMetricChange = (newMetric) => {
    const clientCNPJ = localStorage.getItem("@XPM_CLIENT_COMPANY:");
    setMetric(newMetric);

    if (typeUnit && lineOrQtd) {
      // Verifica se o tipo de unidade e a linha ou quantidade já estão no array
      const existingIndex = metrics.findIndex(
        (item) => item.type === typeUnit && item.line_or_qtd === lineOrQtd
      );

      if (existingIndex !== -1) {
        // Atualiza a métrica existente
        const updatedMetrics = [...metrics];
        updatedMetrics[existingIndex].metric = newMetric;
        updatedMetrics[existingIndex].client_cnpj = clientCNPJ;

        setMetrics(updatedMetrics);
      } else {
        // Adiciona uma nova métrica
        setMetrics([
          ...metrics,
          {
            type: typeUnit,
            line_or_qtd: lineOrQtd,
            metric: newMetric,
            client_cnpj: clientCNPJ,
          },
        ]);
      }
    }
  };

  // Função para enviar as métricas para a API
  const handleSubmit = () => {
    createParameter(metrics, closeModal);
    setMetrics([]);
    setMetric(0);
    setTypeUnit("");
    setLineOrQtd("");
  };

  // Atualiza a métrica exibida no input ao trocar de tipo de unidade
  useEffect(() => {
    if (!alreadyRequestParameters) getParameters();

    console.log(parameters);

    if (parameters?.length) {
      // Verifica se há uma métrica existente para o tipo de unidade e linha/quantidade selecionados
      const existingMetric = parameters.find(
        (item) => item.type === typeUnit && item.line_or_qtd === lineOrQtd
      );

      setMetric(existingMetric ? existingMetric.metric : 0);
    } else if (metrics?.length) {
      // Verifica se há uma métrica existente nos métricos temporários
      const existingMetric = metrics.find(
        (item) => item.type === typeUnit && item.line_or_qtd === lineOrQtd
      );

      setMetric(existingMetric ? existingMetric.metric : 0);
    } else {
      setMetric(0);
    }
  }, [typeUnit, parameters, lineOrQtd]);

  return (
    <div style={styles.container}>
      <div style={styles.boxGeneral}>
        <div style={{ textAlign: "center" }}>
          <h4>Cadastrar parâmetros</h4>
        </div>

        <div style={styles.boxInputs}>
          <label className="textTitleModalContacted">
            Tipo de unidade:
            <select
              style={styles.selectBox}
              onChange={(e) => setTypeUnit(e.target.value)}
              value={typeUnit}
              className="textTitleSelectContacted"
            >
              <option value="">Selecione</option>
              <option value="Recebimento">Recebimento</option>
              <option value="Saida">Expedição</option>
              <option value="Urgente">Urgência</option>
            </select>
          </label>

          <label className="textTitleModalContacted">
            Linha ou Quantidade:
            <select
              style={styles.selectBox}
              onChange={(e) => setLineOrQtd(e.target.value)}
              value={lineOrQtd}
              className="textTitleSelectContacted"
              disabled={!String(typeUnit).length}
            >
              <option value="">Selecione</option>
              <option value="Linha">Linha</option>
              <option value="Quantidade">Quantidade</option>
            </select>
          </label>

          <InputsPrime
            label={"Métrica de contrato:"}
            type={"number"}
            width={"200px"}
            className={"comumn"}
            mode={"decimal"}
            value={metric}
            disabled={!String(lineOrQtd).length || !String(typeUnit).length}
            onChange={(e) => handleMetricChange(e.value)}
          />
        </div>
      </div>
      <Button
        padding="0 20px"
        height="35px"
        background="var(--color-primary)"
        borderRadius="8px"
        marginTop="0"
        name="Salvar"
        color="#fff"
        border="none"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default ModalParameterContract;
