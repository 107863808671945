import React, { useEffect, useState } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../../../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import CardParameters from "../components/CardParameters";
import CardDetailsInvoicing from "../components/CardDetailsInvoicing";
import * as moment from "moment";
import "moment/locale/pt-br";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import ClientsSelect from "../components/ClientsSelect";
import ListParameter from "../components/ListParameter";
import ModalContainer from "../components/ModalContainer";
import ModalGenerality from "../components/ModalGenerality";
import { toast } from "react-toastify";
import GeneralitieParameters from "../components/GeneralitieParameters";
import LineBorderGap from "../components/LineBorderGap";
import { useContext } from "react";
import { DashContext } from "../../../context/Dashboard/DashContext";
import CardDetailsGeneralities from "../components/CardDetailsGeneralities";
import Loading from "../components/Loading";
import ModalMinimumBilling from "../components/ModalMinimunBilling";
import SelectSessionButtons from "../components/SelectSessionButtons";
import AdjustmentContainer from "../components/AdjustmentContainer";
import GroupingContainer from "../components/GroupingContainer";
import ModalGroupingVisualization from "../components/ModalGroupingVisualization";
import checkIconOn from "../../../images/svg/checkOnIcon.svg";
import checkIconOff from "../../../images/svg/checkOffIcon.svg";
import jwtDecode from "jwt-decode";

const Company = () => {
  const {
    getParametersGeneralities,
    listGeneralities,
    getGeneralities,
    getFixedGeneralities,
    listClientGeneralities,
    setBilling,
    waitingConference,
    awaitingPaymentVirtual,
    setWaitingConference,
    awaitingPayment,
    setAwaitingPayment,
    monthUpdate,
    discount,
    setDiscount,
    minimumBilling,
    setMinimumBilling,
    billingType,
    setBillingType,
    billingTaxed,
    setBillingTaxed,
    minBillingTaxed,
    setMinBillingTaxed,
    tax,
    setTax,
    minTax,
    setMinTax,
    getVariables,
    setOverload,
    getParamsCategory,
    permission,
    arrayOfParams,
    setArrayOfParams,
    update: updateGlobal,
  } = useContext(DashContext);
  const [loading, setLoading] = useState(true);

  const [dateNotFormactApi, setDateNotFormactApi] = useState();
  const [update, setUpdate] = useState(true);
  const [configureParameter, setConfigureParameter] = useState(false);

  const [configureGenerality, setConfigureGenerality] = useState(false);
  const [parameterArry, setParameterArry] = useState([1]);
  const [generalitieParametersArry, setGeneralitieParametersArry] = useState([
    1,
  ]);

  const [receiptsAll, setReceiptsAll] = useState([]);
  const [storageAll, setStorageAll] = useState([]);

  const [expeditionAll, setExpeditionAll] = useState([]);
  const [safeAll, setSafeAll] = useState([]);

  const [storagePrice, setStoragePrice] = useState(0);

  const [expeditionQtd, setExpeditionQtd] = useState(0);
  const [expeditionPrice, setExpeditionPrice] = useState(0);

  const [safeQtd, setSafeQtd] = useState(0);
  const [safePrice, setSafePrice] = useState(0);

  const [receiptsPrice, setReceiptsPrice] = useState(0);

  /* ------- */

  const [checkReceipt, setCheckReceipt] = useState(0);
  const [checkStorage, setCheckStorage] = useState(0);

  const [checkExpedition, setCheckExpedition] = useState(0);
  const [checkSafe, setCheckSafe] = useState(0);

  const [btnIsActive, setBtnIsActive] = useState(true);

  const [updateSelect, setUpdateSelect] = useState(false);

  /* ................*/

  /* Gerando o título conforme tipo de faturamento */

  const [clientsSelect, setClientsSelect] = useState("");
  const [title, setTitile] = useState("");
  const [fixedBilling, setFixedBilling] = useState();

  /* MINIMUM VALUE */
  const [minimumBllingMenu, setMinimumBillingMenu] = useState(false);

  /* RESUMES FOR ADMIN AND CLIENT */
  const [simulated, setSimulated] = useState([]);

  /* TYPE FORM PARAMETER */
  const [btnSelect, setBtnSelect] = useState("Principal");

  /* SUBTOTALS */
  const [subtotalsArray, setSubtotalsArray] = useState([]);

  /* CHECK-ALL */
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    switch (billingType) {
      case "atividade":
        setTitile("Faturamento por atividade");
        break;
      case "porcentual":
        setTitile("Faturamento por porcentual");
        break;
      case "mao de obra":
        setTitile("Faturamento por mão de obra");
        break;
      case "atividade sem imposto":
        setTitile("Faturamento por atividade S/ imposto");
        break;
      default:
        break;
    }
  }, [billingType, clientsSelect]);

  /* ----------------------------------------------- */

  const navigate = useNavigate();

  const getInformation = async () => {
    const month = moment(dateNotFormactApi).format('MM');
    const year = moment(dateNotFormactApi).format('YYYY');
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
    localStorage.setItem("@XPM_CLIENT_YEAR:", year);
    localStorage.setItem("@XPM_CLIENT_MONTH:", month);
    setLoading(false);
    monthUpdate();

    try {
      setLoading(true);
      setBilling(0);
      setWaitingConference(0);
      setAwaitingPayment(0);
      setBtnIsActive(false);

      if (clientCnpj && year && month) {
        const [receipts, storage, expedition, safe, existingDiscount, taxes] =
          await Promise.all([
            api.get(`/receipts/month/${clientCnpj}/${year}/${month}`),
            api.get(`/storage/month/${clientCnpj}/${year}/${month}`),
            api.get(`/expedition/month/${clientCnpj}/${year}/${month}`),
            api.get(`/safe/month/${clientCnpj}/${year}/${month}`),
            api.get(`/billing/admin/discount/${clientCnpj}/${year}-${month}`),
            api.get(`/billing/admin/taxes/${clientCnpj}/${year}-${month}`),
          ]);
        setReceiptsAll(receipts.data);
        setStorageAll(storage.data);
        setExpeditionAll(expedition.data);
        setSafeAll(safe.data);
        setLoading(false);
        setBtnIsActive(true);
        // getParamsCategory();
        if (receipts.data || storage.data || expedition.data || safe.data) {
          setDiscount(existingDiscount.data.discount || 0);
          setTax(taxes.data.tax);
          setMinTax(taxes.data.minTax);
        }
      }
    } catch (error) {
      console.log(error);
      // setLoading(false);
      setBtnIsActive(true);
      toast.error(error?.response.data?.message, { autoClose: 2000 });
    }
  };

  const getSumTotal = async () => {
    let sum =
      (receiptsPrice || 0) +
      (storagePrice || 0) +
      (expeditionPrice || 0) +
      (safePrice || 0);

    if (billingType === "mao de obra") {
      if (listClientGeneralities.length > 1) {
        sum += listClientGeneralities[0].sum_total || 0;
      } else {
        sum = 0;
      }
    } else {
      sum += listClientGeneralities[0]?.sum_total || 0;
    }
    setFixedBilling(sum);
    setBilling(sum - (discount || 0));
  };

  const getWaitingConference = async (total, additionOrSubtraction) => {
    if (additionOrSubtraction == true) {
      const sum = waitingConference - total;
      let numFormact = sum;

      setWaitingConference(numFormact);
      setAwaitingPayment(total + awaitingPayment);
    } else {
      const sum = waitingConference + total;
      let numFormact = sum;

      setWaitingConference(numFormact);
      if (awaitingPayment > 0) {
        setAwaitingPayment(awaitingPayment - total);
      }
    }
  };

  const getParametersAll = async () => {
    try {
      const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
      const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
      const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
      const response = await api.get(`/parameter/${clientCnpj}/${year}-${month}`);
      setParameterArry(response.data);
      console.log(response.data)
      console.log('------- finalizou parameter get')
    } catch (error) {}
  };

  const addParms = () => {
    if (btnSelect === "Principal") {
      setParameterArry([
        ...parameterArry,
        {
          process: "1",
          description: "",
          unit: "1",
          price: "",
        },
      ]);
    } else if (btnSelect === "Generalidades") {
      setGeneralitieParametersArry([...generalitieParametersArry, 1]);
    }
  };

  const verifyContent = (content, type, page) => {
    if (content != 0) {
      navigate(page);
    } else if (type === 'Expedição') {
      navigate(page);
    } else {
      toast(`Não há ${type.toLowerCase()} no mês selecionado`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        bodyStyle: { color: "var(--color-primary)" },
        progressStyle: { backgroundColor: "var(--color-primary)" },
      });
    }
  };

  const destroyParameters = async (id) => {
    try {
      const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
      const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
      const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
      const date = `${year}-${month}`;
      await api.delete(`/parameter/${id}`);
      const response = await api.get(`/parameter/${clientCnpj}/${date}`);

      setParameterArry(response.data);
      toast.success(`Deletado com sucesso`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (typeof dateNotFormactApi == "string") {
      getInformation();
    }
  }, [dateNotFormactApi, update, updateGlobal]);

  const calcTotalGeneral = () => {
    const newArray = [];
    const totalReceipts = receiptsAll.reduce((acc, curr) => {
      const overload =
        billingType === "porcentual"
          ? curr.qtd_item - curr.qtd_contract || 0
          : curr.qtd_item;
      if (overload > 0) {
        return acc + overload * curr.price;
      } else {
        return acc + 0;
      }
    }, 0);
    setReceiptsPrice(parseFloat(totalReceipts.toFixed(2)));
    newArray.push({
      name: "Recebimento",
      value: parseFloat(totalReceipts.toFixed(2)),
    });

    const totalStorage = storageAll.reduce((acc, curr) => {
      const overload =
        billingType === "porcentual"
          ? curr.qtd_item - curr.qtd_contract || 0
          : curr.qtd_item;
      if (overload > 0) {
        return acc + overload * curr.price * curr.factor || 1;
      } else {
        return acc + 0;
      }
    }, 0);
    setStoragePrice(parseFloat(totalStorage.toFixed(2)));
    newArray.push({
      name: "Armazenagem",
      value: parseFloat(totalStorage.toFixed(2)),
    });

    const totalExpedition = expeditionAll.reduce((acc, curr) => {
      const overload =
        billingType === "porcentual"
          ? curr.qtd_item - curr.qtd_contract || 0
          : curr.qtd_item;
      if (overload > 0) {
        return acc + overload * curr.price;
      } else {
        return acc + 0;
      }
    }, 0);
    setExpeditionPrice(parseFloat(totalExpedition.toFixed(2)));
    newArray.push({
      name: "Expedição",
      value: parseFloat(totalExpedition.toFixed(2)),
    });

    const totalSafe = safeAll.reduce((acc, curr) => {
      const overload = curr.qtd_item || 0;
      if (overload > 0) {
        return acc + overload * (curr.price / 100);
      } else {
        return acc + 0;
      }
    }, 0);
    setSafePrice(parseFloat(totalSafe.toFixed(2)));
    newArray.push({ name: "Seguro", value: parseFloat(totalSafe.toFixed(2)) });

    const totalGeneralities = listClientGeneralities[0]?.sum_total || 0;
    newArray.push({
      name: "Generalidades",
      value: parseFloat(totalGeneralities.toFixed(2)),
    });

    setSubtotalsArray(newArray);
  };

  const checkAll = () => {
    const array = document.querySelectorAll(".card-details-check");
    for (let i = 0; i < array.length; i++) {
      setTimeout(() => {
        array[i].click();
      }, 500);
    }
    setAllChecked(!allChecked);
  };

  useEffect(() => {
    calcTotalGeneral();
  }, [receiptsAll, storageAll, expeditionAll, safeAll]);

  useEffect(() => {
    const recebimentos = receiptsAll.filter((e) => e.id);
    const armazenagem = storageAll.filter((e) => e.id);
    const expedicao = expeditionAll.filter((e) => e.id);
    const seguros = safeAll.filter((e) => e.id);
    const consolidedArray = [
      ...recebimentos,
      ...armazenagem,
      ...expedicao,
      ...seguros,
    ];
    const overload = subtotalsArray.reduce(
      (acc, curr) => (curr.name !== "Generalidades" ? acc + curr.value : acc),
      0
    );
    setOverload(overload);
    setArrayOfParams(consolidedArray);
    getParamsCategory([...recebimentos, ...armazenagem, ...expedicao]);
    setSimulated(subtotalsArray);
  }, [subtotalsArray]);

  useEffect(() => {
    getSumTotal();
  }, [
    receiptsPrice,
    storagePrice,
    expeditionPrice,
    safePrice,
    discount,
    listClientGeneralities,
  ]);

  useEffect(() => {
    const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
    const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
    const date = `${year}-${month}`;

    if (!parameterArry?.some((e) => e.date === date)) {
      getParametersAll();
    }

    getParametersGeneralities();
    getFixedGeneralities();
    getGeneralities();
  }, [update, updateSelect, updateGlobal, dateNotFormactApi]);

  useEffect(() => {
    setGeneralitieParametersArry(listGeneralities);
  }, [listGeneralities]);

  useEffect(() => {
    getParametersGeneralities();
    // getInformation();
    if (!configureParameter) {
      setBtnSelect("Principal");
      getParametersGeneralities();
      // getInformation();
    }
  }, [configureParameter]);

  useEffect(() => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    if (year && month) {
      // Convertendo year e month para números
      const numericYear = parseInt(year, 10);
      const numericMonth = parseInt(month, 10);

      // Usando set corretamente com números
      const prevSelectectedDate = moment().set({ month: numericMonth - 1, year: numericYear }).format('YYYY-MM-DD');
      setDateNotFormactApi(prevSelectectedDate);
    } else {
      // Se year e month não estiverem definidos no localStorage, use a data atual
      console.log('não há data no localstorage');
      setDateNotFormactApi(moment().format('YYYY-MM-DD'));
    }
  }, []);

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };

  const boxCard = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };


  return (
    <ThowColumLayout
      page={"Company"}
      colum1={<Sidebar page="Company" />}
      colum2={
        <div style={container}>
          <HeaderDashboard
            back={configureParameter}
            onClick={() => {
              setConfigureParameter(false)
              getInformation()
            }}
            name={configureParameter ? "Configurar parâmetro" : title}
            adm={true}
          />
            <div style={{ margin: "0 auto" }} className="containerBox">
              <CardParameters
                configureParameter={configureParameter}
                setConfigureParameter={setConfigureParameter}
                configureGeneralityOn={() => setConfigureGenerality(true)}
                dateNotFormactApi={dateNotFormactApi}
                setDateNotFormactApi={setDateNotFormactApi}
                btnIsActive={btnIsActive}
                setBtnIsActive={setBtnIsActive}
                upAtt={true}
                update={() => setUpdate(!update)}
                billingType={billingType}
                setBillingType={setBillingType}
                clientsSelect={clientsSelect}
                setClientsSelect={setClientsSelect}
                discount={discount}
                setDiscount={setDiscount}
                minimumBilling={minimumBilling}
                setMinimumBilling={setMinimumBilling}
                fixedBilling={fixedBilling}
                setFixedBilling={setFixedBilling}
                billingTaxed={billingTaxed}
                setBillingTaxed={setBillingTaxed}
                minBillingTaxed={minBillingTaxed}
                setMinBillingTaxed={setMinBillingTaxed}
                tax={tax}
                setTax={setTax}
                minTax={minTax}
                setMinTax={setMinTax}
                setMinimumBillingMenu={() =>
                  setMinimumBillingMenu(!minimumBllingMenu)
                }
                simulated={simulated}
                subtotalsArray={subtotalsArray}
                generalidades={listClientGeneralities}
                arrayOfParams={arrayOfParams}
              />
              {
                permission !== "Operacional" && (
                  <div
                    style={{display: "flex", marginBottom: 40, cursor: "pointer", width: "20%",}}
                    onClick={() => checkAll()}
                  >
                    <img
                      style={{
                        marginRight: "10px",
                      }}
                      src={
                        waitingConference ? checkIconOn : checkIconOff
                      }
                      alt="check-all"
                    />
                    <p>Selecionar todos</p>
                  </div>
                )
              }

              {loading ? (
                <Loading />
              ) : (
                <div style={boxCard}>
                  {billingType !== "mao de obra" && permission !== 'Operacional' ? (
                    <CardDetailsInvoicing
                      onClick={(qtd) =>
                        verifyContent(
                          qtd,
                          "Recebimento",
                          "/dashboard/company/details/invoice"
                        )
                      }
                      title={"Recebimento"}
                      getWaitingConference={getWaitingConference}
                      marginLeft="40px"
                      contentAll={receiptsAll}
                      check={checkReceipt}
                      setCheck={setCheckReceipt}
                      priceTotal={receiptsPrice || 0}
                      comments={true}
                      hasContract={billingType === "porcentual"}
                      subtotalsArray={subtotalsArray}
                      setSubtotalsArray={setSubtotalsArray}
                    />
                  ) : null}

                  {billingType !== "mao de obra" && permission !== 'Operacional' ? (
                    <CardDetailsInvoicing
                      onClick={(qtd) =>
                        verifyContent(
                          qtd,
                          "Armazenagem",
                          "/dashboard/company/details/storage"
                        )
                      }
                      title={"Armazenagem"}
                      getWaitingConference={getWaitingConference}
                      marginLeft="40px"
                      contentAll={storageAll}
                      priceTotal={storageAll[0]?.sum_all || 0}
                      check={checkStorage}
                      setCheck={setCheckStorage}
                      // onlyOne={false}
                      hasContract={billingType === "porcentual"}
                      subtotalsArray={subtotalsArray}
                      setSubtotalsArray={setSubtotalsArray}
                    />
                  ) : null}

                  {billingType !== "mao de obra" && permission !== 'Operacional' ? (
                    <CardDetailsInvoicing
                      onClick={() =>
                        verifyContent(
                          safeQtd,
                          "Seguro de Armazenagem",
                          "/dashboard/company/details/insurance"
                        )
                      }
                      title={"Seguro de Armazenagem"}
                      getWaitingConference={getWaitingConference}
                      marginLeft="40px"
                      contentAll={safeAll}
                      priceTotal={safeAll[0]?.sum_all || 0}
                      check={checkSafe}
                      setCheck={setCheckSafe}
                      percentage={true}
                      hasContract={billingType === "porcentual"}
                      subtotalsArray={subtotalsArray}
                      setSubtotalsArray={setSubtotalsArray}
                    />
                  ) : null}

                  {billingType !== "mao de obra" && permission !== 'Operacional' ? (
                    <CardDetailsInvoicing
                      onClick={() =>
                        verifyContent(
                          expeditionQtd,
                          "Expedição",
                          "/dashboard/company/details/shipping"
                        )
                      }
                      title={"Expedição"}
                      getWaitingConference={getWaitingConference}
                      marginLeft="40px"
                      contentAll={expeditionAll}
                      priceTotal={expeditionAll[0]?.sum_all || 0}
                      check={checkExpedition}
                      setCheck={setCheckExpedition}
                      hasContract={billingType === "porcentual"}
                      subtotalsArray={subtotalsArray}
                      setSubtotalsArray={setSubtotalsArray}
                    />
                  ) : null}

                  <CardDetailsGeneralities
                    onClick={(qtd) =>
                      verifyContent(
                        qtd,
                        "Generalidades",
                        "/dashboard/company/details/generalities"
                      )
                    }
                    title={
                      billingType === "mao de obra"
                        ? "Memorial de cálculo"
                        : "Generalidades"
                    }
                    getWaitingConference={getWaitingConference}
                    marginLeft="40px"
                    contentAll={safeAll}
                    check={checkSafe}
                    setCheck={setCheckSafe}
                    percentage={true}
                    permission={permission}
                  />
                </div>
              )}
            </div>
        </div>
      }
    ></ThowColumLayout>
  );
};

export default Company;
