import CardGraphicsLarge from "./CardGraphicsLarge"
import CardYear from "./CardYear"
import CardsDetailsQuality from "./CardsDetailsQuality"


const ContainerQuality = () => {

    return (
        <div style={{ marginTop: "30px", display: "flex", flexDirection: "column", gap: '30px' }}>
            <CardYear />
            <CardsDetailsQuality />

            <CardGraphicsLarge max={100} title={"Índice de qualidade de serviços"} listMonths={[11, 98, 12, 20, 85, 73, 99, 92, 5, 10, 10, 80]} />
        </div>
    )
}

export default ContainerQuality