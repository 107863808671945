

const Development = () => {


    return(
        <div>
            <h2>Em desenvolvimento</h2>
            
        </div>
    )
}

export default Development