import React, { useState } from 'react';
import arrowUpIcon from "../../../images/svg/arrowCircleUpAction.svg";
import arrowDownIcon from "../../../images/svg/arrowCircleDownAction.svg";
import DocumentCardToList from './DocumentCardToList';

const DocumentCard = ({ document, onClick }) => {
    const [isArrowUp, setIsArrowUp] = useState(true);
    const [isHovered, setIsHovered] = useState(false);
    
    const toggleArrowIcon = () => {
        setIsArrowUp((prevValue) => !prevValue);
    };
    
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const borderHoverStyle = {
        borderColor: isHovered ? 'var(--color-primary)' : 'var(--grey-0)',
    };

    const container = {
        width: "999px",
        height: "73px",
        border: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "0.8s",
        ...borderHoverStyle
    };

    const styleLinkHover = {
        cursor: 'pointer',
        textDecoration: isHovered ? 'underline' : null,
        color: isHovered ? 'var(--color-primary)' : 'black',
    };

    const styleValue = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        fontWeight: 400,
        transition: "0.8s"
    };

    return (
        <>
            <li style={container} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div style={{ width: "45px", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={toggleArrowIcon}>
                    {document.subDocuments.length > 0 && (
                        <img src={isArrowUp ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                    )}
                </div>
                <div style={{ width: "120px" }}>
                <p style={{ ...styleValue, ...styleLinkHover }} onClick={onClick}>{document.code}</p>
                </div>
                <div style={{ width: "496px" }}>
                    <p style={styleValue}>{document.title}</p>
                </div>
                <div style={{ width: "146px" }}>
                    <p style={styleValue}>{document.category}</p>
                </div>
                <div style={{ width: "154px" }}>
                    <p style={styleValue}>{document.date}</p>
                </div>
            </li>
            {!isArrowUp && (
                <ul style={{ margin: "0", padding: "0" }}>
                    <DocumentCardToList subDocuments={document.subDocuments} />
                </ul>
            )}
        </>
    );
};

export default DocumentCard;
