import React, { useContext } from "react";
import ContainerControllerInternalDocuments from "./ContainerControllerInternalDocuments";
import { InternalDocumentsContext } from "../../../context/Quality/InternalDocumentsContext";
import ContainerContentDocuments from "./ContainerContentDocuments";
import ContainerContentPendencies from "./ContainerContentPendencies";
import ModalContainer from "./ModalContainer";
import closeIcon from '../../../images/svg/closeIcon.svg';
import ModalFilterCards from "./ModalFilterCards";

const ContainerContentInternalDocumentPage = () => {

  const { btnSelect, modalFilterCardsOpen, handleModalClose } = useContext(InternalDocumentsContext);

  return (
    <div style={{ margin: "0 auto" }} className="containerBox">

      <ContainerControllerInternalDocuments />

      {btnSelect === "Pendências" && (
        <ContainerContentPendencies />
      )}
      {btnSelect === "Documentos" &&
        <ContainerContentDocuments />
      }

      <ModalContainer isActive={modalFilterCardsOpen} closeModal={handleModalClose}>
        <ModalFilterCards
          closeModal={handleModalClose}
          closeIcon={closeIcon}
        />
      </ModalContainer>
    </div>
  );
};

export default ContainerContentInternalDocumentPage;
