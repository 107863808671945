import { useEffect, useState } from "react";
import ArrowIcon from "./ArrowIcon";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const CardTableList = ({
  qtdItem,
  qtdPalete,
  pesoBruto,
  pesoLiquido,
  m3,
  dateOpen,
  dateCesv,
  nameItem,
  skuDefault,
  typeStock,
  seqAvisoRecebto,
  restriction,
  qtdItemDetail,
  qtyPhysicalPallet,
  qtyDefaultPallet,
  pesoBrutoDetails,
  netWeight,
  volumeM3,
  categoryItem,
  listItens,
  setListItens,
  index,
  fiscalNote,
}) => {
  /* STATES */
  const [arrowPosition, setArrowPosition] = useState(true);
  const [idOne, setIdOne] = useState("");
  const [idTwo, setIdTwo] = useState("");

  useEffect(() => {
    const idDiv1 = uuidv4();
    const idDiv2 = uuidv4();

    setIdOne(idDiv1);
    setIdTwo(idDiv2);

    setListItens((prevList) => [...prevList, idDiv1, idDiv2]);
  }, []);

  /* STYLES */
  const containerStyle = {
    width: "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    marginTop: "15px",
    padding: "25px",
    overflow: "hidden",
    backgroundColor: "#fff",
  };

  const headerRowStyle = {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    overflowX: "hidden",
  };

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  };

  const hiddenContentStyle = {
    display: !arrowPosition ? "none" : "block",
  };

  /* RENDER */
  return (
    <div style={containerStyle}>
      <div
        id={idOne}
        style={{
          ...headerRowStyle,
          paddingBottom: arrowPosition && "20px",
          marginBottom: arrowPosition && "20px",
          borderBottom: arrowPosition ? "1px solid #d7d7d7" : "",
        }}
      >
        <ArrowIcon
          onClick={() => setArrowPosition(!arrowPosition)}
          position={arrowPosition}
        />
        <div style={{ ...contentValueCard, minWidth: "220px" }}>
          <p className="titleNameCompanyCard">{nameItem}</p>
          <p className="textTitleValueCard">Item</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "90px" }}>
          <p className="titleNameCompanyCard">{skuDefault}</p>
          <p className="textTitleValueCard">SKU padrão</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "97px" }}>
          <p className="titleNameCompanyCard">{typeStock}</p>
          <p className="textTitleValueCard">Tipo de estoque</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "90px" }}>
          <p className="titleNameCompanyCard">{restriction}</p>
          <p className="textTitleValueCard">Restrição</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "90px" }}>
          <p className="titleNameCompanyCard">{categoryItem}</p>
          <p className="textTitleValueCard">Categoria item</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "238px" }}>
          <p className="titleNameCompanyCard">
            {moment.utc(dateOpen).format("DD/MM/YYYY - HH:mm")}
          </p>
          <p className="textTitleValueCard">
            Data & Hora abertura CESV documento
          </p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "248px" }}>
          <p className="titleNameCompanyCard">
            {moment.utc(dateCesv).format("DD/MM/YYYY - HH:mm")}
          </p>
          <p className="textTitleValueCard">
            Data & Hora conclusão CESV documento
          </p>
        </div>
      </div>

      <div style={hiddenContentStyle}>
        {/* <div
          id="test3"
          style={{
            ...headerRowStyle,
            paddingBottom: "20px",
            marginBottom: "20px",
            borderBottom: arrowPosition ? "1px solid #d7d7d7" : "",
          }}
        >
          <div style={{ ...contentValueCard, minWidth: "220px" }}>
            <p className="titleNameCompanyCard">
              Válvula com Fluxômetro Digital
            </p>
            <p className="textTitleValueCard">Item</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">VSE1066</p>
            <p className="textTitleValueCard">SKU padrão</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "97px" }}>
            <p className="titleNameCompanyCard">TE0001</p>
            <p className="textTitleValueCard">Tipo de estoque</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">RS0001</p>
            <p className="textTitleValueCard">Restrição</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "57px" }}>
            <p className="titleNameCompanyCard">1.000</p>
            <p className="textTitleValueCard">QTD item</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "102px" }}>
            <p className="titleNameCompanyCard">2</p>
            <p className="textTitleValueCard">QTD palete fisico</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">4.150 KG</p>
            <p className="textTitleValueCard">Peso bruto</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">15.223</p>
            <p className="textTitleValueCard">Peso liquido</p>
          </div>
        </div> */}

        <div id={idTwo} style={headerRowStyle}>
          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">{qtdItem}</p>
            <p className="textTitleValueCard">QTD item</p>
          </div>
          <div style={{ ...contentValueCard, minWidth: "102px" }}>
            <p className="titleNameCompanyCard">{qtdPalete}</p>
            <p className="textTitleValueCard">QTD palete fisico</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "65px" }}>
            <p className="titleNameCompanyCard">{pesoBruto}</p>
            <p className="textTitleValueCard">Peso bruto</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "73px" }}>
            <p className="titleNameCompanyCard">{pesoLiquido}</p>
            <p className="textTitleValueCard">Peso liquido</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "65px" }}>
            <p className="titleNameCompanyCard">{m3}</p>
            <p className="textTitleValueCard">Volume M³</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "200px" }}>
            <p className="titleNameCompanyCard">{seqAvisoRecebto}</p>
            <p className="textTitleValueCard">Sequencia Aviso recebimento</p>
          </div>
          <div style={{ ...contentValueCard, minWidth: "57px" }}>
            <p className="titleNameCompanyCard">{qtdItemDetail}</p>
            <p className="textTitleValueCard">QTD item</p>
          </div>
          <div style={{ ...contentValueCard, minWidth: "102px" }}>
            <p className="titleNameCompanyCard">{qtyPhysicalPallet}</p>
            <p className="textTitleValueCard">QTD palete fisico</p>
          </div>
          <div style={{ ...contentValueCard, minWidth: "120px" }}>
            <p className="titleNameCompanyCard">{qtyDefaultPallet}</p>
            <p className="textTitleValueCard">QTD palete padrão</p>
          </div>
          <div style={{ ...contentValueCard, minWidth: "102px" }}>
            <p className="titleNameCompanyCard">{volumeM3}</p>
            <p className="textTitleValueCard">Volume m³</p>
          </div>
          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">{pesoBrutoDetails}</p>
            <p className="textTitleValueCard">Peso bruto</p>
          </div>
          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">{netWeight}</p>
            <p className="textTitleValueCard">Peso liquido</p>
          </div>
          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">{fiscalNote}</p>
            <p className="textTitleValueCard">Nota fiscal</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTableList;
