import React from 'react';

const ModalActions = ({ closeIcon, closeModal, setDeleteOpen }) => {

  const stylesMenu = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #D7D7D7',
    borderRadius: '20px',
    width: '360px',
    height: '275px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.18)',
    gap: '10px',
  };

  const stylesHeaderMenu = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: '1px solid #D7D7D7',
    padding: '20px 15px',
    boxSizing: 'border-box',
  };

  const stylesCloseBtn = {
    background: 'none',
    border: 'none',
  };

  const stylesDeleteOptions = {
    width: '100%',
    textDecoration: 'none',
    textAlign: 'center',
    color: 'red',
    background: 'none',
    borderTop: '0px',
    borderLeft: '0px',
    borderRight: '0px',
    borderBottom: '1px solid #D7D7D7',
    height: '45px',
  };

  return (
    <section style={stylesMenu}>
      <header style={stylesHeaderMenu}>
        <p>Ações</p>
        <button name="closeMenuActions" style={stylesCloseBtn} onClick={() => closeModal()}>
          <img src={closeIcon} alt='close' />
        </button>
      </header>
      <button name="openDeleteMenu" style={stylesDeleteOptions} onClick={() => {
        setDeleteOpen(true); closeModal()
      }}>Excluir pesquisa</button>
    </section>
  )
}

export default ModalActions;
