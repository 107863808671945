import { useState } from "react";


const CardsDetailsYearInbound = ({ data }) => {
    let total = 0
    /* MODEL RESPONSE LIST DETAILS */

    const listReceived = [
        { month: "Jan", value: "34" },
        { month: "Fev", value: "64" },
        { month: "Mar", value: "97" },
        { month: "Abr", value: "31" },
        { month: "Mai", value: "73" },
        { month: "Jun", value: "54" },
        { month: "Jul", value: "26" },
        { month: "Ago", value: "19" },
        { month: "Set", value: "87" },
        { month: "Out", value: "39" },
        { month: "Nov", value: "61" },
        { month: "Dez", value: "73" },
    ];

    const listReceivedSkus = [
        { month: "Jan", value: "1.958" },
        { month: "Fev", value: "3.205" },
        { month: "Mar", value: "4.567" },
        { month: "Abr", value: "2.856" },
        { month: "Mai", value: "4.945" },
        { month: "Jun", value: "2.457" },
        { month: "Jul", value: "3.876" },
        { month: "Ago", value: "1.789" },
        { month: "Set", value: "5.298" },
        { month: "Out", value: "4.134" },
        { month: "Nov", value: "2.980" },
        { month: "Dez", value: "1.502" },
    ];

    const listTruckReceived = [
        { month: "Jan", value: "6.258" },
        { month: "Fev", value: "5.768" },
        { month: "Mar", value: "4.563" },
        { month: "Abr", value: "7.938" },
        { month: "Mai", value: "6.481" },
        { month: "Jun", value: "8.105" },
        { month: "Jul", value: "6.542" },
        { month: "Ago", value: "4.901" },
        { month: "Set", value: "7.328" },
        { month: "Out", value: "6.918" },
        { month: "Nov", value: "5.403" },
        { month: "Dez", value: "6.972" },
    ];

    const listTrailerReceived = [
        { month: "Jan", value: "1.635" },
        { month: "Fev", value: "2.560" },
        { month: "Mar", value: "3.389" },
        { month: "Abr", value: "2.876" },
        { month: "Mai", value: "1.987" },
        { month: "Jun", value: "3.702" },
        { month: "Jul", value: "2.421" },
        { month: "Ago", value: "3.809" },
        { month: "Set", value: "1.935" },
        { month: "Out", value: "2.710" },
        { month: "Nov", value: "2.489" },
        { month: "Dez", value: "1.803" },
    ];

    const listUnitsReceived = [
        { month: "Jan", value: "1.635" },
        { month: "Fev", value: "2.560" },
        { month: "Mar", value: "3.389" },
        { month: "Abr", value: "2.876" },
        { month: "Mai", value: "1.987" },
        { month: "Jun", value: "3.702" },
        { month: "Jul", value: "2.421" },
        { month: "Ago", value: "3.809" },
        { month: "Set", value: "1.935" },
        { month: "Out", value: "2.710" },
        { month: "Nov", value: "2.489" },
        { month: "Dez", value: "1.803" },
    ];

    const sumValues = (dataList) => {
        let total = 0;

        dataList.forEach((item) => {
            total += Number(item?.value);
        });

        return total;
    };


    const container = {
        display: "flex",
        flexDirection: "column",
        gap: "13px"
    }

    const containerContent = {
        width: "100%",
        padding: "20px 24px",
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
        borderRadius: "10px"
    }

    const contentValueCard = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
        whiteSpace: "nowrap"
    }

    return (
        <div style={container}>
            {/* LIST RECEIVED */}
            <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute", left: "28px" }}>
                        <p className="titleNameCompanyCard">N° de processos recebidos</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "280px" }}>
                        {
                            data.map(item => {
                                let sum = 0
                                item?.value.map(elem => sum += elem?.qtd_item || 0)
                                total += sum    
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{(sum).toLocaleString("pt-BR")}</p>
                                        <p className="textTitleValueCard">{item.month}</p>
                                    </div>
                                )
                            })
                        }

                        <div style={contentValueCard}>
                            <p className="titleNameCompanyCard">{(total).toLocaleString("pt-BR")}</p>
                            <p className="textTitleValueCard">Acumulado</p>
                        </div>
                    </div>

                </div>
            </div>

            {/* LIST RECEIVED SKUs */}
            {/* <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute", left: "22px" }}>
                        <p className="titleNameCompanyCard">N° de linhas recebidas (SKUs)</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "280px" }}>
                        {
                            listReceivedSkus.map(item => {
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{item.value}</p>
                                        <p className="textTitleValueCard">{item.month}</p>
                                    </div>
                                )
                            })
                        }
                        <div style={contentValueCard}>
                            <p className="titleNameCompanyCard">{sumValues(listReceivedSkus)}</p>
                            <p className="textTitleValueCard">Acumulado</p>
                        </div>
                    </div>

                </div>
            </div> */}

            {/* LIST TRUCK RECEIVED */}
            {/* <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute" }}>
                        <p className="titleNameCompanyCard">Container 20/Toco/truck recebidos</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "280px" }}>
                        {
                            listTruckReceived.map(item => {
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{item.value}</p>
                                        <p className="textTitleValueCard">{item.month}</p>
                                    </div>
                                )
                            })
                        }
                        <div style={contentValueCard}>
                            <p className="titleNameCompanyCard">{sumValues(listTruckReceived)}</p>
                            <p className="textTitleValueCard">Acumulado</p>
                        </div>
                    </div>

                </div>
            </div> */}

            {/* LIST TRAILER RECEIVED */}
            {/* <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute", left: "10px" }}>
                        <p className="titleNameCompanyCard">Container 40/ carreta recebidos</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "280px" }}>
                        {
                            listTrailerReceived.map(item => {
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{item.value}</p>
                                        <p className="textTitleValueCard">{item.month}</p>
                                    </div>
                                )
                            })
                        }
                        <div style={contentValueCard}>
                            <p className="titleNameCompanyCard">{sumValues(listTrailerReceived)}</p>
                            <p className="textTitleValueCard">Acumulado</p>
                        </div>
                    </div>

                </div>
            </div> */}

            {/* LIST UNITS RECEIVED */}
            {/* <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute", left: "29px" }}>
                        <p className="titleNameCompanyCard">N° de unidades recebidas</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "280px" }}>
                        {
                            listUnitsReceived.map(item => {
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{item.value}</p>
                                        <p className="textTitleValueCard">{item.month}</p>
                                    </div>
                                )
                            })
                        }
                        <div style={contentValueCard}>
                            <p className="titleNameCompanyCard">{sumValues(listUnitsReceived)}</p>
                            <p className="textTitleValueCard">Acumulado</p>
                        </div>
                    </div>

                </div>
            </div> */}
        </div>
    )
}

export default CardsDetailsYearInbound