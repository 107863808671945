import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ value, setValue }) => {
    const Font = ReactQuill.Quill.import("formats/font");
    Font.whitelist = ["mirza", "roboto"];
    ReactQuill.Quill.register(Font, true);

    const toolBarOptions = [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ script: "sub" }, { script: "super" }],
        ["link", "image"],
        ["clean"],
    ];

    const module = {
        toolbar: {
            container: toolBarOptions,
        },
    };

    return (
        <>
            <ReactQuill
                modules={module}
                theme="snow"
                style={{ background: "#fff", height: "500px" }}
                value={value}
                onChange={setValue}
            />
            <style>
                {`
          .ql-toolbar.ql-snow {
            border: 1px solid #ccc;
            box-sizing: border-box;
            font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
            padding: 8px;
            border-radius: 7px 7px 0 0;
          }
        `}
            </style>
        </>
    );
};

export default TextEditor;