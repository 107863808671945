import React from "react";

const ListButtons = ({
    isSelected,
    onClick,
    text,
    selectedColor,
    unselectedColor,
}) => {
    const styleButton = {
        minWidth: "150px",
        margin: "5px",
        borderRadius: 60,
        height: "35px",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        fontSize: "15px",
        border: "none",
        padding: '15px',
        ...isSelected ? { backgroundColor: selectedColor, color: unselectedColor } : { backgroundColor: unselectedColor, color: selectedColor }
    };

    return (
        <button
            style={styleButton}
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export default ListButtons;
