import React from 'react';
import Select from './Select';
import { options } from './mocks/SelectGenericOptions';
import Button from './Button';
import Input from './Input';

const ModalFilterCards = ({
  closeModal,
  closeIcon,
}) => {

  const stylesMenu = {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    border: '1px solid #D7D7D7',
    borderRadius: '20px',
    width: '375px',
    height: '595px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.18)',
  };

  const stylesHeaderMenu = {
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: '1px solid #D7D7D7',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 15px',
    width: '100%',
  };

  const stylesCloseBtn = {
    background: 'none',
    border: 'none',
    marginRight: '16px'
  };

  const styleTitle = {
    color: 'var(--black)',
    fontSize: '18px',
    fontWeight: 500
  };

  const styleContentBox = {
    padding: "20px",
    display: "flex",
    flexDirection: "Column",
    gap: "20px",
    overflowY: "auto",
  };

  return (
    <div style={stylesMenu}>
      <header style={stylesHeaderMenu}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button name="closeDeleteMenu" style={stylesCloseBtn} onClick={() => closeModal(false)}>
            <img src={closeIcon} alt='close.png' />
          </button>
          <p style={styleTitle}>Filtros</p>
        </div>
        <Button
            width="130px"
            height="32px"
            background="var(--white)"
            color="var(--grey-2)"
            borderRadius="60px"
            marginTop="0"
            name="Limpar filtros"
            border="1px solid var(--grey-0)"
          />
      </header>
      <div style={styleContentBox}>
        <Select
          label="Unidade"
          width="100%"
          marginBottom="0"
          options={options}
        />
        <Select
          label="Processo"
          width="100%"
          marginBottom="0"
          options={options}
        />
        <Select
          label="Unidade"
          width="100%"
          marginBottom="0"
          options={options}
        />
        <Input 
          name="Data"
          width="100%"
          marginBottom="0"
          type="date"
        />
        <Select
          label="Status"
          width="100%"
          marginBottom="0"
          options={options}
        />
        <div>
          <Button
            width="100%"
            height="37px"
            background="var(--color-primary)"
            color="var(--white)"
            borderRadius="60px"
            marginTop="0"
            name="Salvar filtros"
            border="none"
          />
        </div>
      </div>
    </div>
  )
}

export default ModalFilterCards;
