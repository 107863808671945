import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import CardUser from "./CardUser";
import ModalContainer from "../components/ModalContainer";
import ModalOptions from "./ModalOptions";
import ModalFormClient from "./ModalFormClient";
import ModalFormAdmin from "./ModalFormAdmin";

const TableList = ({
  filterOption,
  searchingUser,
  setNameButtom,
  setColorButtom,
  listCardSelected,
  setListCardSelected,
  modalFormAdmin,
  setModalFormAdmin,
  modalFormClient,
  setModalFormClient,
  optionModalFormAdmin,
  setOptionModalFormAdmin,
  optionModalFormClient,
  setOptionModalFormClient,
}) => {
  const [listAdmins, setListAdmins] = useState([
    {
      id: 1,
      picture:
        "https://upload.wikimedia.org/wikipedia/commons/2/2a/Elmo_20050215.jpg",
      name: "Elmo",
      email: "elmo@mail.com",
      permission: "Administrativo",
      password: "1234",
    },
    {
      id: 2,
      picture:
        "https://www.otempo.com.br/image/contentid/policy:1.2217798:1564840960/image.jpg?f=3x2&q=0.6&w=600&$p$f$q$w=4a9d91e",
      name: "Comecome",
      email: "come@mail.com",
      permission: "Operacional",
      password: "1234",
    },
    {
      id: 3,
      picture:
        "https://i.pinimg.com/236x/5b/70/7c/5b707c442f808a09099bb48019e3c7e1.jpg",
      name: "Beto",
      email: "beto@mail.com",
      permission: "Operacional",
      password: "1234",
    },
    {
      id: 4,
      picture:
        "https://www.petmag.com.br/app/uploads/petteca/famosos/8514/garibaldo-02.jpg",
      name: "Garibaldo",
      email: "gari@mail.com",
      permission: "Administrativo",
      password: "1234",
    },
    {
      id: 5,
      picture:
        "https://pbs.twimg.com/media/EqXar4vWMAYNqTF?format=jpg&name=large",
      name: "Não lembro",
      email: "nao@mail.com",
      permission: "Operacional",
      password: "1234",
    },
  ]);

  const [listClients, setListClients] = useState([
    {
      id: 6,
      picture:
        "https://upload.wikimedia.org/wikipedia/commons/2/2a/Elmo_20050215.jpg",
      name: "Elmo",
      cnpj: "77.890.846/0044-09",
      email: "elmo@mail.com",
      coin: "Real BRL",
      billingDate: "01/10/2023",
      password: "1234",
    },
    {
      id: 7,
      picture:
        "https://www.otempo.com.br/image/contentid/policy:1.2217798:1564840960/image.jpg?f=3x2&q=0.6&w=600&$p$f$q$w=4a9d91e",
      name: "Comecome",
      cnpj: "77.890.846/0057-23",
      email: "come@mail.com",
      coin: "Real BRL",
      billingDate: "01/07/2023",
      password: "1234",
    },
    {
      id: 8,
      picture:
        "https://i.pinimg.com/236x/5b/70/7c/5b707c442f808a09099bb48019e3c7e1.jpg",
      name: "Beto",
      cnpj: "43.683.016/0001-59",
      email: "beto@mail.com",
      coin: "Real BRL",
      billingDate: "01/10/2023",
      password: "1234",
    },
    {
      id: 9,
      picture:
        "https://www.petmag.com.br/app/uploads/petteca/famosos/8514/garibaldo-02.jpg",
      name: "Garibaldo",
      cnpj: "77.890.846/0057-23",
      email: "gari@mail.com",
      coin: "Real BRL",
      billingDate: "01/05/2023",
      password: "1234",
    },
    {
      id: 10,
      picture:
        "https://pbs.twimg.com/media/EqXar4vWMAYNqTF?format=jpg&name=large",
      name: "Não lembro",
      cnpj: "77.890.846/0044-09",
      email: "nao@mail.com",
      coin: "Real BRL",
      billingDate: "01/07/2023",
      password: "1234",
    },
  ]);

  const [globalCheked, setGlobalCheked] = useState(false);
  const [cardSelected, setCardSelected] = useState();

  const [modalOptions, setModalOpstions] = useState(false);

  useEffect(() => {
    if (filterOption == "Acessos admin") {
      setNameButtom("Novo usuário");
    } else if (filterOption == "Acessos clientes") {
      setNameButtom("Novo cliente");
    }
    if (globalCheked) {
      setNameButtom("Deletar todos");
      setColorButtom("red");
    } else {
      setColorButtom("var(--color-primary)");
    }
  }, [globalCheked, filterOption]);

  return (
    <>
      {filterOption == "Acessos admin" ? (
        <div>
          <table
            className="border-table"
            style={{ textAlign: "left", width: "100%", marginTop: "2.68rem" }}
          >
            <thead>
              <tr style={{ position: "relative", top: "20px" }}>
                <td style={{ borderRight: "none" }}>
                  <input
                    type="checkbox"
                    checked={globalCheked}
                    onChange={() => setGlobalCheked(!globalCheked)}
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "3px",
                    }}
                  />
                </td>
                <td style={{ borderRight: "none", paddingLeft: "63px" }}>
                  Nome do usuário
                </td>
                <td style={{ borderRight: "none" }}>E-mail de acesso</td>
                <td style={{ borderRight: "none" }}>Permissão</td>
                <td></td>
              </tr>
            </thead>
            {listAdmins
              // FILTRO DE NOMES
              .filter((user) =>
                user.name.toLowerCase().includes(searchingUser.toLowerCase())
              )
              .map((item) => {
                return (
                  <>
                    <CardUser
                      user={item}
                      filterOption={filterOption}
                      globalCheked={globalCheked}
                      setCardSelected={setCardSelected}
                      setModalOpstions={() => setModalOpstions(true)}
                      setOptionModalFormAdmin={() =>
                        setOptionModalFormAdmin("edit")
                      }
                      setOptionModalFormClient={() =>
                        setOptionModalFormClient("edit")
                      }
                      setNameButtom={setNameButtom}
                      setColorButtom={setColorButtom}
                      listCardSelected={listCardSelected}
                      setListCardSelected={setListCardSelected}
                    />
                    <div style={{ marginTop: "13px" }} />
                  </>
                );
              })}
            <div style={{ paddingBottom: 100 }} />
          </table>
        </div>
      ) : (
        <div>
          <table
            className="border-table"
            style={{ textAlign: "left", width: "100%", marginTop: "2.68rem" }}
          >
            <thead>
              <tr style={{ position: "relative", top: "20px" }}>
                <td style={{ borderRight: "none" }}>
                  <input
                    type="checkbox"
                    checked={globalCheked}
                    onChange={() => setGlobalCheked(!globalCheked)}
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "3px",
                    }}
                  />
                </td>
                <td style={{ borderRight: "none", paddingLeft: "63px" }}>
                  Cliente
                </td>
                <td style={{ borderRight: "none" }}>CNPJ</td>
                <td style={{ borderRight: "none" }}>E-mail de acesso</td>
                <td style={{ borderRight: "none" }}>Moeda</td>
                <td style={{ borderRight: "none" }}>Inicio de faturamento</td>
                <td></td>
              </tr>
            </thead>
            {listClients
              // FILTRO DE NOMES
              .filter((user) =>
                user.name.toLowerCase().includes(searchingUser.toLowerCase())
              )
              .map((item) => {
                return (
                  <>
                    <CardUser
                      user={item}
                      billingDate={item.billingDate}
                      filterOption={filterOption}
                      globalCheked={globalCheked}
                      setCardSelected={setCardSelected}
                      setModalOpstions={() => setModalOpstions(true)}
                      setOptionModalFormAdmin={() =>
                        setOptionModalFormAdmin("edit")
                      }
                      setOptionModalFormClient={() =>
                        setOptionModalFormClient("edit")
                      }
                      setNameButtom={setNameButtom}
                      setColorButtom={setColorButtom}
                      listCardSelected={listCardSelected}
                      setListCardSelected={setListCardSelected}
                    />
                    <div style={{ marginTop: "13px" }} />
                  </>
                );
              })}
            <div style={{ paddingBottom: 100 }} />
          </table>
        </div>
      )}
      <ModalContainer
        isActive={modalOptions}
        closeModal={() => setModalOpstions(!modalOptions)}
      >
        <ModalOptions
          setModalFormAdmin={() => setModalFormAdmin(!modalFormAdmin)}
          setModalFormClient={() => setModalFormClient(!modalFormClient)}
          isActive={modalOptions}
          edit={true}
          type="delete"
          card={cardSelected}
          closeModal={() => setModalOpstions(!modalOptions)}
          filterOption={filterOption}
        />
      </ModalContainer>

      <ModalContainer
        isActive={modalFormAdmin}
        closeModal={() => setModalFormAdmin(!modalFormAdmin)}
      >
        <ModalFormAdmin
          cardSelected={cardSelected}
          closeModal={() => setModalFormAdmin(!modalFormAdmin)}
          optionModalFormAdmin={optionModalFormAdmin}
        />
      </ModalContainer>

      <ModalContainer
        isActive={modalFormClient}
        closeModal={() => setModalFormClient(!modalFormClient)}
      >
        <ModalFormClient
          cardSelected={cardSelected}
          closeModal={() => setModalFormClient(!modalFormClient)}
          optionModalFormClient={optionModalFormClient}
        />
      </ModalContainer>
    </>
  );
};

export default TableList;
