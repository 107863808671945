import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
const moment = require("moment");

//  const { setIdDeletSchedule} =useContext(GraphsAndParametersContext);

export const GraphsAndParametersContext = createContext({});

export const GraphsAndParametersValidation = ({ children }) => {
  const [update, setUpdate] = useState(true);

  const [generalKpis, setGeneralKpis] = useState([]);
  const [generalKpisYear, setGeneralKpisYear] = useState([]);
  const [receiptsDetails, setReceiptsDetails] = useState([]);
  const [receiptsDetailsDownload, setReceiptsDetailsDownload] = useState([]);
  const [exitDetails, setExitDetails] = useState([]);
  const [exitDetailsDownload, setExitDetailsDownload] = useState([]);

  const [urgentDetails, setUrgentDetails] = useState([]);
  const [urgentDetailsDownload, setUrgentDetailsDownload] = useState([]);
  const [detailsJustification, setDetailsJustification] = useState({});

  const [parameters, setParameters] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [storeAccuracyAndConsolidation, setStoreAccuracyAndConsolidation] =
    useState([]);

  const [storagesYears, setStoragesYears] = useState([]);
  const [accuracy, setAccuracy] = useState([]);
  const [consolidation, setConsolidation] = useState([]);
  const [conciliation, setConciliation] = useState([]);

  const [commentsAll, setCommentsAll] = useState([]);

  const [editId, setEditId] = useState("");
  const [editMessage, setEditMessage] = useState("");
  const [hoursUpdate, setHoursUpdate] = useState("");
  const [process, setProcess] = useState("Recebimento");

  const [resultFile, setResultFile] = useState({
    filename: "report.csv",
    headers: "",
    data: "",
  });

  const navigate = useNavigate();

  const showToast = (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.info(message);
        break;
      case "warn":
        toast.warn(message);
        break;
      case "loading":
        return toast.loading(message); // Retorna o ID do toast de loading
      default:
        toast(message); // Exibe um toast padrão sem ícone específico
    }
  };

  const createParameters = async (data) => {
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
    try {
      const loadingToast = toast.loading(
        "Alimentando as tabelas, aguarde um momento!",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      const response = await api.post(
        `/dashboard/graphics/parameters/${clientCnpj}`,
        data
      );

      console.log(response.data);

      toast.dismiss(loadingToast);

      toast.info(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getParameters = async () => {
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
    try {
      const response = await api.get(
        `/dashboard/graphics/parameters/${clientCnpj}`
      );

      setParameters(response.data);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const deleteParameters = async (id) => {
    try {
      await api.delete(`/dashboard/graphics/parameters/${id}`);

      getParameters();
      toast.success("Parametro deletado com sucesso", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getReceptsByMonthAndYear = async () => {
    const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
    const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

    try {
      const response = await api.get(
        `/dashboard/graphics/recepts/${clientCnpj}/${month}-${year}`
      );

      // setReceiptsDetailsDownload(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getExitByMonthAndYear = async () => {
    const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
    const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

    try {
      const response = await api.get(
        `/dashboard/graphics/exit/${clientCnpj}/${month}-${year}`
      );

      // setExitDetailsDownload(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getUrgentsByMonthAndYear = async () => {
    const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
    const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

    try {
      const response = await api.get(
        `/dashboard/graphics/urgents/${clientCnpj}/${month}-${year}`
      );

      // setUrgentDetailsDownload(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getReceptsByPage = async (page, status, justification, daysOrHours) => {
    const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
    const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

    try {
      const response = await api.get(
        `/dashboard/graphics/recepts/${clientCnpj}/${month}-${year}?page=${page}&status=${status}&justification=${justification}&daysOrHours=${daysOrHours}`
      );

      setReceiptsDetails(response.data);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getExitByPage = async (page, status, justification, daysOrHours) => {
    const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
    const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

    try {
      const response = await api.get(
        `/dashboard/graphics/exit/${clientCnpj}/${month}-${year}?page=${page}&status=${status}&justification=${justification}&daysOrHours=${daysOrHours}`
      );

      setExitDetails(response.data);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getUrgentsByPage = async (page, status, justification, daysOrHours) => {
    const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
    const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

    try {
      const response = await api.get(
        `/dashboard/graphics/urgents/${clientCnpj}/${month}-${year}?page=${page}&status=${status}&justification=${justification}&daysOrHours=${daysOrHours}`
      );
      setUrgentDetails(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getKPIGeneral = async (yearParameters) => {
    setLoading(true);
    try {
      const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
      const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

      const response = await api.get(
        `/dashboard/graphics/${clientCnpj}/${yearParameters || year}`
      );

      setGeneralKpis(response.data);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getKPIGeneralYear = async (yearParameters) => {
    setLoading(true);
    try {
      const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
      const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

      const response = await api.get(
        `/dashboard/graphics/general/${clientCnpj}/${yearParameters || year}`
      );

      console.log(response.data, "<============================");

      setGeneralKpisYear(response.data);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      console.log(error?.response?.data);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const createdHolidays = async (data) => {
    try {
      const response = await api.post(`/dashboard/graphics/holidays`, data);

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const createdJustification = async (data, id, closeModal, typeSearch) => {
    try {
      const response = await api.patch(
        `/dashboard/graphics/allowance/${id}`,
        data
      );

      closeModal();

      toast.success(response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getByIdJustification = async (id) => {
    try {
      const response = await api.get(`/dashboard/graphics/allowance/${id}`);

      const render = {
        typeTime: response.data.term.split(" ")[1].trim(),
        time: response.data.allowance_justification,
        justification: response.data.justification,
      };

      if (response.data.justification) {
        setDetailsJustification(render);
      } else {
        setDetailsJustification({
          typeTime: "",
          time: "",
          justification: "",
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getYearsHolidays = async (yearsOrDates) => {
    try {
      const response = await api.get(
        `/dashboard/graphics/holidays/?yearsOrDates=${yearsOrDates}`
      );

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getFilterYearsHolidays = async (year) => {
    try {
      const response = await api.get(
        `/dashboard/graphics/holidays/?year=${year}`
      );

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getAccuracy = async (type) => {
    try {
      console.log(type);
      const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
      const client = localStorage.getItem("@XPM_CLIENT_COMPANY:");

      const response = await api.get(
        `/dashboard/graphics/accuracy?year=${year}&type=${type}&client=${client}`
      );

      if (type === "ACURACIDADE") {
        setAccuracy(response.data);
      } else if (type === "CONCILIACAO") {
        setConciliation(response.data);
      } else {
        setConsolidation(response.data);
      }
    } catch (error) {
      return [];
    }
  };

  const createAccuracy = async (data) => {
    try {
      await api.post("/dashboard/graphics/accuracy", data);
      // await getAccuracy("ACURACIDADE");
      // await getAccuracy("CONSOLIDACAO");
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getStoragesYears = async () => {
    try {
      const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
      const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
      const response = await api.get(
        `/dashboard/graphics/storage?client=${clientCnpj}&year=${year}`
      );

      console.log(response.data, year);
      setStoragesYears(response.data);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const updateStorages = async () => {
    try {
      const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
      const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

      const loadingToast = toast.loading("Atualizando armazenagem", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      const response = await api.patch(
        `dashboard/graphics/storage?client=${clientCnpj}&year=${year}`
      );

      getStoragesYears();

      toast.dismiss(loadingToast);

      toast.success(response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {}
  };

  const dowloadExcel = async (type, data) => {
    const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
    const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

    const monthName = moment()
      .month(month - 1)
      .locale("pt")
      .format("MMMM");
    try {
      const headersExitAndUrgent = [
        { label: "DATA INCLUSÃO", key: "DATA INCLUSÃO" },
        { label: "HORA INCLUSÃO", key: "HORA INCLUSÃO" },
        { label: "DATA CONCLUSÃO", key: "DATA CONCLUSÃO" },
        { label: "HORA CONCLUSÃO", key: "HORA CONCLUSÃO" },
        { label: "TEMPO PERCORRIDO", key: "TEMPO PERCORRIDO" },
        { label: "PRAZO", key: "PRAZO" },
        { label: "ABONO", key: "ABONO" },
        { label: "TEMPO RECALCULADO", key: "APÓS ABONO" },
        { label: "STATUS", key: "STATUS" },
        { label: "JUSTIFICATIVA", key: "JUSTIFICATIVA" },
        { label: "CLIENTE", key: "CLIENTE" },
        { label: "SOLICITAÇÃO CARGA", key: "SOLICITAÇÃO CARGA" },
        {
          label: "CÓDIGO DEPOSITANTE",
          key: "CODIGO DEPOSITANTE",
        },
        { label: "NOME DEPOSITANTE", key: "NOME DEPOSITANTE" },
        { label: "DESTINATÁRIO", key: "DESTINATARIO" },
        { label: "CIDADE", key: "CIDADE" },
        { label: "COD UNI FEDER", key: "COD UNI FEDER" },
        { label: "PEDIDO", key: "PEDIDO" },
        { label: "NOTA FISCAL", key: "NOTA FISCAL" },
        { label: "CATEGORIA", key: "CATEGORIA" },
        {
          label: "COD TRANSPORTADORA",
          key: "COD TRANSPORTADORA",
        },
        { label: "TRANSPORTADORA", key: "TRANSPORTADORA" },
        { label: "QTD LINHAS", key: "QTD LINHAS" },
        { label: "QTD VOLUMES", key: "QTD VOLUMES" },
        { label: "QTD SOLICITADA", key: "QTD SOLICITADA" },
        { label: "QTD CONFERIDA", key: "QTD CONFERIDA" },
      ];

      const headersReceipt = [
        { label: "DATA ENTRADA", key: "DATA ENTRADA" },
        { label: "HORA ENTRADA", key: "HORA ENTRADA" },
        { label: "DATA LIBERAÇÃO", key: "DATA LIBERAÇÃO" },
        { label: "HORA LIBERAÇÃO", key: "HORA LIBERAÇÃO" },
        { label: "TEMPO PERCORRIDO", key: "TEMPO PERCORRIDO" },
        { label: "PRAZO", key: "PRAZO" },
        { label: "ABONO", key: "ABONO" },
        { label: "TEMPO RECALCULADO", key: "APÓS ABONO" },
        { label: "STATUS", key: "STATUS" },
        { label: "JUSTIFICATIVA", key: "JUSTIFICATIVA" },
        { label: "CLIENTE", key: "CLIENTE" },
        { label: "CTR DOCUMENTO", key: "CTR DOCUMENTO" },
        {
          label: "NUMERO DE AVISO DE RECEBIMENTO",
          key: "NUMERO DE AVISO DE RECEBIMENTO",
        },
        { label: "NUMERO NF", key: "NUMERO NF" },
        { label: "DEPOSITANTE", key: "DEPOSITANTE" },
        { label: "FORNECEDOR", key: "FORNECEDOR" },
        { label: "TRANSPORTADORA", key: "TRANSPORTADORA" },
        { label: "QTD ITEM NF", key: "QTD ITEM NF" },
        { label: "QTD CONFERIDA", key: "QTD CONFERIDA" },
      ];

      const details = data.length
        ? data
        : type === "Saida"
        ? exitDetailsDownload
        : type === "Urgencia"
        ? urgentDetailsDownload
        : type === "Recebimento"
        ? receiptsDetailsDownload
        : [];

      const csvReport = {
        filename: `${type}-${monthName}-${year}-${clientCnpj}.csv`,
        headers: type === "Recebimento" ? headersReceipt : headersExitAndUrgent,
        data: data || details,
      };

      console.log(data, csvReport);

      setResultFile(csvReport);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const dowloadExcelXLSX = async (type, data) => {
    const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
    const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
    const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

    const monthName = moment()
      .month(month - 1)
      .locale("pt")
      .format("MMMM");
    try {
      const headersExitAndUrgent = [
        "DATA INCLUSÃO",
        "HORA INCLUSÃO",
        "DATA CONCLUSÃO",
        "HORA CONCLUSÃO",
        "TEMPO PERCORRIDO",
        "PRAZO",
        "ABONO",
        "TEMPO RECALCULADO",
        "STATUS",
        "JUSTIFICATIVA",
        "CLIENTE",
        "SOLICITAÇÃO CARGA",
        "CÓDIGO DEPOSITANTE",
        "NOME DEPOSITANTE",
        "DESTINATÁRIO",
        "CIDADE",
        "COD UNI FEDER",
        "PEDIDO",
        "NOTA FISCAL",
        "CATEGORIA",
        "COD TRANSPORTADORA",
        "TRANSPORTADORA",
        "QTD LINHAS",
        "QTD VOLUMES",
        "QTD SOLICITADA",
        "QTD CONFERIDA",
      ];

      const headersReceipt = [
        "DATA ENTRADA",
        "HORA ENTRADA",
        "DATA LIBERAÇÃO",
        "HORA LIBERAÇÃO",
        "TEMPO PERCORRIDO",
        "PRAZO",
        "ABONO",
        "TEMPO RECALCULADO",
        "STATUS",
        "JUSTIFICATIVA",
        "CLIENTE",
        "CTR DOCUMENTO",
        "NUMERO DE AVISO DE RECEBIMENTO",
        "NUMERO NF",
        "DEPOSITANTE",
        "FORNECEDOR",
        "TRANSPORTADORA",
        "DEVOLUÇÃO",
        "QTD ITEM NF",
        "QTD CONFERIDA",
      ];

      return {
        filename: `${type.substring(0, 3)}-${monthName}-${year}`,
        headers: type === "Recebimento" ? headersReceipt : headersExitAndUrgent,
        data,
      };
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // COMENTÁROS
  const getCommentsAll = async (month, year) => {
    try {
      const momentNow = moment();

      const processNow =
        localStorage.getItem("@XPM_CLIENT_PROCESS_COMMENT:") || process;

      const cnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

      const monthNow =
        month ||
        localStorage.getItem("@XPM_CLIENT_MONTH_COMMENT:") ||
        momentNow.format("MM");

      const yearNow =
        year ||
        localStorage.getItem("@XPM_CLIENT_YEAR:") ||
        momentNow.format("YYYY");

      const response = await api.get(
        `/comments/dash?cnpj=${cnpj}&month=${monthNow}&year=${yearNow}&process=${processNow}`
      );

      setCommentsAll(response.data);
      console.log(response.data, cnpj, monthNow, yearNow);
    } catch (error) {
      console.log(error, 879454261351);
    }
  };

  const createdComment = async (data) => {
    try {
      console.log(data);
      await api.post("/comments/dash", data);

      getCommentsAll();
    } catch (error) {}
  };

  const destroyComment = async (id) => {
    try {
      await api.delete(`/comments/dash/${id}`);
      getCommentsAll();

      toast.info("Comentario apagado!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const editedComment = async (data) => {
    try {
      await api.patch(`/comments/dash/${editId}`, data);
      getCommentsAll();
      setEditId("");
      setEditMessage("");
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // ANEXO
  const getAnexo = async ({ month, year, type, client }) => {
    try {
      const { data } = await api.get(
        `/dashboard/graphics/attachment?month=${month}&year=${year}&type=${type}&client=${client}`
      );

      return data;
    } catch (error) {
      console.log(error);
      showToast("info", error?.response?.data?.message);
    }
  };

  const createAnexo = async (dataCreate, search) => {
    try {
      const { data } = await api.post(
        "/dashboard/graphics/attachment",
        dataCreate
      );
      const response = await getAnexo(search);
      showToast("success", data?.message);

      return response;
    } catch (error) {
      console.log(error);
      showToast("info", error?.response?.data?.message);
    }
  };

  const deleteAnexo = async (id, search) => {
    try {
      const { data } = await api.delete(`/dashboard/graphics/attachment/${id}`);

      showToast("info", data?.message);
      const response = await getAnexo(search);
      showToast("success", data?.message);

      return response;
    } catch (error) {
      console.log(error);
      showToast("info", error?.response?.data?.message);
    }
  };

  return (
    <>
      <GraphsAndParametersContext.Provider
        value={{
          createParameters,
          getReceptsByMonthAndYear,
          getExitByMonthAndYear,
          receiptsDetails,
          exitDetails,
          getUrgentsByMonthAndYear,
          urgentDetails,
          getKPIGeneral,
          generalKpis,
          loading,
          getParameters,
          parameters,
          setParameters,
          deleteParameters,
          update,
          setUpdate,
          getKPIGeneralYear,
          generalKpisYear,
          createdHolidays,
          getUrgentsByPage,
          getExitByPage,
          dowloadExcel,
          dowloadExcelXLSX,
          resultFile,
          setResultFile,
          getReceptsByPage,
          createdJustification,
          getByIdJustification,
          detailsJustification,
          setDetailsJustification,
          getYearsHolidays,
          getFilterYearsHolidays,
          getAccuracy,
          storeAccuracyAndConsolidation,
          setStoreAccuracyAndConsolidation,
          createAccuracy,
          getStoragesYears,
          storagesYears,
          updateStorages,
          accuracy,
          consolidation,
          commentsAll,
          getCommentsAll,
          createdComment,
          destroyComment,
          editId,
          setEditId,
          editMessage,
          setEditMessage,
          editedComment,
          hoursUpdate,
          setHoursUpdate,
          process,
          setProcess,
          createAnexo,
          getAnexo,
          deleteAnexo,
          conciliation,
        }}
      >
        {children}
      </GraphsAndParametersContext.Provider>
    </>
  );
};
