import searchIcon from "../../../images/svg/searchIcon.svg";

const SearchMoreFilter = () => {
  /* STYLES */
  const boxInput = {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "19px",
    padding: "11px 13px",
    border: "1px solid #d7d7d7",
    borderRadius: "60px",
  };

  const focusInput = () => {};

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <div style={boxInput}>
        <img src={searchIcon} style={{ height: "24px" }} />
        <input
          style={{
            height: "20px",
            width: "250px",
            outline: "none",
            border: "none",
          }}
        />
      </div>
    </div>
  );
};

export default SearchMoreFilter;
