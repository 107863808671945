import onTimeIcon from "../../../images/svg/onTime.svg"
import runningOutOfDeadlineIcon from "../../../images/svg/runningOutOfDeadline.svg"
import lateIcon from "../../../images/svg/late.svg"

// Precisa trocar o nome do ícone quando souber qual será o valor dele
import iconeParaValorProvisorioTrocarOnome from "../../../images/svg/iconeParaValorProvisorioTrocarOnome.svg"

const Legend = () => {
    const container = {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: "20px"
    };

    const styleTitle = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleItemAndItemBox = {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    };

    const styleValue = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        fontWeight: 400,
    };

    return (
        <div style={container}>
            <h3 style={styleTitle}>Legenda:</h3>
            <div style={styleItemAndItemBox}>
                <div style={styleItemAndItemBox}>
                    <img src={onTimeIcon} />
                    <p style={styleValue}>No prazo</p>
                </div>
                <div style={styleItemAndItemBox}>
                    <img src={runningOutOfDeadlineIcon} />
                    <p style={styleValue}>Vencendo</p>
                </div>
                <div style={styleItemAndItemBox}>
                    <img src={lateIcon} />
                    <p style={styleValue}>Atrasado</p>
                </div>

                {/* Precisa trocar o valor para o valor correto quando for atualizado no Figma  */}
                <div style={styleItemAndItemBox}>
                    <img src={iconeParaValorProvisorioTrocarOnome} />
                    <p style={styleValue}>Valor provisório (Precisa trocar o nome)</p>
                </div>

            </div>
        </div>
    );
};

export default Legend;