import React, { useState } from 'react'
import closeEyeIcon from '../../../images/svg/closeEyeIcon.svg'
import openEyeIcon from '../../../images/svg/openEyeIcon.svg'
import InputMask from 'react-input-mask';
const Input = ({ name, type, placeholder, onChange, width, error, height, marginLeft, color, onBlur, value, rightEye, topEye, mask, marginBottom }) => {

    const [stateEye, setStateEye] = useState(false)


    const containerInput = {

        display: "flex",
        flexDirection: "column",
        marginLeft: marginLeft,
        width: width,
    }

    const styleInput = {
        width: width != undefined ? width : "418px",
        color: color != undefined ? color : "",
        height: height != undefined ? height : "50px",
        borderRadius: "10px",
        border: "1px solid #D7D7D7",
        marginBottom: marginBottom ? marginBottom : "1.9rem",
        outline: "none",
    }

    const styleLabel = {
        marginBottom: "14.43px"
    }

    const containerInputMail = {
        width: width != undefined ? width : "418px",

        display: "flex",
        flexDirection: "column",
        position: "relative",

    }

    const containerInputPassowrd = {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: width != undefined ? width : "418px",

    }

    const image = {

        position: "absolute",
        right: 0,
        top: topEye != undefined ? topEye : "50px",
        right: rightEye != undefined ? rightEye : "10px",
        cursor: "pointer"
    }

    return (

        <div style={containerInput}>

            {type == "password"

                ?
                <div style={containerInputPassowrd}>
                    <label className="textPrimaryLabelInput" style={styleLabel}>{name}</label>
                    <input onBlur={onBlur} className="textPrimaryInput" onChange={onChange} placeholder={placeholder} type={stateEye == true ? "text" : type} style={styleInput} />

                    {stateEye == false

                        ?
                        <img src={closeEyeIcon} onClick={() => setStateEye(!stateEye)} style={image} />
                        :
                        <img src={openEyeIcon} onClick={() => setStateEye(!stateEye)} style={image} />

                    }

                    {error != undefined

                        ?
                        <p className="textErrorInput">{error}</p>
                        :
                        <></>
                    }
                </div>
                :

                <div style={containerInputMail}>
                    <label className="textPrimaryLabelInput" style={styleLabel}>{name}</label>

                    <InputMask onBlur={onBlur} maskChar="" mask={mask} className="textPrimaryInput" value={value} onChange={onChange} placeholder={placeholder} type={type} style={styleInput} />
                    {error != undefined

                        ?
                        <p className="textErrorInput">{error}</p>
                        :
                        <></>
                    }
                </div>
            }


        </div>


    )

}

export default Input