import { useEffect, useState } from "react";
import BoxParameterSelect from "./BoxParameterSelect";
import api from "../../../services/api";
import moment from "moment";
import { toast } from "react-toastify";
import CategoriesSelect from "./CategoriesSelect";

const ListParameter = ({
  i,
  processData,
  descriptionData,
  typeStructureData,
  categoryTypeData,
  typeCalculationData,
  unitData,
  priceData,
  typeCoin,
  id,
  update,
  updateList,
  destroy,
  cubagemLogixData,
  cubagemContractData,
  factorData,
  // getInformation,
  quantityData,
}) => {
  /* PARAMETER */
  const [process, setProcess] = useState("");
  const [typeStructure, setTypeStructure] = useState("");
  const [categoryType, setCategoryType] = useState(".");
  const [typeCalculation, setTypeCalculation] = useState(".");

  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState("");

  const [cubagemLogix, setCubagemLogix] = useState(0);
  const [cubageContract, setCubageContract] = useState(0);
  const [factor, setFactor] = useState(0);

  const [processBlur, setProcessBlur] = useState(undefined);
  const [typeStructureBlur, setTypeStructureBlur] = useState("");
  const [categoryTypeBlur, setCategoryTypeBlur] = useState("");

  const [typeCalculationBlur, setTypeCalculationBlur] = useState("");
  const [descriptionBlur, setDescriptionBlur] = useState("");

  const [unitBlur, setUnitBlur] = useState("");
  const [priceBlur, setPriceBlur] = useState("");

  const [cubagemLogixBlur, setCubagemLogixBlur] = useState(0);
  const [cubageContractBlur, setCubageContractBlur] = useState(0);
  const [factorBlur, setFactorBlur] = useState(0);

  const [typeStructureAll, setTypeStructureAll] = useState([]);

  const [typeCategoryAll, setTypeCategoryAll] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [modalCategories, setModalCategories] = useState(false);

  const [quantityContract, setQuantityContract] = useState("");
  const [quantityContractBlur, setQuantityContractBlur] = useState("");

  const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
  const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
  const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
  const date = `${year}-${month}`;

  const createParamCategory = async (name, code, paramId) => {
    try {
      const payload = {
        param_id: paramId,
        name: name,
        date: date,
        code: code,
      }
      const response = await api.post('/param-categories', payload)
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getParamCategory = async () => {
    try {
      if (id) {
        const categories = await api.get(`/param-categories/${id}/${date}`);
        if (categories.data && categories.data.length > 0) {
          setSelectedCategories(categories.data);
        }
      }
    } catch (error) {
      console.log('erro ao recuperar categorias de parâmetros', error);
    }
  }

  const deleteParamCategory = async (categoryId) => {
    try {
      await api.delete(`/param-categories/${categoryId}`)
    } catch (error) {
      console.log('erro ao deletar categoria de parâmetro', error);
    }
  };

  const createParameters = async () => {
    try {
      console.log('createParameters')
      const data = {
        process: processBlur.trim(""),
        description: descriptionBlur.trim(""),
        type_structure: typeStructureBlur.trim(""),
        // category_type: categoryTypeBlur.trim(""),
        // category_type: selectedCategories[0].trim(""), // excluir quando ajustar back
        type_calculation: typeCalculationBlur.trim(""),
        unit: unitBlur.trim(""),
        cubagem_logix: cubagemLogix,
        cubagem_contract: cubageContractBlur,
        quantity: quantityContractBlur.trim(""),
        date: date,
      };
      console.log(data)
      if (id) {
        await api.patch(`/parameter/${id}/${date}`, data);
      } else {
        console.log('não tem id')
        data.price = priceBlur.trim("");
        let response = {};
        if (processBlur !== "Armazenagem" && selectedCategories.length > 0 && quantityContractBlur !== undefined) {
        console.log('postar parametro diferente de armazenagem')
          response = await api.post(`/parameter/${clientCnpj}/${date}`, data);
        }
        if (selectedCategories.length > 0 && cubagemLogix && quantityContractBlur !== undefined) {
        console.log('postar parametro de armazenagem')
          response = await api.post(`/parameter/${clientCnpj}/${date}`, data);
        }
        if (data.process === 'Seguro de Armazenagem' && data.description && data.price && data.unit) {
        console.log('postar seguro')
          response = await api.post(`/parameter/${clientCnpj}/${date}`, data);
        }
        if (response.data && response.data.id && processBlur !== 'Seguro de Armazenagem') {
        console.log('criar categorias para o parâmetro')
          await Promise.all(selectedCategories.map(async (category) => await createParamCategory(category.name, category.code, response.data.id)));
          // await createQuantityContract(response.data.id);
          // await createParamUnit(response.data.id);
        }
        updateList();
      }
    } catch (error) {
      console.log(error)
    }
  };

  const updateParameters = async () => {
    try {

      // Cria um objeto de dados vazio
      const data = {};

      // Verifica e adiciona ao objeto de dados apenas os campos que estão definidos
      if (processBlur) {
        data.process = processBlur.trim();
      }
      if (descriptionBlur) {
        data.description = descriptionBlur.trim();
      }
      if (typeStructureBlur) {
        data.type_structure = typeStructureBlur.trim();
      }
      if (categoryTypeBlur) {
        data.category_type = categoryTypeBlur.trim();
      }
      if (unitBlur) {
        data.unit = unitBlur.trim();
      }
      if (typeCalculationBlur) {
        data.type_calculation = typeCalculationBlur.trim();
      }
      if (factorBlur) {
        data.factor = factorBlur;
      }
      if (cubagemLogixBlur) {
        data.cubagem_logix = cubagemLogixData ? cubagemLogixData : cubagemLogix;
      }
      if (cubageContractBlur) {
        data.cubagem_contract = cubageContractBlur;
      }
      if (quantityContractBlur) {
        data.quantity = quantityContractBlur;
      }

      // Verifica se há pelo menos um campo definido antes de fazer a atualização
      if (Object.keys(data).length > 0) {
        // Faz a solicitação de atualização para a API
        await api.patch(`/parameter/${id}/${date}`, data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStructure = async () => {
    try {
      const response = await api.get(`/grouping-structures/all/${clientCnpj}`);
      if (response.data && response.data.length === 0) {
        toast.error('Verifique se há agrupamento cadastrado para o cliente', { autoClose: 2000 });
      } else {
        setTypeStructureAll(response.data);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getCategory = async () => { // categorias cadastradas para o cliente via logix
    try {
      const response = await api.get(
        `/category/${clientCnpj}`
      );

      setTypeCategoryAll(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCubagem = async () => {
    try {
      if (typeStructureAll && typeStructureAll.some((e) => e.name === typeStructure)) {
        // console.log('structures do grupo: ',typeStructure);
        const groups = typeStructureAll.filter((e) => e.process === process && e.name === typeStructure);
        // console.log('grupos: ',typeStructureAll);
        if (groups.length > 0) {
          const response = await api.get(`/structures/all/${groups[0].id}/${date}`);
          // console.log('structures', response.data);
          const structures = response.data;
          const cubagem = structures.reduce((acc, structure) => acc + Number(structure.cubagem_logix), 0);
          setCubagemLogix(Number(cubagem.toFixed(4)));
          setCubagemLogixBlur(Number(cubagem.toFixed(4)));
          setFactor((Number(cubagem) / Number(cubageContract)).toFixed(4))
        }
      }
    } catch (error) {
      console.log(error)
      toast.error('Não foi possível recuperar a cubagem logix', { autoClose: 2000 })
    }
  };

  const getAdjustedPrice = async () => {
    try {
      const response = await api.get(`/adjustment/${id}`);
      if (response.data && response.data.length > 0) {
        setPrice(response.data[0].adjusted_price);
      } else {
        setPrice(priceData);
      }
    } catch (error) {
      console.log(error)
      toast.error('Não foi possível encontrar o preço atualizado', { autoClose: 2000 }) 
    }
  };

  // const getParamUnity = async () => {
  //   try {
  //     const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
  //     const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
  //     const data = year && month ? date : moment().format('YYYY-MM');
  //     const response = await api.get(`/param-unit/${id}/${data}`)
  //     if (response.data && response.data.length > 0) {
  //       setUnit(response.data[0].unit);
  //     }
  //   } catch (error) {
  //     console.log('Erro ao tentar recuperar quantidade contratada', error);
  //   }
  // };

  // const createParamUnit= async (paramId) => {
  //    try {
  //     const response = await api.post('/param-unit', {unit: unitBlur, param_id: paramId, date: `${date}`});
  //     if (response.id) {
  //       toast.success('Unidade registrada', { autoClose: 2000 })
  //     }
  //   } catch (error) {
  //     toast.error(`Erro ao tentar cadastrar unidade no item: ${i + 1}`, { autoClose: 2000 });
  //   }
  // };

  useEffect(() => {
    getStructure();
    getCategory();
    // getQuantityContract();
    // getParamUnity();
  }, [update]);

  // useEffect(() => {
  //   if (id && quantityContractBlur) {
  //     createQuantityContract(id);
  //   }
  // }, [quantityContractBlur])

  // useEffect(() => {
  //   if (id && unitBlur) {
  //     createParamUnit(id);
  //   }
  // }, [unitBlur])

  useEffect(() => {
    getCubagem()
  }, [typeStructure, typeStructureAll, typeStructureBlur, cubageContract])

  useEffect(() => {
    if (!id) {
      if (
          processBlur !== "" &&
          descriptionBlur !== "" &&
          unitBlur !== "" &&
          priceBlur !== "" &&
          quantityContractBlur !== "" &&
          typeCalculationBlur !== "" &&
          processBlur !== "Seguro de Armazenagem"
        ) {
          createParameters();
        }
      if (
          processBlur !== "" &&
          descriptionBlur !== "" &&
          unitBlur !== "" &&
          priceBlur !== "" &&
          processBlur === "Seguro de Armazenagem"
        ) {
          console.log('tentando criar seguro')
          createParameters();
        }
    }
    if (id) {
      console.log('tem id, atualizar parâmetro')
      updateParameters();
      // console.log(factorBlur, 6464646);
    }
  }, [
    processBlur,
    descriptionBlur,
    typeStructureBlur,
    categoryTypeBlur,
    unitBlur,
    typeCalculationBlur,
    priceBlur,
    cubagemLogix,
    cubagemLogixBlur,
    cubageContractBlur,
    // factorBlur,
    quantityContractBlur,
  ]);

  useEffect(() => {
    if (typeStructureAll) {
      setTypeStructure(typeStructureData);
    }

    if (typeCategoryAll) {
      setCategoryType(categoryTypeData);
    }
  }, [typeStructureAll, typeCategoryAll]);

  useEffect(() => {
    setProcess(processData);
    setDescription(descriptionData);
    setUnit(unitData);
    // setPrice(priceData);
    setTypeStructure(typeStructureData);
    setCategoryType(categoryTypeData);
    setTypeCalculation(typeCalculationData || ".");
    // setCubagemLogix(cubagemLogixData || ".")
    setCubageContract(cubagemContractData || ".");
    // setCubagemLogix(cubagemLogixData || ".");
    setFactor(factorData || ".");
    setQuantityContract(quantityData)
    // getCubagem(processData, typeStructureData)
  }, [
    processData,
    descriptionData,
    unitData,
    // priceData,
    typeStructureData,
    categoryTypeData,
    typeCalculationData,
    typeStructureAll,
    typeCategoryAll,
    cubagemContractData,
    factorData,
  ]);

  // useEffect(() => {
  //   const calculation = cubagemLogix / cubageContract;
  //   setFactor(calculation);
  //   setFactorBlur(calculation);
  // }, [cubagemLogix, cubageContract]);

  useEffect(() => {
    if (id) {
      getAdjustedPrice();
      getParamCategory();
    }
  }, [id])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        position: "relative",
        width: "100%",
      }}
    >
      {process !== undefined && (
        <>
          <div
            style={{
              display: "flex",
              gap: "14px",
              marginRight: "25px",
              // flexWrap: 'wrap',
            }}
          >
            <BoxParameterSelect
              valueInput={processData}
              onChange={(e) => setProcess(e.target.value)}
              onBlur={(e) => setProcessBlur(e.target.value)}
              width={"100%"}
              wait={true}
              number={i + 1}
              type={"process"}
            />
            {process !== "Seguro de Armazenagem" && (
              <>
                <BoxParameterSelect
                  valueInput={description}
                  onChange={(e) => setDescription(e.target.value)}
                  onBlur={(e) => setDescriptionBlur(e.target.value)}
                  width={"100%"}
                  wait={true}
                  type={"description"}
                />
                <BoxParameterSelect
                  valueInput={typeStructureData || "Selecione"}
                  onChange={(e) => setTypeStructure(e.target.value)}
                  onBlur={(e) => setTypeStructureBlur(e.target.value)}
                  width={"100%"}
                  wait={true}
                  listValues={typeStructureAll}
                  type={"typeStructure"}
                  options={process}
                  withDefault={id ? true : false}
                />
                {/* <BoxParameterSelect
                  valueInput={categoryType || "Selecione"}
                  onChange={(e) => setCategoryType(e.target.value)}
                  onBlur={(e) => setCategoryTypeBlur(e.target.value)}
                  width={"100%"}
                  wait={true}
                  type={"typeCategory"}
                  listValues={typeCategoryAll}
                  update={update}
                  options={process}
                /> */}
                <CategoriesSelect
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  typeCategoryAll={typeCategoryAll}
                  modalCategories={modalCategories}
                  setModalCategories={setModalCategories}
                  modalId={`modal`}
                  topModal={80}
                  paramId={id}
                  deleteParamCategory={deleteParamCategory}
                  createParamCategory={createParamCategory}
                />
              </>
            )}

            {process === "Seguro de Armazenagem" && (
              <>
                <BoxParameterSelect
                  valueInput={description}
                  onChange={(e) => setDescription(e.target.value)}
                  onBlur={(e) => setDescriptionBlur(e.target.value)}
                  width={"100%"}
                  wait={true}
                  type={"description"}
                />
                <BoxParameterSelect
                  valueInput={unit || "Selecione"}
                  onChange={(e) => setUnit(e.target.value)}
                  onBlur={(e) => setUnitBlur(e.target.value)}
                  width={"100%"}
                  type={"unit"}
                  wait={true}
                  options={process}
                />

                <BoxParameterSelect
                  valueInput={price}
                  onChange={(e) => setPrice(e.target.value)}
                  onBlur={(e) => setPriceBlur(e.target.value)}
                  value={unit?.split(":")[1]}
                  width={"100%"}
                  coin={typeCoin}
                  wait={true}
                  percentage={true}
                  type={"price"}
                  withDefault={id ? true : false}
                />
              </>
            )}
          </div>

          <div
            style={{
              display: "flex",
              gap: "14px",
              width: "100%",
              paddingRight: "25px",
              // flexWrap: 'wrap'
            }}
          >
            {process !== "Seguro de Armazenagem" &&
              process !== "Armazenagem" && (
                <>
                  <BoxParameterSelect
                    valueInput={unit || "Selecione"}
                    onChange={(e) => setUnit(e.target.value)}
                    onBlur={(e) => setUnitBlur(e.target.value)}
                    width={"100%"}
                    type={"unit"}
                    gap={true}
                    wait={true}
                    options={process}
                  />

                  {/* <BoxParameterSelect
                  valueInput={conversion}
                  onChange={(e) => setConversion(e.target.value)}
                  onBlur={(e) => setConversionBlur(e.target.value)}
                  width={"279px"}
                  type={"conversion"}
                /> */}

                  <BoxParameterSelect
                    wait={true}
                    valueInput={typeCalculation}
                    onChange={(e) => setTypeCalculation(e.target.value)}
                    onBlur={(e) => setTypeCalculationBlur(e.target.value)}
                    width={"100%"}
                    type={"typeCalculation"}
                    options={process}
                  />

                  <BoxParameterSelect
                    valueInput={price}
                    onChange={(e) => setPrice(e.target.value)}
                    onBlur={(e) => setPriceBlur(e.target.value)}
                    value={unit?.split(":")[1]}
                    width={"100%"}
                    coin={typeCoin}
                    type={"price"}
                    withDefault={id ? true : false}
                  />

                  <BoxParameterSelect
                    valueInput={quantityContract}
                    onChange={(e) => setQuantityContract(e.target.value)}
                    onBlur={(e) => setQuantityContractBlur(e.target.value)}
                    width={"100%"}
                    wait={true}
                    type={"quantityContract"}
                  /> 
                </>
              )}
            {process === "Armazenagem" && (
              <div
                style={{
                  display: "flex",
                  gap: "14px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{width: "150%"}}>
                  <BoxParameterSelect
                    valueInput={unit || "Selecione"}
                    onChange={(e) => setUnit(e.target.value)}
                    onBlur={(e) => setUnitBlur(e.target.value)}
                    width={"100%"}
                    type={"unit"}
                    gap={true}
                    wait={true}
                    options={process}
                    // flexGrow={2}
                  />
                </div>

                <BoxParameterSelect
                  wait={true}
                  valueInput={typeCalculation}
                  onChange={(e) => setTypeCalculation(e.target.value)}
                  onBlur={(e) => setTypeCalculationBlur(e.target.value)}
                  width={"100%"}
                  type={"typeCalculation"}
                  options={process}
                />

                <BoxParameterSelect
                  valueInput={price}
                  onChange={(e) => setPrice(e.target.value)}
                  onBlur={(e) => setPriceBlur(e.target.value)}
                  value={unit?.split(":")[1]}
                  width={"100%"}
                  coin={typeCoin}
                  type={"price"}
                  withDefault={id ? true : false}
                />

                <BoxParameterSelect
                  valueInput={cubagemLogix}
                  onChange={(e) => console.log(e.target.value)}
                  onBlur={(e) => console.log(e.target.value)}
                  width={"100%"}
                  coin={typeCoin}
                  type={"cubagemLogix"}
                  withDefault={true}
                />
                <BoxParameterSelect
                  valueInput={cubageContract}
                  onChange={(e) => setCubageContract(e.target.value)}
                  onBlur={(e) => setCubageContractBlur(e.target.value)}
                  width={"100%"}
                  coin={typeCoin}
                  type={"cubageContract"}
                />
                <BoxParameterSelect
                  valueInput={factor}
                  onChange={(e) => setFactor(e.target.value)}
                  onBlur={(e) => setFactorBlur(e.target.value)}
                  width={"100%"}
                  coin={typeCoin}
                  withDefault={true}
                  type={"factor"}
                />
              </div>
            )}            
          </div>
          {process === "Armazenagem" && (
              <div
                style={{
                  display: "flex",
                  gap: "14px",
                  width: "18%",
                }}
              >
                <BoxParameterSelect
                  valueInput={quantityContract}
                  onChange={(e) => setQuantityContract(e.target.value)}
                  onBlur={(e) => setQuantityContractBlur(e.target.value)}
                  width={"100%"}
                  wait={true}
                  type={"quantityContract"}
                  margin={"0px 0px 0px 60px"}
                />                
              </div>
            )}

          <div
            onClick={destroy}
            style={{
              position: "absolute",
              right: "-5px",
              top: "38px",
              cursor: "pointer",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="25"
              viewBox="0 0 20 25"
              fill="none"
            >
              <path
                d="M18.316 4.46693H15.3276C15.1039 3.33369 14.5119 2.31544 13.6514 1.58381C12.791 0.852179 11.7147 0.4519 10.604 0.450439L8.676 0.450439C7.56527 0.4519 6.48899 0.852179 5.62855 1.58381C4.76812 2.31544 4.17614 3.33369 3.9524 4.46693H0.964C0.708331 4.46693 0.463134 4.57272 0.282349 4.76103C0.101564 4.94934 0 5.20474 0 5.47105C0 5.73736 0.101564 5.99277 0.282349 6.18108C0.463134 6.36939 0.708331 6.47518 0.964 6.47518H1.928V19.5288C1.92953 20.8598 2.43784 22.1359 3.34144 23.0771C4.24503 24.0183 5.47013 24.5478 6.748 24.5494H12.532C13.8099 24.5478 15.035 24.0183 15.9386 23.0771C16.8422 22.1359 17.3505 20.8598 17.352 19.5288V6.47518H18.316C18.5717 6.47518 18.8169 6.36939 18.9977 6.18108C19.1784 5.99277 19.28 5.73736 19.28 5.47105C19.28 5.20474 19.1784 4.94934 18.9977 4.76103C18.8169 4.57272 18.5717 4.46693 18.316 4.46693ZM8.676 2.45869H10.604C11.2019 2.45944 11.785 2.65286 12.2733 3.0124C12.7615 3.37195 13.131 3.88001 13.3312 4.46693H5.94884C6.14895 3.88001 6.51847 3.37195 7.00673 3.0124C7.49498 2.65286 8.07806 2.45944 8.676 2.45869ZM15.424 19.5288C15.424 20.3277 15.1193 21.0939 14.577 21.6588C14.0346 22.2238 13.299 22.5411 12.532 22.5411H6.748C5.98099 22.5411 5.2454 22.2238 4.70305 21.6588C4.16069 21.0939 3.856 20.3277 3.856 19.5288V6.47518H15.424V19.5288Z"
                fill="#555555"
              />
              <path
                d="M7.71205 18.5248C7.96772 18.5248 8.21291 18.419 8.3937 18.2307C8.57448 18.0424 8.67605 17.787 8.67605 17.5207V11.4959C8.67605 11.2296 8.57448 10.9742 8.3937 10.7859C8.21291 10.5976 7.96772 10.4918 7.71205 10.4918C7.45638 10.4918 7.21118 10.5976 7.0304 10.7859C6.84961 10.9742 6.74805 11.2296 6.74805 11.4959V17.5207C6.74805 17.787 6.84961 18.0424 7.0304 18.2307C7.21118 18.419 7.45638 18.5248 7.71205 18.5248Z"
                fill="#555555"
              />
              <path
                d="M11.5675 18.5247C11.8232 18.5247 12.0684 18.4189 12.2492 18.2306C12.43 18.0423 12.5315 17.7869 12.5315 17.5206V11.4958C12.5315 11.2295 12.43 10.9741 12.2492 10.7858C12.0684 10.5975 11.8232 10.4917 11.5675 10.4917C11.3118 10.4917 11.0666 10.5975 10.8859 10.7858C10.7051 10.9741 10.6035 11.2295 10.6035 11.4958V17.5206C10.6035 17.7869 10.7051 18.0423 10.8859 18.2306C11.0666 18.4189 11.3118 18.5247 11.5675 18.5247Z"
                fill="#555555"
              />
            </svg>
          </div>
        </>
      )}
    </div>
  );
};

export default ListParameter;
