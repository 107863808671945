import { useState } from "react";
import ClientsSelect from "./ClientsSelect";
import CardContractualLine from "./CardContractualLine";

const CardsContractualParameters = () => {
  const [update, setUpdate] = useState(true);
  const [parameterArry, setParameterArry] = useState([1, 2, 3, 4]);

  const deleteLine = (index) => {
    const filter = parameterArry.filter((item, i) => i !== index);
    setParameterArry(filter);
  };

  const addLine = () => {
    const lastItem = parameterArry[parameterArry.length - 1];
    setParameterArry([...parameterArry, lastItem + 1]);
  };

  return (
    <>
      <ClientsSelect limit={false} update={() => setUpdate(!update)} />
      <div
        className="animate__animated animate__fadeIn"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          position: "relative",
          backgroundColor: "#fff",
          marginTop: "35px",
          border: "1px solid #d7d7d7",
          borderRadius: "10px",
          padding: "23px",
        }}
      >
        {parameterArry.map((item, i) => {
          return <CardContractualLine i={i} destroy={() => deleteLine(i)} />;
        })}
        <div
          style={{
            marginTop: "20px",
            color: "#9966CC",
            cursor: "pointer",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <p onClick={addLine}>+ Adicionar outro parâmetro contratual</p>
        </div>
      </div>
    </>
  );
};

export default CardsContractualParameters;
