export const surveyData = [
    {
        id: 1,
        title: "Pesquisa de satisfação de clientes 2º semestre",
        period: "27/08/2023 à 27/09/2023",
        responseCount: 100,
    },
    {
        id: 2,
        title: "Outra pesquisa",
        period: "01/09/2023 à 30/09/2023",
        responseCount: 75,
    },
];