const LineBorderGap = () => {
  return (
    <div
      style={{
        width: "91%",
        border: "1px solid #d7d7d7",
        margin: "20px auto 10px 6%",
      }}
    ></div>
  );
};

export default LineBorderGap;
