import React, { useContext } from "react";
import flagDownIcon from "../../../images/svg/flagDown.svg";
import dotsIcon from "../../../images/svg/dots.svg"
import { InternalDocumentsContext } from "../../../context/Quality/InternalDocumentsContext";

const DocumentIndicators = () => {

    const { setModalColumnsCardOpen } = useContext(InternalDocumentsContext);
    
    const container = {
        width: "999px",
        height: "73px",
        borderRadius: "10px 10px 0 0",
        border: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px 20px 20px 65px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleIndicatorBox = {
        gap: "10px",
        display: "flex",
        alignItems: "center"
    };

    const styleIndicator = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        fontWeight: 500,
    };

    return (
        <div style={container}>
            <div style={{ ...styleIndicatorBox, width: "120px" }}>
                <h4 style={styleIndicator}>Código</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{ ...styleIndicatorBox, width: "496px" }}>
                <h4 style={styleIndicator}>Documento</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{ ...styleIndicatorBox, width: "146px" }}>
                <h4 style={styleIndicator}>Processo</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{ ...styleIndicatorBox, width: "94px" }}>
                <h4 style={styleIndicator}>Publicação</h4>
                <img src={flagDownIcon} />
            </div>
            <div style={{ width: "60px", display: "flex", justifyContent: "flex-end" }}>
                <img src={dotsIcon} onClick={() => setModalColumnsCardOpen(true)} />
            </div>
        </div>
    );
};

export default DocumentIndicators;