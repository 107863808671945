import { useEffect, useState } from "react";
import BoxParameterSelect from "./BoxParameterSelect";
import { DashContext } from "../../../context/Dashboard/DashContext";
import { useContext } from "react";

const GeneralitieParameters = ({ item, i }) => {
  const {
    createParametersGeneralities,
    updateParametersGeneralities,
    destroyParametersGeneralities,
    updateFixedGeneralitites,
  } = useContext(DashContext);
  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState(".");
  const [price, setPrice] = useState("");
  const [recurrence, setRecurrence] = useState("");
  const [quantity, setQuantity] = useState("");

  const [descriptionBlur, setDescriptionBlur] = useState("");
  const [unitBlur, setUnitBlur] = useState("");
  const [priceBlur, setPriceBlur] = useState("");
  const [recurrenceBlur, setRecurrenceBlur] = useState("");
  const [quantityBlur, setQuantityBlur] = useState("");

  const mountData = async () => {
    const cnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
    const date = `${localStorage.getItem("@XPM_CLIENT_YEAR:")}-${localStorage.getItem("@XPM_CLIENT_MONTH:")}`;
    const data = {
      description: descriptionBlur,
      unit: unitBlur,
      price: priceBlur,
      client_cnpj: cnpj,
      date: date,
      recurrence: recurrenceBlur,
      quantity: quantityBlur,
    };

    if (description && unit && price && recurrence) {
      if (!item?.id) {
        if (recurrence !== 'withoutRecurrence' && quantity) {
          await createParametersGeneralities(data);
        } else if (recurrence === 'withoutRecurrence') {
          await createParametersGeneralities(data);
        }
        return null;
      }

      if (item?.id && (descriptionBlur || unitBlur || priceBlur)) {
        if (recurrence !== 'withoutRecurrence' && (quantity && quantity >= 0)) {
          await updateParametersGeneralities(item.id, data);
          const payload = {
            description,
            unit,
            price,
            quantity,
            date,
            generalities_parameter_id: item.id,
          }
          await updateFixedGeneralitites(payload)
        } else if (recurrence === 'withoutRecurrence') {
          await updateParametersGeneralities(item.id, data);
        }
      }
    }
  };

  useEffect(() => {
    setDescription(item?.description);
    setUnit(item?.unit || ".");
    setPrice(item?.price);
    setRecurrence(item?.recurrence || "");
    setQuantity(item?.quantity || "");

    // setDescriptionBlur(item?.description);
    // setUnitBlur(item?.unit || "");
    // setPriceBlur(item?.price || "");
    // setRecurrenceBlur(item?.recurrence || "");
    // setQuantityBlur(item?.quantity || "");
  }, [item]);

  useEffect(() => {
    mountData();
  }, [descriptionBlur, unitBlur, priceBlur, recurrenceBlur, quantityBlur]);

  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", justifyContent: 'space-between', paddingRight: 25, gap: 10 }}>
        <div style={{width: '200%'}}>
          <BoxParameterSelect
            valueInput={description}
            onChange={(e) => setDescription(e.target.value)}
            onBlur={(e) => setDescriptionBlur(e.target.value)}
            width={"100%"}
            number={i + 1}
            type={"activityDescription"}
          />
        </div>

        <BoxParameterSelect
          valueInput={unit}
          wait={true}
          onChange={(e) => setUnit(e.target.value)}
          onBlur={(e) => setUnitBlur(e.target.value)}
          width={"100%"}
          type={"unit"}
          options={"default"}
        />

        <BoxParameterSelect
          valueInput={recurrence || "."}
          value={recurrence}
          onChange={(e) => setRecurrence(e.target.value)}
          onBlur={(e) => setRecurrenceBlur(e.target.value)}
          wait={true}
          width={"100%"}
          type={"recurrences"}
          options={"administrative"}
          withDefault={item?.id ? true : false}
        />

        <BoxParameterSelect
          valueInput={quantity}
          width={"100%"}
          onChange={(e) => setQuantity(e.target.value)}
          onBlur={(e) => setQuantityBlur(e.target.value)}
          type={"qtdGenerality"}
          withDefault={ !(recurrence !== "withoutRecurrence") }
        />

        <BoxParameterSelect
          valueInput={price}
          onChange={(e) => setPrice(e.target.value)}
          onBlur={(e) => setPriceBlur(e.target.value)}
          width={"100%"}
          type={"price"}
        />

      </div>
      <div
        onClick={() => destroyParametersGeneralities(item?.id)}
        style={{
          position: "absolute",
          right: "-5px",
          top: "38px",
          cursor: "pointer",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="25"
          viewBox="0 0 20 25"
          fill="none"
        >
          <path
            d="M18.316 4.46693H15.3276C15.1039 3.33369 14.5119 2.31544 13.6514 1.58381C12.791 0.852179 11.7147 0.4519 10.604 0.450439L8.676 0.450439C7.56527 0.4519 6.48899 0.852179 5.62855 1.58381C4.76812 2.31544 4.17614 3.33369 3.9524 4.46693H0.964C0.708331 4.46693 0.463134 4.57272 0.282349 4.76103C0.101564 4.94934 0 5.20474 0 5.47105C0 5.73736 0.101564 5.99277 0.282349 6.18108C0.463134 6.36939 0.708331 6.47518 0.964 6.47518H1.928V19.5288C1.92953 20.8598 2.43784 22.1359 3.34144 23.0771C4.24503 24.0183 5.47013 24.5478 6.748 24.5494H12.532C13.8099 24.5478 15.035 24.0183 15.9386 23.0771C16.8422 22.1359 17.3505 20.8598 17.352 19.5288V6.47518H18.316C18.5717 6.47518 18.8169 6.36939 18.9977 6.18108C19.1784 5.99277 19.28 5.73736 19.28 5.47105C19.28 5.20474 19.1784 4.94934 18.9977 4.76103C18.8169 4.57272 18.5717 4.46693 18.316 4.46693ZM8.676 2.45869H10.604C11.2019 2.45944 11.785 2.65286 12.2733 3.0124C12.7615 3.37195 13.131 3.88001 13.3312 4.46693H5.94884C6.14895 3.88001 6.51847 3.37195 7.00673 3.0124C7.49498 2.65286 8.07806 2.45944 8.676 2.45869ZM15.424 19.5288C15.424 20.3277 15.1193 21.0939 14.577 21.6588C14.0346 22.2238 13.299 22.5411 12.532 22.5411H6.748C5.98099 22.5411 5.2454 22.2238 4.70305 21.6588C4.16069 21.0939 3.856 20.3277 3.856 19.5288V6.47518H15.424V19.5288Z"
            fill="#555555"
          />
          <path
            d="M7.71205 18.5248C7.96772 18.5248 8.21291 18.419 8.3937 18.2307C8.57448 18.0424 8.67605 17.787 8.67605 17.5207V11.4959C8.67605 11.2296 8.57448 10.9742 8.3937 10.7859C8.21291 10.5976 7.96772 10.4918 7.71205 10.4918C7.45638 10.4918 7.21118 10.5976 7.0304 10.7859C6.84961 10.9742 6.74805 11.2296 6.74805 11.4959V17.5207C6.74805 17.787 6.84961 18.0424 7.0304 18.2307C7.21118 18.419 7.45638 18.5248 7.71205 18.5248Z"
            fill="#555555"
          />
          <path
            d="M11.5675 18.5247C11.8232 18.5247 12.0684 18.4189 12.2492 18.2306C12.43 18.0423 12.5315 17.7869 12.5315 17.5206V11.4958C12.5315 11.2295 12.43 10.9741 12.2492 10.7858C12.0684 10.5975 11.8232 10.4917 11.5675 10.4917C11.3118 10.4917 11.0666 10.5975 10.8859 10.7858C10.7051 10.9741 10.6035 11.2295 10.6035 11.4958V17.5206C10.6035 17.7869 10.7051 18.0423 10.8859 18.2306C11.0666 18.4189 11.3118 18.5247 11.5675 18.5247Z"
            fill="#555555"
          />
        </svg>
      </div>
    </div>
  );
};

export default GeneralitieParameters;
