import configIcon from "../../../images/svg/configIcon.svg"

const ConfigParameter = ({onClick}) => {

    const container = {
        padding: "13px 14px",
        border: "1px solid #d7d7d7",
        borderRadius: "12px",
        backgroundColor: "#fff",
        cursor: "pointer"
    }

    return(
        <div onClick={onClick} style={container}>
            <img src={configIcon}/>
        </div>
    )
}

export default ConfigParameter