/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { useEffect } from "react";
import CardDetailsContent from "./CardDetailsContent";
import { DashContext } from "../../../context/Dashboard/DashContext";
import { useContext } from "react";
import CardDetailsContentGeneralities from "./CardDetailsContentGeneralities";

const CardDetailsGeneralities = ({
  title,
  content,
  quantity,
  UOM,
  priceUnit,
  total,
  marginLeft,
  onClick,
  contentAll,
  priceTotal,
  onlyOne,
  percentage,
  getWaitingConference,
  comments,
  permission,
}) => {
  const { listClientGeneralities } = useContext(DashContext);

  const [contentApi, setContentApi] = useState([]);

  const [sumAllOne, setSumAllOne] = useState("");

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  };

  const oneCardSettings = () => {
    if (contentApi[0]) {
      setSumAllOne(contentApi[0].sum_all);
    }
  };

  useEffect(() => {
    if (onlyOne) {
      oneCardSettings();
    }
  }, [onlyOne, contentAll, contentApi]);

  useEffect(() => {
    setContentApi(contentAll);
  }, [contentAll]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "13px",
      }}
    >
      <h3 className="textTitleDetailsInvoicing">{title}</h3>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "13px",
        }}
      >
        {listClientGeneralities?.length > 1 ? (
          permission !== "Operacional" ? (
            listClientGeneralities.map((item, i) => {
              if (i > 0) {
                return (
                  <CardDetailsContentGeneralities
                    onClick={onClick}
                    marginLeft={marginLeft}
                    item={item}
                    getWaitingConference={getWaitingConference}
                  />
                );
              }
            })
          ) : (
            listClientGeneralities.filter((e) => e.recurrence !== 'monthly').map((item, i) => {
              if (i > 0) {
                return (
                  <CardDetailsContentGeneralities
                    onClick={onClick}
                    marginLeft={marginLeft}
                    item={item}
                    getWaitingConference={getWaitingConference}
                  />
                );
              }
            })
          )
        ) : (
          <CardDetailsContent
            item={{
              description: "Não há parametros",
              qtd_item: 0,
              soma_qtd_item: 0,
              price: 0,
              UOM: "Não encontrado",
            }}
            marginLeft={marginLeft}
            onClick={onClick}
            comments={comments}
            title={title}
          />
        )}
      </div>
      <div
        style={{
          border: "1px solid #d7d7d7",
          borderRadius: "10px",
          padding: "17px 23px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <p className="textTotalValueInvoicing">Valor Total:</p>
          <p className="totalValueInvoicing">
            {
              listClientGeneralities.length > 1 ? (
                  listClientGeneralities[0]?.sum_total.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              ) : `R$ 0,00`              
            }
          </p>
        </div>        
      </div>
    </div>
  );
};

export default CardDetailsGeneralities;
