import { useContext, useEffect, useState } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import CardContractualParameter from "./CardContractualParameter";
import ContainerAveragingMonth from "./ContainerAveragingMonth";
import moment, { months } from "moment";
import CardYear from "./CardYear";
import InventoryAccuracy from "./InventoryAccuracy";
import InventoryConsolidation from "./InventoryConsolidation";
import CommentYearDashboard from "./CommentYearDashboard";
import InventoryConciliation from "./InventoryConciliation";

const ContainerContractualParameter = () => {
  const { getKPIGeneral, generalKpis, loading, update, getKPIGeneralYear } =
    useContext(GraphsAndParametersContext);
  const [dateMoment, setDateMoment] = useState(moment().format("YYYY"));

  useEffect(() => {
    getKPIGeneral(dateMoment);
    getKPIGeneralYear(dateMoment);
  }, [dateMoment, update]);

  useEffect(() => {
    console.log(generalKpis);
  }, [generalKpis]);

  return (
    <div>
      <div style={{ marginTop: "30px" }}>
        <CardYear dateMoment={dateMoment} setDateMoment={setDateMoment} />
      </div>
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {!loading ? (
          generalKpis?.length > 0 ? (
            generalKpis.map((elem) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                    width: "100%",
                  }}
                >
                  <ContainerAveragingMonth
                    listMonths={elem.months.map((month) => month.averageOnTime)}
                    title={`${elem.parametrs.description}`}
                    metrics={Number(elem.parametrs.metrics)}
                  />
                  <CardContractualParameter
                    max={100}
                    title={`${elem.parametrs.process}: ${elem.parametrs.description}`}
                    nameOne={"No Prazo"}
                    nameTwo={"Fora do Prazo"}
                    metrics={elem.parametrs.metrics}
                    listMonths={elem.months.map((month) => month.averageOnTime)}
                    listMonthsTwo={elem.months.map(
                      (month) => month.averageOffTime
                    )}
                  />
                </div>
              );
            })
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {!loading ? <CommentYearDashboard /> : <></>}

        {!loading ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <InventoryAccuracy update={dateMoment} />
              <InventoryConsolidation update={dateMoment} />
            </div>
            <InventoryConciliation update={dateMoment} />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ContainerContractualParameter;
