import React from 'react';

const Graphic = () => {
    const styleContainer = {
        width: "589.04px",
        height: "230px",
        border: "1px solid var(--grey-0)",
        borderRadius: "7px",
        backgroundColor: "var(--white)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    };

    const styleBox = {
        display: "flex",
        alignItems: "center",
    };

    const styleBarBase = {
        width: "400px",
        height: "45px",
        borderRadius: "5px",
        backgroundColor: "var(--grey-0)",
        marginBottom: "15px"
    };

    const styleColorBar = {
        height: "45px",
        borderRadius: "5px",
    };

    const styleInfos = {
        width: "100px"
    };

    const styleType = {
        fontSize: "12px",
    };

    const styleNumber = {
        fontSize: "18px",
        fontWeight: 500,
    };

    return (
        <div style={styleContainer}>

            <div style={styleBox}>
                <div style={styleInfos}>
                    <p style={{ ...styleNumber, color: "#00B386" }}>{88}</p>
                    <p style={styleType}>{"Promotores"}</p>
                </div>
                <div style={styleBarBase}>
                    <div style={{ ...styleColorBar, width: `${88}%`, backgroundColor: "#00B386" }} />
                </div>
            </div>

            <div style={styleBox}>
                <div style={styleInfos}>
                    <p style={{ ...styleNumber, color: "#F9CF4A" }}>{8}</p>
                    <p style={styleType}>{"Neutros"}</p>
                </div>
                <div style={styleBarBase}>
                    <div style={{ ...styleColorBar, width: `${8}%`, backgroundColor: "#F9CF4A" }} />
                </div>
            </div>

            <div style={styleBox}>
                <div style={styleInfos}>
                    <p style={{ ...styleNumber, color: "#ea0000" }}>{4}</p>
                    <p style={styleType}>{"Detratores"}</p>
                </div>
                <div style={styleBarBase}>
                    <div style={{ ...styleColorBar, width: `${4}%`, backgroundColor: "#ea0000" }} />
                </div>
            </div>

        </div>
    );
};

export default Graphic;

