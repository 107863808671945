import Button from "./Button"
import InputSearch from "./InputSearch"

const ContainerInputSearchAndButton = ({widthInput, heightInput,onClick}) => {

    const container = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }


    return (
        <div className="containerBox" style={container}>
            <InputSearch width={widthInput} height={heightInput}/>
            <Button width="195px" height="45px" background={"var(--color-primary)"}
                borderRadius="60px" marginTop="0" name="Exportar dados"
                color={"var(--white)"} onClick={onClick}
                border="none" />
        </div>
    )
}

export default ContainerInputSearchAndButton