import { useContext, useState } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";

const ModalCardEditOrDelete = ({ id, onClose, message, edit }) => {
  const { destroyComment, setEditId, setEditMessage } = useContext(
    GraphsAndParametersContext
  );

  const [generalOptions, setGeneralOptions] = useState(true);

  const onDestroy = async () => {
    await destroyComment(id);
    onClose();
  };

  const onEdit = async () => {
    setEditId(id);
    setEditMessage(message);
    onClose();
  };

  const containerModal = {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 2,
  };

  const cardModal = {
    position: "absolute",
    border: "1px solid #d7d7d7",
    backgroundColor: "#fff",

    borderRadius: "10px",
    padding: "10px 0",
    top: 0,
    zIndex: 3,
    right: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "5px",
  };

  const lineOptions = {
    borderBottom: "1px solid #d7d7d7",
    width: "200px",
    textAlign: "center",
    paddingBottom: "2px",
    cursor: "pointer",
  };

  const lineOptionTwo = {
    width: "200px",
    textAlign: "center",
    cursor: "pointer",
  };

  return (
    <>
      <div onClick={onClose} style={containerModal}></div>
      <div style={cardModal}>
        {generalOptions ? (
          <>
            {edit !== false ? (
              <div style={lineOptions}>
                <p onClick={onEdit} className="editChatDash">
                  Editar
                </p>
              </div>
            ) : (
              <></>
            )}
            <div style={lineOptionTwo}>
              <p
                onClick={() => setGeneralOptions(false)}
                className="destroyChatDash"
              >
                Apagar
              </p>
            </div>
          </>
        ) : (
          <>
            <div>
              <p className="questionOptions">Tem certeza que deseja apagar?</p>
            </div>

            <div style={{ display: "flex", gap: "15px" }}>
              <p onClick={onDestroy} className="editChatDash colorRedHover">
                Sim
              </p>
              <p
                onClick={() => setGeneralOptions(true)}
                className="editChatDash"
              >
                Não
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ModalCardEditOrDelete;
