import React, { useContext } from "react";
import Button from "./Button";
import Input from "./Input";
import Select from "./Select";
import { options } from "./mocks/SelectGenericOptions";
import AttachmentBoxForModal from "./AttachmentBoxForModal";
import TextEditorBoxModal from "./TextEditorBoxModal";
import ResponsibleBoxCreateInternalDocumentModal from "./ResponsibleBoxCreateInternalDocumentModal";
import PermissionsBoxCreateInternalDocumentModal from "./PermissionsBoxCreateInternalDocumentModal";
import AttachedDocument from "./AttachedDocument";
import { InternalDocumentsContext } from "../../../context/Quality/InternalDocumentsContext";
import trashIcon from "../../../images/svg/trashWithBackground.svg"
import downloadIcon from "../../../images/svg/downloadWithBackground.svg"
import printIcon from "../../../images/svg/printWithBackground.svg"

const ModalEditDocument = ({
  item,
  closeModal,
  closeIcon,
}) => {

  const { setModalDeleteOpen, setModalEditDocumentOpen } = useContext(InternalDocumentsContext);

  const container = {
    display: "flex",
    flexDirection: "column",
    background: "var(--white)",
    border: "1px solid var(--grey-0)",
    borderRadius: "20px",
    width: "881px",
    height: "704px",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.18)",
  };

  const stylesHeaderMenu = {
    alignItems: "center",
    boxSizing: "border-box",
    borderBottom: "1px solid var(--grey-0)",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    width: "100%",
  };

  const stylesCloseBtn = {
    background: "none",
    border: "none",
    marginRight: "16px",
  };

  const styleTitle = {
    color: "var(--black)",
    fontSize: "18px",
    fontWeight: 500,
  };

  const styleContentBox = {
    padding: "20px",
    overflowY: "auto",
  };

  const styleInfoDocumentsBox = {
    borderBottom: "1px solid var(--grey-0)",
  };

  const styleInfoDocumentsItemsBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  };

  return (
    <div style={container}>
      <header style={stylesHeaderMenu}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            name="closeDeleteMenu"
            style={stylesCloseBtn}
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} alt="close.png" />
          </button>
          <p style={styleTitle}>Editar Documento interno</p>
        </div>
        <div>
          <img src={trashIcon} onClick={() => { setModalDeleteOpen(true); setModalEditDocumentOpen(false) }} />
          <img src={downloadIcon} />
          <img src={printIcon} style={{ marginRight: "10px" }} />
          <Button
            width="188px"
            height="36px"
            background="var(--color-primary)"
            color="var(--white)"
            borderRadius="60px"
            marginTop="0"
            name="Salvar documento"
            border="none"
          />
        </div>
      </header>
      <div style={styleContentBox}>
        <div style={styleInfoDocumentsBox}>
          <div style={styleInfoDocumentsItemsBox}>
            <Input
              name="Revisão*"
              width="100px"
            />
            <Input
              name="Código*"
              width="100px"
            />
            <Input
              name="Título do documento*"
              width="342px"
            />
            <Select
              label="Categoria*"
              width="202px"
              options={options}
            />
          </div>
          <div style={styleInfoDocumentsItemsBox}>
            <Input
              name="Unidade*"
              width="362px"
            />
            <Select
              label="Processo*"
              width="202px"
              options={options}
            />
            <Select
              label="Associar a outros DOCs*"
              width="202px"
              options={options}
            />
          </div>
        </div>

        {/* No componente AttachmentBoxForModal está incluída a função para abrir a galeria e anexar arquivos do computador.  */}
        <AttachmentBoxForModal children={
          <>
            {item.subDocuments && item.subDocuments.length > 0 && (
              <ul style={{ margin: "0", padding: "0", display: "flex", alignItems: "center", gap: "20px" }}>
                {item.subDocuments.map((subDocument) => <AttachedDocument subDocuments={subDocument} key={subDocument.id} />)}
              </ul>
            )}
          </>
        } />
        <ResponsibleBoxCreateInternalDocumentModal />
        <PermissionsBoxCreateInternalDocumentModal />
        <TextEditorBoxModal />
      </div>
    </div>
  );
};

export default ModalEditDocument;
