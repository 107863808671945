import React from 'react';

const ReviewCard = ({ revision, publication, requester, changes }) => {
  const styleIndicatorBox = {
    gap: "10px",
    display: "flex",
    alignItems: "center"
  };

  const styleDocumentinfosValue = {
    color: "(--black)",
    fontSize: "14px",
    fontWeight: 400,
  };

  return (
    <li style={{ border: "1px solid var(--grey-0)", backgroundColor: "var(--white)", padding: "20px 20px 20px 32px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div style={{ ...styleIndicatorBox, width: "136px" }}>
        <p style={styleDocumentinfosValue}>{revision}</p>
      </div>
      <div style={{ ...styleIndicatorBox, width: "150px" }}>
        <p style={styleDocumentinfosValue}>{publication}</p>
      </div>
      <div style={{ ...styleIndicatorBox, width: "184px" }}>
        <p style={styleDocumentinfosValue}>{requester}</p>
      </div>
      <div style={{ ...styleIndicatorBox, width: "309px" }}>
        <p style={styleDocumentinfosValue}>{changes}</p>
      </div>
    </li>
  );
};

export default ReviewCard;
