export const externalDocuments = [
  {
    id: 1,
    title: "Documento 1",
    distribution: "Eletrônica",
    location: "Pasta A",
    responsible: "Janaina Barros",
    validity: "31/12/2024",  
    attachments: [
      {
        id: 101,
        date_inclusion: "08/02/2024",  
        title: "Anexo 1"
      },
      {
        id: 102,
        date_inclusion: "10/02/2024",  
        title: "Anexo 2"
      }
    ]
  },
  {
    id: 2,
    title: "Documento 2",
    distribution: "Eletrônica",
    location: "Warehouse B",
    responsible: "Janaina Barros",
    validity: "30/06/2025",  
    attachments: [
      {
        id: 103,
        date_inclusion: "15/02/2024",  
        title: "Anexo 1"
      }
    ]
  },
  {
    id: 3,
    title: "Documento 3",
    distribution: "Eletrônica",
    location: "Lab C",
    responsible: "Janaína Barros",
    validity: "15/08/2024",  
    attachments: []
  }
];
