import { useState } from "react";
import SelectSessionButtons from "./SelectSessionButtons";
import CardsProductConversion from "./CardsProductConversion";
import CardsContractualParameters from "./CardsContractualParameters";

const ContainerCardsSettings = () => {
  const [btnSelect, setBtnSelect] = useState("Conversão de produtos");

  const [listOption, setListOption] = useState([
    "Conversão de produtos",
    "Parâmetros contratuais",
  ]);

  return (
    <div style={{ margin: "0 auto" }} className="containerBox">
      <SelectSessionButtons
        setBtnSelect={setBtnSelect}
        btnSelect={btnSelect}
        buttons={listOption}
        width={"480px"}
        marginBottom={"40px"}
      />

      {btnSelect === "Conversão de produtos" && (
        <CardsProductConversion
          company={"Agraria SP"}
          cnpj={"77.890.846/0044-09"}
        />
      )}
      {btnSelect === "Parâmetros contratuais" && <CardsContractualParameters />}
    </div>
  );
};

export default ContainerCardsSettings;
