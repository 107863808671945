import { useState } from "react";
import checkIconOn from "../../../images/svg/checkOnIcon.svg";
import checkIconOff from "../../../images/svg/checkOffIcon.svg";

const CardDetailsGeneralitiesTitle = ({
  title,
  content,
  quantity,
  UOM,
  total,
  marginLeft,
  paddingLeft,
  priceUnit,
}) => {
  const [check, setCheck] = useState(false);

  /* STYLES */
  const container = {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
  };

  const boxContent = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "13px",
  };

  const content2 = {
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "17px 23px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    width: "100%",
    position: "relative",
  };

  const boxContainerCard = {
    borderLeft: "1px solid #79797970",
    marginLeft: marginLeft,
    paddingLeft: paddingLeft,
    display: "flex",
    gap: "15px",
  };

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  };

  return (
    <div style={container}>
      <h3 className="textTitleDetailsInvoicing">{title}</h3>
      <div style={boxContent}>
        <div style={content2}>
          <div
            onClick={() => setCheck(!check)}
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              style={{
                marginRight: "19px",
                cursor: "pointer",
              }}
              src={check ? checkIconOn : checkIconOff}
            />
            <p
              style={{ cursor: "pointer" }}
              className="textContentDetailsInvoicing"
            >
              {content}
            </p>
          </div>

          <div style={boxContainerCard}>
            <div style={contentValueCard}>
              <p className="titleNameCompanyCard">{quantity}</p>
              <p className="textTitleValueCard">Quantidade</p>
            </div>

            <div style={contentValueCard}>
              <p className="titleNameCompanyCard">{UOM}</p>
              <p className="textTitleValueCard">UOM</p>
            </div>

            <div style={contentValueCard}>
              <p className="titleNameCompanyCard">{priceUnit}</p>
              <p className="textTitleValueCard">Preço unitário</p>
            </div>

            <div style={contentValueCard}>
              <p className="titleNameCompanyCard">{total}</p>
              <p className="textTitleValueCard">Total geral</p>
            </div>

            <div
              style={{
                position: "absolute",
                right: "20px",
                top: "20px",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetailsGeneralitiesTitle;
