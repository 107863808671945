import sheetIcon from "../../../images/svg/sheetFloatButton.svg"
import plusIcon from "../../../images/svg/plusFloatButton.svg"
import { InternalDocumentsContext } from "../../../context/Quality/InternalDocumentsContext";
import { useContext } from "react";

const FloatButtonBox = () => {

    const { setModalCreateDocumentOpen } = useContext(InternalDocumentsContext);

    const container = {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        bottom: "0px",
        right: "0px"
    };

    return (
        <div style={container}>
            <img src={sheetIcon} alt="Criar" />
            <div onClick={() => setModalCreateDocumentOpen(true)}>
                <img src={plusIcon} alt="" />
            </div>
        </div>
    );
};

export default FloatButtonBox;