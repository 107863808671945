import React from 'react';

const ControlMenu = ({items, active, setActive, width}) => {

  const stylesContainer = {
    border: '1px solid #D7D7D7',
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'space-around',
    padding: '5px 6px',
    width,
  };

  const stylesActiveBtn = {
    background: '#96C',
    color: '#fff',
    padding: '5px 18px',
    borderRadius: 50,
    border: 'none',
  };

  const stylesBtn = {
    background: 'none',
    color: '#96C',
    padding: '5px 18px',
    borderRadius: 50,
    border: 'none',
  };

  const renderButton = (item) => {
    if(item === active) {
      return <button style={stylesActiveBtn} onClick={() => setActive(item)}>{item}</button>
    } else {
      return <button style={stylesBtn} onClick={() => setActive(item)}>{item}</button>
    }
  };

  return (
    <nav style={stylesContainer}>
      {
        items.map((item) => (
          renderButton(item)
        ))
      }
    </nav>
  )
};

export default ControlMenu;
