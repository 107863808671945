import React, { useContext } from "react";
import DocumentCard from "./DocumentCard";
import DocumentIndicators from "./DocumentIndicators";
import FloatButtonBox from "./FloatButtonBox";
import documentListMock from "./mocks/documentListMock";
import ModalContainer from "./ModalContainer";
import ModalCreateDocument from "./ModalCreateDocument";
import closeIcon from '../../../images/svg/closeIcon.svg';
import { InternalDocumentsContext } from "../../../context/Quality/InternalDocumentsContext";
import ModalDocumentDetail from "./ModalDocumentDetail";
import ModalDelete from "./ModalDelete";
import ModalEditDocument from "./ModalEditDocument";
import ModalColumnsCard from "./ModalColumnsCard";

const ContainerContentDocuments = () => {

    const { modalColumnsCardOpen,
        modalCreateDocumentOpen,
        handleModalClose,
        modalDocumentDetailOpen,
        setModalDocumentDetailOpen,
        setSelectedCard,
        selectedCard,
        modalDeleteOpen,
        modalEditDocumentOpen } = useContext(InternalDocumentsContext);

    const handleDeleteCard = (cardId) => {
        console.log(cardId);

        setSelectedCard(null);
        handleModalClose();
    };

    const container = {
        marginTop: "40px",
    };

    const handleCardClick = (document) => {
        setSelectedCard(document);
        setModalDocumentDetailOpen(true);
    };

    return (
        <main>
            <div style={container}>
                <DocumentIndicators />
                <ul style={{ margin: "0", padding: "0" }}>
                    {documentListMock.map((document) => (
                        <DocumentCard key={document.id} document={document} onClick={() => handleCardClick(document)} />
                    ))}
                </ul>

                <ModalContainer isActive={modalCreateDocumentOpen} closeModal={handleModalClose}>
                    <ModalCreateDocument
                        closeModal={handleModalClose}
                        closeIcon={closeIcon}
                    />
                </ModalContainer>

                <ModalContainer isActive={modalColumnsCardOpen} closeModal={handleModalClose}>
                    <ModalColumnsCard
                        closeModal={handleModalClose}
                        closeIcon={closeIcon}
                    />
                </ModalContainer>

                <ModalContainer isActive={modalEditDocumentOpen} closeModal={handleModalClose}>
                    <ModalEditDocument
                        item={selectedCard}
                        closeModal={handleModalClose}
                        closeIcon={closeIcon}
                    />
                </ModalContainer>

                <ModalContainer isActive={modalDocumentDetailOpen} closeModal={handleModalClose}>
                    <ModalDocumentDetail
                        closeModal={handleModalClose}
                        closeIcon={closeIcon}
                        item={selectedCard}
                    />
                </ModalContainer>

                <ModalContainer isActive={modalDeleteOpen} closeModal={handleModalClose}>
                    <ModalDelete
                        item={selectedCard}
                        closeModal={handleModalClose}
                        deleteItem={() => handleDeleteCard(selectedCard)}
                        closeIcon={closeIcon}
                    />
                </ModalContainer>

            </div>
            <FloatButtonBox />
        </main>
    );
};

export default ContainerContentDocuments;