const BackArrow = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: "var(--color-primary)",
        width: "25px",
        height: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
      }}
    >
      <svg
        width="7"
        height="11"
        viewBox="0 0 7 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_111_82" fill="white">
          <path d="M6.31725 9.39287L2.65621 5.53994L6.31725 1.687L5.19016 0.503418L0.394043 5.53994L5.19016 10.5765L6.31725 9.39287Z" />
        </mask>
        <path
          d="M6.31725 9.39287L7.76561 10.7721C8.50055 10.0003 8.50121 8.78781 7.76711 8.01522L6.31725 9.39287ZM2.65621 5.53994L1.20636 4.16229C0.472832 4.93426 0.472832 6.14561 1.20636 6.91758L2.65621 5.53994ZM6.31725 1.687L7.76711 3.06465C8.50121 2.29207 8.50055 1.07956 7.76561 0.307774L6.31725 1.687ZM5.19016 0.503418L6.63852 -0.875808C6.26103 -1.27222 5.73756 -1.49658 5.19016 -1.49658C4.64277 -1.49658 4.1193 -1.27222 3.74181 -0.875808L5.19016 0.503418ZM0.394043 5.53994L-1.05431 4.16071C-1.78984 4.9331 -1.78984 6.14677 -1.05431 6.91916L0.394043 5.53994ZM5.19016 10.5765L3.74181 11.9557C4.1193 12.3521 4.64277 12.5765 5.19016 12.5765C5.73756 12.5765 6.26103 12.3521 6.63852 11.9557L5.19016 10.5765ZM7.76711 8.01522L4.10607 4.16229L1.20636 6.91758L4.86739 10.7705L7.76711 8.01522ZM4.10607 6.91758L7.76711 3.06465L4.86739 0.309352L1.20636 4.16229L4.10607 6.91758ZM7.76561 0.307774L6.63852 -0.875808L3.74181 1.88264L4.8689 3.06622L7.76561 0.307774ZM3.74181 -0.875808L-1.05431 4.16071L1.8424 6.91916L6.63852 1.88264L3.74181 -0.875808ZM-1.05431 6.91916L3.74181 11.9557L6.63852 9.19723L1.8424 4.16071L-1.05431 6.91916ZM6.63852 11.9557L7.76561 10.7721L4.8689 8.01365L3.74181 9.19723L6.63852 11.9557Z"
          fill="white"
          mask="url(#path-1-inside-1_111_82)"
        />
      </svg>
    </div>
  );
};

export default BackArrow;
