/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from "react";
import footerComments from "../../../images/svg/footerComments.svg";
import profile from "../../../images/svg/profile.svg";
import CardMensageAndSubMensage from "./CardMensageAndSubMensage";
import FooterMensageInput from "./FooterMensageInput";
import { DashContext } from "../../../context/Dashboard/DashContext";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";

const CardComments = ({ closeCard, id }) => {
  const { getComments, comments, update } = useContext(DashContext);
  const [subCommentType, setSubCommentType] = useState(false);
  const [commentId, setCommentId] = useState("");

  const getUserInformation = () => {
    const decoded = jwt_decode(localStorage.getItem("@XPM_CLIENT_TOKEN:"));
    getComments(id, decoded.id);
  };

  useEffect(() => {
    getUserInformation();
  }, [id, update]);

  useEffect(() => {
    console.log(id);
  }, [id]);

  useEffect(() => {
    console.log(comments);
  }, [comments]);

  /* STYLES */
  const cardContainerStyle = {
    width: "370px",
    height: "470px",
    backgroundColor: "#fff",
    border: "1px solid #d7d7d7",
    position: "absolute",
    zIndex: 10,
    bottom: "71px",
    right: 0,
    borderRadius: "10px",
    boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.25)",
  };

  const headerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "27px",
    padding: "19px 19px 15px",
    borderBottom: "1px solid #d7d7d7",
  };

  const closeButtonStyle = {
    cursor: "pointer",
    width: "11px",
  };

  const titleStyle = {
    marginLeft: "5px",
  };

  const scrollContainerStyle = {
    padding: "5px",
    height: "340px",
  };

  const scrollCardStyle = {
    padding: "11px",
    overflowY: "scroll",
    height: "100%",
  };

  return (
    <div
      id={id}
      style={cardContainerStyle}
      className="animate__animated animate__fadeIn"
    >
      <div style={headerStyle}>
        <svg
          onClick={closeCard}
          style={closeButtonStyle}
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z"
            fill="black"
          />
        </svg>
        <p className="textCommentsTitleCard" style={titleStyle}>
          Comentários fatura
        </p>
      </div>

      <div style={scrollContainerStyle}>
        <div className="scrollCard" style={scrollCardStyle}>
          {comments?.map((item) => {
            return (
              <CardMensageAndSubMensage
                name={item?.name}
                picture={item?.picture}
                subComments={item?.subResponse}
                createdAt={item?.createdAt}
                content={item?.message}
                file={item?.file}
                subCommentType={subCommentType}
                setSubCommentType={setSubCommentType}
                id={item?.id}
                commentId={commentId}
                setCommentId={setCommentId}
              />
            );
          })}
        </div>
      </div>

      <FooterMensageInput
        response={false}
        name={"Lucas"}
        paramId={id}
        commentId={commentId}
        subCommentType={subCommentType}
        setSubCommentType={setSubCommentType}
      />

      <img
        style={{ position: "absolute", bottom: "-23px", right: "10px" }}
        src={footerComments}
      />
    </div>
  );
};

export default CardComments;
