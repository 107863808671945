import React, { useState } from 'react';
import Input from './Input';
import Select from './Select';
import { options } from "./mocks/SelectGenericOptions"
import QuestionCard from './QuestionCard';

const ModalCreateNewSearch = ({
  closeModal,
  closeIcon,
}) => {

  const [name, setName] = useState();
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();

  // O campo error está com a estilização da mensagem de erro configura no componente Input desse módulo
  const [nameError, setNameError] = useState();

  const stylesMenu = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #D7D7D7',
    borderRadius: '20px',
    width: '410px',
    maxHeight: '605px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.18)'
  };

  const stylesHeaderMenu = {
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: '1px solid #D7D7D7',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 15px',
    width: '100%',
  };

  const stylesCloseBtn = {
    background: 'none',
    border: 'none',
    marginRight: '16px'
  };

  const stylesEditBtn = {
    background: '#96C',
    borderRadius: 50,
    border: 'none',
    color: '#fff',
    padding: '8px 25px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const styleTitle = {
    color: '#000000',
    fontSize: '18px',
    fontWeight: 500
  };

  return (
    <main style={stylesMenu}>
      <header style={stylesHeaderMenu}>
        <div style={{ display: 'flex', alignItems: 'center' }}>

          <button name="closeModalCreateNewSearch" style={stylesCloseBtn} onClick={() => closeModal(false)}>
            <img src={closeIcon} alt='close' />
          </button>
          <p style={styleTitle}>Criar pesquisa</p>
        </div>
        {/* <button style={stylesEditBtn} onClick={() => submit()}> */}
        <button style={stylesEditBtn} onClick={() => console.log('submit')}>
          Criar
        </button>
      </header>
      <div style={{ padding: '20px', width: '100%', overflowY: 'auto' }}>
        <Input
          name="Nome da pesquisa"
          error={nameError}
          onChange={(e) => setName(e.target.value)}
          placeholder="Insira um nome"
          type={"text"}
          width="100%"
        />
        <Select
          label="Lista de envio"
          options={options}
        />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Input
            name="Data inicial"
            onChange={(e) => setInitialDate(e.target.value)}
            type={"date"}
            width="100%"
            marginRight={"5px"}
          />
          <Input
            name="Data final"
            onChange={(e) => setFinalDate(e.target.value)}
            type={"date"}
            width="100%"
            marginLeft={"5px"}
          />
        </div>
        <Select
          label="Programação de envio"
          options={options}
        />


        <QuestionCard/>
  
      </div>
    </main>
  )
}

export default ModalCreateNewSearch;
