import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";

export const ContactedContext = createContext({});

export const ContactedValidation = ({ children }) => {
  const [receipt, setReceipt] = useState([]);
  const [receiptParameter, setReceiptParameter] = useState([]);
  const [weekOrMonth, setWeekOrMonth] = useState("month");
  const [numberWeek, setNumberWeek] = useState(1);

  const [typeUnit, setTypeUnit] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [parameters, setParameters] = useState([]);
  const [alreadyRequestParameters, setAlreadyRequestParameters] =
    useState(false);

  const showToast = (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.info(message);
        break;
      case "warn":
        toast.warn(message);
        break;
      case "loading":
        return toast.loading(message); // Retorna o ID do toast de loading
      default:
        toast(message); // Exibe um toast padrão sem ícone específico
    }
  };

  const onContacted = async (week) => {
    try {
      const clientCNPJ = localStorage.getItem("@XPM_CLIENT_COMPANY:");

      const response = await api.get(
        `contracted/graphics?month=${month}&year=${year}&type=${typeUnit}${
          week ? `&week=${week}` : ""
        }&client=${clientCNPJ}`
      );

      setAlreadyRequestParameters(false);

      setReceipt(response.data.result);
      setReceiptParameter(response.data.parameters);
    } catch (error) {}
  };

  const onBringUpdatedLogixData = async () => {
    try {
      const toastId = showToast("loading", "Buscando dados no logix...");
      const clientCNPJ = localStorage.getItem("@XPM_CLIENT_COMPANY:");
      await api.post(
        `contracted/graphics?month=${month}&year=${year}&client=${clientCNPJ}`
      );
      toast.dismiss(toastId);
      showToast("success", "Dados atualizados com sucesso!");
      onContacted();
    } catch (error) {}
  };

  const createParameter = async (body, closeModal) => {
    try {
      await api.post("/contracted/graphics/parameter", body);
      closeModal();
      showToast("success", "Paramêtro salvo com sucesso!");
      setAlreadyRequestParameters(false);
      onContacted();
    } catch (error) {
      console.log(error);
      showToast("error", "Houve um erro!");
    }
  };

  const getParameters = async () => {
    try {
      const clientCNPJ = localStorage.getItem("@XPM_CLIENT_COMPANY:");
      const { data } = await api.get(
        `/contracted/graphics/parameter?client=${clientCNPJ}`
      );

      setParameters(data);
      setAlreadyRequestParameters(true);
    } catch (error) {
      showToast("error", "Houve um erro!");
    }
  };

  return (
    <>
      <ContactedContext.Provider
        value={{
          receipt,
          onContacted,
          weekOrMonth,
          setWeekOrMonth,
          numberWeek,
          setNumberWeek,
          typeUnit,
          setTypeUnit,
          month,
          setMonth,
          year,
          setYear,
          onBringUpdatedLogixData,
          createParameter,
          getParameters,
          parameters,
          alreadyRequestParameters,
          receiptParameter,
        }}
      >
        {children}
      </ContactedContext.Provider>
    </>
  );
};
