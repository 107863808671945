import CardMinInsurance from "./CardMinInsurance";

const CardInsurance = ({ item }) => {
  /* STYLES */
  const container = {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    marginTop: "22px",
    justifyContent: "space-between",
  };

  return (
    <div style={container}>
      {item?.length > 0 ? (
        item.map((elem) => {
          return (
            <CardMinInsurance
              date={elem.data_base_calculo}
              valueStock={
                elem.tipo_movimento == "E"
                  ? elem.sum_line
                  : elem.sum_line - elem.sum_line * 2
              }
              invoicedStock={elem.percentage}
            />
          );
        })
      ) : (
        <></>
      )}
      <CardMinInsurance
        date={"04/08/2023"}
        valueStock={"R$ 5.000.000,00"}
        invoicedStock={"R$ 9.000"}
      />
    </div>
  );
};

export default CardInsurance;
