import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import BoxParameterSelect from "./BoxParameterSelect";
import { DashContext } from "../../../context/Dashboard/DashContext";
import closeIcon from "../../../images/svg/closeIcon.svg";
import add from '../../../images/svg/add.svg';
import deleteIcon from '../../../images/svg/deleteIcon.svg';
import api from "../../../services/api";
import NewVariableCard from "./NewVariableCard";
import { toast } from "react-toastify";
const moment = require("moment-timezone");

const ModalMinimumBilling = ({ closeModal, onClick, variables, setVariables, deleteVariable, updateVariable, arrayOfParams, onOpen }) => {
  const { minimumBilling, setMinimumBilling, setFixedMonthlyValue} = useContext(DashContext);
  const [updatedVariables, setUpdatedVariables] = useState([]);
  const [newVariables, setNewVariables] = useState([]);
  const [fixedValue, setFixedValue] = useState('');
  const [fixedValueBlur, setFixedValueBlur] = useState('');
  // const [typeStructureAll, setTypeStructureAll] = useState([]);
  const [fixedValueObject, setFixedValueObject] = useState({});
  const [params, setParams] = useState([]);

  /* STYLES */
  const container = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: "690px",
    boxShadow: "0px 0px 13px -3px #00000033",
    border: "1px solid transparent",
    borderRadius: "20px",
  };

  const headerOption = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    padding: "15px 18px 18px",
  };

  const content = {
    width: "100%",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    overflowY: "scroll",
    paddingRight: 10,
    maxHeight: 400,
  };

  const boxTextDelete = {
    display: "flex",
    alignItems: "center",
    padding: "18px",
    flexDirection: "column",
  };

  const createNewVariable = () => {
    const newArray = [...newVariables];
    newArray.push({ process: '', name: '', value: '' });
    setNewVariables(newArray);
  };

  const handleChange = ({target}, index, type) => {
    const {name, value} = target;
    if (type === 'existing') {
      const newArray = [...variables ];      
      newArray[index][name] = value || "0";
      const exist = newArray.filter((e) => e.process === newArray[index].process && e.name === newArray[index].name);
      if (exist.length === 1) {
        setVariables(newArray);
      } else {
        toast.error('Variável já utilizada', { autoClose: 2000 });
        return false;
      }
      if (updatedVariables.length === 0 || !updatedVariables.some((e) => e.id === newArray[index].id)) {
        const newUpdatedArray = [...updatedVariables];
        newUpdatedArray.push(newArray[index]);
        setUpdatedVariables(newUpdatedArray);
      }

      // Atualizar a variável se já existir no array
      const objIndex = updatedVariables.findIndex((e) => e.id === newArray[index].id);
      if (objIndex !== -1) {
        setUpdatedVariables((prevUpdatedVariables) => {
          const updatedCopy = [...prevUpdatedVariables];
          updatedCopy[objIndex][name] = value || "0";
          return updatedCopy;
        });
      }
    }

    if (type === 'new') {
      const newArray = [...newVariables];
      newArray[index][name] = value;
      const existInNewVariables = newArray.filter((e) => e.process === newArray[index].process && e.name === newArray[index].name);
      const existInVariables = variables.filter((e) => e.process === newArray[index].process && e.name === newArray[index].name);
      if (newVariables.length > 0 && existInNewVariables.length === 1 && existInVariables.length === 0) {
        setNewVariables(newArray);
      } else {
        toast.error('Variável já utilizada', { autoClose: 2000 });
        return false;
      }
      if (newArray[index].name && newArray[index].process) {
        newArray[index].value = params.filter((e) => e.process === newArray[index].process && e.name === newArray[index].name)[0].value;
      };
      setNewVariables(newArray);
    }
  };

  const getFormattedValue = (value) => {
    const cleanString = value.replace('R$', '').replace(',', '.').replace('%', '').replaceAll(' ', '');
    const formattedValue = Number(cleanString).toLocaleString('pt-BR',
      {
        style: 'currency',
        currency: 'BRL',
      }
    );
    setFixedValue(formattedValue)
  };

  const handleFixedValue = ({target}) => {
    const {value} = target;
    const newFixedValue = value.replace('R$', '').replace(',', '.').replace('%', '').replaceAll(' ', '')
    const newObj = minimumBilling;
    newObj.fixed = newFixedValue;
    setMinimumBilling(newObj);
    setFixedValue(value);
  };

  const handleFixedValueBlur = async  ({target}) => {
    try {
      const {value} = target;
      getFormattedValue(value);
      const newFixedValue = value.replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.').replaceAll('%', '').replaceAll(' ', '');
      const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
      const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
      const cnpj = localStorage.getItem('@XPM_CLIENT_COMPANY:');
      const payload = {
        name: "fixo mensal",
        date: `${year}-${month}`,
        value: newFixedValue.trim(),
        client_cnpj: cnpj,
      }
      console.log(payload);
      if (fixedValueObject.id) {
        const response = await api.patch(`/minimum-billing/${fixedValueObject.id}`, payload);
        if (response.data) {
          setFixedValueObject(response.data);
          setFixedMonthlyValue(response.data.value);
          toast.success('Fixo mensal alterado com sucesso.', { autoClose: 2000 });
        }
      } else {
        const response = await api.post(`/minimum-billing`, payload);
        if (response.data) {
          setFixedValueObject(response.data);
          setFixedMonthlyValue(response.data.value);
          toast.success('Fixo mensal alterado com sucesso.', { autoClose: 2000 });
        }
      }
    } catch (error) {
      toast.error('Não foi possível alterar o fixo mensal', { autoClose: 2000 })
    }
  };

  const handleDelete = (type, index, id) => {
    if (type === 'existing') {
      const newArray = variables.filter((e, i) => e.id !== id);
      setVariables(newArray);
      deleteVariable(id);
    } else {
      const newArray = newVariables.filter((e, i) => i !== index);
      setNewVariables(newArray);
    }
  };

  const editVariables = () => {
    if (newVariables) {
      onClick(newVariables)
    }
    if (updatedVariables) {
      updateVariable(updatedVariables);
    }
    closeModal();
  };

  const getParamters = async () => {
    const newArray = arrayOfParams.map((e) => {
      const value = (e.overload * e.price * (e.factor || 1)) || 0;
      return { process: e.process, name: e.description, value: Number(value.toFixed(2)) }
    })
    setParams(newArray)
  };

  const getExistingFixedMonthlyValue = async () => {
    try {
      const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
      const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
      const date = `${year}-${month}`;
      const cnpj = localStorage.getItem('@XPM_CLIENT_COMPANY:');
      const response = await api.get(`/minimum-billing/${cnpj}/${date}`);
      if (response.data && response.data.id) {
        toast.success('Encontrado fixo mensal', { autoClose: 2000 });
        setFixedValueObject(response.data);
        getFormattedValue(`${response.data.value}`);
      } else {
        const payload = {
          name: 'fixo mensal',
          date: date,
          value: 0,
          client_cnpj: cnpj,
        }
        toast.error('Não encontrado fixo mensal', { autoClose: 2000 })
        setFixedValueObject(payload); 
        getFormattedValue(`${payload.value}`);    
      }
    } catch (error) {
      // console.log(error);
      toast.error('Erro ao recuperar dados de faturamento mínimo', { autoClose: 2000 })
    }
  };

  useEffect(() => {
    getParamters();
    onOpen();
    if (fixedValueObject.id === undefined) {
      getExistingFixedMonthlyValue();
    }
  }, [])

  return (
    <div className="modalEdit" style={container}>
      <div style={headerOption}>
        <div
          style={{
            display: "flex",
            gap: "27px",
            alignItems: "center",
          }}
        >
          <div
            onClick={closeModal}
            style={{
              cursor: "pointer",
            }}
          >
            <img src={closeIcon} />
          </div>
          <p className="textModalTitleOption">Mínimo mensal</p>
        </div>
        <Button
          width="100px"
          height="33px"
          background="var(--color-primary)"
          borderRadius="60px"
          marginTop="0"
          name="Salvar"
          onClick={editVariables}
          border="none"
        />
      </div>
      <div style={boxTextDelete}>
        <div style={content} className="scrollLower">
          <h3 className="textTitleInfo">Composição do faturamento mínimo mensal</h3>
          {
            <div style={{ display: "flex", gap: "20px", alignItems: 'center' }}>
              <BoxParameterSelect
                name='name'
                width={"100%"}
                onChange={(e) => handleFixedValue(e)}
                onBlur={(e) => handleFixedValueBlur(e)}
                type={"fixedMonthValue"}
                valueInput={fixedValue}
                value={fixedValue}
              />
            </div>
          }
          {
            variables  && variables.length > 0 ? (
              variables.map((variable, index) => (
                <NewVariableCard
                  index={index}
                  variable={variable}
                  variables={variables}
                  setVariables={setVariables}
                  deleteVariable={deleteVariable}
                  updateVariable={updateVariable}
                  updatedVariables={updatedVariables}
                  setUpdatedVariables={setUpdatedVariables}
                  deleteIcon={deleteIcon}
                  params={params}
                  handleChange={handleChange}
                  type={"existing"}
                  handleDelete={handleDelete}
                  key={`${variable.process}_${variable.name}_${index}`}
                />
              ))
            ) : null
          }
          {
            newVariables && newVariables.length > 0 ? (
              newVariables.map((variable, index) => (
                <NewVariableCard
                  index={index}
                  variable={variable}
                  variables={variables}
                  setVariables={setVariables}
                  deleteVariable={deleteVariable}
                  updateVariable={updateVariable}
                  updatedVariables={updatedVariables}
                  setUpdatedVariables={setUpdatedVariables}
                  deleteIcon={deleteIcon}
                  params={params}
                  handleChange={handleChange}
                  type={"new"}
                  handleDelete={handleDelete}
                  key={`${variable.process}_${variable.name}_${index}`}
                />
              ))
            ) : null
          }
        </div>
          <div
            style={{display: 'flex', gap: 10, color: '#9966CC', cursor: 'pointer', alignSelf: 'flex-start', marginTop: 15}}
            onClick={createNewVariable}
          >
            <img src={add} alt="adicionar checklist" />
            <p>Adicionar outro mínimo</p>
          </div>
        </div>
    </div>
  );
};

export default ModalMinimumBilling;
