import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { InternalDocumentsContext } from "../../../context/Quality/InternalDocumentsContext";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../../../components/Sidebar";
import HeaderManager from "../components/HeaderManager";
import ContainerContentInternalDocumentPage from "../components/ContainerContentInternalDocumentPage";

const InternalDocuments = () => {
  const navigate = useNavigate();

  const { btnSelect } = useContext(InternalDocumentsContext);

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };

  return (
    <ThowColumLayout
      page={"InternalDocuments"}
      colum1={<Sidebar page="InternalDocuments" />}
      colum2={
        <div style={container}>
          <HeaderManager name={btnSelect === "Pendências" ? "Minhas pendências" : "Documentos internos"} adm={true} />

          <ContainerContentInternalDocumentPage />
        </div>
      }
    />
  )
};

export default InternalDocuments;