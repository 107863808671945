import { InputNumber } from "primereact/inputnumber";
import { useContext, useEffect, useState } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";

const LinePercentageInput = ({ month, value, type, typeMonths }) => {
  const { setStoreAccuracyAndConsolidation, storeAccuracyAndConsolidation } =
    useContext(GraphsAndParametersContext);
  const [valueInput, setValueInput] = useState(0);

  const createLine = () => {
    const newLine = {
      month: month,
      accuracy: valueInput,
      type: type,
      typeMonths: typeMonths,
      year: localStorage.getItem("@XPM_CLIENT_YEAR:"),
    };
    setStoreAccuracyAndConsolidation([
      ...storeAccuracyAndConsolidation,
      newLine,
    ]);
  };

  const handleInputChange = (event) => {
    const newValue = Math.min(event.value, 100); // Limit to 100
    setValueInput(newValue);
  };

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  return (
    <InputNumber
      className="primereact textMonthAccuracyTitle"
      onChange={handleInputChange}
      onBlur={() => createLine()}
      value={valueInput}
      placeholder="Insira a métrica"
      suffix="%"
      decimalSeparator=","
      minFractionDigits={2}
      style={{ width: "35px", margin: "0 auto" }}
      disabled
    />
  );
};

export default LinePercentageInput;
