import React from 'react'

const ButtonIconText = ({ width, height, background, borderRadius, marginTop, name, svg, border, disabled, onClick, color, padding }) => {

	const styleButton = {
		width: width,
		height: height,
		background: background,
		borderRadius: borderRadius,
		marginTop: marginTop,
		border: border,
		cursor: "pointer",
		color: color,
		padding: padding,
		display: "flex",
		alignItems: "center",
	}

	const styleButtonDisabled = {
		width: width,
		height: height,
		background: "#CDCDCDCD",
		borderRadius: borderRadius,
		marginTop: marginTop,
		border: border,
		display: "flex",
		alignItems: "center",
	}

	const styledImg = {
		marginRight: "5px"
	}

	return (
		disabled == true ?
			(
				<button
					className="textPrimaryButton"
					disabled={disabled}
					onClick={onClick}
					style={styleButtonDisabled}
				>
					<img style={styledImg} src={svg}/>
					{name}
				</button>
			) :
			(
				<button
					className="textPrimaryButton"
					disabled={disabled}
					onClick={onClick}
					style={styleButton}
				>
					<img style={styledImg} src={svg}/>
					{name}
				</button>
			)
	)
};

export default ButtonIconText;