import React, { useState } from 'react';
import ListButtons from './ListButtons';
import Input from './Input';
import { buttonData } from './mocks/buttonData';
import trashIcon from "../../../images/svg/trash.svg"
import sheetIcon from "../../../images/svg/sheet.svg"
import plusIcon from "../../../images/svg/plus.svg"
import PeopleCard from './PeopleCard';
import ButtonIconText from './ButtonIconText';
import smallPlusIcon from "../../../images/svg/add.svg"

// Os componetes estão criados, mas falta fazer a lógica de renderização por lista criada
// A lógica de enreziação do nome no botão de acordo com o nome da lista está está feita

const ModalShippingList = ({
  closeModal,
  closeIcon,
}) => {
  const [selectedButton, setSelectedButton] = useState(1);

  const handleButtonSelection = (status) => {
    setSelectedButton(status);
  };
  const [name, setName] = useState();

  // O campo error está com a estilização da mensagem de erro configura no componente Input desse módulo
  const [nameError, setNameError] = useState();

  const stylesMenu = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #D7D7D7',
    borderRadius: '20px',
    width: '375px',
    maxHeight: '90%',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.18)'
  };

  const stylesHeaderMenu = {
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: '1px solid #D7D7D7',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 15px',
    width: '100%',
  };

  const stylesCloseBtn = {
    background: 'none',
    border: 'none',
    marginRight: '16px'
  };

  const stylesEditBtn = {
    background: '#96C',
    borderRadius: 50,
    border: 'none',
    color: '#fff',
    padding: '8px 25px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const styleTitle = {
    color: '#000000',
    fontSize: '18px',
    fontWeight: 500
  };

  const styleListButton = {
    minWidth: "auto",
    maxHeight: "45px",
    display: "flex",
    alignItems: "center",
    margin: "0",
    padding: "0",
    border: "1px solid var(--grey-0)",
    borderRadius: 60,
  }

  const inputIconBox = {
    display: "flex",
    alignItems: "center",
  }

  const customersIconBox = {
    width: "45px",
    height: "45px",
    borderRadius: "12px",
    border: "1px solid var(--grey-0)",
    marginLeft: "12px",
    background: "var(--white)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }

  return (
    <main style={stylesMenu}>
      <header style={stylesHeaderMenu}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button name="closeModalCreateNewSearch" style={stylesCloseBtn} onClick={() => closeModal(false)}>
            <img src={closeIcon} alt='close' />
          </button>
          <p style={styleTitle}>Lista de envio</p>
        </div>
        {/* <button style={stylesEditBtn} onClick={() => submit()}> */}
        <button style={stylesEditBtn} onClick={() => console.log('submit')}>
          Criar
        </button>
      </header>
      <div style={{ padding: "20px", width: "100%", overflowY: "auto" }}>
        <div style={{ paddingBottom: "10px", overflowX: "auto", marginBottom: "20px" }}>
          <div style={{
            display: "flex", alignItems: "center"
          }}>
            <ul style={styleListButton}>
              {buttonData.map((button, index) => (
                <ListButtons
                  key={index}
                  isSelected={selectedButton === index + 1}
                  onClick={() => handleButtonSelection(index + 1)}
                  text={button.name}
                  selectedColor={"var(--color-primary)"}
                  unselectedColor={"var(--white)"}
                />
              ))}
            </ul>
          </div>
        </div>
        <div style={inputIconBox}>
          <Input
            name="Nome da Lista"
            error={nameError}
            onChange={(e) => setName(e.target.value)}
            placeholder="Insira um nome"
            type={"text"}
            width="153px"
          />
          <button style={customersIconBox} onClick={() => console.log("clicou botão lixeira")}>
            <img src={trashIcon} />
          </button>
          <button style={customersIconBox} onClick={() => console.log("clicou botão folha com x")}>
            <img src={sheetIcon} />
          </button>
          <button style={customersIconBox} onClick={() => console.log("clicou botão +")}>
            <img src={plusIcon} />
          </button>
        </div>
        <div>
          <h3 style={{ fontSize: "17px", marginBottom: '20px' }}>Pessoas da lista</h3>
          <ul style={{ padding: "0" }}>
            <PeopleCard />
          </ul>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonIconText width="155px" height="45px" background={"var(--white)"}
            borderRadius="60px" marginTop="0" name={"Nova pessoa"} svg={smallPlusIcon}
            color={"var(--color-primary)"} onClick={() => console.log('Clicou no botão Nova pessoa no card')}
            border="none" />
          <ButtonIconText width="155px" height="45px" background={"var(--white)"}
            borderRadius="60px" padding="0" marginTop="0" name={"Upload de lista"} svg={sheetIcon}
            color={"var(--color-primary)"} onClick={() => console.log('Clicou no botão Nova pessoa no card')}
            border="none" />
        </div>
      </div>
    </main>
  )
}

export default ModalShippingList;
