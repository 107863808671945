import { useContext, useEffect } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";

const CardsDetailsLabeling = () => {
  const { storagesYears, getStoragesYears } = useContext(
    GraphsAndParametersContext
  );

  /* MODEL RESPONSE LIST DETAILS */

  const listLabeledUnits = [
    { month: "Jan", value: "8" },
    { month: "Jan", value: "8" },
    { month: "Fev", value: "2" },
    { month: "Mar", value: "3" },
    { month: "Abr", value: "9" },
    { month: "Mai", value: "0" },
    { month: "Jun", value: "0" },
    { month: "Jul", value: "6" },
    { month: "Ago", value: "4" },
    { month: "Set", value: "29" },
    { month: "Out", value: "6" },
    { month: "Nov", value: "4" },
    { month: "Dez", value: "5" },
  ];

  useEffect(() => {
    getStoragesYears();
  }, []);

  useEffect(() => {
    console.log(storagesYears);
  }, [storagesYears]);

  const container = {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
  };

  const containerContent = {
    width: "100%",
    padding: "20px 24px",
    backgroundColor: "#fff",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
  };

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
    whiteSpace: "nowrap",
  };

  return (
    <div style={container}>
      {/* LIST SHELVES */}
      {storagesYears?.length > 0 ? (
        storagesYears.map((elem) => {
          return (
            <>
              <div style={containerContent}>
                <div
                  className="scrollCardDetailsYear"
                  style={{
                    width: "100%",
                    overflowX: "scroll",
                    display: "flex",
                    paddingBottom: "10px",
                    position: "relative",
                  }}
                >
                  {
                    <>
                      <div
                        style={{
                          ...contentValueCard,
                          minWidth: "300px",
                          // position: "absolute",
                          // left: "35px",
                        }}
                      >
                        <p className="titleNameCompanyCard">
                          {elem?.structure}
                        </p>
                        <p className="textTitleValueCard">Descrição</p>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        {elem?.months?.map((month) => {
                          return (
                            <div style={{ ...contentValueCard, width: 90 }}>
                              <p className="titleNameCompanyCard">
                                {month?.quantity.toLocaleString()}
                              </p>
                              <p className="textTitleValueCard">
                                {listLabeledUnits[month.month].month}
                              </p>
                            </div>
                          );
                        })}

                        <div style={contentValueCard}>
                          <p className="titleNameCompanyCard">
                            {/* {sumValues(listLabeledUnits)} */}
                            {elem?.months
                              ?.reduce((acc, cur) => acc + cur.quantity, 0)
                              .toLocaleString()}
                          </p>
                          <p className="textTitleValueCard">Acumulado</p>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default CardsDetailsLabeling;
