import React, { useState, useEffect } from "react";
import moment from "moment";
import backArrow from "../../../images/svg/backArrow.svg";
import passArrow from "../../../images/svg/passArrow.svg";

const CardDateYearAlternative = ({ search }) => {
  // Verificar se já existe mês e ano setados no localStorage
  const storedYear = localStorage.getItem("@XPM_CLIENT_YEAR:");
  const storedMonth = localStorage.getItem("@XPM_CLIENT_MONTH:");

  // Configurar a data inicial com base nos valores armazenados ou usar a data atual
  const initialDate =
    storedYear && storedMonth
      ? moment(`${storedYear}-${storedMonth}`, "YYYY-MM")
      : moment();

  const [date, setDate] = useState(initialDate); // Estado para armazenar a data com Moment.js

  // Atualizar o localStorage com o mês e o ano
  useEffect(() => {
    localStorage.setItem("@XPM_CLIENT_YEAR:", date.format("YYYY"));
    localStorage.setItem("@XPM_CLIENT_MONTH:", date.format("MM"));
    search();
  }, [date]);

  // Função para avançar para o próximo mês
  const nextMonth = () => {
    setDate((prevDate) => prevDate.clone().add(1, "month"));
  };

  // Função para voltar para o mês anterior
  const prevMonth = () => {
    setDate((prevDate) => prevDate.clone().subtract(1, "month"));
  };

  // Obter o mês e o ano da data
  const currentMonth = date.format("MMMM");
  const currentYear = date.format("YYYY");

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        gap: "30px",
        width: "250px",
      }}
    >
      <img
        onClick={prevMonth}
        style={{ cursor: "pointer", position: "absolute", left: 0 }}
        src={backArrow}
      />
      <p
        style={{ fontSize: "18px" }}
        className="textNameCompanyCard"
      >{`${currentMonth} de ${currentYear}`}</p>
      <img
        onClick={nextMonth}
        style={{ cursor: "pointer", position: "absolute", right: 0 }}
        src={passArrow}
      />
    </div>
  );
};

export default CardDateYearAlternative;
