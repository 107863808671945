import React from 'react';
const ModalDelete = ({
  item,
  closeModal,
  deleteItem,
  closeIcon,
}) => {

  const stylesMenu = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #D7D7D7',
    borderRadius: '20px',
    width: '360px',
    height: '275px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.18)'
  };

  const stylesHeaderMenu = {
    alignItems: 'center',
    boxSizing: 'border-box',
    borderBottom: '1px solid #D7D7D7',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 15px',
    width: '100%',
  };

  const stylesCloseBtn = {
    background: 'none',
    border: 'none',
    marginRight: '16px'
  };

  const stylesDeleteBtn = {
    background: '#FFE1E1',
    borderRadius: '20px',
    border: 'none',
    color: '#EA0000',
    padding: '8px 25px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const stylesMessage = {
    textAlign: 'center',
    padding: '30px',
    boxSizing: 'border-box',
  };

  const styleTitle = {
    color: 'var(--black)',
    fontSize: '18px',
    fontWeight: 500
  };

  return (
    <section style={stylesMenu}>
      <header style={stylesHeaderMenu}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button name="closeDeleteMenu" style={stylesCloseBtn} onClick={() => closeModal(false)}>
            <img src={closeIcon} alt='close.png' />
          </button>
          <p style={styleTitle}>Excluir documento</p>
        </div>
        <button style={stylesDeleteBtn} onClick={() => deleteItem(item)}>
          Excluir
        </button>
      </header>
      <p style={stylesMessage}>
        Tem certeza que deseja excluir
        <p>
          <strong>
            {item.title}?
          </strong>
        </p>
      </p>
    </section>
  );
};

export default ModalDelete;
