import React, { useContext, useState } from "react";
import ScheduleForm from "./ScheduleForm";
// import mockChecklists from "./mocks/mockChecklists";
// import { v4 as uuidv4 } from "uuid"; // usado para simular post de um novo local
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

const ModalNewSchedule = ({
    schedules,
    setSchedules,
    branches,
    currentBranch,
    setCurrentBranch,
    closeModal,
    closeIcon,
    places,
    typeForm,
}) => {
    const { createSchedule } = useContext(SchedulesContext);
    const [menus, setMenus] = useState(["Horários", "Checklist", "Filiais"]);
    const [active, setActive] = useState("Horários");

    const stylesMenu = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        border: "1px solid #D7D7D7",
        borderRadius: "20px",
        width: "375px",
        height: "90%",
        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.18)",
    };

    const stylesHeaderMenu = {
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid #D7D7D7",
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 15px",
        width: "100%",
    };

    const stylesCloseBtn = {
        background: "none",
        border: "none",
    };

    const stylesEditBtn = {
        background: "#96C",
        borderRadius: 50,
        border: "none",
        color: "#fff",
        padding: "8px 25px",
        height: "90%",
    };

    return (
        <main style={stylesMenu}>
            <header style={stylesHeaderMenu}>
                <button
                    name="closeDeleteMenu"
                    style={stylesCloseBtn}
                    onClick={() => closeModal(false)}
                >
                    <img src={closeIcon} alt="close" />
                </button>

                <p>Configuração</p>

                <button style={stylesEditBtn} onClick={() => createSchedule()}>
                    {typeForm}
                </button>
            </header>

            <div style={{ padding: "20px 10px", width: "100%" }}>
                {menus ? (
                    <section
                        style={{
                            padding: "0px 10px",
                            overflow: "hidden",
                            overflowY: "auto",
                            width: "100%",
                            height: "100%",
                        }}
                        className="scrollLower"
                    >
                        {active === "Horários" ? (
                            <ScheduleForm
                                items={schedules}
                                setItems={setSchedules}
                                branches={branches}
                                currentBranch={currentBranch}
                                setCurrentBranch={setCurrentBranch}
                                places={places}
                                typeForm={typeForm}
                                // newSchedule={newSchedule}
                                // setNewSchedule={setNewSchedule}
                            />
                        ) : null}
                    </section>
                ) : null}
            </div>
        </main>
    );
};

export default ModalNewSchedule;
