

const CardsDetailsQuality = () => {

    
    /* MODEL RESPONSE LIST DETAILS */

    const listShippedOrders = [
        { month: "Jan", value: "8" },
        { month: "Fev", value: "2" },
        { month: "Mar", value: "3" },
        { month: "Abr", value: "9" },
        { month: "Mai", value: "0" },
        { month: "Jun", value: "0" },
        { month: "Jul", value: "6" },
        { month: "Ago", value: "4" },
        { month: "Set", value: "29" },
        { month: "Out", value: "6" },
        { month: "Nov", value: "4" },
        { month: "Dez", value: "5" },
    ];

    const listProvenClaims = [
        { month: "Jan", value: "1" },
        { month: "Fev", value: "3" },
        { month: "Mar", value: "5" },
        { month: "Abr", value: "2" },
        { month: "Mai", value: "4" },
        { month: "Jun", value: "2" },
        { month: "Jul", value: "3" },
        { month: "Ago", value: "17" },
        { month: "Set", value: "5" },
        { month: "Out", value: "4" },
        { month: "Nov", value: "2" },
        { month: "Dez", value: "1" },
    ];


    const sumValues = (dataList) => {
        let total = 0;

        dataList.forEach((item) => {
            total += Number(item?.value);
        });

        return total;
    };

    const container = {
        display: "flex",
        flexDirection: "column",
        gap: "13px"
    }

    const containerContent = {
        width: "100%",
        padding: "20px 24px",
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
        borderRadius: "10px"
    }

    const contentValueCard = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
        whiteSpace: "nowrap"
    }

    return (
        <div style={container}>

            {/* LIST SHIPPED */}
            <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute", left: "15px" }}>
                        <p className="titleNameCompanyCard">N° de NFS/Pedidos expedidos</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "250px" }}>
                        {
                            listShippedOrders.map(item => {
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{item?.value}</p>
                                        <p className="textTitleValueCard">{item?.month}</p>
                                    </div>
                                )
                            })
                        }
                        <div style={contentValueCard}>
                            <p className="titleNameCompanyCard">{sumValues(listShippedOrders)}</p>
                            <p className="textTitleValueCard">Acumulado</p>
                        </div>
                    </div>

                </div>
            </div>

            {/* LIST PROVEN CLAIMS */}
            <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute",  }}>
                        <p className="titleNameCompanyCard">N° de reclamações comprovadas</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "250px" }}>
                        {
                            listProvenClaims.map(item => {
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{item?.value}</p>
                                        <p className="textTitleValueCard">{item?.month}</p>
                                    </div>
                                )
                            })
                        }
                        <div style={contentValueCard}>
                            <p className="titleNameCompanyCard">{sumValues(listProvenClaims)}</p>
                            <p className="textTitleValueCard">Acumulado</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default CardsDetailsQuality