import React, { useEffect, useState } from "react";
import treePoints from "../../../images/svg/treePoints.svg";

const CardUser = ({
  user,
  filterOption,
  globalCheked,
  setCardSelected,
  setModalOpstions,
  setOptionModalFormClient,
  setOptionModalFormAdmin,
  setNameButtom,
  setColorButtom,
  listCardSelected,
  setListCardSelected,
}) => {
  const [checkedCard, setCheckedCard] = useState(false);

  const updateListUsers = () => {
    if (checkedCard) {
      const newArray = listCardSelected.filter((elm) => {
        return elm !== user.id;
      });
      setListCardSelected(newArray);
    } else {
      setListCardSelected([...listCardSelected, user.id]);
    }
  };

  useEffect(() => {
    setCheckedCard(globalCheked);
  }, [globalCheked]);

  useEffect(() => {
    if (checkedCard) {
      setNameButtom("Deletar usuário(s)");
      setColorButtom("red");
    }
    if (listCardSelected.length == 0) {
      setColorButtom("var(--color-primary)");
    }
  }, [checkedCard]);

  return (
    <>
      {filterOption == "Acessos admin" ? (
        <tbody style={{ position: "relative", top: "20px" }}>
          <tr style={{ position: "relative", top: "20px", border: "none" }}>
            <td style={{ borderRight: "none" }}>
              <input
                type="checkbox"
                checked={checkedCard}
                onChange={() => {
                  setCheckedCard(!checkedCard);
                  updateListUsers();
                }}
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "3px",
                }}
              />
            </td>
            <td style={{ borderRight: "none" }}>
              <div
                style={{ display: "flex", position: "relative", top: "9px" }}
              >
                {user.picture == undefined ? (
                  <div
                    style={{
                      width: "45px",
                      height: "35px",
                      position: "relative",
                      top: "-17px",
                      left: "-5px",
                      borderRadius: "100%",
                    }}
                  >
                    <img
                      src={""}
                      style={{
                        width: "35px",
                        height: "35px",
                        position: "relative",
                        top: "10px",
                        left: "0",
                        borderRadius: "100px",
                        border: "1px solid #020066",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <img
                      src={user.picture}
                      style={{
                        width: "35px",
                        height: "35px",
                        position: "relative",
                        top: "-8px",
                        left: "-5px",
                        borderRadius: "100px",
                        border: "1px solid #020066",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </div>
                )}
                <p
                  style={{
                    borderRight: "none",
                    color: "black",
                    position: "relative",
                    left: "10px",
                  }}
                >
                  {user.name}
                </p>
              </div>
            </td>
            <td style={{ borderRight: "none", color: "black" }}>
              {user.email}
            </td>
            <td style={{ borderRight: "none", color: "black" }}>
              {user.permission}
            </td>
            <td
              onClick={() => {
                setCardSelected(user);
                setModalOpstions();
                setOptionModalFormAdmin();
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={treePoints} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody style={{ position: "relative", top: "20px" }}>
          <tr style={{ position: "relative", top: "20px", border: "none" }}>
            <td style={{ borderRight: "none" }}>
              <input
                type="checkbox"
                checked={checkedCard}
                onChange={() => setCheckedCard(!checkedCard)}
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "3px",
                }}
              />
            </td>
            <td style={{ borderRight: "none" }}>
              <div
                style={{ display: "flex", position: "relative", top: "9px" }}
              >
                {user.picture == undefined ? (
                  <div
                    style={{
                      width: "45px",
                      height: "35px",
                      position: "relative",
                      top: "-17px",
                      left: "-5px",
                      borderRadius: "100%",
                    }}
                  >
                    <img
                      src={""}
                      style={{
                        width: "35px",
                        height: "35px",
                        position: "relative",
                        top: "10px",
                        left: "0",
                        borderRadius: "100px",
                        border: "1px solid #020066",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <img
                      src={user.picture}
                      style={{
                        width: "35px",
                        height: "35px",
                        position: "relative",
                        top: "-8px",
                        left: "-5px",
                        borderRadius: "100px",
                        border: "1px solid #020066",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </div>
                )}
                <p
                  style={{
                    borderRight: "none",
                    color: "black",
                    position: "relative",
                    left: "10px",
                  }}
                >
                  {user.name}
                </p>
              </div>
            </td>
            <td style={{ borderRight: "none", color: "black" }}>{user.cnpj}</td>
            <td style={{ borderRight: "none", color: "black" }}>
              {user.email}
            </td>
            <td style={{ borderRight: "none", color: "black" }}>{user.coin}</td>
            <td style={{ borderRight: "none", color: "black" }}>
              {user.billingDate}
            </td>
            <td
              onClick={() => {
                setCardSelected(user);
                setModalOpstions();
                setOptionModalFormClient();
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={treePoints} />
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};

export default CardUser;
