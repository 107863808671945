import { useEffect, useState } from "react";
import Button from "./Button";

const SelectSessionButtons = ({
  btnSelect,
  setBtnSelect,
  items,
  marginTop,
}) => {
  // const buttons = [
  //   "KPIS",
  //   "Entrada",
  //   "Saída",
  //   "Ocupação",
  //   "Rotulagem",
  //   "Qualidade",
  //   "Valor do estoque",
  // ];

  const container = {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "8px",
    marginTop: marginTop,
    padding: "6px",
    display: "flex",
    justifyContent: "center",
    border: "1px solid #d7d7d7",
    gap: "20px",
  };

  return (
    <div style={container}>
      {items.map((item) => {
        const backgroundColor =
          item == btnSelect ? "var(--color-primary)" : "#fff";
        const colorText = item == btnSelect ? "#fff" : "var(--color-primary)";

        return (
          <Button
            padding={"0 20px"}
            height="35px"
            background={backgroundColor}
            borderRadius="8px"
            marginTop="0"
            name={item}
            color={colorText}
            border="none"
            onClick={() => setBtnSelect(item)}
          />
        );
      })}
    </div>
  );
};

export default SelectSessionButtons;
