import React, { useRef, useState } from "react";
import uploadIcon from "../../../images/svg/upload.svg";

const AttachmentBoxForModal = () => {
    const fileInputRef = useRef(null);

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        console.log(files);
    };

    const handleUploadBoxClick = () => {
        fileInputRef.current.click();
    };

    const container = {
        width: "100%",
        backgroundColor: "var(--white)",
        padding: "5px 0 0 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    };

    const styleText = {
        margin: "0",
        fontSize: "13px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleUploadBox = {
        border: "3px dotted var(--grey-0)",
        borderRadius: "60px",
        width: "100%",
        height: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        cursor: "pointer",
    };

    return (
        <div style={container}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div onClick={handleUploadBoxClick} style={styleUploadBox}>
                    <img src={uploadIcon} alt="Upload" />
                    <p style={styleText}>Incluir anexos</p>
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                />
            </div>
        </div>
    );
};

export default AttachmentBoxForModal;
