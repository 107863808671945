import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ExternalDocumentsContext = createContext({});

export const ExternalDocumentsValidation = ({ children }) => {
  const navigate = useNavigate();

  const [modalFilterCardsOpen, setModalFilterCardsOpen] = useState(false);
  const [modalColumnsCardOpen, setModalColumnsCardOpen] = useState(false);
  const [modalCreateDocumentOpen, setModalCreateDocumentOpen] = useState(false);

  const handleModalClose = () => {
    setModalFilterCardsOpen(false);
    setModalColumnsCardOpen(false);
    setModalCreateDocumentOpen(false);
  };

  return (
    <>
      <ExternalDocumentsContext.Provider
        value={{
          modalFilterCardsOpen,
          setModalFilterCardsOpen,
          handleModalClose,
          modalColumnsCardOpen,
          setModalColumnsCardOpen,
          modalCreateDocumentOpen,
          setModalCreateDocumentOpen
        }}
      >
        {children}
      </ExternalDocumentsContext.Provider>
    </>
  );
};
