import React, { useState } from 'react';

const Select = ({ options, onSelect, defaultValue, label, ariaLabel, marginLeft, marginRight, marginBottom, width, height, color }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || '');

  const handleSelectChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedValue(selectedOption);
    onSelect(selectedOption);
  };

  const containerSelect = {
    display: "flex",
    flexDirection: "column",
    marginLeft: marginLeft,
    marginRight: marginRight,
    width: width,
    position: 'relative',
  }

  const styleSelect = {
    width: width != undefined ? width : "100%",
    color: color != undefined ? color : "",
    height: height != undefined ? height : "50px",
    paddingLeft: "16px",
    borderRadius: "10px",
    border: "1px solid #D7D7D7",
    marginBottom: marginBottom ? marginBottom : "1.9rem",
    outline: "none",
    cursor: "pointer", 
  }

  const styleLabel = {
    marginBottom: "14.43px",
    fontSize: "14px"
  }

  const styleOptions = {
    maxWidth: '100%'
  }

  return (
    <div style={containerSelect}>
      {label && <label style={styleLabel}>{label}</label>}
      <select
        style={styleSelect}
        value={selectedValue}
        onChange={handleSelectChange}
        aria-label={ariaLabel || label}
        
      >
        {defaultValue && (
          <option value="" disabled hidden>
            {defaultValue}
          </option>
        )}
        {options.map((option) => (
          <option style={styleOptions} key={option.id} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
