/* eslint-disable jsx-a11y/alt-text */
import checkIconOn from "../../../images/svg/checkOnIcon.svg";
import checkIconOff from "../../../images/svg/checkOffIcon.svg";
import Button from "./Button";
import { useContext, useState } from "react";
import { useEffect } from "react";
import CardComments from "./CardComments";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DashContext } from "../../../context/Dashboard/DashContext";

const CardDetailsContent = ({
  marginLeft,
  onClick,
  item,
  onlyOne,
  percentage,
  hasContract,
  title,
}) => {
  const { checkBilling, setAwaitingPayment, paymentConfirmed } =
    useContext(DashContext);
  const [check, setCheck] = useState(false);
  const [commentsVisible, setCommentsVisible] = useState(false);

  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);

  const [UOM, setUOM] = useState(0);
  const [description, setDescription] = useState("");
  const [priceUnit, setPriceUnit] = useState(0);
  const [factor, setFactor] = useState(1);

  const [contractQuantity, setContractQuantity] = useState(0);

  const [btnIsActive, setBtnIsActive] = useState(true);

  const getReceipts = async () => {
    if (item) {
      setQuantity(item.qtd_item.toLocaleString("pt-BR", {
        maximumFractionDigits: 2
      }));
      const totalForOthers = (item.qtd_item - (item.qtd_contract || 0)) * item.price * (item.factor || 1);

      const totalForSafe = (item.qtd_item - (item.qtd_contract || 0)) * (item.price / 100)
      setTotal(
        percentage ? (
            totalForSafe > 0 ? totalForSafe : 0
          ) :
          (
            totalForOthers > 0 ? totalForOthers : 0
          )
      );
      setUOM(item.UOM);
      setPriceUnit(
        percentage
          ? `${item.price}%`
          : item?.price?.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
      );
      setDescription(item.description);
      setContractQuantity(item.qtd_contract || 0);
      setFactor(item.factor || 1);
    } else {
      setQuantity(0);
      setTotal(0);
      setUOM(0);
      setPriceUnit(0);
      setDescription("Selecione um parâmetro");
    }
    setBtnIsActive(true);
  };

  const navigationDetailsPage = () => {
    localStorage.setItem("@XPM_CLIENT_PAGEMENT_DETAILS_ID:", item.id);
    onClick(quantity);
    // console.log(quantity);
  };

  const letterLimit = (text, limit, threePoints) => {
    let description = [];

    if (threePoints) {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 1) {
          description = `${description.join("")}...`;
        }
      });
    } else {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 2) {
          description = `${description.join("")}...`;
        }
      });
    }

    return description;
  };

  const onCheckBillingFunction = () => {
    checkBilling(total, !check);
    setCheck(!check);
  };

  const calcTotal = () => {
    let formattedQuantity = 0;
    if (item.qtd_item > 0) {
     formattedQuantity = item.qtd_item;
    }
    const diff = formattedQuantity - contractQuantity;
    if (diff > 0) {
      let result = (diff * item.price * (item.factor || 1));
      result = percentage ? result / 100 : result
      result = result.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        maximumFractionDigits: 2,
      });
      return ({ diference: diff, total: result})
    }
    return ({ diference: 0, total: 'R$ 0,00'});
  };

  useEffect(() => {
    setAwaitingPayment(0);
  }, []);

  useEffect(() => {
    getReceipts();
  }, [item]);

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  };
  return (
    <div
      style={{
        border: "1px solid #d7d7d7",
        borderRadius: "10px",
        padding: "17px 23px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        width: "100%",
        // justifyContent: "space-between"
        // marginLeft: 'auto',
        // position: "relative",
      }}
    >
      <div
        className="card-details-check"
        onClick={() => onCheckBillingFunction()}
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "150px",
          cursor: "pointer",
        }}
      >
        <img
          style={{
            marginRight: "10px",
          }}
          src={
            paymentConfirmed ? checkIconOn : check ? checkIconOn : checkIconOff
          }
        />

        <p
          data-tooltip-place="top"
          data-tooltip-position-strategy="fixed"
          data-tooltip-id="description"
          data-tooltip-content={description}
          className="textContentDetailsInvoicing"
        >
          {letterLimit(description, 11, true)}
          <ReactTooltip id="description" />
        </p>
      </div>

      <div
        style={{
          borderLeft: "1px solid #79797970",
          // marginLeft: marginLeft,
          paddingLeft: "15px",
          display: "flex",
          gap: "15px",
          textAlign: "center",
          justifyContent: "space-between",
          grow: 1,
          width: "100%",
        }}
      >
        <div style={contentValueCard}>
          <p className="titleNameCompanyCard">{quantity}</p>
          <p className="textTitleValueCard">
            {onlyOne ? "N° de estruturas" : "Quantidade"}
          </p>
        </div>

        {
          hasContract ? (
            <div style={contentValueCard}>
              <p className="titleNameCompanyCard">{contractQuantity}</p>
              <p className="textTitleValueCard">Contrato</p>
            </div>
          ) : null
        }

        {!onlyOne && title !== 'Generalidades' && title !== 'Seguro de Armazenagem' && hasContract ? (
            <div style={contentValueCard}>
              <p className="titleNameCompanyCard">{calcTotal().diference === 0 ? 0 : calcTotal().diference.toFixed(2)}</p>
              <p className="textTitleValueCard">Excedente</p>
            </div>
          ) : null
        }

        <div style={{ ...contentValueCard, width: "max-content" }}>
          <p className="titleNameCompanyCard">{UOM}</p>
          <p className="textTitleValueCard">UOM</p>
        </div>

        {!onlyOne && (
          <div style={contentValueCard}>
            <p className="titleNameCompanyCard">{priceUnit}</p>
            <p className="textTitleValueCard">Preço unitário</p>
          </div>
        )}

        {
          factor && factor !== 1 && (
            <div style={contentValueCard}>
              <p className="titleNameCompanyCard">{Number(factor).toFixed(4)}</p>
              <p className="textTitleValueCard">Fator cubagem</p>
            </div>
          )
        }

        <div style={contentValueCard}>
          <p className="titleNameCompanyCard">
            {
              hasContract ? calcTotal().total : (total?.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }) || 'R$ 0,00')
            }
          </p>
          <p className="textTitleValueCard">Total geral</p>
        </div>

        <div style={{ ...contentValueCard, position: "relative" }}>
          {commentsVisible && (
            <>
              <div
                onClick={() => setCommentsVisible(false)}
                style={{
                  position: "fixed",
                  width: "100vw",
                  height: "100vh",
                  top: 0,
                  left: 0,
                  zIndex: 2,
                }}
              ></div>
              <CardComments
                id={item.id}
                closeCard={() => setCommentsVisible(!commentsVisible)}
              />
            </>
          )}
          <p
            onClick={() => setCommentsVisible(!commentsVisible)}
            className="titleNameCompanyCardComments"
          >
            Comentarios
          </p>
          <p className="textTitleValueCard">Comentario</p>
        </div>

        <div
          style={{
            // position: "absolute",
            // right: "20px",
            // top: "20px",
            marginLeft: 'auto',
            grow: 1,
            // marginRight: '0px'
          }}
        >
          <Button
            width="144px"
            height="37px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Detalhes"
            border="none"
            onClick={navigationDetailsPage}
          />
        </div>
      </div>
    </div>
  );
};

export default CardDetailsContent;
