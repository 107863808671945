import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderManager from "../../AccessManager/components/HeaderManager";
import ContainerCardsSettings from "../components/ContainerCardsSettings";
import Sidebar from "../../../components/Sidebar";

const Settings = () => {
  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };

  return (
    <ThowColumLayout
      page={"Company"}
      colum1={<Sidebar page="Setting" />}
      colum2={
        <div style={container}>
          <HeaderManager name="Configurações" adm={true} />

          <ContainerCardsSettings />
        </div>
      }
    ></ThowColumLayout>
  );
};

export default Settings;
