import Button from "./Button"
import InputSearch from "./InputSearch"
import customersIcon from "../../../images/svg/customers.svg"

const ContainerInputSearchAndButton = ({ widthInput, heightInput, setModalCreateNewSearchOpen, setModalShippingListOpen }) => {

    const container = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }

    const inputIconBox = {
        display: "flex",
        alignItems: "center",
    }

    const customersIconBox = {
        width: "45px",
        height: "45px",
        borderRadius: "12px",
        border: "1px solid var(--grey-0)",
        marginLeft: "12px",
        background: "var(--white)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }

    return (
        <div className="containerBox" style={container}>
            <div style={inputIconBox}>
                <InputSearch width={widthInput} height={heightInput} />
                <button style={customersIconBox} onClick={() => setModalShippingListOpen(true)}>
                    <img src={customersIcon} />
                </button>
            </div>
            <Button width="195px" height="45px" background={"var(--color-primary)"}
                borderRadius="60px" marginTop="0" name="Nova pesquisa"
                color={"var(--white)"} onClick={() => setModalCreateNewSearchOpen(true)}
                border="none" />
        </div>
    )
}

export default ContainerInputSearchAndButton;