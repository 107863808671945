

const CardsDetailsStockValue = ({data}) => {

    let total = 0
    /* MODEL RESPONSE LIST DETAILS */

    // const listStockValue = [
    //     { month: "Jan", value: "8000" },
    //     { month: "Fev", value: "18544" },
    //     { month: "Mar", value: "10564" },
    //     { month: "Abr", value: "9654" },
    //     { month: "Mai", value: "6843" },
    //     { month: "Jun", value: "16596" },
    //     { month: "Jul", value: "18664" },
    //     { month: "Ago", value: "4654" },
    //     { month: "Set", value: "2958" },
    //     { month: "Out", value: "6461" },
    //     { month: "Nov", value: "15585" },
    //     { month: "Dez", value: "5651" },
    // ];




    const container = {
        display: "flex",
        flexDirection: "column",
        gap: "13px"
    }

    const containerContent = {
        width: "100%",
        padding: "20px 24px",
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
        borderRadius: "10px"
    }

    const contentValueCard = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
        whiteSpace: "nowrap"
    }

    return (
        <div style={container}>

            {/* LIST STOCK VALUE */}
            <div style={containerContent}>
                <div className="scrollCardDetailsYear" style={{ width: "100%", overflowX: "scroll", display: "flex", paddingBottom: "10px", position: "relative" }}>

                    <div style={{ ...contentValueCard, position: "absolute", left: "65px" }}>
                        <p className="titleNameCompanyCard">Valor do estoque</p>
                        <p className="textTitleValueCard">Descrição</p>
                    </div>

                    <div style={{ display: "flex", gap: "40px", marginLeft: "250px" }}>
                    {
                            data.map(item => {
                                let sum = 0
                                item?.value.map(elem => sum += elem.qtd_item)
                                total += sum    
                                return (
                                    <div style={contentValueCard}>
                                        <p className="titleNameCompanyCard">{(sum).toLocaleString("pt-BR")}</p>
                                        <p className="textTitleValueCard">{item?.month}</p>
                                    </div>
                                )
                            })
                        }

                        <div style={contentValueCard}>
                            <p className="titleNameCompanyCard">{(total).toLocaleString("pt-BR")}</p>
                            <p className="textTitleValueCard">Acumulado</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default CardsDetailsStockValue