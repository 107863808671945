import { useContext, useEffect, useState } from "react";
import CardMonth from "./CardMonth";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";

const ContainerAveragingMonth = ({ title, listMonths, metrics }) => {
  const { generalKpisYear } = useContext(GraphsAndParametersContext);

  const [months, setMonths] = useState([
    { month: "Janeiro", average: 0 },
    { month: "Fevereiro", average: 0 },
    { month: "Março", average: 0 },
    { month: "Abril", average: 0 },
    { month: "Maio", average: 0 },
    { month: "Junho", average: 0 },
    { month: "Julho", average: 0 },
    { month: "Agosto", average: 0 },
    { month: "Setembro", average: 0 },
    { month: "Outubro", average: 0 },
    { month: "Novembro", average: 0 },
    { month: "Dezembro", average: 0 },
  ]);

  useEffect(() => {
    if (listMonths.length > 0) {
      const listAverage = listMonths.map((elem, i) => {
        const formactPercentage = `${elem.toFixed(3).split(".")[0]}.${elem
          .toFixed(3)
          .split(".")[1]
          .split("")
          .splice(0, 2)
          .join("")}`;
        return { month: months[i].month, average: formactPercentage };
      });
      setMonths(listAverage);
    }
  }, [listMonths]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        marginTop: "30px",
      }}
    >
      <h3 className="textPerformaceIncators">
        Indicadores de performance: {title}
      </h3>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {months.map((elem) => {
          return (
            <CardMonth
              name={elem.month}
              average={elem.average}
              metrics={metrics}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ContainerAveragingMonth;
