import React, { useRef, useState } from "react";

const PendingCard = ({ pending, cardId, openModal }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const containerRef = useRef(null);

    const styleContainerHover = {
        cursor: "pointer",
        borderColor: isHovered ? "var(--color-primary)" : "var(--grey-0)",
    };

    const container = {
        width: "999px",
        height: "73px",
        borderRadius: "10px",
        border: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "0.8s",
        position: "relative",
        ...styleContainerHover
    };

    const styleIndicatorBox = {
        gap: "10px",
        display: "flex",
        alignItems: "center"
    };

    const styleValue = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        weight: 400,
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setShowTooltip(false);
    };

    const handleMouseMove = (e) => {
        if (isHovered) {
            const rect = containerRef.current.getBoundingClientRect();
            setTooltipPosition({ x: e.clientX - rect.left + 12, y: e.clientY - rect.top });
        }
    };

    const getIconForStatus = (status) => {
        switch (status) {
            case 'onTime':
                return require("../../../images/svg/onTime.svg").default;
            case 'runningOutOfDeadline':
                return require("../../../images/svg/runningOutOfDeadline.svg").default;
            case 'late':
                return require("../../../images/svg/late.svg").default;
            default:
                return require("../../../images/svg/iconeParaValorProvisorioTrocarOnome.svg").default;
        }
    };

    const iconSrc = getIconForStatus(pending.status);

    return (
        <div style={container} ref={containerRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} onClick={() => openModal(cardId)}>
            <div style={{ ...styleIndicatorBox, width: "105px", display: "flex", justifyContent: "center" }}>
                <img src={iconSrc} />
            </div>
            <div style={{ ...styleIndicatorBox, width: "152px" }}>
                <p style={styleValue}>{pending.pending}</p>
            </div>
            <div style={{ ...styleIndicatorBox, width: "188px" }}>
                <p style={styleValue}>{pending.document}</p>
            </div>
            <div style={{ ...styleIndicatorBox, width: "171px" }}>
                <p style={styleValue}>{pending.process}</p>
            </div>
            <div style={{ ...styleIndicatorBox, width: "256px" }}>
                <p style={styleValue}>{pending.responsible}</p>
            </div>
            <div style={{ ...styleIndicatorBox, width: "72px" }}>
                <p style={styleValue}>{pending.time}</p>
            </div>
            {showTooltip && (
                <div
                    style={{
                        position: "absolute",
                        top: tooltipPosition.y + "px",
                        left: tooltipPosition.x + "px",
                        padding: "5px 10px",
                        background: "var(--color-primary)",
                        color: "var(--white)",
                        borderRadius: "60px",
                        fontSize: "10px"
                    }}
                >
                    Exibir detalhes
                </div>
            )}
        </div>

    );
};

export default PendingCard;