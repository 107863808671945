export const buttonData = [
    {
        id: "1",
        name: "Clientes filial 1",
        people: [
            {
                name: "Nome do cliente 1",
                email: "emailcliente1@mail.com",
                phone: "11 90000-0000"
            },
            {
                name: "Nome do cliente 2",
                email: "emailcliente2@mail.com",
                phone: "11 90000-0000"
            },
        ]
    },
    {
        id: "2",
        name: "Clientes filial 2",
        people: [
            {
                name: "Nome do cliente 1",
                email: "emailcliente1@mail.com",
                phone: "11 90000-0000"
            },
            {
                name: "Nome do cliente 2",
                email: "emailcliente2@mail.com",
                phone: "11 90000-0000"
            },
        ]
    },
    {
        id: "3",
        name: "Clientes filial 3",
        people: [
            {
                name: "Nome do cliente 1",
                email: "emailcliente1@mail.com",
                phone: "11 90000-0000"
            },
            {
                name: "Nome do cliente 2",
                email: "emailcliente2@mail.com",
                phone: "11 90000-0000"
            },
        ]
    },
    {
        id: "4",
        name: "Clientes filial 4",
                people: [
            {
                name: "Nome do cliente 1",
                email: "emailcliente1@mail.com",
                phone: "11 90000-0000"
            },
            {
                name: "Nome do cliente 2",
                email: "emailcliente2@mail.com",
                phone: "11 90000-0000"
            },
        ]
    },
];