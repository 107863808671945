

const ArrowIcon = ({ onClick, position }) => {

    return (
        <div onClick={onClick} style={{
            color: "var(--color-primary)",
            border: "2px solid var(--color-primary)",
            borderRadius: "60px",
            minHeight: "22px",
            minWidth: "27px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: "#fff",
            marginRight: "31px"
        }}>
            <div style={{
                 transform: position ? "rotate(90deg)" : "rotate(270deg)",
                 marginLeft: !position && "1.7px",
                 marginRight: position && "1.7px"
            }}>
                {"<"}
            </div>

        </div>

    )
}

export default ArrowIcon