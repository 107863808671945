const XLSX = require("xlsx");

function createExcelFileHeader(
  data,
  sheetName,
  fileName,
  header
) {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data, { header });

  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

  XLSX.writeFile(workbook, fileName);
}

export default createExcelFileHeader;
