import React, { useContext } from "react";
import InputSearch from "./InputSearch"
import filterIcon from "../../../images/svg/filter.svg"
import { ExternalDocumentsContext } from "../../../context/Quality/ExternalDocumentsContext";

const ContainerControllerExternalDocuments = () => {

    const { setModalFilterCardsOpen } = useContext(ExternalDocumentsContext);

    const container = {
        display: "flex",
        alignItems: "center",
    };

    const inputIconBox = {
        display: "flex",
        alignItems: "center",
    };

    const customersIconBox = {
        width: "45px",
        height: "45px",
        borderRadius: "12px",
        border: "1px solid var(--grey-0)",
        marginLeft: "12px",
        background: "var(--white)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div className="containerBox" style={container}>
            <div style={inputIconBox}>
                <InputSearch width="294px" height="45px" />
                <button style={customersIconBox} onClick={() => setModalFilterCardsOpen(true)}>
                    <img src={filterIcon} />
                </button>
            </div>
        </div>
    );
};

export default ContainerControllerExternalDocuments;