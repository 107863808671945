import React, { useContext } from "react";
import InputSearch from "./InputSearch"
import filterIcon from "../../../images/svg/filter.svg"
import SelectSessionButtons from "./SelectSessionButtons"
import { InternalDocumentsContext } from "../../../context/Quality/InternalDocumentsContext";

const ContainerControllerInternalDocuments = ({ widthInput, heightInput }) => {

    const { btnSelect, setBtnSelect, listOption, setModalFilterCardsOpen } = useContext(InternalDocumentsContext);

    const container = {
        display: "flex",
        alignItems: "center",
        marginTop: "20px"
    };

    const inputIconBox = {
        display: "flex",
        alignItems: "center",
    };

    const customersIconBox = {
        width: "45px",
        height: "45px",
        borderRadius: "12px",
        border: "1px solid var(--grey-0)",
        marginLeft: "12px",
        background: "var(--white)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div className="containerBox" style={container}>
            <div style={inputIconBox}>
                <InputSearch width={widthInput} height={heightInput} />
                <button style={customersIconBox} onClick={() => setModalFilterCardsOpen(true)}>
                    <img src={filterIcon} />
                </button>
            </div>
            <SelectSessionButtons
                setBtnSelect={setBtnSelect}
                btnSelect={btnSelect}
                buttons={listOption}
                width={"282px"}
                marginLeft={"12px"}
            />
        </div>
    );
};

export default ContainerControllerInternalDocuments;