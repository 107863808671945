import React from "react";
import { useNavigate } from "react-router-dom";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderManager from "..//components/HeaderManager";
import Sidebar from "../../../components/Sidebar";
import ContainerContentExternalDocumentPage from "../components/ContainerContentExternalDocumentPage";

const ExternalDocuments = () => {
  const navigate = useNavigate();

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };
  return (
    <ThowColumLayout
      page={"ExternalDocuments"}
      colum1={<Sidebar page="ExternalDocuments" />}
      colum2={
        <div style={container}>
          <HeaderManager name="Documentos externos" adm={true} />

          <div style={{ margin: "0 auto", marginTop: "42px" }} className="containerBox">
            <ContainerContentExternalDocumentPage />
          </div>

        </div>
      }
    />
  )
};

export default ExternalDocuments;