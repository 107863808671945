import React, { useState } from 'react';

const Switch = ({ onToggle }) => {
    const [isOn, setOn] = useState(false);

    const switchStyles = {
        width: '35px',
        height: '16px',
        backgroundColor: isOn ? 'var(--color-primary)' : '#CDCDCD',
        borderRadius: '60px',
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
    };

    const sliderStyles = {
        width: '12px',
        height: '12px',
        backgroundColor: 'var(--white)',
        borderRadius: '50%',
        position: 'absolute',
        top: '2px',
        left: '2px',
        transition: 'transform 0.3s',
        transform: isOn ? 'translateX(19px)' : 'translateX(0)',
    };

    const handleToggle = () => {
        const newState = !isOn;
        setOn(newState);
        onToggle(newState);
    };

    return (
        <div style={switchStyles} className={`switch ${isOn ? 'on' : ''}`} onClick={handleToggle}>
            <div style={sliderStyles} className={`slider ${isOn ? 'on' : ''}`} />
        </div>
    );
};

export default Switch;
