import { useEffect, useState } from "react";
import CardDateYear from "./CardDateYear";
import moment from "moment";
import updateIconImg from "../../../images/png/atualizar.png";

const CardYear = ({
  dateMoment,
  setDateMoment,
  updateIcon,
  updateFunction,
}) => {
  const [dateNotFormact, setDateNotFormact] = useState(moment());

  const addMonth = () => {
    const newValue = moment(dateNotFormact).add(1, "year");
    localStorage.setItem("@XPM_CLIENT_YEAR:", newValue.format("YYYY"));
    setDateNotFormact(moment(dateNotFormact).add(1, "year"));
    setDateMoment(newValue.format("YYYY"));
  };

  const backMonth = () => {
    const newValue = moment(dateNotFormact).add(-1, "year");
    localStorage.setItem("@XPM_CLIENT_YEAR:", newValue.format("YYYY"));
    setDateNotFormact(moment(dateNotFormact).add(-1, "year"));
    setDateMoment(newValue.format("YYYY"));
  };

  useEffect(() => {
    localStorage.setItem("@XPM_CLIENT_YEAR:", moment().year());
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
      <CardDateYear
        isActive={true}
        fontSize={"18px"}
        width={"200px"}
        add={() => addMonth()}
        back={() => backMonth()}
        date={`Ano de ${dateMoment}`}
      />
      {updateIcon ? (
        <img
          src={updateIconImg}
          style={{ cursor: "pointer", width: "28px" }}
          className="hoverRotation"
          onClick={() => updateFunction()}
        />
      ) : null}
    </div>
  );
};

export default CardYear;
