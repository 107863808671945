import React, { useEffect, useState } from "react";
import Textarea from "./Textarea";

const arrowSvg = `
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.274428 1.61166C0.109402 1.37389 0.109402 1.05863 0.274428 0.820855C0.550264 0.423421 1.13801 0.423421 1.41385 0.820855L2.57301 2.49102C3.41008 3.69709 5.19369 3.69709 6.03076 2.49102L7.18993 0.820855C7.46576 0.423421 8.05351 0.423421 8.32934 0.820855C8.49437 1.05863 8.49437 1.37389 8.32934 1.61166L7.20524 3.23131C5.79952 5.25671 2.80425 5.25671 1.39854 3.23131L0.274428 1.61166Z" fill="black"/>
  </svg>
`;

const arrowSvgUri = encodeURIComponent(arrowSvg);

const BoxParameterSelect = ({
  number,
  type,
  width,
  onChange,
  value,
  valueInput,
  onBlur,
  height,
  options,
  listValues,
  update,
  percentage,
  coin,
  gap,
}) => {
  const [valueInputState, setValueInputState] = useState("");
  const [optionSelect, setOptionSelect] = useState("");
  const [typeStructureAll, setTypeStructureAll] = useState([]);

  const [typeCategoryAll, setTypeCategoryAll] = useState([]);
  const [typeNumber, setTypeNumber] = useState(true);

  /* STYLES */
  const boxCard = {
    display: "flex",
    gap: "18px",
    alignItems: "end",
  };

  const numberBox = {
    backgroundColor: "#96C",
    color: "#fff",
    fontSize: "19px",
    padding: "6px 16px",
    borderRadius: "60px",
    marginBottom: "5px",
  };

  const selectBox = {
    outline: "none",
    width: width || "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "12px 13px",
    appearance: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px center",
    paddingRight: "30px",
  };

  const inputBox = {
    outline: "none",
    width: width || "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "12px 13px",
  };

  const verifyNameType = () => {
    if (type === "process") {
      return "Processo";
    } else if (type === "description") {
      return "Descrição da atividade";
    } else if (type === "unit") {
      return "Unidade de medida";
    } else if (type === "price") {
      return `Preço ${value || ""}`;
    } else if (type === "titleGenerality") {
      return "Tipo de generalidade";
    } else if (type === "dateGenerality") {
      return "Data";
    } else if (type === "qtdGenerality") {
      return "Quantidade";
    } else if (type === "typeStructure") {
      return "Tipo de estrutura";
    } else if (type === "typeCategory") {
      return "Tipo de categoria";
    } else if (type === "justification") {
      return "Justificativa";
    } else if (type === "typeCalculation") {
      return "Tipo de calculo";
    } else if (type === "conversion") {
      return "Conversão (Em desenvolvimento)";
    } else if (type === "code") {
      return "Código SKU";
    } else if (type === "qtdBox") {
      return "QTD por caixa";
    } else if (type === "time") {
      return "Tempo";
    } else if (type === "kpi") {
      return "KPI exigido";
    }
  };

  const formatCoin = (typeCoin, valueCoin, setValueCoin) => {
    if (percentage) {
      const number = Number(valueCoin);
      setValueCoin(`${number}%`);
    } else if (coin === "real" || typeCoin === "real") {
      const number = Number(valueCoin);
      setValueCoin(
        number.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
    }
  };

  useEffect(() => {
    setTypeCategoryAll(listValues);
    setTypeStructureAll(listValues);
  }, [listValues]);

  // useEffect(() => {
  //   getStructure();
  // }, []);

  // useEffect(() => {
  //   getStructure();
  // }, []);

  // useEffect(() => {
  //   getCategory();
  // }, [update]);

  useEffect(() => {
    verifyNameType(value);
  }, [value]);

  useEffect(() => {
    setOptionSelect(options);
  }, [options]);

  useEffect(() => {
    setValueInputState(valueInput);
  }, [valueInput]);

  return (
    <div style={boxCard}>
      {number && <div style={numberBox}>{number}</div>}
      {gap && <div style={{ marginLeft: "39px ", backgroundColor: "" }}></div>}
      <div style={{ display: "flex", gap: "13px", flexDirection: "column" }}>
        {<p className="textTitleParms">{verifyNameType()}</p>}

        {type !== "description" &&
          type !== "price" &&
          type !== "price" &&
          type !== "dateGenerality" &&
          type !== "qtdGenerality" &&
          type !== "code" &&
          type !== "qtdBox" &&
          type !== "kpi" &&
          type !== "conversion" && (
            <select
              defaultValue={valueInput}
              onBlur={onBlur}
              onChange={onChange}
              style={{
                ...selectBox,
                backgroundImage: `url('data:image/svg+xml;charset=utf-8,${arrowSvgUri}')`,
              }}
            >
              {type === "process" && (
                <>
                  <option value="">Selecione</option>
                  <option value="Recebimento">Recebimento</option>
                  <option value="Expedição">Expedição</option>
                  <option value="Armazenagem">Armazenagem</option>
                  <option value="Inventário">Inventário</option>
                  <option value="Seguro de Armazenagem">
                    Seguro de Armazenagem
                  </option>
                </>
              )}
              {type === "time" && (
                <>
                  <option value="">Selecione</option>
                  <option value="D+ 1 dias úteis">D+ 1 dias úteis</option>
                  <option value="D+ 2 dias úteis">D+ 2 dias úteis</option>
                  <option value="D+ 3 dias úteis">D+ 3 dias úteis</option>
                  <option value="D+ 4 dias úteis">D+ 4 dias úteis</option>
                  <option value="D+ 5 dias úteis">D+ 5 dias úteis</option>
                  <option value="D+ 6 dias úteis">D+ 6 dias úteis</option>
                  <option value="D+ 7 dias úteis">D+ 7 dias úteis</option>
                  <option value="D+ 8 dias úteis">D+ 8 dias úteis</option>
                  <option value="D+ 9 dias úteis">D+ 9 dias úteis</option>
                  <option value="D+ 10 dias úteis">D+ 10 dias úteis</option>
                </>
              )}
              {type === "unit" && optionSelect === "Armazenagem" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="qtd_palete_fisico - sum: Palete fisico">
                    Palete fisico
                  </option>
                  <option value="qtd_enderecos - sum linha: Endereços">
                    Endereços
                  </option>
                  <option value="qtd_saldo - sum: Saldo">Saldo</option>
                </>
              )}
              {type === "unit" && optionSelect === "Recebimento" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_item - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                  <option value="qtd_palete_fisico - sum: Pallet">
                    Pallet
                  </option>
                  <option value="seq_aviso_recebto - sum: Linhas">
                    Linhas
                  </option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                </>
              )}
              {type === "unit" && optionSelect === "Expedição" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_itens - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                  <option value="trans_solicitacao - sum linhas: Notas Fiscais Expedidas">
                    Notas Fiscais Expedidas
                  </option>
                  <option value="qtd_palete_fisico - sum: Pallet">
                    Pallet
                  </option>
                  <option value="qtd_sequencias - sum: Linhas">Linhas</option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                </>
              )}
              {type === "unit" && optionSelect === "Seguro de Armazenagem" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_itens - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                </>
              )}
              {type === "titleGenerality" && (
                <>
                  <option value="">Selecione</option>
                  <option value="Recebimento">
                    Hora extra/homem - Dias úteis
                  </option>
                </>
              )}
              {type === "typeCalculation" && (
                <>
                  <option value={valueInput}>{valueInput}</option>
                  <option value="">Selecione</option>
                  <option value="Soma">Soma</option>
                  {options !== "Recebimento" && (
                    <option value="Pico">Pico</option>
                  )}
                </>
              )}
              {type === "typeStructure" &&
                typeStructureAll.map((item, i) => {
                  if (
                    i == 0 &&
                    options !== "Recebimento" &&
                    i == 0 &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={valueInput}>{valueInput}</option>
                        <option value="default">Selecione</option>;
                        <option value={item.des_tip_estrutura}>
                          {item.des_tip_estrutura}
                        </option>
                        ;
                      </>
                    );
                  } else if (
                    options !== "Recebimento" &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={item.des_tip_estrutura}>
                          {item.des_tip_estrutura}
                        </option>
                      </>
                    );
                  } else if (
                    (i == 0 && options === "Recebimento") ||
                    (i == 0 && options === "Expedição")
                  ) {
                    return <option value="default">Não se aplica</option>;
                  }
                })}
              {type === "typeCategory" && typeCategoryAll?.length ? (
                typeCategoryAll.map((item, i) => {
                  if (i == 0) {
                    return (
                      <>
                        <option value={valueInput}>{valueInput}</option>
                        <option value="default">Selecione</option>;
                        <option value={item.des_categoria}>
                          {item.des_categoria}
                        </option>
                        ;
                      </>
                    );
                  }
                  return (
                    <option value={item.des_categoria}>
                      {item.des_categoria}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          )}
        {type === "description" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Descrição"
            style={inputBox}
          />
        )}
        {type === "code" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira o sku"
            style={inputBox}
          />
        )}
        {type === "kpi" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira a kpi"
            style={inputBox}
          />
        )}
        {type === "qtdBox" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira a quantidade"
            style={inputBox}
          />
        )}
        {type === "conversion" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira a conversão"
            style={inputBox}
          />
        )}
        {type === "price" && (
          <input
            onBlur={(e) => {
              setTypeNumber(false);
              onBlur(e);
              formatCoin("real", valueInput, setValueInputState);
            }}
            onChange={onChange}
            onClick={() => {
              setTypeNumber(true);
              setValueInputState(valueInput);
            }}
            value={valueInputState}
            placeholder="Preço"
            type={typeNumber ? "number" : "string"}
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "dateGenerality" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Preço"
            type="date"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "qtdGenerality" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Quantidade"
            type="number"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "justification" && (
          <Textarea
            width={width}
            height={height}
            value={valueInputState}
            placeholder="Descrição da justificativa"
            type="number"
            style={{ inputBox }}
          />
        )}
      </div>
    </div>
  );
};

export default BoxParameterSelect;
