export const pendingCardsData = [
    {
        cardId: "1",
        status: "onTime",
        pending: "Consenso",
        document: "DC0067:00 teste",
        process: "Adequação",
        responsible: "Leandro Hass Thomaz",
        time: "21/07/2023",
    },
    {
        cardId: "2",
        status: "late",
        pending: "Consenso",
        document: "DC0067:00 teste",
        process: "Adequação",
        responsible: "Leandro Hass Thomaz",
        time: "21/07/2023",
    },
    {
        cardId: "3",
        status: "runningOutOfDeadline",
        pending: "Consenso",
        document: "DC0067:00 teste",
        process: "Adequação",
        responsible: "Leandro Hass Thomaz",
        time: "21/07/2023",
    },
    {
        cardId: "4",
        status: "",
        pending: "Consenso",
        document: "DC0067:00 teste",
        process: "Adequação",
        responsible: "Leandro Hass Thomaz",
        time: "21/07/2023",
    },
];
