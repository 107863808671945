import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderManager from "../components/HeaderManager";
import ContainerInputSearchAndButton from "../components/ContainerInputSearchAndButton";
import SatisfactionSurveyCard from "../components/SatisfactionSurveyCard";
import Sidebar from "../../../components/Sidebar";
import ModalActions from "../components/ModalActions";
import ModalContainer from "../components/ModalContainer";
import closeIcon from '../../../images/svg/closeIcon.svg';
import ModalDelete from "../components/ModalDelete";
import ModalCreateNewSearch from "../components/ModalCreateNewSearch";
import ModalShippingList from "../components/ModalShippingList";
import { surveyData } from "../components/mocks/SurveyData";

const NpsResearch = () => {
    const navigate = useNavigate();

    const [selectedCard, setSelectedCard] = useState(null);
    const [modalActionsOpen, setModalActionsOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [modalCreateNewSearchOpen, setModalCreateNewSearchOpen] = useState(false);
    const [modalShippingListOpen, setModalShippingListOpen] = useState(false);

    const handleCardDeleteClick = (survey) => {
        setSelectedCard(survey);
        setModalActionsOpen(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteOpen(false);
        setModalCreateNewSearchOpen(false);
        setModalShippingListOpen(false)
    };

    const handleDeleteCard = (cardId) => {
        console.log(cardId);

        setSelectedCard(null);
        handleDeleteModalClose();
    };

    const container = {
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
        paddingBottom: "50px",
    };

    return (
        <ThowColumLayout
            page={"NpsResearch"}
            colum1={<Sidebar page="NpsResearch" />}
            colum2={
                <div style={container}>
                    <HeaderManager name="Pesquisa NPS" adm={true} />

                    <div style={{ margin: "0 auto", marginTop: "42px" }} className="containerBox">
                        <ContainerInputSearchAndButton setModalCreateNewSearchOpen={setModalCreateNewSearchOpen} setModalShippingListOpen={setModalShippingListOpen} />

                    </div>
                    <ul style={{ padding: 0, margin: '0 auto', marginTop: '40px' }} className="containerBox">
                        {surveyData.map((survey) => (
                            <SatisfactionSurveyCard
                                key={survey.id}
                                title={survey.title}
                                period={survey.period}
                                responseCount={survey.responseCount}
                                buttonName="Link da pesquisa"
                                onButtonClick={() => console.log(survey)}
                                onDelete={() => handleCardDeleteClick(survey)}
                                cardId={survey.id}
                            />
                        ))}
                    </ul>

                    {/* Ao clicar no icone que abre o modal Actions, está setando o estado com o valor do card clicado e ao 
                    clicar em Excluir pesquisa abre o modal Delete, onde é acessado o estado que guarda as informações do 
                    card selecionado para utilizar na lógica de exclusão. */}
                    <ModalContainer isActive={modalActionsOpen} closeModal={() => setModalActionsOpen(false)}>
                        <ModalActions
                            closeModal={() => setModalActionsOpen(false)}
                            setDeleteOpen={() => setDeleteOpen(true)}
                            closeIcon={closeIcon}
                        />
                    </ModalContainer>

                    <ModalContainer isActive={deleteOpen} closeModal={handleDeleteModalClose}>
                        <ModalDelete
                            item={selectedCard}
                            closeModal={handleDeleteModalClose}
                            deleteItem={() => handleDeleteCard(selectedCard)}
                            closeIcon={closeIcon}
                        />
                    </ModalContainer>

                    <ModalContainer isActive={modalCreateNewSearchOpen} closeModal={handleDeleteModalClose}>
                        <ModalCreateNewSearch
                            closeModal={handleDeleteModalClose}
                            closeIcon={closeIcon}
                        />
                    </ModalContainer>

                    <ModalContainer isActive={modalShippingListOpen} closeModal={handleDeleteModalClose}>
                        <ModalShippingList
                            closeModal={handleDeleteModalClose}
                            closeIcon={closeIcon}
                        />
                    </ModalContainer>
                </div>
            }
        />
    )
};

export default NpsResearch;