import { useEffect, useState } from "react";
import ArrowIcon from "./ArrowIcon";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const CardTableListShipping = ({
  ctrOpenAndExit,
  loadingPlanning,
  qtdSequencias,
  qtdItem,
  qtdPaleteFisico,
  qtdPaletePadrao,
  pesoBruto,
  pesoLiquido,
  expedition,
  volumeM3,
  dataComplet,
  dataInclusao,
  dataOpen,
  typeStock,
  sequencialItem,
  restriction,
  valueUnit,
  item,
  listItens,
  setListItens,
  notaFiscal,
  pedido,
}) => {
  /* STATES */
  const [arrowPosition, setArrowPosition] = useState(false);
  const [idOne, setIdOne] = useState("");
  const [idTwo, setIdTwo] = useState("");

  useEffect(() => {
    const idDiv1 = uuidv4();
    const idDiv2 = uuidv4();

    setIdOne(idDiv1);
    setIdTwo(idDiv2);

    setListItens((prevList) => [...prevList, idDiv1, idDiv2]);
  }, []);

  /* STYLES */
  const containerStyle = {
    width: "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    marginTop: "15px",
    padding: "25px",
    overflow: "hidden",
    backgroundColor: "#fff",
  };

  const headerRowStyle = {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    overflowX: "hidden",
  };

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  };

  const hiddenContentStyle = {
    display: !arrowPosition ? "none" : "block",
  };

  /* RENDER */
  return (
    <div style={containerStyle}>
      <div
        id={idOne}
        style={{
          ...headerRowStyle,
          paddingBottom: arrowPosition && "20px",
          marginBottom: arrowPosition && "20px",
          borderBottom: arrowPosition ? "1px solid #d7d7d7" : "",
        }}
      >
        <ArrowIcon
          onClick={() => setArrowPosition(!arrowPosition)}
          position={arrowPosition}
        />

        <div style={{ ...contentValueCard, minWidth: "150px" }}>
          <p className="titleNameCompanyCard">{ctrOpenAndExit}</p>
          <p className="textTitleValueCard">CTR entrada saida veiculo</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "150px" }}>
          <p className="titleNameCompanyCard">{loadingPlanning}</p>
          <p className="textTitleValueCard">Planejamento embarque</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "100px" }}>
          <p className="titleNameCompanyCard">{qtdSequencias}</p>
          <p className="textTitleValueCard">QTD Sequencias</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "73px" }}>
          <p className="titleNameCompanyCard">{qtdItem}</p>
          <p className="textTitleValueCard">QTD item</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "110px" }}>
          <p className="titleNameCompanyCard">{qtdPaleteFisico}</p>
          <p className="textTitleValueCard">QTD Palete fisico</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "110px" }}>
          <p className="titleNameCompanyCard">{qtdPaletePadrao}</p>
          <p className="textTitleValueCard">QTD Palete padrão</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "65px" }}>
          <p className="titleNameCompanyCard">{pesoBruto}</p>
          <p className="textTitleValueCard">Peso bruto</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "70px" }}>
          <p className="titleNameCompanyCard">{pesoLiquido}</p>
          <p className="textTitleValueCard">Peso liquido</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "70px" }}>
          <p className="titleNameCompanyCard">{volumeM3}</p>
          <p className="textTitleValueCard">Volume m³</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "238px" }}>
          <p className="titleNameCompanyCard">
            {" "}
            {moment.utc(dataComplet).format("DD/MM/YYYY - HH:mm")}
          </p>
          <p className="textTitleValueCard">
            Data & Hora conclução CESV Documento
          </p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "238px" }}>
          <p className="titleNameCompanyCard">
            {moment.utc(dataInclusao).format("DD/MM/YYYY - HH:mm")}
          </p>
          <p className="textTitleValueCard">Data & Hora inclusão solicitação</p>
        </div>

        <div style={{ ...contentValueCard, minWidth: "238px" }}>
          <p className="titleNameCompanyCard">
            {moment.utc(dataOpen).format("DD/MM/YYYY - HH:mm")}
          </p>
          <p className="textTitleValueCard">
            Data & Hora abertura CESV documento
          </p>
        </div>
      </div>

      <div style={hiddenContentStyle}>
        <div id={idTwo} style={headerRowStyle}>
          <div style={{ ...contentValueCard, minWidth: "120px" }}>
            <p className="titleNameCompanyCard">{sequencialItem}</p>
            <p className="textTitleValueCard">Sequencial item</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">{item}</p>
            <p className="textTitleValueCard">Item</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "97px" }}>
            <p className="titleNameCompanyCard">{typeStock}</p>
            <p className="textTitleValueCard">Tipo de estoque</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">{restriction}</p>
            <p className="textTitleValueCard">Restrição</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "90px" }}>
            <p className="titleNameCompanyCard">{qtdItem}</p>
            <p className="textTitleValueCard">QTD item</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "110px" }}>
            <p className="titleNameCompanyCard">{qtdPaleteFisico}</p>
            <p className="textTitleValueCard">QTD Palete fisico</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "110px" }}>
            <p className="titleNameCompanyCard">{qtdPaletePadrao}</p>
            <p className="textTitleValueCard">QTD Palete padrão</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "65px" }}>
            <p className="titleNameCompanyCard">{pesoBruto}</p>
            <p className="textTitleValueCard">Peso bruto</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "70px" }}>
            <p className="titleNameCompanyCard">{pesoLiquido}</p>
            <p className="textTitleValueCard">Peso liquido</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "70px" }}>
            <p className="titleNameCompanyCard">{volumeM3}</p>
            <p className="textTitleValueCard">Volume m³</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "150px" }}>
            <p className="titleNameCompanyCard">{valueUnit}</p>
            <p className="textTitleValueCard">Valor unitario item</p>
          </div>
          
          <div style={{ ...contentValueCard, minWidth: "150px" }}>
            <p className="titleNameCompanyCard">{notaFiscal}</p>
            <p className="textTitleValueCard">Nota fiscal</p>
          </div>

          <div style={{ ...contentValueCard, minWidth: "150px" }}>
            <p className="titleNameCompanyCard">{pedido}</p>
            <p className="textTitleValueCard">Pedido</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTableListShipping;
