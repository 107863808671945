import moment from "moment";
import Button from "./Button";
import { useContext, useEffect, useState } from "react";
import { ContactedContext } from "../../../context/Dashboard/ContactedContext";
import updateIconImg from "../../../images/png/atualizar.png";
import configIcon from "../../../images/png/config.png";
import ModalContainer from "./ModalContainer";
import ModalParameterContract from "./ModalParameterContract";

const ContainerContactedGraphics = () => {
  const {
    onContacted,
    typeUnit,
    setTypeUnit,
    month,
    setMonth,
    year,
    setYear,
    onBringUpdatedLogixData,
  } = useContext(ContactedContext);

  const [isModalActive, setIsModalActive] = useState(false);

  // Criando um array com os meses formatados
  const months = moment.months().map((month, index) => ({
    label: month.charAt(0).toUpperCase() + month.slice(1),
    value: index + 1,
  }));

  // Criando um array de anos
  const currentYear = moment().year();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

  const getOnContacted = () => {
    try {
      onContacted();
    } catch (error) {}
  };

  const styles = {
    container: {
      marginTop: "30px",
      width: "100%",
      backgroundColor: "#fff",
      border: "1px solid #d7d7d7",
      borderRadius: "10px",
      padding: "20px",
    },
    box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    selectBox: {
      border: "none",
      borderBottom: "1px solid rgb(169 168 168)",
      outline: "none",
    },
    labelGroup: {
      display: "flex",
      gap: "30px",
    },
  };

  useEffect(() => {
    setMonth(moment().utc().month() + 1);
    setYear(moment().utc().year());
    setTypeUnit("Recebimento");
  }, []);

  return (
    <div style={styles.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          position: "relative",
        }}
      >
        <h3 className="textContactedVSAccomplished">Paramêtros de busca</h3>
      </div>

      <div style={styles.box}>
        <div style={styles.labelGroup}>
          <label className="textTitleFilterContacted">
            Tipo de unidade
            <select
              style={styles.selectBox}
              onChange={(e) => setTypeUnit(e.target.value)}
              value={typeUnit}
            >
              <option value="Recebimento">Recebimento</option>
              <option value="Saida">Expedição</option>
              <option value="Urgente">Urgência</option>
            </select>
          </label>
          <label className="textTitleFilterContacted">
            Mês
            <select
              style={styles.selectBox}
              onChange={(e) => setMonth(e.target.value)}
              value={month}
            >
              {months.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </label>
          <label className="textTitleFilterContacted">
            Ano
            <select
              style={styles.selectBox}
              onChange={(e) => setYear(e.target.value)}
              value={year}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {/* <Button
            padding="0 20px"
            height="35px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Atualização dos dados"
            color="#fff"
            border="none"
            onClick={() => onBringUpdatedLogixData()}
          /> */}

          <Button
            padding="0 20px"
            height="35px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Buscar"
            color="#fff"
            border="none"
            onClick={() => getOnContacted()}
          />

          <ModalContainer
            isActive={isModalActive}
            closeModal={() => setIsModalActive(false)}
          >
            <ModalParameterContract
              closeModal={() => setIsModalActive(false)}
            />
          </ModalContainer>
        </div>
      </div>
    </div>
  );
};

export default ContainerContactedGraphics;
