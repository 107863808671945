import React, { useState } from "react";
import Button from "./Button";
import Switch from "./Switch";

const ResearchSummary = () => {

    const [switchValue, setSwitchValue] = useState(false);

    const styleContainer = {
        width: "384px",
        height: "421px",
        border: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        borderRadius: "10px",
    };

    const styleHeader = {
        borderBottom: "1px solid var(--grey-0)",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: "20px",
        height: "52px",
    };

    const styleTitle = {
        color: "var(--black)",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 0,
        marginTop: "6px"
    };

    const styleContentBox = {
        padding: "20px",
        height: "278px",
        overflowY: "auto", 
    };

    const styleKey = {
        fontSize: "14px",
        fontWeight: 500,
        color: "var(--grey-1)",
        lineHeight: "19px",
    };

    const styleValue = {
        fontSize: "14px",
        fontWeight: 400,
        color: "var(--black)",
        lineHeight: "21px",
        marginBottom: "15px"
    };

    const styleButtonBox = {
        borderTop: "1px solid var(--grey-0)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
    };

    return (
        <div style={styleContainer}>
            <div style={styleHeader}>
                <p style={styleTitle}>Resumo da pesquisa</p>
                <Switch onToggle={(isOn) => setSwitchValue(isOn)} />
            </div>
            <div>
                <div style={styleContentBox}>
                    <p style={styleKey}>Nome da pesquisa</p>
                    <p style={styleValue}>{"Pesquisa ambiente de trabalho"}</p>

                    <p style={styleKey}>Período da pesquisa</p>
                    <p style={styleValue}>{"27/08/2023 à 27/09/2023"}</p>

                    <p style={styleKey}>N° de respostas</p>
                    <p style={styleValue}>{"100"}</p>

                    <p style={styleKey}>Pergunta 1</p>
                    <p style={styleValue}>{"Organização e limpeza"}</p>

                    <p style={styleKey}>Pergunta 2</p>
                    <p style={styleValue}>{"Infraestrutura"}</p>
                </div>
                <div style={styleButtonBox}>
                    <Button width="100%" height="37px" background={"var(--color-primary)"}
                        borderRadius="60px" margin="0" marginBottom="0" name="Link da pesquisa"
                        color={"var(--white)"} onClick={() => console.log('Clicou em Link da pesquisa')}
                        border="none" disabled={!switchValue} />
                </div>
            </div>
        </div>
    );
};

export default ResearchSummary;