import { useEffect, useState } from "react"
import api from "../../../services/api"
import CardGraphicsLarge from "./CardGraphicsLarge"
import CardYear from "./CardYear"
import CardsDetailsLabeling from "./CardsDetailsLabeling"
import CardsDetailsStockValue from "./CardsDetailsStockValue"
import moment, { months } from "moment"


const ContainerStockValue = () => {

    const [dateMoment, setDateMoment] = useState(moment().format("YYYY"))
    const [dataClient, setDataClient] = useState([])
    const [sumTotal, setSumTotal] = useState(0)


    const [sumArray, setSumArray] = useState([])
    const [monthAll, setMonthAll] = useState([])
    const [visible, setVisible] = useState(false)

    const getReceipts = async () => {
        try {
            const client = localStorage.getItem("@XPM_CLIENT_COMPANY:")
            const response = await api.get(`/safe/date/${client}/${dateMoment}`)
            setDataClient(response.data)
        } catch (error) {

        }
    }

    const countTotal = () => {
        setVisible(false)
        const filterTotal = dataClient.map(item => {
            let sumArr = 0
            item?.value.map(elem => {
                sumArr += elem.qtd_item
            })
            return sumArr
        })
        const filterNum = dataClient.map(item => {
            let sumArr = 0
            item?.value.map(elem => {
                sumArr += elem.qtd_item
            })
            return sumArr
        })
        const filterMonth = dataClient.map(item => {

            return item?.month
        })
        // const totalQuantity = filterTotal.reduce((total, quantity) => total + quantity, 0);
        console.log(filterMonth)
        setSumTotal(Math.max(...filterTotal))
        setSumArray(filterNum)
        setMonthAll(filterMonth)
    }

    useEffect(() => {
        if (dateMoment) {
            getReceipts()
            countTotal()
        }
    }, [dateMoment])

    useEffect(() => {
        countTotal()

    }, [dataClient])

    useEffect(() => {
        if (monthAll.length > 0) {
            setVisible(true)
        }
    }, [monthAll])

    return (
        <div style={{ marginTop: "30px", display: "flex", flexDirection: "column", gap: '30px' }}>
            <CardYear
                dateMoment={dateMoment}
                setDateMoment={setDateMoment}
            />
            <CardsDetailsStockValue data={dataClient}/>

            {visible &&
                <CardGraphicsLarge max={sumTotal} title={"Valor do estoque"} listMonths={sumArray} months={monthAll} />
            }
        </div>
    )
}

export default ContainerStockValue