import React from 'react';

const NumberButtonList = ({assessmentQuestion, setAssessmentQuestion}) => {

  const handleButtonClick = (number) => {
    setAssessmentQuestion(number);
  };

  const renderButton = (number, color) => (
    <button
      key={number}
      style={{
        width: '60px',
        height: '60px',
        backgroundColor: color,
        color: 'white',
        fontSize: '25px',
        fontWeight: 500,
        border: assessmentQuestion === number ? '2px solid black' : 'none',
        borderRadius: "6px"
      }}
      onClick={() => handleButtonClick(number)}
    >
      {number}
    </button>
  );

  return (
    <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
      {[1, 2, 3, 4, 5, 6].map((number) => renderButton(number, '#FF4C54'))}
      {[7, 8].map((number) => renderButton(number, '#F9CF4A'))}
      {[9, 10].map((number) => renderButton(number, '#00B386'))}
    </div>
  );
};

export default NumberButtonList;
