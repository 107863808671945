import React, { useRef, useState } from 'react';
import TextEditor from './TextEditor';
import uploadIcon from "../../../images/svg/upload.svg";

const TextEditorBoxModal = () => {
    const [editorValue, setEditorValue] = useState()

    const fileInputRef = useRef(null);

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        console.log(files);
    };

    const handleUploadBoxClick = () => {
        fileInputRef.current.click();
    };

    const container = {
        width: "100%",
        backgroundColor: "var(--white)",
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "20px"
    };

    const styleTitle = {
        margin: "0",
        fontSize: "17px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleText = {
        margin: "0",
        fontSize: "13px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleUploadBox = {
        border: "3px dotted var(--grey-0)",
        borderRadius: "60px",
        width: "243px",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        cursor: "pointer"
    };

    return (
        <div style={container}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px", marginBottom: "20px" }}>
                <h3 style={styleTitle}>Elaborar Documento</h3>
                <div style={{paddingBottom: "20px"}}>
                    <div onClick={handleUploadBoxClick} style={styleUploadBox}>
                        <img src={uploadIcon} alt="Upload" />
                        <p style={styleText}>Fazer upload de documento</p>
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                    />
                </div>
            </div>
                <TextEditor value={editorValue} setValue={setEditorValue} />
        </div>
    );
};

export default TextEditorBoxModal;
