import React from 'react'
const OneColumnLayout = ({colum1,background}) => {

    const container = {
        "background":background,
        "display":"grid",
        "gridTemplateColumns": "1fr",
    }

    const colum1Layout = {

        gridColumnStart: '1',
        gridColumnEnd: '1',
    }
    
    return(

        <div style={container}>

        <div style={colum1Layout}>
    
        {colum1}

        </div>      
                 

        </div>

    )

}

export default OneColumnLayout
