import React from "react";
import CardAnswersAndComments from "./CardAnswersAndCommets";

const AnswersAndComments = () => {

    const styleContainer = {
        border: "1px solid var(--grey-0)",
        backgroundcolor: "var(--white)",
        borderRadius: "10px",
        width: "591px",
        heigth: "421px",
        backgroundColor: "var(--white)",
    };

    const styleHeader = {
        borderBottom: "1px solid var(--grey-0)",
        display: "flex",
        alignItems: "center",
        padding: "20px",
        height: "52px",
    };

    const styleTitle = {
        color: "var(--black)",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: 0,
        margin: 0
    };

    const styleContentBox = {
        padding: "20px",
        height: "368px",
        overflowY: "auto",
    };

    return (
        <div style={styleContainer}>
            <div style={styleHeader}>
                <h2 style={styleTitle}>Resposta & Comentários</h2>
            </div>
            <div>
                <div style={styleContentBox}>
                    {/* Cards com informações aleatórias adicionados para efeito visual */}
                    <CardAnswersAndComments
                        companyName={"Startpn tech LTDA"}
                        comment={"Acredito que a empresa está em um ótimo caminho, nada a reclamar."}
                        feedbackGeneralNumber={1}
                        question1={"Pergunta 1"}
                        answer1={"Organização e limpeza"}
                        feedbackNumberQuestion1={1}
                        question2={"Pergunta 2"}
                        answer2={"Infraestrutura"}
                        feedbackNumberQuestion2={1}
                    />
                    <CardAnswersAndComments
                        companyName={"Startpn tech LTDA"}
                        comment={"Acredito que a empresa está em um ótimo caminho, nada a reclamar."}
                        feedbackGeneralNumber={5}
                        question1={"Pergunta 1"}
                        answer1={"Organização e limpeza"}
                        feedbackNumberQuestion1={5}
                        question2={"Pergunta 2"}
                        answer2={"Infraestrutura"}
                        feedbackNumberQuestion2={5}
                    />
                    <CardAnswersAndComments
                        companyName={"Startpn tech LTDA"}
                        comment={"Acredito que a empresa está em um ótimo caminho, nada a reclamar."}
                        feedbackGeneralNumber={10}
                        question1={"Pergunta 1"}
                        answer1={"Organização e limpeza"}
                        feedbackNumberQuestion1={10}
                        question2={"Pergunta 2"}
                        answer2={"Infraestrutura"}
                        feedbackNumberQuestion2={10}
                    />
                </div>
            </div>
        </div>
    );
};

export default AnswersAndComments;