import React, { useState } from 'react';
import arrowUpIcon from "../../../images/svg/arrowCircleUpAction.svg";
import arrowDownIcon from "../../../images/svg/arrowCircleDownAction.svg";
import ViewCard from './ViewCard';

const ViewsBoxDocumentDetailModal = () => {
    const [isArrowUp, setIsArrowUp] = useState(true);

    const toggleArrowIcon = () => {
        setIsArrowUp((prevValue) => !prevValue);
    };

    const container = {
        maxWidth: "100%",
        backgroundColor: "var(--white)",
        margin: "0 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderBottom: "1px solid var(--grey-0)",
        padding: "20px 0"
    };

    const styleTitle = {
        margin: "0",
        fontSize: "17px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleHeaderList = {
        height: "73px",
        borderRadius: "10px 10px 0 0",
        border: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px 20px 20px 32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px"
    };

    const styleIndicatorBox = {
        gap: "10px",
        display: "flex",
        alignItems: "center"
    };

    const styleIndicator = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        fontWeight: 500,
    };

    return (
        <div style={container}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }} onClick={toggleArrowIcon}>
                <img src={isArrowUp ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                <h3 style={styleTitle}>Visualizações</h3>
            </div>
            {!isArrowUp && (
                <>
                    <div style={styleHeaderList}>
                        <div style={{ ...styleIndicatorBox, width: "407px" }}>
                            <h4 style={styleIndicator}>Quem leu o documento</h4>
                        </div>
                        <div style={{ ...styleIndicatorBox, width: "374px" }}>
                            <h4 style={styleIndicator}>Quando</h4>
                        </div>
                    </div>
                    <ul style={{ margin: "0", padding: "0" }}>
                        <ViewCard
                            person="Leandro Hass Thomaz"
                            date="11/05/2023"
                        />
                    </ul>
                </>
            )}
        </div>
    );
};

export default ViewsBoxDocumentDetailModal;
