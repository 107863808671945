import React from "react";

const FeedbackGeneralBox = ({ number }) => {
    let backgroundColor, textColor, feedbackText;

    if (number >= 1 && number <= 4) {
        backgroundColor = "#f4aaaa";
        textColor = "#ea0000";
        feedbackText = "Detratores";
    } else if (number >= 5 && number <= 8) {
        backgroundColor = "#FFF7AA";
        textColor = "#F9CF4A";
        feedbackText = "Neutros";
    } else if (number >= 9 && number <= 10) {
        backgroundColor = "#E0FFF0";
        textColor = "#00B386";
        feedbackText = "Promotores";
    }

    const styleContainer = {
        width: "140px",
        height: "32px",
        borderRadius: "60px",
        backgroundColor: backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const styleText = {
        color: textColor,
        margin: 0,
    };

    return (
        <div style={styleContainer}>
            <p style={styleText}>{number} - {feedbackText}</p>
        </div>
    );
};

export default FeedbackGeneralBox;
