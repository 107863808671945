import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ThowColumLayoutLogin from "../../../layouts/ThowColumLayoutLogin";
import Input from "../components/Input";
import Button from "../components/Button";
import companyBackground from "../../../images/png/backgroundLogin.png";
import companyIcon from "../../../images/svg/companyIcon.svg";
import api from "../../../services/api";
import { useContext } from "react";
import { AuthContext } from "../../../context/Auth/AuthContext";

const Landing = () => {
  const { sessionLogin } = useContext(AuthContext);

  const [mail, setMail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  /* MESSAGE ERROS */

  const [mailError, setMailError] = useState();
  const [paswordError, setPasswordError] = useState();

  /* BTN STATE */

  const [btnState, setBtnState] = useState();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmitSession = async () => {
    if ((!mail, !password)) {
      return toast.error("Preencha os campos de e-mail e senha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!validateEmail(mail)) {
      return toast.error("Email inválido", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      email: mail,
      password: password,
    };

    sessionLogin(data);
  };

  useEffect(() => {
    localStorage.removeItem("@XPM_CLIENT_TOKEN:");
  }, []);

  const container = {
    width: "418px",
  };

  const boxFooterButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const image = {
    height: "100%",
    width: "100%",
  };

  const containerColum2 = {
    display: "flex",
    marginTop: "125px",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    overflow: "hidden",
  };

  return (
    <ThowColumLayoutLogin
      page={"Landing"}
      colum1={
        <div style={image}>
          <img
            src={companyBackground}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      }
      colum2={
        <div style={containerColum2}>
          <div style={container}>
            <div
              style={{
                width: "100%",
              }}
            >
              <img src={companyIcon} />
            </div>

            <h2 className="textPrimaryLanding">Dados de acesso</h2>

            <Input
              name="E-mail"
              error={mailError}
              onChange={(e) => setMail(e.target.value)}
              placeholder="Insira seu e-mail"
              type={"text"}
            />
            <Input
              name="Senha"
              error={paswordError}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Insira sua senha"
              type={"password"}
            />

            <div style={boxFooterButton}>
              <Button
                width="100%"
                height="48px"
                background="var(--color-primary)"
                borderRadius="60px"
                marginTop="0"
                name="Entrar"
                onClick={onSubmitSession}
                border="none"
                disabled={btnState}
              />
            </div>
          </div>
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default Landing;
