import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const NpsResearchContext = createContext({});

export const NpsResearchValidation = ({ children }) => {
  const navigate = useNavigate();

  return (
    <>
      <NpsResearchContext.Provider
        value={{}}
      >
        {children}
      </NpsResearchContext.Provider>
    </>
  );
};
