import React, { useState } from 'react';

const CardValuePorcentage = ({ icon, title, value, afterIcon, result, type }) => {
  const [isDivVisible, setDivVisibility] = useState(false);

  const stylesCardContainerNormal = {
    position: 'relative',
    width: '50%',
    height: 110,
    border: '1px solid #d7d7d7',
    borderRadius: '10px',
    padding: '17px',
    display: 'flex',
    flexDirection: 'column',
    gap: '17px',
    backgroundColor: '#fff',      
  };

  const stylesCardContainerResult = {
    position: 'relative',
    width: '100%',
    height: 110,
    border: '1px solid #9966CC',
    borderRadius: '10px',
    padding: '17px',
    display: 'flex',
    flexDirection: 'column',
    gap: '17px',
    backgroundColor: '#FCF9FF',
    color: '#9966CC'
  };

  const getContainerStyles = () => {
    return result ? stylesCardContainerResult : stylesCardContainerNormal;
  };

  const helperContainer = {
    position: 'absolute',
    top: 40, // Posiciona a div abaixo do afterIcon
    left: 125,
    backgroundColor: '#F9F3FF',
    padding: '0px 10px',
    border: '1px solid #9966CC',
    borderRadius: 10,
    color: '#9966CC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1,
  };

  const helperText = {
    width: 150,
    borderBottom: '1px solid #9966CC',
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: 11,
  };

  const helperExemple = {
    fontWeight: 500,
    fontSize: 12
  };

  return (
    <div
      style={getContainerStyles()}
    >
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        {
          icon ? (<img src={icon} />) : null
        }
        <p className="textTitleTotalCard">{title}</p>
        {afterIcon && (
          <img
            src={afterIcon}
            alt="afterIcon"
            onMouseEnter={() => setDivVisibility(true)}
            onMouseLeave={() => setDivVisibility(false)}
          />
        )}
      </div>

      {isDivVisible && (
        <div
          style={helperContainer}
        >
          <div style={helperText}>
            <p>Valor Fixo</p>
            <p style={helperExemple}>R$ 100.000,00</p>
          </div>
          <div style={helperText}>
            <p>Filial Fiscal - Virtual</p>
            <p style={helperExemple}>R$ 5.000,00</p>
          </div>
          <div style={helperText}>
            <p>Seguro Armazenagem</p>
            <p style={helperExemple}>R$ 40.000,00</p>
          </div>
        </div>
      )}
      
      <div>
          <p className="textNameCompanyCard">
            {
              value ? (
               `${value * 100}%`
              ) : '0%'
            }
          </p>
        </div>

    </div>
  );
};

export default CardValuePorcentage;
