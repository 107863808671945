import { useEffect } from "react";
import CardStorage from "./CardStorage";
import StorageLineDetailsCardTable from "./StorageLineDetailsCardTable";

const CardTablesStorage = ({ storage }) => {
  /* STYLES */
  const container = {
    height: "100%",
    width: "100%",
    marginTop: "29px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: "200px",
    gap: "25px",
  };

  return (
    <div style={container}>
      {storage.length &&
        storage.map((item) => {
          if (!item.total) {
            return (
              <CardStorage
                titleCard={item.type_structure}
                value={item.group}
                unit={item.unit}
                typeCount={item.type_count}
                valueCount={item.value_count}
                priceCard={item.sum}
              />
            );
          }
        })}
    </div>
  );
};

export default CardTablesStorage;
