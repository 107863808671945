import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import ClientsSelect from "./ClientsSelect";
import BoxParameterSelect from "./BoxParameterSelect";
import { DashContext } from "../../../context/Dashboard/DashContext";
import closeIcon from "../../../images/svg/closeIcon.svg";
const moment = require("moment-timezone");

const ModalGenerality = ({ closeModal }) => {
  const { createGeneralities } = useContext(DashContext);
  const [document, setDocument] = useState("");
  const [quantity, setQuantity] = useState("");
  const [date, setDate] = useState("");
  const [recurrence, setRecurrence] = useState("");
  const [justification, setJustification] = useState("");
  const [generalitiesParameterId, setGeneralitiesParameterId] = useState("");

  /* STYLES */
  const container = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: "690px",
    boxShadow: "0px 0px 13px -3px #00000033",
    border: "1px solid transparent",
    borderRadius: "20px",
  };

  const headerOption = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    padding: "15px 18px 18px",
  };

  const content = {
    width: "100%",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  };

  const boxTextDelete = {
    display: "flex",
    alignItems: "center",
    padding: "18px",
    flexDirection: "column",
  };

  const mountData = () => {
    const data = {
      document: document,
      quantity: quantity,
      date: date,
      // recurrence: recurrence,
      recurrence: 'withoutRecurrence',
      justification: justification,
      client_cnpj: localStorage.getItem("@XPM_CLIENT_COMPANY:"),
      generalities_parameter_id: generalitiesParameterId,
    };

    createGeneralities(data, closeModal);
  };

  useEffect(() => {
    const saoPauloTime = moment().tz("America/Sao_Paulo");
    setDate(saoPauloTime.format("YYYY-MM-DD"));
  }, []);

  return (
    <div className="modalEdit" style={container}>
      <div style={headerOption}>
        <div
          style={{
            display: "flex",
            gap: "27px",
            alignItems: "center",
          }}
        >
          <div
            onClick={closeModal}
            style={{
              cursor: "pointer",
            }}
          >
            <img src={closeIcon} />
          </div>
          <p className="textModalTitleOption">Criar generalidade</p>
        </div>
        <Button
          width="181px"
          height="33px"
          background="var(--color-primary)"
          borderRadius="60px"
          marginTop="0"
          name="Criar generalidade"
          onClick={mountData}
          border="none"
        />
      </div>
      <div style={boxTextDelete}>
        {/* <ClientsSelect
          title={"Cliente"}
          width={"660px"}
          borderRadius={"8px"}
          paddingTop={"0px"}
        /> */}
        <div style={content}>
          <h3 className="textTitleInfo">Informações da generalidade</h3>
          <div style={{ display: "flex", gap: "20px" }}>
            <BoxParameterSelect
              valueInput={"."}
              value={"."}
              onChange={(e) => setGeneralitiesParameterId(e.target.value)}
              wait={true}
              width={"100%"}
              type={"titleGenerality"}
            />
            {/* <BoxParameterSelect
              valueInput={"."}
              value={"."}
              onChange={(e) => setRecurrence(e.target.value)}
              wait={true}
              width={"100%"}
              type={"recurrences"}
              options={"analyst"}
            /> */}
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <BoxParameterSelect
              width={"100%"}
              onChange={(e) => setDocument(e.target.value)}
              type={"documentation"}
            />
            <BoxParameterSelect
              width={"100%"}
              valueInput={date}
              onChange={(e) => setDate(e.target.value)}
              type={"dateGenerality"}
            />
            <BoxParameterSelect
              width={"100%"}
              onChange={(e) => setQuantity(e.target.value)}
              type={"qtdGenerality"}
            />
          </div>
          <BoxParameterSelect
            width={"650px"}
            height={"110px"}
            onChange={(e) => setJustification(e.target.value)}
            type={"justification"}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalGenerality;
