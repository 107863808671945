import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import moment from "moment";

export const SchedulesContext = createContext({});

export const SchedulesValidation = ({ children }) => {
    const [selectedDate, setSelectedDate] = useState(undefined);

    const [selectedBranches, setSelectedBranches] = useState("");
    const [selectedBranchByForm, setSelectedBranchByForm] = useState("");
    const [branchs, setBranchs] = useState([]);

    const [schedulesByBranch, setSchedulesByBranch] = useState([]);

    const [locations, setLocations] = useState([]);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectedLocationByForm, setSelectedLocationByForm] = useState("");

    const [nameByForm, setNameByForm] = useState("");
    const [vehicle_plateByForm, setVehicle_plateByForm] = useState("");
    const [model_vehicleByForm, setModel_vehicleByForm] = useState("");

    const [cesvByForm, setCesvByForm] = useState("");

    const [dateByForm, setDateByForm] = useState("");
    const [anexoByForm, setAnexoByForm] = useState("");
    const [statusByForm, setStatusByForm] = useState("");
    const [hourByForm, setHourByForm] = useState("");

    const [receivedSchedules, setReceivedSchedules] = useState([]);
    const [deliveredSchedules, setDeliveredSchedules] = useState([]);

    const getBrachs = async () => {
        try {
            const response = await api.get("/schedules/branches");

            setBranchs(response.data);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const getLocationByBranch = async (branchId, selectedDate) => {
        try {
            const parts = selectedDate.split("-");
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

            const response = await api.get(
                `/schedules/locals/${branchId}/${formattedDate}`
            );

            setLocations(response.data.locals);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const getFetchedSchedules = async (branchId, selectedDate) => {
        try {
            const parts = selectedDate.split("-");
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

            const response = await api.get(
                `/schedules/all/received/delivered/${branchId}/${formattedDate}`
            );

            setReceivedSchedules(response.data.received);
            setDeliveredSchedules(response.data.delivered);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const getHoursDisponibilitByLocation = async (localId, date) => {
        try {
            const parts = date.split("-");
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

            const response = await api.get(
                `/schedules/locals/info/${localId}/${formattedDate}`
            );

            setAvailableTimes(response.data.availableTimes);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const createSchedule = async () => {
        try {
            const parts = selectedDate.split("-");
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

            const data = {
                branchId: selectedBranchByForm,
                localId: selectedLocationByForm,
                transporterName: nameByForm,
                vehiclePlate: vehicle_plateByForm,
                vehicleModel: model_vehicleByForm,
                cesv: cesvByForm,
                startTime: `${formattedDate} ${hourByForm}`,
                attachments: [],
            };

            const response = await api.post("/schedules", data);

            toast.success(response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setSelectedBranchByForm(undefined);
            setSelectedLocationByForm(undefined);
            setNameByForm(undefined);
            setVehicle_plateByForm(undefined);
            setModel_vehicleByForm(undefined);
            setCesvByForm(undefined);
            setHourByForm(undefined);
            setSelectedDate(undefined);

            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const deleteSchedule = async (scheduleId, cancelReason) => {
        try {
            const response = await api.post(`/schedules/delete/${scheduleId}`, {
                cancelReason,
            });

            toast.success(response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            const schedules = locations.map((location) => {
                location.schedules.map((schedule) => {
                    if (schedule.id === scheduleId) {
                        schedule.status = "cancelado";
                    }

                    return schedule;
                });

                return location;
            });

            setLocations(schedules);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const editSchedule = async (scheduleId, branchId, localId) => {
        try {
            const parts = selectedDate.split("-");
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

            const data = {
                branchId: branchId,
                localId: localId,
                transporterName: nameByForm,
                vehiclePlate: vehicle_plateByForm,
                vehicleModel: model_vehicleByForm,
                cesv: cesvByForm,
                startTime: `${formattedDate} ${hourByForm}`,
                attachments: [],
            };

            const response = await api.patch(`/schedules/${scheduleId}`, data);

            toast.success(response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setSelectedBranchByForm(undefined);
            setSelectedLocationByForm(undefined);
            setNameByForm(undefined);
            setVehicle_plateByForm(undefined);
            setModel_vehicleByForm(undefined);
            setCesvByForm(undefined);
            setHourByForm(undefined);
            setSelectedDate(undefined);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        getLocationByBranch(selectedBranches, selectedDate);

        if (selectedBranches) 
            getFetchedSchedules(selectedBranches, selectedDate);
    }, [selectedBranches]);

    useEffect(() => {
        getLocationByBranch(selectedBranchByForm, selectedDate);
    }, [selectedBranchByForm]);

    useEffect(() => {
        if (selectedLocationByForm && selectedDate) {
            getHoursDisponibilitByLocation(
                selectedLocationByForm,
                selectedDate
            );
        }
    }, [selectedLocationByForm]);

    useEffect(() => {
        const dateNow = moment();
        const dateFormact = dateNow.format("DD-MM-YYYY");
        setSelectedDate(dateFormact);
    }, []);

    return (
        <SchedulesContext.Provider
            value={{
                branchs,
                getBrachs,
                selectedBranches,
                setSelectedBranches,
                selectedBranchByForm,
                setSelectedBranchByForm,
                locations,
                selectedLocationByForm,
                setSelectedLocationByForm,
                nameByForm,
                setNameByForm,
                vehicle_plateByForm,
                setVehicle_plateByForm,
                model_vehicleByForm,
                setModel_vehicleByForm,
                cesvByForm,
                setCesvByForm,
                dateByForm,
                setDateByForm,
                anexoByForm,
                setAnexoByForm,
                statusByForm,
                setStatusByForm,
                hourByForm,
                setHourByForm,
                availableTimes,
                createSchedule,
                setSelectedDate,
                schedulesByBranch,
                deleteSchedule,
                editSchedule,
                receivedSchedules,
                setReceivedSchedules,
                deliveredSchedules,
                setDeliveredSchedules,
            }}
        >
            {children}
        </SchedulesContext.Provider>
    );
};
