import React, { useState } from 'react';
import arrowUpIcon from "../../../images/svg/arrowCircleUpAction.svg";
import arrowDownIcon from "../../../images/svg/arrowCircleDownAction.svg";
import selectedCheckboxIcon from "../../../images/svg/selectedCheckbox.svg";
import unselectedCheckboxIcon from "../../../images/svg/unselectedCheckbox.svg";
import Input from './Input';

const PermissionsBoxCreateInternalDocumentModal = () => {
    const [isArrowUp, setIsArrowUp] = useState(true);
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);

    const toggleArrowIcon = () => {
        setIsArrowUp((prevValue) => !prevValue);
    };

    const toggleCheckbox1 = () => {
        setIsChecked1((prevValue) => !prevValue);
    };

    const toggleCheckbox2 = () => {
        setIsChecked2((prevValue) => !prevValue);
    };

    const toggleCheckbox3 = () => {
        setIsChecked3((prevValue) => !prevValue);
    };

    const container = {
        width: "100%",
        borderBottom: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    };

    const styleTitle = {
        margin: "0",
        fontSize: "17px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleInfosBox = {
        display: "flex",
        alignItems: "center",
        gap: "20px",
    };

    const styleTextInfos = {
        fontSize: "14px",
        color: "var(--black)",
    };

    return (
        <div style={container}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }} onClick={toggleArrowIcon}>
                <img src={isArrowUp ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                <h3 style={styleTitle}>Permissões / Cópias distribuídas</h3>
            </div>
            {!isArrowUp && (
                <div style={{ margin: "20px 0 0 27px" }}>
                    <div style={styleInfosBox} onClick={toggleCheckbox1}>
                        <img src={isChecked1 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
                        <p style={styleTextInfos}>Ao publicar, solicitar revisão para os documentos internos associados</p>
                    </div>
                    <div style={styleInfosBox} onClick={toggleCheckbox2}>
                        <img src={isChecked2 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
                        <p style={styleTextInfos}>Avisar outras pessoas por email quando publicar</p>
                        <Input
                            width="400px"
                            marginBottom="0"
                        />
                    </div>
                    <div style={styleInfosBox} onClick={toggleCheckbox3}>
                        <img src={isChecked3 ? selectedCheckboxIcon : unselectedCheckboxIcon} />
                        <p style={styleTextInfos}>Restringir acesso, impressões e download</p>
                    </div>
                    <div style={{ ...styleInfosBox, justifyContent: "space-between", paddingTop: "20px" }}>
                        <Input
                            name="Quem pode consultar"
                            width="245px"
                            marginBottom="0"
                        />
                        <Input
                            name="Quem pode imprimir"
                            width="245px"
                            marginBottom="0"
                        />
                        <Input
                            name="Quem pode baixar"
                            width="245px"
                            marginBottom="0"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PermissionsBoxCreateInternalDocumentModal;
