import ThowColumLayout from "../../../layouts/ThowColumLayout";
import ContainerWigth from "../components/ContainerWigth";
import HeaderManager from "../components/HeaderManager";
import SearchMoreFilter from "../components/SearchMoreFilter";

import Sidebar from "../../../components/Sidebar";

const Productivity = () => {
  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };

  return (
    <ThowColumLayout
      page={"Company"}
      colum1={<Sidebar page="Productivity" />}
      colum2={
        <div style={container}>
          <HeaderManager name="Produtividade" adm={true} />
          <ContainerWigth>
            <SearchMoreFilter />
          </ContainerWigth>
        </div>
      }
    ></ThowColumLayout>
  );
};

export default Productivity;
