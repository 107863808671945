import React from "react";
import DocumentBox from "./DocumentBox";
import Button from "./Button";

const ModalDetails = ({
  item,
  closeModal,
  closeIcon,
}) => {

  const container = {
    display: "flex",
    flexDirection: "column",
    background: "var(--white)",
    border: "1px solid var(--grey-0)",
    borderRadius: "20px",
    width: "881px",
    height: "704px",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.18)",
  };

  const stylesHeaderMenu = {
    alignItems: "center",
    boxSizing: "border-box",
    borderBottom: "1px solid var(--grey-0)",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 15px",
    width: "100%",
  };

  const stylesCloseBtn = {
    background: "none",
    border: "none",
    marginRight: "16px",
  };

  const styleTitle = {
    color: "var(--black)",
    fontSize: "18px",
    fontWeight: 500,
  };

  const stylePendingDocumentBox = {
    height: "68px",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    borderBottom: "1px solid var(--grey-0)",
  };

  // Estilização provisória, substituir por um componente quando souber o padrão de texto e cor para o valor recebido.
  const provisorio = {
    backgroundColor: "#FFF7AA",
    color: "#C6B200",
    fontSize: "10px",
    fontWeight: 500,
    borderRadius: "60px",
    padding: "10px 20px"
  };

  const styleContentBox = {
    padding: "0 20px",

    overflowY: "auto",
  };

  // Utilizei o wrap para o espaçamento para ficar similar ao modal ao protótipo do Figma, 
  // pois lá o espaço parecia ser de acordo com o tamanho da string do valor recebido, não com um valor fixo para cada chave e valor
  const styleContainerInfos = {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    padding: "20px 0",
    borderBottom: "1px solid var(--grey-0)",
  };

  const styleKeyValueBox = {
    gap: "10px",
  };

  const styleKey = {
    color: "var(--black)",
    fontSize: "14px",
    fontWeight: 500,
  };

  const styleValue = {
    color: "#6A6A6A",
    fontSize: "14px",
    fontWeight: 400,
  };

  const styleDocumentBox = {
    padding: "20px 0"
  };;

  const styleButtonBottomBox = {
    height: "83px",
    width: "100%",
    display: "flex",
    padding: "20px",
    borderTop: "1px solid var(--grey-0)",
    gap: "20px"
  }

  return (
    <section style={container}>
      <header style={stylesHeaderMenu}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            name="closeDeleteMenu"
            style={stylesCloseBtn}
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} alt="close.png" />
          </button>
          <p style={styleTitle}>Detalhes do documento</p>
        </div>
      </header>
      <div style={styleContentBox}>
        <div style={stylePendingDocumentBox}>
          <p style={styleTitle}>{item.document}</p>
          <div style={provisorio}>
            Etapa de consenso
          </div>
        </div>

        <div style={styleContainerInfos}>
          <div style={styleKeyValueBox}>
            <p style={styleKey}>Elaboração</p>
            <p style={styleValue}>{`${item.responsible} (${item.time})`}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Consenso</p>
            <p style={styleValue}>{"Leandro Hass Thomaz (Pendente)"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Aprovação</p>
            <p style={styleValue}>
              {"(Aguardando consenso) Leilton Santana"}
            </p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Processo</p>
            <p style={styleValue}>{"Adequação"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Unidade</p>
            <p style={styleValue}>{"XPM - Matriz"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Validade</p>
            <p style={styleValue}>{"Sem validade"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Avisar por e-mail</p>
            <p style={styleValue}>{"-"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Observações</p>
            <p style={styleValue}>{"Revisão iniciada"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Cadastro inicial</p>
            <p style={styleValue}>{"Leandro Hass Thomaz"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Cadastro em</p>
            <p style={styleValue}>{"14/07/2023"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Prazo de elaboração</p>
            <p style={styleValue}>{"13/08/2023"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Prazo de consenso</p>
            <p style={styleValue}>{"21/07/2023"}</p>
          </div>

          <div style={styleKeyValueBox}>
            <p style={styleKey}>Prazo de aprovação</p>
            <p style={styleValue}>{"7 dia(s)"}</p>
          </div>
        </div>
        <div style={styleDocumentBox}>
          <DocumentBox />
        </div>
      </div>

      <div style={styleButtonBottomBox}>

        <Button
          width="100%"
          height="40px"
          background="#E0FFF0"
          color="#00B386"
          borderRadius="60px"
          marginTop="0"
          name="Aprovar documento"
          border="none"
        />

        <Button
          width="100%"
          height="40px"
          background="#FFE1E1"
          color="#EA0000"
          borderRadius="60px"
          marginTop="0"
          name="Reprovar documento"
          border="none"
        />
      </div>
    </section>
  );
};

export default ModalDetails;
