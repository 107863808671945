import React, { useState } from 'react';
import arrowUpIcon from "../../../images/svg/arrowCircleUpAction.svg";
import arrowDownIcon from "../../../images/svg/arrowCircleDownAction.svg";
import DocumentCardToList from './DocumentCardToList';

const DocumentCard = ({ document }) => {
    const [isArrowUp, setIsArrowUp] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    const toggleArrowIcon = () => {
        setIsArrowUp((prevValue) => !prevValue);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const borderHoverStyle = {
        borderColor: isHovered ? 'var(--color-primary)' : 'var(--grey-0)',
    };

    const container = {
        width: "999px",
        height: "73px",
        border: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "0.8s",
        ...borderHoverStyle
    };

    const styleValue = {
        margin: "0",
        fontSize: "14px",
        color: "var(--black)",
        fontWeight: 400,
        transition: "0.8s"
    };

    return (
        <>
            <li style={container} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div style={{ width: "45px", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={toggleArrowIcon}>
                    {document.attachments.length > 0 && (
                        <img src={isArrowUp ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                    )}
                </div>
                <div style={{ width: "266px" }}>
                    <p style={styleValue}>{document.title}</p>
                </div>
                <div style={{ width: "166px" }}>
                    <p style={styleValue}>{document.distribution}</p>
                </div>
                <div style={{ width: "166px" }}>
                    <p style={styleValue}>{document.location}</p>
                </div>
                <div style={{ width: "166px" }}>
                    <p style={styleValue}>{document.responsible}</p>
                </div>
                <div style={{ width: "166px" }}>
                    <p style={styleValue}>{document.validity}</p>
                </div>
            </li>
            {!isArrowUp && (
                <ul style={{ margin: "0", padding: "0" }}>
                    <DocumentCardToList attachments={document.attachments} />
                </ul>
            )}
        </>
    );
};

export default DocumentCard;
