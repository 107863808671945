import React, { useContext, useState } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../../../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import CardParameters from "../components/CardParameters";
import CardDetailsInvoicing from "../components/CardDetailsInvoicing";
import { useNavigate } from "react-router-dom";
import ClientsSelect from "../components/ClientsSelect";
import SelectSessionButtons from "../components/SelectSessionButtons";
import ContainerContractualParameter from "../components/ContainerContractualParameter";
import ContentDashboard from "../components/ContentDashboard";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import ContactedVSAccomplished from "../components/ContactedVSAccomplished";

const InitialDashboard = () => {
  const { update, setUpdate } = useContext(GraphsAndParametersContext);

  const navigate = useNavigate();
  const [btnSelect, setBtnSelect] = useState("KPIS");

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };

  const boxCard = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };

  // const buttons = [
  //   "KPIS",
  //   "Entrada",
  //   "Saída",
  //   "Urgente",
  //   "Armazenagem",
  //   "Qualidade",
  //   "Valor do estoque",
  // ];

  const buttons = [
    "KPIS",
    "Entrada",
    "Saída",
    "Urgente",
    "Contratada vs Realizada",
  ];

  return (
    <ThowColumLayout
      page={"Company"}
      colum1={<Sidebar page="Initial" />}
      colum2={
        <div style={container}>
          <HeaderDashboard name="Dashboard" adm={true} />
          <div style={{ margin: "0 auto" }} className="containerBox">
            <SelectSessionButtons
              setBtnSelect={setBtnSelect}
              btnSelect={btnSelect}
              items={buttons}
              marginTop={24}
            />
            <ContentDashboard btnState={btnSelect} />
            {btnSelect === "Contratada vs Realizada" ? (
              <ContactedVSAccomplished type={btnSelect} />
            ) : (
              <></>
            )}
          </div>
        </div>
      }
    ></ThowColumLayout>
  );
};

export default InitialDashboard;
